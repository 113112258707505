import type { EnvironmentResource, ProjectResource, Repository } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { KubernetesLiveObjectStatus } from "~/areas/projects/components/Observability/KubernetesLiveStatus";
import { ProjectPaperLayout } from "~/areas/projects/components/ProjectPaperLayout";
import FeatureToggleVisibility from "~/components/FeatureToggle/New/FeatureToggleVisibility";
import { NotFound } from "~/components/NotFound/NotFound";
export async function liveStatusPageLoader(repository: Repository, environmentId: string): Promise<LoaderData> {
    return {
        environment: await repository.Environments.get(environmentId),
    };
}
interface LoaderData {
    environment: EnvironmentResource;
}
type LiveStatusPageProps = {
    project: ProjectResource;
    environmentId: string;
    tenantIdOrUntenanted: string;
    loaderData: LoaderData;
};
interface LiveStatusPageInternalProps extends LiveStatusPageProps {
    project: ProjectResource;
}
export const LiveStatusPageInternal: React.FC<LiveStatusPageInternalProps> = (props) => {
    // TODO #project-md-k8s-live-object-status
    // Replace with actual implementation
    return (<FeatureToggleVisibility toggle="KubernetesLiveObjectStatusFeatureToggle" disabledContent={<NotFound />}>
            <ProjectPaperLayout title={props.project.Name + " " + props.loaderData.environment.Name + " Live Status"} enableLessIntrusiveLoadingIndicator={false} fullWidth={true}>
                <KubernetesLiveObjectStatus projectId={props.project.Id} environmentId={props.environmentId} tenantIdOrUntenanted={props.tenantIdOrUntenanted}/>
            </ProjectPaperLayout>
        </FeatureToggleVisibility>);
};
LiveStatusPageInternal.displayName = "LiveStatusPageInternal"
export function LiveStatusPage(props: LiveStatusPageProps) {
    return <LiveStatusPageInternal {...props}/>;
}
