import { css } from "@emotion/css";
import { CircularProgress, DeploymentIcon, Popover, TenantsIcon } from "@octopusdeploy/design-system-components";
import { letterSpacing, lineHeight, themeTokens, space, text } from "@octopusdeploy/design-system-tokens";
import type { ProjectsPageDeployment, DetailedDashboardItemResource } from "@octopusdeploy/octopus-server-client";
import React from "react";
import TaskStatusIcon from "~/areas/projects/components/TaskStatusIcon/index";
interface DeploymentTaskPopoverProps {
    anchorEl: HTMLElement | null;
    deployment: DetailedDashboardItemResource | ProjectsPageDeployment;
    open: boolean;
    onClose: () => void;
    environment: string;
}
export function DeploymentTaskPopover({ anchorEl, open, deployment, onClose, environment }: DeploymentTaskPopoverProps) {
    return (<Popover placement={"right-start"} anchorEl={anchorEl} open={open} onClose={onClose}>
            <DeploymentTaskPopoverContent deployment={deployment} environment={environment}/>
        </Popover>);
}
const deploymentTaskPopoverContentStyles = css({ padding: space["24"], minWidth: "360px" });
interface DeploymentTaskPopoverContentProps {
    deployment: DetailedDashboardItemResource | ProjectsPageDeployment;
    environment: string;
}
function DeploymentTaskPopoverContent({ deployment, environment }: DeploymentTaskPopoverContentProps) {
    return (<div className={deploymentTaskPopoverContentStyles}>
            <SectionTitle icon={<DeploymentIcon />} label={"Recently Deployed to..."}/>
            <DeploymentTaskSectionDetail>
                <TaskDetail deployment={deployment} environment={environment}/>
            </DeploymentTaskSectionDetail>
        </div>);
}
const loadingPopoverContentStyles = css({
    padding: space["24"],
    minWidth: "360px",
    display: "flex",
    justifyContent: "center",
});
function LoadingPopoverContent() {
    return (<div className={loadingPopoverContentStyles}>
            <CircularProgress size={"large"}/>
        </div>);
}
interface TaskDetailProps {
    deployment: DetailedDashboardItemResource | ProjectsPageDeployment;
    environment: string;
}
const taskDetailStyles = css({
    display: "flex",
    columnGap: space["12"],
    alignItems: "center",
});
const taskDetailTextStyles = css({
    flex: 1,
    color: themeTokens.color.text.primary,
    font: text.regular.bold.small,
    lineHeight: lineHeight.xSmall,
    letterSpacing: letterSpacing.wide,
});
const taskDetailIconStyles = css({
    color: themeTokens.color.icon.tertiary,
});
function TaskDetail({ deployment, environment }: TaskDetailProps) {
    const deployStatusText = deployment.IsCompleted ? "Deployed" : "Deploy";
    const isTenanted = Boolean(deployment.TenantId);
    return (<div className={taskDetailStyles}>
            <TaskStatusIcon item={deployment}/>
            <span className={taskDetailTextStyles}>
                {deployStatusText} to {environment}
            </span>
            {isTenanted && (<span className={taskDetailIconStyles}>
                    <TenantsIcon />
                </span>)}
        </div>);
}
interface DeploymentTaskSectionDetailProps {
    children: React.ReactNode;
}
function DeploymentTaskSectionDetail({ children }: DeploymentTaskSectionDetailProps) {
    return <div>{children}</div>;
}
interface SectionTitleProps {
    icon: React.ReactNode;
    label: string;
}
const sectionTitleStyles = css({
    display: "flex",
    color: themeTokens.color.text.secondary,
    alignItems: "center",
    columnGap: space["4"],
});
function SectionTitle({ label, icon }: SectionTitleProps) {
    return (<div className={sectionTitleStyles}>
            <span>{icon}</span>
            <h1 className={sectionTitleTextStyles}>{label}</h1>
        </div>);
}
const sectionTitleTextStyles = css({
    color: themeTokens.color.text.secondary,
    font: text.regular.bold.medium,
    lineHeight: lineHeight.xSmall,
    letterSpacing: letterSpacing.wide,
});
