/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { RadioButton, RadioButtonGroup } from "@octopusdeploy/design-system-components";
import type { KubernetesRawYamlProperties } from "@octopusdeploy/legacy-action-properties";
import { ActionExecutionLocation, GetNamedPackageReferences, GetPrimaryPackageReference, InitialisePrimaryGitDependency, InitialisePrimaryPackageReference, RemovePrimaryGitDependency, RemovePrimaryPackageReference, SetPrimaryPackageReference, } from "@octopusdeploy/octopus-server-client";
import * as _ from "lodash";
import { isEqual } from "lodash";
import * as React from "react";
import { useOptionalProcessContext } from "~/areas/projects/components/Process/Contexts/ProcessContext";
import { useGitCredentialsFromContext, useRefreshGitCredentialsFromContext } from "~/areas/projects/components/Process/Contexts/ProcessGitCredentialsContextProvider";
import { TargetRoles } from "~/areas/projects/components/Process/types";
import { buildRelatedTriggerDependencies } from "~/areas/projects/components/Triggers/ProcessCallouts/triggerPackageRelations";
import { useOptionalProjectContext } from "~/areas/projects/context";
import type { ActionSummaryProps } from "~/components/Actions/actionSummaryProps";
import { kubernetesMixedExecutionLocationConfig } from "~/components/Actions/kubernetes/kubernetesMixedExecutionLocationConfig";
import KubernetesStatusCheckComponent, { InitialStatusCheckWithTimeoutProperties } from "~/components/Actions/kubernetes/kubernetesStatusCheckComponent";
import type { ActionEditProps } from "~/components/Actions/pluginRegistry";
import pluginRegistry from "~/components/Actions/pluginRegistry";
import type { IScriptActionContext } from "~/components/Actions/script/ScriptActionContext";
import { ScriptActionContext } from "~/components/Actions/script/ScriptActionContext";
import { BaseComponent } from "~/components/BaseComponent/BaseComponent";
import { CodeEditor } from "~/components/CodeEditor/CodeEditor";
import StructuredConfigurationVariablesEdit from "~/components/Features/structuredConfigurationVariables/structuredConfigurationVariables";
import { GitRepositorySourceSelector, isProjectRepositoryGitSourceSupported } from "~/components/GitRepositorySource/GitRepositorySourceSelector";
import PackageSelector from "~/components/PackageSelector/PackageSelector";
import { codeEditorVariablesList } from "~/utils/ScriptIntellisense/scriptIntellisense";
import { toBoolString } from "~/utils/fieldConverters";
import { useFeedsFromContext, useRefreshFeedsFromContext } from "../../../areas/projects/components/Process/Contexts/ProcessFeedsContextProvider";
import CommonSummaryHelper from "../../../utils/CommonSummaryHelper";
import { TextFormat } from "../../CodeEditor/CodeEditor";
import ExternalLink from "../../Navigation/ExternalLink";
import { FormSectionHeading, Note, required, Summary } from "../../form";
import ExpandableFormSection, { CardFill } from "../../form/Sections/ExpandableFormSection";
import { VariableLookupText } from "../../form/VariableLookupText";
import type { ActionWithDirtyState, ActionWithFeeds, ActionWithGitRepositorySource } from "../commonActionHelpers";
import { ContainerImageReferenceList } from "../packageReferences";
import type { ScriptPackageProperties } from "../script/ScriptPackageReferenceDialog";
import { getKubernetesTargetDiscoveryCloudProviders } from "./getKubernetesTargetDiscoveryCloudProviders";
import KubernetesNamespaceFormSection from "./kubernetesNamespaceFormSection";
import { ServerSideApplyFormSection } from "./serverSideApplyFormSection";
interface KubernetesDeployRawYamlActionEditState {
    serverNames: string[];
}
class KubernetesDeployRawYamlActionSummary extends BaseComponent<ActionSummaryProps, never> {
    constructor(props: any) {
        super(props);
    }
    render() {
        return <div>Deploy raw YAML resources to Kubernetes</div>;
    }
    static displayName = "KubernetesDeployRawYamlActionSummary";
}
type KubernetesDeployRawYamlActionEditProps = ActionEditProps<KubernetesRawYamlProperties, ScriptPackageProperties>;
type KubernetesDeployRawYamlActionEditInternalProps = KubernetesDeployRawYamlActionEditProps & ActionWithFeeds & ActionWithGitRepositorySource & ActionWithDirtyState;
export class KubernetesDeployRawYamlActionEditInternal extends BaseComponent<KubernetesDeployRawYamlActionEditInternalProps, KubernetesDeployRawYamlActionEditState> {
    private currentVariablesKey?: string;
    static contextType = ScriptActionContext;
    context: IScriptActionContext | undefined;
    constructor(props: KubernetesDeployRawYamlActionEditInternalProps) {
        super(props);
        this.state = {
            serverNames: [],
        };
    }
    private supportsProjectRepository() {
        return isProjectRepositoryGitSourceSupported(this.props.project, this.props.processType);
    }
    private setDefaultScriptSource() {
        this.props.setProperties({ ["Octopus.Action.Script.ScriptSource"]: "GitRepository" });
        if (this.supportsProjectRepository()) {
            this.props.setProperties({ ["Octopus.Action.GitRepository.Source"]: "Project" });
        }
        else {
            this.props.setProperties({ ["Octopus.Action.GitRepository.Source"]: "External" });
            this.props.setGitDependencies?.(InitialisePrimaryGitDependency(this.props.gitDependencies), false);
        }
    }
    private setDefaultServerSideApply() {
        this.props.setProperties({ ["Octopus.Action.Kubernetes.ServerSideApply.Enabled"]: "True" });
        this.props.setProperties({ ["Octopus.Action.Kubernetes.ServerSideApply.ForceConflicts"]: "True" });
    }
    private onGitRepositoryFilePathFiltersChanged(val: string) {
        this.props.setProperties({ ["Octopus.Action.KubernetesContainers.CustomResourceYamlFileName"]: val });
    }
    async componentDidMount() {
        if (!this.props.properties["Octopus.Action.Script.ScriptSource"]) {
            this.setDefaultScriptSource();
        }
        // ensure that any existing YAML paths value is copied across to the FilePathFilters so
        // the git source can be filtered properly
        if (this.props.properties["Octopus.Action.GitRepository.Source"] === "Project" &&
            this.props.properties["Octopus.Action.KubernetesContainers.CustomResourceYamlFileName"] &&
            !this.props.properties["Octopus.Action.GitRepository.FilePathFilters"]) {
            this.props.setProperties({ ["Octopus.Action.GitRepository.FilePathFilters"]: this.props.properties["Octopus.Action.KubernetesContainers.CustomResourceYamlFileName"] });
        }
        if (this.props.properties["Octopus.Action.Kubernetes.ServerSideApply.Enabled"] === undefined) {
            this.setDefaultServerSideApply();
        }
        await this.reloadVariables();
    }
    async componentDidUpdate(prevProps: KubernetesDeployRawYamlActionEditProps) {
        if (this.context?.key !== this.currentVariablesKey) {
            await this.reloadVariables();
        }
    }
    async reloadVariables() {
        let variableNames: string[] = [];
        if (this.context) {
            this.currentVariablesKey = this.context.key;
            variableNames = await this.context.loadVariables();
        }
        this.setState({
            serverNames: variableNames,
        });
    }
    render() {
        const pkg = GetPrimaryPackageReference(this.props.packages);
        const packageReferences = GetNamedPackageReferences(this.props.packages);
        const localNames = _.concat(this.props.localNames ? this.props.localNames : [], this.packageVariableNames());
        const autoComplete = codeEditorVariablesList(this.state.serverNames, localNames ?? []);
        return (<div>
                <ExpandableFormSection errorKey="Octopus.Action.Script.ScriptSource|Octopus.Action.KubernetesContainers.CustomResourceYaml" isExpandedByDefault={this.props.expandedByDefault || this.props.properties["Octopus.Action.Script.ScriptSource"] === "Inline"} title="YAML Source" fillCardWidth={CardFill.FillRight} summary={this.summaryRawYaml()} help={"Select the source of the Kubernetes resources."}>
                    <Note>Kubernetes resources can be selected from a Git repository, package, or entered inline.</Note>
                    <RadioButtonGroup accessibleName="Script Source" value={this.props.properties["Octopus.Action.Script.ScriptSource"]} onChange={(val) => this.onChangeTemplateSource(val)} error={this.props.getFieldError("Octopus.Action.Script.ScriptSource")}>
                        <RadioButton accessibleName="Git Repository" value={"GitRepository"} label="Git Repository" isDefault={true}/>
                        <RadioButton accessibleName="Package" value={"Package"} label="Package"/>
                        <RadioButton accessibleName="Inline" value={"Inline"} label="Inline YAML"/>
                    </RadioButtonGroup>
                    {this.props.properties["Octopus.Action.Script.ScriptSource"] === "Inline" && (<div>
                            <br />
                            <CodeEditor value={this.props.properties["Octopus.Action.KubernetesContainers.CustomResourceYaml"]} language={TextFormat.YAML} localNames={localNames} allowFullScreen={true} showToolbar={true} showCopyButton={true} autoExpand={true} autoComplete={autoComplete} showInsertVariableButton={true} onChange={(value) => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.CustomResourceYaml"]: value })}/>
                            <br />
                        </div>)}
                </ExpandableFormSection>
                {this.props.properties["Octopus.Action.Script.ScriptSource"] === "Package" && (<div>
                        <ExpandableFormSection errorKey="Octopus.Action.Package.FeedId|Octopus.Action.Package.PackageId" isExpandedByDefault={this.props.expandedByDefault} title="Package" summary={CommonSummaryHelper.packageSummary(pkg, this.props.feeds)} help={"Choose the package that contains the Kubernetes resource YAML."}>
                            <PackageSelector packageId={pkg?.PackageId} feedId={pkg?.FeedId} packageVersion={pkg?.Version} onPackageIdChange={(packageId) => this.props.setPackages(SetPrimaryPackageReference({ PackageId: packageId }, this.props.packages))} onFeedIdChange={(feedId) => this.props.setPackages(SetPrimaryPackageReference({ FeedId: feedId }, this.props.packages))} onPackageVersionChange={(version) => this.props.setPackages(SetPrimaryPackageReference({ Version: version }, this.props.packages))} packageIdError={this.props.getFieldError("Octopus.Action.Package.PackageId")} feedIdError={this.props.getFieldError("Octopus.Action.Package.FeedId")} projectId={this.props.projectId} feeds={this.props.feeds} localNames={this.props.localNames} refreshFeeds={this.loadFeeds} relatedTriggersDependencies={buildRelatedTriggerDependencies(this.props.inputDependencies)} modelDirty={this.props.modelDirty}/>
                            <VariableLookupText localNames={this.props.localNames} value={this.props.properties["Octopus.Action.KubernetesContainers.CustomResourceYamlFileName"]} onChange={(x) => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.CustomResourceYamlFileName"]: x })} label={this.getPackagePathsLabel()} error={this.props.getFieldError("Octopus.Action.KubernetesContainers.CustomResourceYamlFileName")} validate={(p) => validatePaths(p, this.getPathsNotSetValidationMessage())} accessibleName={this.getPackagePathsAccessibleName()} multiline={true}/>
                            {this.getPackagePathsNote()}
                        </ExpandableFormSection>
                    </div>)}
                {this.props.properties["Octopus.Action.Script.ScriptSource"] === "GitRepository" && (<GitRepositorySourceSelector properties={this.props.properties} gitCredentials={this.props.gitCredentials} refreshGitCredentials={this.props.refreshGitCredentials} project={this.props.project} processType={this.props.processType} gitDependencies={this.props.gitDependencies} expandedByDefault={this.props.expandedByDefault} getFieldError={this.props.getFieldError} setProperties={this.props.setProperties} setGitDependencies={this.props.setGitDependencies} localNames={this.props.localNames} enableFilePathFilters={true} onFilePathFilterChanged={(filePaths) => this.onGitRepositoryFilePathFiltersChanged(filePaths)} filePathOverrides={{
                    accessibleName: "Paths to the YAML files in your Git Repository",
                    label: "Kubernetes YAML file paths",
                    help: "Provide the path(s) to the YAML file(s)",
                    validation: validatePaths,
                    summary: (filePaths) => (filePaths ? Summary.summary(filePaths) : Summary.placeholder("Provide a path to your YAML file(s)")),
                }} relatedTriggerDependencies={buildRelatedTriggerDependencies(this.props.inputDependencies)} showAvailableTriggerCallout={!this.props.isNew}/>)}
                <FormSectionHeading title="Additional Configuration Options"/>
                <KubernetesStatusCheckComponent jobsSupported={true} timeoutSupported={true} statusCheckSupported={true} showLegacyWait={false} properties={this.props.properties} packages={this.props.packages} plugin={this.props.plugin} errors={this.props.errors} busy={this.props.busy} expandedByDefault={this.props.expandedByDefault} getFieldError={this.props.getFieldError} setProperties={this.props.setProperties} setPackages={this.props.setPackages} doBusyTask={this.props.doBusyTask}/>
                <StructuredConfigurationVariablesEdit {...this.props} defaultTargetsGlob={"**/*.{yml,yaml}"} supportedDataTypes={["YAML"]} cannotSupplyTargets={true}/>
                <ExpandableFormSection title={"Referenced Packages"} isExpandedByDefault={this.props.expandedByDefault} errorKey="Octopus.Action.Script.Packages" summary={this.packageReferenceSummary()} help={"Add packages to be referenced by your scripts at execution-time"}>
                    <Note>
                        Learn more about <ExternalLink href="ScriptStepPackageReferences">package references</ExternalLink>.
                    </Note>
                    <ContainerImageReferenceList packageReferences={packageReferences} relatedTriggersDependencies={buildRelatedTriggerDependencies(this.props.inputDependencies)} {...this.props}/>
                </ExpandableFormSection>
                <KubernetesNamespaceFormSection namespace={this.props.properties["Octopus.Action.KubernetesContainers.Namespace"]} onChange={(ns) => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.Namespace"]: ns })}/>
                <ServerSideApplyFormSection enabled={this.props.properties["Octopus.Action.Kubernetes.ServerSideApply.Enabled"] === "True"} forceConflicts={this.props.properties["Octopus.Action.Kubernetes.ServerSideApply.ForceConflicts"] === "True"} onEnabledChange={(value) => this.props.setProperties({ ["Octopus.Action.Kubernetes.ServerSideApply.Enabled"]: toBoolString(value) })} onForceConflictsChange={(value) => this.props.setProperties({ ["Octopus.Action.Kubernetes.ServerSideApply.ForceConflicts"]: toBoolString(value) })}></ServerSideApplyFormSection>
            </div>);
    }
    getPackagePathsNote() {
        return (<Note>
                This field supports glob patterns syntax e.g. <code>my/folder/*</code> will include all files in a folder.
                <br />
                Separate multiple paths with new lines. See our <ExternalLink href={"glob-patterns-cheat-sheet"}>glob patterns cheat sheet</ExternalLink> for more info.
            </Note>);
    }
    getPathsNotSetValidationMessage() {
        return "Enter the paths to your YAML files";
    }
    getPackagePathsAccessibleName() {
        return "Paths to the YAML files in your Package";
    }
    getPackagePathsLabel() {
        return "Kubernetes YAML file paths";
    }
    packageVariableNames = (): string[] => _.flatten(GetNamedPackageReferences(this.props.packages).map((pkg) => [
        `Octopus.Action.Package[${pkg.Name}].PackageId`,
        `Octopus.Action.Package[${pkg.Name}].FeedId`,
        `Octopus.Action.Package[${pkg.Name}].PackageVersion`,
        `Octopus.Action.Package[${pkg.Name}].Path`,
    ]));
    packageReferenceSummary = () => {
        const namedPackageReferences = GetNamedPackageReferences(this.props.packages);
        if (namedPackageReferences.length === 0) {
            return Summary.placeholder("No additional packages referenced");
        }
        return Summary.summary(`${namedPackageReferences.length} package references`);
    };
    private summaryRawYaml = () => {
        const source = this.props.properties["Octopus.Action.Script.ScriptSource"];
        if (source === "GitRepository") {
            return Summary.default("File(s) from a Git repository");
        }
        if (source === "Inline") {
            return Summary.summary("Source code");
        }
        if (source === "Package") {
            return Summary.summary("File(s) inside a package");
        }
        return Summary.placeholder("Resource source not specified");
    };
    private onChangeTemplateSource(value: string) {
        this.props.setProperties({
            ["Octopus.Action.Script.ScriptSource"]: value,
        });
        if (value === "GitRepository") {
            if (this.supportsProjectRepository()) {
                this.props.setProperties({ ["Octopus.Action.GitRepository.Source"]: "Project" });
                this.props.setGitDependencies?.(RemovePrimaryGitDependency(this.props.gitDependencies), false);
            }
            else {
                this.props.setProperties({ ["Octopus.Action.GitRepository.Source"]: "External" });
                this.props.setGitDependencies?.(InitialisePrimaryGitDependency(this.props.gitDependencies), false);
            }
            // ensure that any existing YAML paths value is copied across to the FilePathFilters so
            // the git source can be filtered properly
            if (this.props.properties["Octopus.Action.KubernetesContainers.CustomResourceYamlFileName"]) {
                this.props.setProperties({ ["Octopus.Action.GitRepository.FilePathFilters"]: this.props.properties["Octopus.Action.KubernetesContainers.CustomResourceYamlFileName"] });
            }
        }
        else {
            this.props.setGitDependencies?.(RemovePrimaryGitDependency(this.props.gitDependencies), false);
            this.props.setProperties({ ["Octopus.Action.GitRepository.FilePathFilters"]: undefined, ["Octopus.Action.GitRepository.Source"]: undefined });
        }
        if (value === "Package") {
            this.props.setPackages(InitialisePrimaryPackageReference(this.props.packages, this.props.feeds));
        }
        else {
            this.props.setPackages(RemovePrimaryPackageReference(this.props.packages));
        }
    }
    private loadFeeds = async () => {
        await this.props.refreshFeeds();
    };
    static displayName = "KubernetesDeployRawYamlActionEditInternal";
}
export const invalidPathsErrorMessage = "The path to your YAML file(s) cannot stem from a root directory or use '.' or '..' directories.";
export function validatePaths(pathsString: string, notSetValidationMessage: string) {
    const requiredMessage = required(notSetValidationMessage)(pathsString);
    if (requiredMessage || pathsString.trim().length < 1) {
        return notSetValidationMessage;
    }
    const paths = pathsString
        .split(/[\n\r]/)
        .map((p) => p.trim())
        .filter((p) => p.length > 0) ?? [];
    if (paths.length === 0)
        return "";
    // If you change this regex, also change it in KubernetesDeployRawYamlActionValidator.cs
    // This Regex matches:
    //  - Paths which start with a root directory eg: '/', '\', 'C:\'
    //  - Paths which use either '.' or '..' directories eg: '.\', 'folder/../', '../'
    // See kubernetesRawYamlAction.spec.tsx for examples of valid and invalid paths.
    const invalidPathRegexes = ["^[A-Za-z]:(\\\\|/|$)", "(^|(^|\\\\|/)[.][.]?)(\\\\|/)"];
    if (paths.some((p) => invalidPathRegexes.some((r) => p.match(r)))) {
        return invalidPathsErrorMessage;
    }
    return "";
}
export function KubernetesDeployRawYamlActionEdit(props: React.PropsWithChildren<KubernetesDeployRawYamlActionEditProps>) {
    const feeds = useFeedsFromContext();
    const refreshFeeds = useRefreshFeedsFromContext();
    const gitCredentials = useGitCredentialsFromContext();
    const refreshGitCredentials = useRefreshGitCredentialsFromContext();
    const projectContext = useOptionalProjectContext();
    const processContext = useOptionalProcessContext();
    return (<KubernetesDeployRawYamlActionEditInternal {...props} feeds={feeds} refreshFeeds={refreshFeeds} project={projectContext?.state.model} processType={processContext?.selectors.getProcessType()} gitCredentials={gitCredentials} refreshGitCredentials={refreshGitCredentials} modelDirty={!isEqual(processContext?.state.model, processContext?.state.cleanModel)}/>);
}
pluginRegistry.registerAction({
    executionLocation: ActionExecutionLocation.AlwaysOnServer,
    actionType: "Octopus.KubernetesDeployRawYaml",
    summary: (properties, targetRolesAsCSV) => <KubernetesDeployRawYamlActionSummary properties={properties} targetRolesAsCSV={targetRolesAsCSV}/>,
    editSections: {
        top: (props: KubernetesDeployRawYamlActionEditProps) => <KubernetesDeployRawYamlActionEdit {...props}/>,
        default: (_: KubernetesDeployRawYamlActionEditProps) => <></>,
    },
    canHaveChildren: (step) => true,
    canBeChild: true,
    targetRoleOption: (action) => TargetRoles.Required,
    hasPackages: (action) => false,
    targetDiscoveryCloudConnectionProviders: getKubernetesTargetDiscoveryCloudProviders,
    getInitialProperties: () => InitialStatusCheckWithTimeoutProperties,
    canUseExecutionTimeouts: false,
    mixedExecutionLocations: kubernetesMixedExecutionLocationConfig,
    disableInlineExecutionContainers: true,
    docsLink: "deployKubernetesYAML",
});
