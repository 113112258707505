import { ActionButton, ActionButtonType, Callout } from "@octopusdeploy/design-system-components";
import type { ProjectResource } from "@octopusdeploy/octopus-server-client";
import { HasGitPersistenceSettings, HasRunbooksInGit, HasVariablesInGit } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { useState } from "react";
import { Action, useProjectScopedAnalyticActionDispatch } from "~/analytics/Analytics";
import { useProjectContext } from "~/areas/projects/context";
import CustomDialog from "~/components/Dialog/CustomDialog";
import MigrateProjectRunbooksWizard from "./MigrateProjectRunbooksDialog";
interface NewFeatureCalloutTitleProps {
    title: string;
}
const NewFeatureCalloutTitle: React.FC<NewFeatureCalloutTitleProps> = (props: NewFeatureCalloutTitleProps) => {
    return <>{props.title}</>;
};
NewFeatureCalloutTitle.displayName = "NewFeatureCalloutTitle"
interface MigrageProjectRunbooksToGitProps {
    project?: ProjectResource;
}
const MigrateProjectRunbooksBanner: React.FC<MigrageProjectRunbooksToGitProps> = (props: MigrageProjectRunbooksToGitProps) => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const projectContext = useProjectContext();
    const project = projectContext.state.model;
    const dispatchAction = useProjectScopedAnalyticActionDispatch(projectContext.state.model.Id);
    // If this is a database project, or runbooks are already in Git, don't show the banner
    if (!HasGitPersistenceSettings(project.PersistenceSettings) || !HasVariablesInGit(project.PersistenceSettings) || HasRunbooksInGit(project.PersistenceSettings)) {
        return <></>;
    }
    const openDialogWithAnalytics = () => {
        dispatchAction("Start Moving Runbooks to Git", { resource: "Runbooks", action: Action.Configure });
        setDialogOpen(true);
    };
    return (<Callout type={"new-feature"} title={<NewFeatureCalloutTitle title="Store Runbooks in Git"/>}>
            <p>You can now store your Runbooks in a Git repository alongside your deployment process and variables.</p>
            <ActionButton type={ActionButtonType.Secondary} label="Migrate Runbooks to Git" onClick={() => openDialogWithAnalytics()}/>
            <CustomDialog open={dialogOpen} close={() => setDialogOpen(false)} render={(props) => <MigrateProjectRunbooksWizard {...props}/>}/>
        </Callout>);
};
MigrateProjectRunbooksBanner.displayName = "MigrateProjectRunbooksBanner"
export default MigrateProjectRunbooksBanner;
