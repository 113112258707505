import { type GitBranchResource, type GitRefResource, type ProjectResource, type ResourceCollection, type GitRef, HasGitPersistenceSettings } from "@octopusdeploy/octopus-server-client";
import React from "react";
import { RefTypes } from "~/areas/projects/components/GitRefDropDown/GitRefDropDown";
import type { GitRefOption } from "~/areas/projects/components/GitRefDropDown/GitRefOption";
import type { GitRefSelectorProps } from "~/areas/projects/components/GitRefSelector/GitRefSelector";
import { GitRefSelector } from "~/areas/projects/components/GitRefSelector/GitRefSelector";
import { repository } from "~/clientInstance";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/index";
import DataBaseComponent from "~/components/DataBaseComponent/index";
import { getProjectBranchesList, getFilteredProjectBranchesList } from "~/utils/BranchHelpers/ProjectBranchHelpers";
export type ProjectGitRefSelectorProps = Omit<GitRefSelectorProps, "projectId" | "busy" | "items" | "totalItemCount" | "loadItems" | "searchItems" | "defaultBranch"> & {
    project: Readonly<ProjectResource>;
    onError?: (error: Error) => void;
};
interface ProjectGitRefSelectorState extends DataBaseComponentState {
    project: Readonly<ProjectResource>;
    items: GitRefOption[];
    totalCount: number;
}
export class ProjectGitRefSelector extends DataBaseComponent<ProjectGitRefSelectorProps, ProjectGitRefSelectorState> {
    constructor(props: ProjectGitRefSelectorProps) {
        super(props);
        this.state = {
            project: props.project,
            items: [],
            totalCount: 0,
        };
    }
    static getDerivedStateFromProps(props: ProjectGitRefSelectorProps, state: ProjectGitRefSelectorState) {
        return {
            ...state,
            project: props.project,
        };
    }
    loadItems = (refType: RefTypes, selectedGitRef: GitRef | undefined): void => {
        this.doBusyTask(async () => {
            try {
                let resources: ResourceCollection<GitRefResource> | null = null;
                let items: GitRefOption[] = [];
                if (refType === RefTypes.Branches) {
                    const branchResources: ResourceCollection<GitBranchResource> = await repository.Projects.getBranches(this.state.project);
                    resources = branchResources;
                    items = await getProjectBranchesList(this.state.project, branchResources.Items, selectedGitRef);
                }
                else if (refType === RefTypes.Tags) {
                    resources = await repository.Projects.getTags(this.state.project);
                    items = resources.Items.map((t) => ({
                        value: t.CanonicalName,
                        isDefault: false,
                        canWrite: false,
                    }));
                }
                this.setState({
                    ...this.state,
                    items,
                    totalCount: resources?.TotalResults ?? 0,
                });
            }
            catch (e) {
                if (this.props.onError)
                    this.props.onError(e);
            }
        });
    };
    searchItems = async (refType: RefTypes, searchTerm: string): Promise<GitRefOption[]> => {
        try {
            if (refType === RefTypes.Tags) {
                const tagResources = await repository.Projects.searchTags(this.props.project, searchTerm);
                return tagResources.Items.map((t) => ({
                    value: t.CanonicalName,
                    isDefault: false,
                    canWrite: false,
                }));
            }
            else {
                // Fallback to branches
                return getFilteredProjectBranchesList(this.props.project, searchTerm);
            }
        }
        catch (e) {
            if (this.props.onError)
                this.props.onError(e);
            return [];
        }
    };
    render() {
        if (!HasGitPersistenceSettings(this.props.project.PersistenceSettings)) {
            // If we don't have a Git project, we don't need to render the branch selector
            return null;
        }
        return (<GitRefSelector projectId={this.props.project.Id} defaultBranch={this.props.project.PersistenceSettings.DefaultBranch} items={this.state.items} totalItemCount={this.state.totalCount} loadItems={this.loadItems} busy={this.state.busy} searchItems={this.searchItems} {...this.props}/>);
    }
    static displayName = "ProjectGitRefSelector";
}
