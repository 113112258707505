import { Callout } from "@octopusdeploy/design-system-components";
import { generateSlug, noOp } from "@octopusdeploy/utilities";
import { Section } from "app/components/Section/Section";
import * as React from "react";
import PackageRequirementExpander from "~/areas/projects/components/Process/Common/PackageRequirementExpander";
import { useProcessBlueprintsFromContext } from "~/areas/projects/components/Process/Contexts/ProcessBlueprintsContextProvider";
import { useProcessContext } from "~/areas/projects/components/Process/Contexts/ProcessContext";
import { ProcessSubPageLayout } from "~/areas/projects/components/Process/ProcessSubPageLayout";
import { RoleChip } from "~/components/Chips";
import { ExpandableContainer } from "~/components/Expandable";
import { StepRolling } from "~/components/Images/Process/StepRolling";
import { TargetTagMultiSelect } from "~/components/MultiSelect/TargetTagMultiSelect";
import convertPropertyValueResourceToString from "~/components/convertPropertyValueResourceToString";
import { ExpandableFormSection, Summary, FormSectionHeading, ExpansionButtons } from "~/components/form";
import NameSummaryWithSlug from "~/primitiveComponents/form/Slugs/NameSummaryWithSlug";
import SlugEditor from "~/primitiveComponents/form/Slugs/SlugEditor";
import { DebounceText } from "~/primitiveComponents/form/Text/Text";
import ParseHelper from "~/utils/ParseHelper/ParseHelper";
import RunTriggerExpander from "../Process/Common/RunTriggerExpander";
import StartTriggerExpander from "../Process/Common/StartTriggerExpander";
import StepName from "../Process/Common/StepName";
import { MaxParallelismOptions } from "./Common/MaxParallelismOptions";
import styles from "./ProcessParentStepDetails.module.less";
import type { StoredStep } from "./types";
export interface ProcessBlueprintParentStepDetailsProps {
    stepNumber: string;
    step: StoredStep;
    machineRoles: string[];
    isFirstStep: boolean;
    blueprintId: string;
}
const ProcessBlueprintParentStepDetails: React.FC<ProcessBlueprintParentStepDetailsProps> = ({ stepNumber, step, machineRoles, isFirstStep, blueprintId }) => {
    const processContext = useProcessContext();
    const blueprints = useProcessBlueprintsFromContext();
    const blueprint = blueprints.find((b) => b.Id === blueprintId);
    if (!blueprint) {
        return (<Section>
                <Callout type={"information"} title={"Blueprint could not be found"}>
                    {<span>The requested blueprint could not be found. Please select from the available steps.</span>}
                </Callout>
            </Section>);
    }
    const stepNumberInBlueprint = blueprint.Steps.map((s) => s.Id).indexOf(step.Id) + 1;
    const executionPlanSummary = () => {
        const summary = [<span>This step will run</span>];
        const roles = ParseHelper.parseCSV(convertPropertyValueResourceToString(step.Properties["Octopus.Action.TargetRoles"]));
        const parallelism = step.Properties["Octopus.Action.MaxParallelism"];
        if (roles.length > 0) {
            summary.push(roles.length > 1 ? <span> with tags</span> : <span> with tag</span>);
            roles.forEach((r) => {
                summary.push(<RoleChip role={r} key={"role-" + r}/>);
            });
        }
        if (parallelism) {
            summary.push(<span>
                    {" "}
                    as <strong>rolling step</strong> that will run on <strong>{parallelism}</strong> target{parallelism !== "1" ? "s" : ""} at a time
                </span>);
        }
        else {
            summary.push(<span>
                    {" "}
                    with all targets deployed in <strong>parallel</strong>
                </span>);
        }
        return Summary.summary(React.Children.toArray(summary));
    };
    const nameSummary = (stepName: string, stepSlug: string | undefined) => (<div className={styles.nameSummaryContainer}>
            <NameSummaryWithSlug name={stepName} slug={stepSlug}/>
        </div>);
    return (<ExpandableContainer containerKey={step.Id}>
            <ExpansionButtons containerKey={step.Id} expandAllOnMount={false}/>
            <ProcessSubPageLayout title={<StepName name={step.Name} number={`${stepNumber}.${stepNumberInBlueprint}`} stepType="Parent Step"/>} titleLogo={<div className={styles.parentStepIcon}>
                        <StepRolling width={"3.1rem"} height={"3.1rem"}/>
                    </div>}>
                <ExpandableFormSection errorKey="Name" title="Step Name" focusOnExpandAll summary={step.Name ? Summary.summary(nameSummary(step.Name, step.Slug)) : Summary.placeholder("Please enter a name for your step")} help="A short, memorable, unique name for this step.">
                    <DebounceText value={step.Name} onChange={noOp} label="Step name" autoFocus={true} disabled={true}/>
                    <SlugEditor value={step.Slug ?? generateSlug(step.Name)} originalSlug={step.Slug ?? ""} name={step.Name} onChange={noOp} label="Step slug" readOnly={true}/>
                </ExpandableFormSection>

                <ExpandableFormSection isExpandedByDefault={!step.Name} errorKey="Octopus.Action.TargetRoles" title="Execution Plan" summary={executionPlanSummary()} help="Where should this step run?">
                    <TargetTagMultiSelect label="Runs on targets with tags" onChange={noOp} value={ParseHelper.parseCSV(convertPropertyValueResourceToString(step.Properties["Octopus.Action.TargetRoles"]))} items={machineRoles} disabled={true}/>
                    <MaxParallelismOptions projectId={processContext.state.ownerId} maxParallelism={convertPropertyValueResourceToString(step.Properties["Octopus.Action.MaxParallelism"])} setStepProperties={noOp}/>
                </ExpandableFormSection>

                <FormSectionHeading title="Conditions"/>
                <RunTriggerExpander isFirstStep={isFirstStep} condition={step.Condition} onConditionChange={noOp} variableExpression={convertPropertyValueResourceToString(step.Properties["Octopus.Step.ConditionVariableExpression"])} onVariableExpressionChange={noOp} projectId={processContext.state.ownerId} variableExpressionError={undefined}/>

                {!isFirstStep && <StartTriggerExpander startTrigger={step.StartTrigger} onChange={noOp}/>}
                {/*The logic for hiding PackageRequirementExpander is complicated - see processModelSelectors.shouldShowPackageRequirementOptionForAction*/}
                {/*Always showing it for now since it's not configurable anyway*/}
                <PackageRequirementExpander packageRequirement={step.PackageRequirement} onChange={noOp}/>
            </ProcessSubPageLayout>
        </ExpandableContainer>);
};
ProcessBlueprintParentStepDetails.displayName = "ProcessBlueprintParentStepDetails"
export default ProcessBlueprintParentStepDetails;
