/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import { PortalPageLayout } from "~/components/PortalPageLayout/PortalPageLayout";
interface LibraryLayoutProps {
    spaceId: string;
    children: React.ReactNode;
}
export function LibraryLayout({ spaceId, children }: LibraryLayoutProps) {
    return <PortalPageLayout navItems={[]} content={children}/>;
}
export default LibraryLayout;
