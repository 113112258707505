import type { InstanceFeatureToggle } from "@octopusdeploy/octopus-server-client";
import { session } from "~/clientInstance";
// Exposes the feature toggles to consumers
export function isFeatureToggleEnabled(featureToggle: InstanceFeatureToggle): boolean {
    if (!session.featureToggles) {
        throw "Feature toggles have not yet loaded. These settings are only available once logged in.";
    }
    const toggle = session.featureToggles.find((x) => x.Name === featureToggle);
    return toggle ? toggle.IsEnabled : false;
}
