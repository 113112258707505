import type { PackageReference, TriggerResource } from "@octopusdeploy/octopus-server-client";
import React, { useMemo, useState } from "react";
import type { RelatedTriggersDependencies } from "./CalloutReferencedPackageFeedTriggerConnections";
import { MultipleRelatedFeedTriggersModifiedCallout, SingleRelatedFeedTriggerModifiedCallout } from "./RelatedFeedTriggerCallouts";
import { getRelatedFeedTriggersForReferencedPackages, getPackageNamesUsedInFeedTriggers } from "./triggerPackageRelations";
interface CalloutModifiedFeedTriggerConnectionsProps {
    dependencies: RelatedTriggersDependencies;
    packageReferences: PackageReference[];
    modelDirty: boolean;
}
export function CalloutModifiedFeedTriggerConnections({ dependencies, packageReferences, modelDirty }: CalloutModifiedFeedTriggerConnectionsProps) {
    const [originalPackageNames, setOriginalPackageNames] = useState<string[]>([]);
    const [originalTriggers, setOriginalTriggers] = useState<TriggerResource[]>([]);
    const { spaceId, projectSlug } = dependencies;
    const relatedTriggers = useMemo(() => getRelatedFeedTriggersForReferencedPackages(dependencies, packageReferences), [dependencies, packageReferences]);
    const packageNames = getPackageNamesUsedInFeedTriggers(relatedTriggers, packageReferences);
    const uniqueTriggerCount = [...new Set(relatedTriggers.map((trigger) => trigger.Id))].length;
    if (originalPackageNames.length === 0 && packageNames.length !== 0) {
        // Can't re-use packageNames as it only uses the first element for some reason
        setOriginalPackageNames(getPackageNamesUsedInFeedTriggers(relatedTriggers, packageReferences));
    }
    if (originalTriggers.length === 0 && relatedTriggers.length !== 0) {
        setOriginalTriggers(relatedTriggers);
    }
    // We should never show a warning if there wasn't an existing related trigger
    if (originalTriggers.length === 0 || originalPackageNames.length === 0)
        return <></>;
    const changedPackageNames = originalPackageNames.filter((name) => !packageNames.includes(name));
    if (changedPackageNames.length > 0) {
        if (uniqueTriggerCount === 1) {
            const triggerId = relatedTriggers[0].Id;
            return <SingleRelatedFeedTriggerModifiedCallout triggerId={triggerId} spaceId={spaceId} projectSlug={projectSlug} disableLink={modelDirty}/>;
        }
        else {
            return <MultipleRelatedFeedTriggersModifiedCallout spaceId={spaceId} projectSlug={projectSlug} disableLink={modelDirty}/>;
        }
    }
    return <></>;
}
