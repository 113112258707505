import { PageHeaderPrimary } from "@octopusdeploy/design-system-components";
import type { SpaceResource } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import MissingImportExportPermissionCallout from "app/areas/projects/components/ImportExport/MissingImportExportPermissionCallout";
import * as React from "react";
import { ProjectPaperLayout } from "~/areas/projects/components/ProjectPaperLayout";
import Tasks from "~/areas/tasks/components/Tasks/Tasks";
import { repository, session } from "~/clientInstance";
import type { DataBaseComponentState } from "~/components/DataBaseComponent";
import DataBaseComponent from "~/components/DataBaseComponent";
import { getImportExportMenuItems } from "./ImportExportMenu";
import styles from "./style.module.less";
interface ImportExportProjectTasksPageState extends DataBaseComponentState {
    space?: SpaceResource;
}
interface ImportExportProjectTasksPageProps {
    spaceId: string;
}
export class ImportExportProjectTasksPage extends DataBaseComponent<ImportExportProjectTasksPageProps, ImportExportProjectTasksPageState> {
    constructor(props: ImportExportProjectTasksPageProps) {
        super(props);
        this.state = {};
    }
    async componentDidMount() {
        await this.doBusyTask(async () => {
            if (!repository.spaceId) {
                throw new Error("Attempted to render ImportExportTasks in a system context. This should never happen.");
            }
            const [space] = await Promise.all([repository.Spaces.get(repository.spaceId)]);
            this.setState({
                space,
            });
        });
    }
    isSpaceManager(): boolean {
        if (!session.currentPermissions) {
            throw new Error("Attempted to access the current user's permissions, but they weren't found. This should never happen.");
        }
        return this.state.space !== undefined && session.currentPermissions.isSpaceManager(this.state.space);
    }
    render() {
        const isSpaceManager = this.isSpaceManager();
        return (<main>
                <PageHeaderPrimary title="Import/Export Tasks" breadcrumbs={[{ label: "Projects", pageUrl: links.projectsPage.generateUrl({ spaceId: this.props.spaceId }) }]} overflowActions={getImportExportMenuItems(this.props.spaceId)}/>
                <div className={styles.container}>
                    <Tasks renderLayout={(layoutProps) => (<ProjectPaperLayout busy={layoutProps.busy} errors={this.errors} fullWidth={true}>
                                {this.state.space && !isSpaceManager && <MissingImportExportPermissionCallout />}
                                {this.state.space && isSpaceManager && (<>
                                        <div className={styles.layoutContainer}>{layoutProps.children}</div>
                                    </>)}
                            </ProjectPaperLayout>)} restrictToTaskTypes={["ExportProjects", "ImportProjects"]} hideAdvancedFilters={true}/>
                </div>
            </main>);
    }
    static displayName = "ImportExportProjectTasksPage";
}
