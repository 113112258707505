/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { css } from "@emotion/css";
import { ActionButton, List, Tooltip } from "@octopusdeploy/design-system-components";
import { space } from "@octopusdeploy/design-system-tokens";
import type { HelmChartUpgradeProperties } from "@octopusdeploy/legacy-action-properties";
import type { FeedResource, GitDependencyReference } from "@octopusdeploy/octopus-server-client";
import ExpandableFormSection from "app/components/form/Sections/ExpandableFormSection";
import jsSHA from "jssha";
import { cloneDeep } from "lodash";
import * as React from "react";
import { useMemo, useState } from "react";
import type { RunOn } from "~/areas/projects/components/Process/types";
import ActionList from "~/components/ActionList/index";
import type { HelmTemplateValuesSaveResult } from "~/components/Actions/helmChartUpgrade/HelmTemplateValuesDrawer";
import { isGitDependencyTemplateValuesSaveResult, HelmTemplateValuesDrawer, isPackageReferenceTemplateValuesSaveResult } from "~/components/Actions/helmChartUpgrade/HelmTemplateValuesDrawer";
import { HelmTemplateValuesSorterDialog } from "~/components/Actions/helmChartUpgrade/HelmTemplateValuesSorterDialog";
import type { PackagedHelmValuesReference } from "~/components/Actions/helmChartUpgrade/PackagedHelmValuesDialog";
import type { TemplateValuesSourceListItemModel } from "~/components/Actions/helmChartUpgrade/TemplateValueSourceListItem";
import { TemplateValueSourceListItem } from "~/components/Actions/helmChartUpgrade/TemplateValueSourceListItem";
import type { TemplateValuesSource, TemplateValuesSources } from "~/components/Actions/helmChartUpgrade/TemplateValuesSource";
import { isGitRepositoryTemplateValuesSource, isPackageTemplateValuesSource } from "~/components/Actions/helmChartUpgrade/TemplateValuesSource";
import { removeArtifact, saveArtifact } from "~/components/Actions/helmChartUpgrade/artifactHelpers";
import type { ArtifactEqualToPredicate } from "~/components/Actions/helmChartUpgrade/artifactHelpers";
import { getValuesPackageReferences } from "~/components/Actions/helmChartUpgrade/helmPackageHelpers";
import { tryMigrateToTemplateValuesSources } from "~/components/Actions/helmChartUpgrade/helmTemplateValuesSourcesMigration";
import type { BoundFieldProps } from "~/components/Actions/pluginRegistry";
import BorderedListItem from "~/components/BorderedListItem/index";
import { Summary } from "~/components/form/index";
import type { CodeEditorVariable } from "~/utils/ScriptIntellisense/scriptIntellisense";
import StringHelper from "~/utils/StringHelper/index";
const templateValuesListStyles = {
    container: css({
        marginTop: space[16],
    }),
    itemContainer: css({
        padding: space[16],
    }),
};
type HelmTemplateValuesSectionV2Props = BoundFieldProps & {
    properties: HelmChartUpgradeProperties;
    setProperties: (properties: Partial<HelmChartUpgradeProperties>, initialise?: boolean, callback?: () => void) => void;
    packages: Array<PackagedHelmValuesReference>;
    setPackages: (packages: Array<PackagedHelmValuesReference>, initialise?: boolean) => void;
    yamlAutocompleteResults: CodeEditorVariable[];
    runOn: RunOn | undefined;
    feeds: FeedResource[];
    gitDependencies: Array<GitDependencyReference>;
    setGitDependencies: (gitDependencies: GitDependencyReference[], initialise?: boolean) => void;
};
const gitDependencyEqualToPredicate: ArtifactEqualToPredicate<GitDependencyReference> = (gd1: GitDependencyReference, gd2: GitDependencyReference) => gd1.Name === gd2.Name;
const packageEqualToPredicate: ArtifactEqualToPredicate<PackagedHelmValuesReference> = (p1, p2) => p1.Id === p2.Id && p1.Name === p2.Name;
const getOrMigrateTemplateValuesSources = (properties: HelmChartUpgradeProperties, packages: Array<PackagedHelmValuesReference>): TemplateValuesSources => {
    //we use the migrated values if they exist in the UI state only. Any change to them will then be persisted and mark the UI as dirty
    return StringHelper.isNullOrWhiteSpace(properties["Octopus.Action.Helm.TemplateValuesSources"]) ? tryMigrateToTemplateValuesSources(properties, packages) : parseTemplateValuesSources(properties["Octopus.Action.Helm.TemplateValuesSources"]);
};
const parseTemplateValuesSources = (templateValuesSourcesJson: string) => {
    if (!templateValuesSourcesJson || StringHelper.isNullOrWhiteSpace(templateValuesSourcesJson)) {
        return [];
    }
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return JSON.parse(templateValuesSourcesJson) as TemplateValuesSources;
};
export const HelmTemplateValuesSectionV2 = (props: HelmTemplateValuesSectionV2Props) => {
    const [templateValuesSources, setTemplateValuesSources] = useState<TemplateValuesSources>(getOrMigrateTemplateValuesSources(props.properties, props.packages));
    const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
    const [isSorterDialogOpen, setIsSorterDialogOpen] = useState<boolean>(false);
    const [editingTemplateValuesSourceIndex, setEditingTemplateValuesSourceIndex] = useState<number | undefined>();
    const [editingPackageReference, setEditingPackageReference] = useState<PackagedHelmValuesReference | undefined>();
    const [editingGitDependency, setEditingGitDependency] = useState<GitDependencyReference | undefined>();
    const closeTemplateValuesDrawer = () => {
        setIsDrawerOpen(false);
        setEditingTemplateValuesSourceIndex(undefined);
        setEditingPackageReference(undefined);
        setEditingGitDependency(undefined);
    };
    const onDrawerSave = (result: HelmTemplateValuesSaveResult) => {
        if (isPackageReferenceTemplateValuesSaveResult(result)) {
            const [newPackageName, updatedPackageReferences] = savePackageReference(result.packageReference);
            saveTemplateValuesSource({ ...result.valuesSource, PackageName: newPackageName }, updatedPackageReferences);
        }
        else if (isGitDependencyTemplateValuesSaveResult(result)) {
            const [newGitDependencyName, updatedGitDependencies] = saveGitDependency(result.gitDependency);
            saveTemplateValuesSource({ ...result.valuesSource, GitDependencyName: newGitDependencyName }, undefined, updatedGitDependencies);
        }
        else {
            saveTemplateValuesSource(result);
        }
        closeTemplateValuesDrawer();
    };
    const onDrawerDelete = (tvs: TemplateValuesSource) => {
        let updatedPackages: PackagedHelmValuesReference[] | undefined = undefined;
        if (editingPackageReference) {
            updatedPackages = removePackageReference(editingPackageReference);
        }
        let updatedGitDependencies: GitDependencyReference[] | undefined = undefined;
        if (editingGitDependency) {
            updatedGitDependencies = removeGitDependency(editingGitDependency);
        }
        removeTemplateValuesSource(tvs, updatedPackages, updatedGitDependencies);
        closeTemplateValuesDrawer();
    };
    const saveTemplateValuesSource = (tvs: TemplateValuesSource, updatedPackages: PackagedHelmValuesReference[] | undefined = undefined, updatedGitDependencies: GitDependencyReference[] | undefined = undefined) => {
        const valueSources = [...templateValuesSources];
        //if we aren't editing, just add the end of the list
        if (editingTemplateValuesSourceIndex !== undefined) {
            valueSources[editingTemplateValuesSourceIndex] = tvs;
        }
        else {
            valueSources.push(tvs);
        }
        saveTemplateValueSources(valueSources, updatedPackages, updatedGitDependencies);
    };
    const removeTemplateValuesSource = (tvs: TemplateValuesSource, updatedPackages: PackagedHelmValuesReference[] | undefined, updatedGitDependencies: GitDependencyReference[] | undefined) => {
        if (editingTemplateValuesSourceIndex === undefined) {
            throw new Error("Not editing a template values source");
        }
        if (tvs !== templateValuesSources[editingTemplateValuesSourceIndex]) {
            throw new Error("Deleting the wrong TemplateValueSource");
        }
        const valueSources = [...templateValuesSources];
        valueSources.splice(valueSources.indexOf(tvs), 1);
        saveTemplateValueSources(valueSources, updatedPackages, updatedGitDependencies);
    };
    const saveTemplateValueSources = (valueSources: TemplateValuesSources, updatedPackages: PackagedHelmValuesReference[] | undefined, updatedGitDependencies: GitDependencyReference[] | undefined) => {
        //update the state
        setTemplateValuesSources(valueSources);
        //update the properties
        props.setProperties({
            ["Octopus.Action.Helm.TemplateValuesSources"]: JSON.stringify(valueSources),
        });
        //update the packages (if there were any)
        if (updatedPackages) {
            props.setPackages(updatedPackages);
        }
        if (updatedGitDependencies && props.setGitDependencies) {
            props.setGitDependencies(updatedGitDependencies);
        }
        clearOldValuesProperties(updatedPackages || props.packages);
    };
    const clearOldValuesProperties = (packages: PackagedHelmValuesReference[]) => {
        //we clear out the old property values as they will have been migrated
        props.setProperties({
            ["Octopus.Action.Helm.KeyValues"]: undefined,
            ["Octopus.Action.Helm.YamlValues"]: undefined,
        });
        //remove the values paths from all the existing packages (if they have them set)
        const packagesToUpdate = [...packages];
        for (let i = 0; i < packagesToUpdate.length; i++) {
            const pkg = packagesToUpdate[i];
            packagesToUpdate[i] = {
                ...pkg,
                Properties: {
                    ...pkg.Properties,
                    //clear the old valuesFilePaths as these are now stored in the TemplateValuesSources
                    ValuesFilePath: "",
                },
            };
        }
        props.setPackages(packagesToUpdate);
    };
    const savePackageReference = (packageReference: PackagedHelmValuesReference) => saveArtifact(packageReference, props.packages, editingPackageReference, packageEqualToPredicate, "ValuesPack");
    const removePackageReference = (pkgRef: PackagedHelmValuesReference) => removeArtifact(pkgRef, props.packages, packageEqualToPredicate);
    const saveGitDependency = (gitDependency: GitDependencyReference) => saveArtifact(gitDependency, props.gitDependencies, editingGitDependency, gitDependencyEqualToPredicate, "TemplateValues");
    const removeGitDependency = (gitDependency: GitDependencyReference) => removeArtifact(gitDependency, props.gitDependencies, gitDependencyEqualToPredicate);
    const getTemplateValuesSummary = () => {
        const sourceCount = templateValuesSources.length;
        if (sourceCount !== 0) {
            const multipleSuffix = sourceCount > 1 ? "s" : "";
            return Summary.summary(`${sourceCount} values source${multipleSuffix} configured`);
        }
        return Summary.placeholder("No value sources configured");
    };
    const templateValuesSourceListItemModels: Array<TemplateValuesSourceListItemModel> = useMemo(() => templateValuesSources.map((tvs) => {
        //we have to hash the contents of the inline yaml & key-values as they need a unique id
        const sha = new jsSHA("SHA-1", "TEXT", { encoding: "UTF8" });
        if (tvs.Type === "KeyValues") {
            sha.update(JSON.stringify(tvs.Value));
            const hash = sha.getHash("HEX");
            return {
                templateValuesSource: tvs,
                id: `${tvs.Type}-${hash}`,
                name: "Key values",
                valueSummary: Object.keys(tvs.Value)
                    .map((key) => `${key} = ${tvs.Value[key]}`)
                    .join(", "),
            };
        }
        if (tvs.Type === "InlineYaml") {
            sha.update(tvs.Value);
            const hash = sha.getHash("HEX");
            return {
                templateValuesSource: tvs,
                id: `${tvs.Type}-${hash}`,
                name: "Inline YAML",
                valueSummary: tvs.Value.split("\n").slice(0, 3),
            };
        }
        if (tvs.Type === "Chart") {
            return {
                templateValuesSource: tvs,
                id: tvs.Type,
                name: "Chart",
                valueSummary: tvs.ValuesFilePaths.split("\n").slice(0, 3),
            };
        }
        if (tvs.Type === "Package") {
            const packageReferences = getValuesPackageReferences(props.packages);
            const pkgRef = packageReferences.find((pkgRef) => pkgRef.Name === tvs.PackageName && pkgRef.PackageId === tvs.PackageId);
            if (!pkgRef) {
                throw new Error(`Package reference not found for name ${tvs.PackageName} & ID ${tvs.PackageId}`);
            }
            const feed = props.feeds.find((f) => f.Id === pkgRef.FeedId);
            const valuesFilePaths = tvs.ValuesFilePaths.split("\n").slice(0, 3).join(", ");
            const version = pkgRef.Version ? ` version ${pkgRef.Version} ` : "";
            return {
                templateValuesSource: tvs,
                id: `${tvs.Type}-${tvs.PackageId}-${tvs.PackageName}`,
                name: "Package",
                valueSummary: `${valuesFilePaths} in ${tvs.PackageId}${version}from feed ${feed?.Name || "Unknown feed"}`,
            };
        }
        if (tvs.Type === "GitRepository") {
            const gitDependency = (props.gitDependencies || []).find((gitDependency) => gitDependency.Name === tvs.GitDependencyName);
            if (!gitDependency) {
                throw new Error(`Git dependency not found for name ${tvs.GitDependencyName}`);
            }
            const valuesFilePaths = tvs.ValuesFilePaths.split("\n").slice(0, 3).join(", ");
            return {
                templateValuesSource: tvs,
                id: `${tvs.Type}-${tvs.GitDependencyName}`,
                name: "Git Repository",
                valueSummary: (<>
                                {valuesFilePaths} in repository <wbr />
                                {gitDependency.RepositoryUri} ({gitDependency.DefaultBranch})
                            </>),
            };
        }
        throw new Error(`Unknown TemplateValuesSource`);
    }), //remove any undefined items
    [templateValuesSources, props.packages, props.feeds, props.gitDependencies]);
    return (<>
            <ExpandableFormSection errorKey={"TemplateValues"} title="Template Values" summary={getTemplateValuesSummary()} help={<span>Provide one or multiple values sources to be passed into the chart</span>}>
                <div>
                    <ActionList actions={[<ActionButton label="Add" onClick={() => setIsDrawerOpen(true)}/>, <ActionButton label="Reorder" onClick={() => setIsSorterDialogOpen(true)} disabled={templateValuesSourceListItemModels.length <= 1}/>]}/>

                    <div className={templateValuesListStyles.container}>
                        <List items={templateValuesSourceListItemModels} includeRowGaps={true} renderRow={({ item }) => (<Tooltip content={"Edit values source"}>
                                    <BorderedListItem key={item.id} onClick={() => {
                const templateValuesSource = item.templateValuesSource;
                let packageRef: PackagedHelmValuesReference | undefined = undefined;
                let gitDependency: GitDependencyReference | undefined = undefined;
                if (isPackageTemplateValuesSource(templateValuesSource)) {
                    packageRef = props.packages.find((ref) => ref.Name === templateValuesSource.PackageName && ref.PackageId === templateValuesSource.PackageId);
                }
                else if (isGitRepositoryTemplateValuesSource(templateValuesSource)) {
                    gitDependency = props.gitDependencies.find((gd) => gd.Name === templateValuesSource.GitDependencyName);
                }
                setEditingTemplateValuesSourceIndex(templateValuesSources.indexOf(templateValuesSource));
                setEditingPackageReference(cloneDeep(packageRef));
                setEditingGitDependency(cloneDeep(gitDependency));
                setIsDrawerOpen(true);
            }}>
                                        <div className={templateValuesListStyles.itemContainer}>
                                            <TemplateValueSourceListItem item={item}/>
                                        </div>
                                    </BorderedListItem>
                                </Tooltip>)}/>
                    </div>
                </div>
            </ExpandableFormSection>
            <HelmTemplateValuesDrawer isOpen={isDrawerOpen} onClose={() => closeTemplateValuesDrawer()} onSave={onDrawerSave} onDelete={onDrawerDelete} templateValuesSources={templateValuesSources} editingTemplateValuesSource={editingTemplateValuesSourceIndex === undefined ? undefined : templateValuesSources[editingTemplateValuesSourceIndex]} editingPackageReference={editingPackageReference} editingGitDependency={editingGitDependency} projectId={props.projectId!} runOn={props.runOn} yamlAutocompleteResults={props.yamlAutocompleteResults}/>

            <HelmTemplateValuesSorterDialog isOpen={isSorterDialogOpen} templateValueSources={templateValuesSourceListItemModels} onCancel={() => setIsSorterDialogOpen(false)} onSave={(orderedTemplateValueSources) => {
            saveTemplateValueSources(orderedTemplateValueSources, undefined, undefined);
            setIsSorterDialogOpen(false);
        }}/>
        </>);
};
