import React from "react";
import type { OverrideoverrideFontProps } from "~/components/DevTools/OverrideFontTab/OverrideFontTab";
import { useThemePaletteType } from "~/components/Theme/useThemePaletteType";
import useSessionStorage from "~/hooks/useSessionStorage";
import Theme from "./Theme";
type ThemeFromLocalStorageProps = {
    children: ({ overrideFontProps }: {
        overrideFontProps: OverrideoverrideFontProps;
    }) => React.ReactNode;
};
export function ThemeFromLocalStorage({ children }: ThemeFromLocalStorageProps) {
    const [selectedFont, setSelectedFont, clearSelectedFont] = useSessionStorage<string>("selectedFont", "");
    const [updateFontFamilyOnBody, setUpdateFontFamilyOnBody, clearUpdateOnBody] = useSessionStorage<boolean>("updateFontFamilyOnBody", true);
    const palette = useThemePaletteType();
    if (selectedFont === "") {
        clearSelectedFont();
        clearUpdateOnBody();
    }
    const overrideFontProps: OverrideoverrideFontProps = {
        selectedFont,
        setSelectedFont,
        updateFontFamilyOnBody,
        setUpdateFontFamilyOnBody,
    };
    return (<Theme palette={palette} selectedFont={selectedFont} updateFontFamilyOnBody={updateFontFamilyOnBody}>
            {children({ overrideFontProps })}
        </Theme>);
}
