import { css } from "@emotion/css";
import { text } from "@octopusdeploy/design-system-tokens";
import { logger } from "@octopusdeploy/logging";
import type { GitRef, ProjectResource } from "@octopusdeploy/octopus-server-client";
import { IsDefaultBranch, isGitBranch } from "@octopusdeploy/octopus-server-client";
import { useCallback, useEffect, useState } from "react";
import * as React from "react";
import { useAnalyticSimpleActionDispatch } from "~/analytics/Analytics";
import { repository } from "~/clientInstance";
import ExternalLink from "~/components/Navigation/ExternalLink";
export interface ProjectCreatePullRequestLinkProps {
    project: ProjectResource;
    gitRef: GitRef | undefined;
}
export function ProjectCreatePullRequestLink({ project, gitRef }: ProjectCreatePullRequestLinkProps) {
    const [pullRequestLink, setPullRequestLink] = useState<string | undefined>(undefined);
    const dispatchAction = useAnalyticSimpleActionDispatch();
    const onPullRequestLinkClicked = useCallback(() => {
        dispatchAction("Create Pull Request");
    }, [dispatchAction]);
    useEffect(() => {
        if (!gitRef) {
            return;
        }
        repository.Projects.getPullRequestLink(project, gitRef)
            .then((resp) => {
            if (resp.Link) {
                setPullRequestLink(resp.Link);
            }
        })
            .catch((e) => {
            logger.warn(e, "Failed to get pull request link");
        });
    }, [project, gitRef]);
    if (!project.IsVersionControlled) {
        return null;
    }
    const isDefaultBranch = IsDefaultBranch(project, gitRef);
    const canCreatePullRequest = isGitBranch(gitRef) && !isDefaultBranch && pullRequestLink;
    return canCreatePullRequest ? (<ExternalLink href={pullRequestLink} onClick={onPullRequestLinkClicked} trackAnalytics={false} className={createPullRequestLinkStyles}>
            Create pull request
        </ExternalLink>) : null;
}
const createPullRequestLinkStyles = css({
    font: text.regular.default.medium,
});
