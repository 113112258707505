import { css } from "@emotion/css";
import { Button } from "@octopusdeploy/design-system-components";
import type { SpaceResource } from "@octopusdeploy/octopus-server-client";
import type { ReactElement } from "react";
import * as React from "react";
import type { NameConfigurationMessages } from "~/areas/infrastructure/components/MachineSettings/Endpoints/KubernetesAgent/ConfigurationDialog";
import { ConfigurationMessages } from "~/areas/infrastructure/components/MachineSettings/Endpoints/KubernetesAgent/ConfigurationMessages";
import { LocalDevServerUrlHelper } from "~/areas/infrastructure/components/MachineSettings/Endpoints/KubernetesAgent/LocalDevServerUrlHelper";
import { StorageContextualHelp } from "~/areas/infrastructure/components/MachineSettings/Endpoints/KubernetesAgent/StorageContextualHelp";
import type { EndpointSelectionScope } from "~/areas/infrastructure/components/MachineSettings/Endpoints/endpointRegistry";
import { required, Text } from "~/components/form/index";
import StringHelper from "~/utils/StringHelper/index";
export interface BaseConfiguration {
    Space: SpaceResource | undefined;
    Name: string;
    ServerUri: string;
    ServerNodeCommsAddresses: {
        nodeName: string;
        commsAddress: string;
    }[];
    AccessToken: string;
    StorageClassName: string;
}
type ConfigurationPageProps = {
    configuration: BaseConfiguration;
    onConfigurationChange: <K extends keyof BaseConfiguration>(configuration: Pick<BaseConfiguration, K>) => void;
    customFormComponents?: ReactElement;
    customAdvancedComponents?: ReactElement;
    canModifyServerUrls: boolean;
    showAdvanced: boolean;
    toggleShowAdvanced: () => void;
    getFieldError: (fieldName: string) => string;
    usePersistentVolumeStorage: boolean;
    onUsePersistentVolumeStorageChanged: (value: boolean) => void;
    requiresHaConfiguration: boolean;
    nameErrorMessages: NameConfigurationMessages;
    storageClassHelperText: string | JSX.Element;
};
const showAdvancedButtonStyle = css({
    alignSelf: "flex-start",
    margin: "0.5rem 0 0.5rem 0",
});
export function ConfigurationPage(props: ConfigurationPageProps) {
    const { getFieldError } = props;
    return (<>
            <Text value={props.configuration.Name} helperText={props.nameErrorMessages.HelperText} onChange={(Name) => props.onConfigurationChange({ Name })} label={"Name"} error={getFieldError("Name")} validate={required(props.nameErrorMessages.Validation.NotProvided)} autoFocus={true}/>

            {props.customFormComponents && props.customFormComponents}

            {props.canModifyServerUrls && (<LocalDevServerUrlHelper onServerAddressesChanged={(serverUri, serverCommsAddress) => {
                if (!props.showAdvanced) {
                    props.toggleShowAdvanced();
                }
                props.onConfigurationChange({
                    ServerUri: serverUri,
                    ServerNodeCommsAddresses: [{ nodeName: "", commsAddress: serverCommsAddress }],
                });
            }}/>)}

            {<div className={showAdvancedButtonStyle}>
                    <Button label={props.showAdvanced ? "Hide optional settings" : "Show optional settings"} importance="tertiary" onClick={(e) => {
                e.preventDefault();
                props.toggleShowAdvanced();
            }}/>
                </div>}
            {props.showAdvanced && (<>
                    {props.customAdvancedComponents && props.customAdvancedComponents}

                    <Text value={props.configuration.StorageClassName} onChange={(val) => {
                props.onConfigurationChange({ StorageClassName: val });
                props.onUsePersistentVolumeStorageChanged(!StringHelper.isNullOrWhiteSpace(val));
            }} helperText={props.storageClassHelperText} label={"Storage class"}/>

                    {props.canModifyServerUrls && (<>
                            <Text value={props.configuration.ServerUri} onChange={(ServerUri) => props.onConfigurationChange({ ServerUri })} error={getFieldError("ServerUri")} label="Octopus Deploy Server URL" helperText={ConfigurationMessages.ServerUri.HelperText} validate={required(ConfigurationMessages.ServerUri.Validation.NotProvided)}/>
                            {!props.requiresHaConfiguration && (<Text value={props.configuration.ServerNodeCommsAddresses[0].commsAddress} onChange={(commsAddress) => props.onConfigurationChange({ ServerNodeCommsAddresses: [{ nodeName: "", commsAddress }] })} error={getFieldError("ServerNodeCommsAddresses")} label="Octopus Deploy Server Communications URL" helperText={ConfigurationMessages.ServerCommsAddresses.HelperText} validate={required(ConfigurationMessages.ServerCommsAddresses.Validation.NotProvided)}/>)}
                        </>)}
                </>)}
        </>);
}
export const ConfigurationPageStorageClassHelperContent = (machineType: EndpointSelectionScope) => (<span>
        Provide a Storage Class <StorageContextualHelp machineType={machineType}/> or leave this blank to use the default NFS storage
    </span>);
