import type { SimpleMenuItem, PrimaryPageAction, PageAction } from "@octopusdeploy/design-system-components";
import { LinearProgress, PageHeaderPrimary } from "@octopusdeploy/design-system-components";
import styles from "app/components/FullWidthPageLayout/style.module.less";
import type { PropsWithChildren } from "react";
import React from "react";
import BusyFromPromise from "~/components/BusyFromPromise/index";
import type { Errors } from "~/components/DataBaseComponent/index";
import ErrorPanel from "~/components/ErrorPanel/ErrorPanel";
import { SkeletonLoadingLayout } from "~/components/SkeletonLoadingLayout/SkeletonLoadingLayout";
import TransitionAnimation from "~/components/TransitionAnimation/TransitionAnimation";
interface LoadedFullWidthPageLayoutProps {
    areaTitle?: string;
    primaryAction?: PrimaryPageAction;
    pageActions?: PageAction[];
    overflowActions?: SimpleMenuItem[];
    busy: Promise<void> | boolean | undefined;
    errors: Errors | undefined;
}
type LoadingFullWidthPageLayoutProps = {
    isInitialLoad: true;
    errors: Errors | undefined;
};
type FullWidthPageLayoutProps = LoadingFullWidthPageLayoutProps | LoadedFullWidthPageLayoutProps;
function isLoadingFullWidthPageLayoutProps(props: FullWidthPageLayoutProps): props is LoadingFullWidthPageLayoutProps {
    return "isInitialLoad" in props && props.isInitialLoad;
}
export function FullWidthPageLayout(props: PropsWithChildren<FullWidthPageLayoutProps>) {
    return (<main>
            {isLoadingFullWidthPageLayoutProps(props) ? (<SkeletonLoadingLayout errors={props.errors}/>) : (<AreaHeaderVNext areaTitle={props.areaTitle} primaryAction={props.primaryAction} pageActions={props.pageActions} overflowActions={props.overflowActions}/>)}

            <div className={styles.container}>
                <div className={styles.content}>
                    {!isLoadingFullWidthPageLayoutProps(props) && <BusyFromPromise promise={props.busy}>{(isBusy: boolean) => <LinearProgress variant={"indeterminate"} show={isBusy}/>}</BusyFromPromise>}
                    {props.errors && (<ErrorPanel errors={props.errors.errors} message={props.errors.message} parsedHelpLinks={props.errors.parsedHelpLinks} helpText={props.errors.helpText} helpLink={props.errors.helpLink} statusCode={props.errors.statusCode}/>)}
                    <ConsumerContent>{props.children}</ConsumerContent>
                </div>
            </div>
        </main>);
}
function ConsumerContent({ children }: React.PropsWithChildren<{}>) {
    return (<div className={styles.consumerContent}>
            <TransitionAnimation>{children}</TransitionAnimation>
        </div>);
}
function AreaHeaderVNext({ areaTitle, primaryAction, pageActions, overflowActions }: Pick<LoadedFullWidthPageLayoutProps, "areaTitle" | "primaryAction" | "pageActions" | "overflowActions">) {
    return <PageHeaderPrimary title={areaTitle ? areaTitle : ""} primaryAction={primaryAction} actions={pageActions} overflowActions={overflowActions}></PageHeaderPrimary>;
}
