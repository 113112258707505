import React from "react";
import { useIsPageVersionControlled } from "~/areas/projects/context/useIsPageVersionControlled";
import { PageHeaderBranchSelector } from "./BranchSelector/PageHeaderBranchSelector";
export function ProjectPageTitleAccessory() {
    const isVersionControlled = useIsPageVersionControlled();
    if (!isVersionControlled) {
        return null;
    }
    return <PageHeaderBranchSelector />;
}
