import type { FieldErrors } from "@octopusdeploy/octopus-react-client";
import { ValidationError } from "@octopusdeploy/octopus-react-client";
import { OctopusError } from "@octopusdeploy/octopus-server-client";
import React, { useCallback, useContext } from "react";
import { useRequiredContext } from "../../hooks";
import type { Errors } from "../DataBaseComponent";
import { DatabaseComponentContextProvider } from "../DataBaseComponent/DataBaseComponentContext";
import { createErrorsFromOctopusError } from "../DataBaseComponent/Errors";
type CurrentErrorActions = {
    setOctopusError: (errors: OctopusError) => void;
    setValidationErrors: (message: string, errors: FieldErrors) => void;
    clearErrors: () => void;
};
type CurrentErrorState = OctopusError | ValidationError | null;
const CurrentErrorContext = React.createContext<CurrentErrorState | undefined>(undefined);
CurrentErrorContext.displayName = "CurrentErrorContext";
const CurrentErrorActionContext = React.createContext<CurrentErrorActions | undefined>(undefined);
CurrentErrorActionContext.displayName = "CurrentErrorActionContext";
type ErrorContextProviderProps = {
    onError?: (error: OctopusError) => void;
};
export const ErrorContextProvider: React.FC<ErrorContextProviderProps> = ({ onError, children }) => {
    const [currentError, setCurrentError] = React.useState<CurrentErrorState>(null);
    const actions: CurrentErrorActions = React.useMemo(() => ({
        setOctopusError: (error: OctopusError) => {
            setCurrentError(error);
            onError?.(error);
        },
        setValidationErrors: (message, errors) => setCurrentError(new ValidationError(message, errors)),
        clearErrors: () => setCurrentError(null),
    }), [setCurrentError, onError]);
    return (<CurrentErrorContext.Provider value={currentError}>
            <CurrentErrorActionContext.Provider value={actions}>
                <DatabaseComponentContextProvider>{children}</DatabaseComponentContextProvider>
            </CurrentErrorActionContext.Provider>
        </CurrentErrorContext.Provider>);
};
ErrorContextProvider.displayName = "ErrorContextProvider"
ErrorContextProvider.displayName = "ErrorContextProvider";
export function useErrorActions() {
    return useRequiredContext(CurrentErrorActionContext, "ErrorAction");
}
export function useCurrentError() {
    const errorContext = useContext(CurrentErrorContext);
    if (errorContext === undefined) {
        throw new Error("ErrorContext could not be found");
    }
    return errorContext;
}
export function useErrors() {
    const currentError = useCurrentError();
    return React.useMemo((): Errors | undefined => {
        if (currentError === null) {
            return undefined;
        }
        if (currentError instanceof OctopusError) {
            const converted = createErrorsFromOctopusError(currentError);
            return { ...converted, fieldErrors: { ...converted.fieldErrors, ...currentError.Details } };
        }
        return { message: currentError.message, fieldErrors: currentError.fieldErrors, details: {}, errors: Object.values(currentError.fieldErrors) };
    }, [currentError]);
}
export default ErrorContextProvider;
export function useGetFieldError() {
    const errors = useErrors();
    return useCallback((name: string) => {
        const fieldErrors = errors?.fieldErrors ?? {};
        if (Object.prototype.hasOwnProperty.call(fieldErrors, name)) {
            return fieldErrors[name];
        }
        const found = Object.keys(fieldErrors).find((k) => k.toLowerCase() === name.toLowerCase());
        if (found) {
            return fieldErrors[found];
        }
        const foundPartialMatch = Object.keys(fieldErrors).find((k) => k.endsWith("." + name));
        if (foundPartialMatch) {
            return fieldErrors[foundPartialMatch];
        }
    }, [errors]);
}
