import { css } from "@emotion/css";
import { ActionButton } from "@octopusdeploy/design-system-components";
import { space } from "@octopusdeploy/design-system-tokens";
import type { ChannelGitResourceRuleResource, ChannelResource, GitRefResource, OctopusError, ProjectResource, ValidateGitRefV2Response } from "@octopusdeploy/octopus-server-client";
import { HasGitPersistenceSettings } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { v4 } from "uuid";
import type { DoBusyTask } from "~/components/DataBaseComponent";
import ExternalLink from "~/components/Navigation/ExternalLink";
import RemovableExpandersList from "~/components/RemovableExpandersList";
import WarningPanel from "~/components/WarningPanel/WarningPanel";
import type { SummaryNode } from "~/components/form";
import { ExpandableFormSection, FormSectionHeading, Note, Summary } from "~/components/form";
import { GitProtectionRules } from "./GitProtectionRules/GitProtectionRules";
import type { DeploymentActionGitDependencyDetails } from "./GitProtectionRules/GitResourceRule";
import { GitResourceRule, getGitResourceRuleSummary } from "./GitProtectionRules/GitResourceRule";
import { MatchingGitRefs } from "./GitProtectionRules/MatchingGitRefs";
import { getGitReferencePatternDescription } from "./GitProtectionRules/getGitReferenceRulePatternsDescription";
function GitReferenceRulesSummary(project: ProjectResource, gitReferenceRules: string[]): SummaryNode {
    const repositoryUrl = HasGitPersistenceSettings(project.PersistenceSettings) ? project.PersistenceSettings.Url : undefined;
    const gitReferenceRulesDescription = getGitReferencePatternDescription(gitReferenceRules);
    if (gitReferenceRulesDescription === undefined) {
        return Summary.summary(<>
                Releases can be created from any Git reference in repository <strong>{repositoryUrl}</strong>
            </>);
    }
    return Summary.summary(<>
            Releases can only be created from {gitReferenceRulesDescription} in repository <strong>{repositoryUrl}</strong>
        </>);
}
type ChannelGitProtectionRulesEditProps = {
    doBusyTask: DoBusyTask;
    project: ProjectResource;
    channel: ChannelResource;
    gitRef?: GitRefResource;
    gitRefValidationError?: ValidateGitRefV2Response;
    gitDependencyActions: DeploymentActionGitDependencyDetails[];
    deploymentActionsError?: OctopusError;
    onGitReferenceRulesChanged: (gitReferenceRules: string[]) => void;
    onGitResourceRulesChanged: (gitResourceRules: ChannelGitResourceRuleResource[]) => void;
};
export function ChannelGitProtectionRulesEdit({ doBusyTask, project, channel, gitRef, gitRefValidationError, gitDependencyActions, deploymentActionsError, onGitReferenceRulesChanged, onGitResourceRulesChanged }: ChannelGitProtectionRulesEditProps) {
    const addGitResourceRule = () => {
        const rule: ChannelGitResourceRuleResource = {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            Id: v4(),
            GitDependencyActions: [],
            Rules: [],
        };
        onGitResourceRulesChanged([...channel.GitResourceRules, rule]);
    };
    const handleGitResourceRuleDeleteByIndex = (index: number) => {
        onGitResourceRulesChanged(channel.GitResourceRules.filter((x, i) => i !== index));
    };
    const onGitResourceRuleChanged = (rule: ChannelGitResourceRuleResource) => {
        const index = channel.GitResourceRules.findIndex((r) => r.Id === rule.Id);
        const newRules = [...channel.GitResourceRules];
        newRules[index] = rule;
        onGitResourceRulesChanged(newRules);
    };
    const styles = {
        gitResourceRulesActions: css({
            display: "flex",
            alignItems: "center",
            gap: space[8],
        }),
    };
    return (<>
            <FormSectionHeading title="Git Protection Rules"/>
            {project.IsVersionControlled && (<ExpandableFormSection errorKey="newGitReferenceRules" title="Project Repository" summary={GitReferenceRulesSummary(project, channel.GitReferenceRules)} help="Restrict which branches and tags can be used as the source of the deployment process and variables when creating releases in this channel">
                    {HasGitPersistenceSettings(project.PersistenceSettings) && (<Note>
                            The deployment process and variables for this project are stored in the Git repository <code>{project.PersistenceSettings.Url}</code>
                        </Note>)}
                    <GitProtectionRules patterns={channel.GitReferenceRules} onPatternsChanged={onGitReferenceRulesChanged}/>
                    <MatchingGitRefs doBusyTask={doBusyTask} project={project} patterns={channel.GitReferenceRules}/>
                </ExpandableFormSection>)}
            {gitRefValidationError && (<WarningPanel title={`An error has occurred showing steps that use external Git repositories in the deployment process: ${gitRefValidationError.Message}. Please select a different branch or tag from the deployment process screen to view steps.`} warnings={[]}/>)}
            {deploymentActionsError && <WarningPanel title={`An error has occurred showing steps that use external Git repositories in the deployment process: ${deploymentActionsError.ErrorMessage}`} warnings={deploymentActionsError.Errors ?? []}/>}
            <RemovableExpandersList helpElement={<div>
                        Add rules to restrict which branches and tags can be used for steps that source files from {project.IsVersionControlled ? "an external" : "a"} Git repository.{" "}
                        <Note>
                            Learn about <ExternalLink href={"ChannelGitResourceRules"}>Git protection rules</ExternalLink>.
                        </Note>
                    </div>} typeDisplayName={"External Repository Rule"} data={channel.GitResourceRules} listActions={[
            <div className={styles.gitResourceRulesActions} data-testid="GitResourceRules-actions">
                        <ActionButton key="AddGitResourceRule" label="Add rule" onClick={addGitResourceRule}/>
                    </div>,
        ]} onRow={(item: ChannelGitResourceRuleResource, index: number) => {
            const gitDependencyActionsNotAlreadySpecifiedInARule = gitDependencyActions.filter((a) => !channel.GitResourceRules.some((r) => r.GitDependencyActions.some((d) => d.DeploymentActionSlug === a.DeploymentActionSlug && d.GitDependencyName === a.GitDependencyName)));
            return (<GitResourceRule index={index} project={project} allDeploymentActionsWithGitDependencies={gitDependencyActions} deploymentActionsError={deploymentActionsError} gitRef={gitRef} gitRefValidationError={gitRefValidationError} availableDeploymentActionsWithGitDependencies={gitDependencyActionsNotAlreadySpecifiedInARule} rule={item} onRuleChanged={onGitResourceRuleChanged}/>);
        }} onRowSummary={(item: ChannelGitResourceRuleResource) => {
            return getGitResourceRuleSummary(item, gitDependencyActions);
        }} onRowHelp={() => {
            return `Add rules to restrict which branches and tags can be used for steps that source files from ${project.IsVersionControlled ? "an external" : "a"} Git repository.`;
        }} onRemoveRowByIndex={handleGitResourceRuleDeleteByIndex}/>
        </>);
}
