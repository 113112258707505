import { css } from "@emotion/css";
import { borderRadius, space, text, themeTokens } from "@octopusdeploy/design-system-tokens";
import type { EnvironmentResource, LifecycleResource, ResourcesById } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import LifecycleMap from "~/areas/library/components/Lifecycle/LifecycleMap";
import { ExternalLinkIcon } from "~/components/Navigation/ExternalLink/ExternalLink";
import InternalLink from "~/components/Navigation/InternalLink";
interface TriggerLifecyclePreviewProps {
    lifecycle: LifecycleResource;
    environmentLookup: ResourcesById<EnvironmentResource>;
    spaceId: string;
}
export function TriggerLifecyclePreview({ lifecycle, environmentLookup, spaceId }: TriggerLifecyclePreviewProps) {
    const styles = {
        container: css({ display: "flex", flexDirection: "column", gap: space[8], marginTop: space[16] }),
        content: css({ display: "flex", flexDirection: "column", gap: space[8] }),
        box: css({ borderColor: themeTokens.color.border.primary, borderWidth: space[1], borderStyle: "solid", borderRadius: borderRadius.small, padding: space[16], paddingBottom: 0 }),
        heading: css({ font: text.regular.bold.large }),
    };
    return (<div className={styles.container}>
            <div className={styles.box}>
                <div className={styles.content}>
                    <div className={styles.heading}>Lifecycle preview</div>
                    <div>Here’s how releases created by this trigger will be deployed:</div>
                </div>
                <LifecycleMap lifecyclePreview={lifecycle} environmentsById={environmentLookup}/>
            </div>
            <InternalLink size={0.875} to={links.editLifecyclePage.generateUrl({ spaceId, lifecycleId: lifecycle.Id })} openInSelf={false}>
                Edit lifecycle <ExternalLinkIcon />
            </InternalLink>
        </div>);
}
