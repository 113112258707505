import { css } from "@emotion/css";
import { Tooltip } from "@octopusdeploy/design-system-components";
import { space, text, themeTokens } from "@octopusdeploy/design-system-tokens";
import type { Url } from "@octopusdeploy/portal-routes";
import type { ReactNode } from "react";
import React from "react";
import Card from "~/components/Card";
import { useUrlResolver } from "~/components/Navigation/useUrlResolver";
export interface AddProjectTriggerCardProps {
    title: string;
    description: string;
    icon: ReactNode;
    path: Url;
    disabled?: boolean;
    disabledMessage?: string;
    chip?: ReactNode;
}
export function AddProjectTriggerCard({ title, description, icon, path, disabled, disabledMessage, chip }: AddProjectTriggerCardProps) {
    const urlResolver = useUrlResolver();
    const resolvedPath = path ? urlResolver.resolve(path) : "";
    const card = (<Card link={!disabled && (<a href={"#" + resolvedPath} className={styles.link}>
                        Add a {title} trigger
                    </a>)} logo={<div className={styles.logo(!disabled)}>{icon}</div>} header={<div className={styles.header}>
                    {title}
                    {chip}
                </div>} content={description} className={styles.card(disabled ?? false)} contentClassName={styles.content} leftAlign={true} isDisabled={disabled} hideDisabledChip={true}/>);
    if (disabled) {
        return <Tooltip content={disabledMessage}>{card}</Tooltip>;
    }
    return card;
}
const styles = {
    // The card component has the link inside the main card, which messes with the `gap` spacing
    // To address this, we ensure there will always be an extra 4px gap and knock if off the card's top padding
    card: (disabled: boolean) => css({
        "&&": {
            flex: 1,
            minWidth: "100%",
            width: "auto",
            gap: space[4],
            margin: 0,
            padding: space[16],
            paddingTop: space[12],
            borderRadius: space[8],
            "@media (min-width: 600px)": {
                minWidth: "230px",
            },
            "@media (min-width: 784px)": {
                // Magic number lines up with the automatic width when used in a dialog 800px wide
                maxWidth: "234.67px",
            },
            "&:hover": disabled
                ? {
                    boxShadow: themeTokens.shadow.small,
                    backgroundColor: themeTokens.color.background.primary.default,
                }
                : {},
        },
    }),
    logo: (cardHasLink: boolean) => css({ marginBottom: space[8], marginTop: cardHasLink ? 0 : space[4], lineHeight: 0 }),
    header: css({
        font: text.regular.bold.medium,
        margin: 0,
        marginBottom: space[4],
        // The card component adds padding to only the heading for small screen sizes, this cancels that out
        "@media (max-width: 600px)": {
            marginLeft: -8,
        },
    }),
    content: css({
        "&&": {
            width: "auto",
            font: text.regular.default.small,
            margin: 0,
            // The card component hides the content on small screen sizes by default
            display: "block",
        },
    }),
    link: css({ opacity: 0 }),
};
