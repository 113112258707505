import { colorScales } from "@octopusdeploy/design-system-tokens";
export interface OctopusTheme {
    // Constants
    whiteConstant: string;
    blackConstant: string;
    cyanConstant: string;
    transparent: string;
    // Paper
    paper0: string;
    paper1: string;
    paper2: string;
    paper3: string;
    // Text
    primaryText: string;
    secondaryText: string;
    ternaryText: string;
    highlightText: string;
    highlightTextBackground: string;
    // Text links
    linkText: string;
    linkTextHover: string;
    sideMenuHover: string;
    sideMenuNestedText: string;
    sidebarMenuSelectedText: string;
    // Nav
    navBackground: string;
    navItemHover: string;
    navItemActive: string;
    navText: string;
    navTextHover: string;
    navTextActive: string;
    spaceMenuBackground: string;
    spaceMenuText: string;
    // Tabs
    tabActiveIndicator: string;
    // Buttons
    primaryButtonBackground: string;
    primaryButtonText: string;
    secondaryButtonBackground: string;
    secondaryButtonText: string;
    ternaryButton: string;
    ternaryButtonHover: string;
    categoryButtonBackground: string;
    disabledButtonBorder: string;
    disabledButtonBackground: string;
    disabledButtonText: string;
    createReleaseBackground: string;
    createReleaseText: string;
    deleteButtonText: string;
    deleteButtonBackground: string;
    cancelButtonBackground: string;
    cancelButtonText: string;
    subtleButtonBackground: string;
    subtleButtonBorder: string;
    // Paging
    pagingButtonBackground: string;
    pagingButtonBackgroundHover: string;
    pagingButtonBackgroundActive: string;
    // Dashboard
    tenantCount: string;
    // Section
    SectionBodyText: string;
    groupHeaderBackground: string;
    groupHeader: string;
    statusBarBackground: string;
    statusBarItemBackground: string;
    contextualHelpBackground: string;
    // Backgrounds & dividers
    primaryBackground: string;
    disabledBackground: string;
    secondaryBackground: string;
    secondaryBackgroundLight: string;
    ternaryBackground: string;
    hover: string;
    divider: string;
    dividerLight: string;
    secondaryDivider: string;
    teritaryDivider: string;
    logoBackground: string;
    stepLogoBackground: string;
    parentStepBackground: string;
    loadingIndicator: string;
    loadingIndicatorBackground: string;
    userOnboardingHelpPanelBackground: string;
    // Cards
    cardBackground: string;
    cardHover: string;
    cardFooterBuiltIn: string;
    cardFooterInstalled: string;
    cardFooterCommunity: string;
    // Form
    focus: string;
    selectBorder: string;
    focusIconButton: string;
    // Chips
    chipBackground: string;
    chipHighlight: string;
    chipText: string;
    chipIcon: string;
    chipDeleteButton: string;
    avatarBackground: string;
    // Code Editor hints
    hintHighlight: string;
    hintActive: string;
    placeholder: string;
    placeholderActive: string;
    codeEditorBackground: string;
    codeEditorToolbarBackground: string;
    codeEditorGutterTextColor: string;
    codeEditorDefaultTextColor: string;
    codeEditorTextBright: string;
    codeEditorTextHighlight: string;
    codeEditorToolbarButtonHover: string;
    codeEditorType: string;
    codeEditorVariable: string;
    codeEditorVariable2: string;
    codeEditorOperator: string;
    codeEditorBuiltIn: string;
    codeEditorNumber: string;
    codeEditorAtom: string;
    codeEditorDef: string;
    codeEditorString: string;
    codeEditorQualifier: string;
    // Icon Editor
    iconEditorIcon: string;
    iconEditorIconHover: string;
    iconEditorColorPaletteInnerBorder: string;
    iconEditorColorPaletteHover: string;
    iconEditorColorPaletteSelected: string;
    // Callouts
    info: string;
    infoConstant: string;
    infoBackground: string;
    infoHeaderText: string;
    infoText: string;
    infoHighlight: string;
    infoBorder: string;
    success: string;
    successConstant: string;
    successBackground: string;
    successCalloutBackground: string;
    successHeaderText: string;
    successText: string;
    successHighlight: string;
    successBorder: string;
    danger: string;
    dangerConstant: string;
    dangerBackground: string;
    dangerCalloutBackground: string;
    dangerHeaderText: string;
    dangerText: string;
    dangerHighlight: string;
    dangerBorder: string;
    alert: string;
    alertBackground: string;
    alertCalloutBackground: string;
    alertConstant: string;
    alertHeaderText: string;
    alertText: string;
    alertHighlight: string;
    alertBorder: string;
    alertIcon: string;
    sunshineText: string;
    darkBackground: string;
    darkText: string;
    auditHighlight: string;
    codeHighlight: string;
    codeText: string;
    codeTextBright: string;
    featureText: string;
    featureBackground: string;
    featureCalloutBackground: string;
    featureBorder: string;
    experimentText: string;
    experimentBackground: string;
    experimentCalloutBackground: string;
    // Task log
    successTaskLogBackground: string;
    successTaskLogBorder: string;
    successTaskLogText: string;
    dangerTaskLogBackground: string;
    dangerTaskLogBorder: string;
    dangerTaskLogText: string;
    alertTaskLogBackground: string;
    alertTaskLogText: string;
    unknownTaskLogBackground: string;
    unknownTaskLogBorder: string;
    unknownTaskLogText: string;
    runningTaskLogText: string;
    //difs
    diffAddedBackground: string;
    diffAddedColor: string;
    diffWordAddedBackground: string;
    diffWordAddedColor: string;
    diffRemovedBackground: string;
    diffRemovedColor: string;
    diffWordRemovedBackground: string;
    diffWordRemovedColor: string;
    diffGutterBackground: string;
    diffAddedGutterColor: string;
    diffAddedGutterBackground: string;
    diffRemovedGutterColor: string;
    diffRemovedGutterBackground: string;
    diffGutterColor: string;
    diffHighlight: string;
    // Form validation
    errorText: string;
    // icons
    iconNavy: string;
    iconDark: string;
    iconLight: string;
    iconNeutral: string;
    iconLifeCycle: string;
    // Tooltip
    tooltipText: string;
    tooltipBackground: string;
    // Primary color
    mainBackground: string;
    mainText: string;
    // Will be superceeded
    primary: string;
    primaryLight: string;
    primaryDark: string;
    primaryDarkest: string;
    primaryLightest: string;
    secondaryDark: string;
    // Images
    imgNavy: string;
    imgCyan: string;
    imgCyan50: string;
    imgWhite: string;
    imgBlueGrey: string;
    imgLightGrey: string;
    imgGrey: string;
    imgLight: string;
    imgDark: string;
    imgGreen: string;
    imgDanger: string;
    // Wizard
    wizardStepBackground: string;
    wizardStepBackgroundActive: string;
}
export const LightTheme: OctopusTheme = {
    //Primary - these colours don't change between themes
    whiteConstant: colorScales.white,
    blackConstant: colorScales.black,
    cyanConstant: colorScales.blue["500"],
    transparent: "transparent",
    paper0: colorScales.white,
    paper1: colorScales.white, // Paper background
    paper2: colorScales.white, // Card background
    paper3: colorScales.white, // Card hover
    // Text
    primaryText: colorScales.grey["800"],
    secondaryText: colorScales.grey["500"],
    ternaryText: colorScales.grey["600"],
    highlightText: colorScales.yellow["200"],
    highlightTextBackground: colorScales.black,
    // Text links
    linkText: colorScales.blue["500"],
    linkTextHover: colorScales.blue["600"],
    sideMenuHover: colorScales.white,
    sideMenuNestedText: colorScales.grey["600"],
    sidebarMenuSelectedText: colorScales.blue["500"],
    // Nav
    navBackground: colorScales.blue["500"],
    navItemHover: colorScales.blue["300"],
    navItemActive: colorScales.blue["600"],
    navText: colorScales.white,
    navTextHover: colorScales.white,
    navTextActive: colorScales.white,
    spaceMenuBackground: colorScales.navy["900"],
    spaceMenuText: colorScales.white,
    // Tabs
    tabActiveIndicator: colorScales.blue["500"],
    // Buttons
    primaryButtonBackground: colorScales.green["500"],
    primaryButtonText: colorScales.white,
    secondaryButtonBackground: colorScales.blue["100"],
    secondaryButtonText: colorScales.blue["500"],
    ternaryButton: colorScales.blue["500"],
    ternaryButtonHover: colorScales.blue["600"],
    categoryButtonBackground: colorScales.blue["500"],
    disabledButtonBorder: colorScales.grey["300"],
    disabledButtonBackground: colorScales.grey["200"],
    disabledButtonText: colorScales.grey["500"],
    createReleaseBackground: colorScales.navy["900"],
    createReleaseText: colorScales.white,
    deleteButtonText: colorScales.white,
    deleteButtonBackground: colorScales.red["500"],
    cancelButtonBackground: colorScales.orange["500"],
    cancelButtonText: colorScales.white,
    subtleButtonBackground: colorScales.slate["200"],
    subtleButtonBorder: colorScales.blue["100"],
    // Paging
    pagingButtonBackground: colorScales.grey["300"],
    pagingButtonBackgroundHover: colorScales.grey["500"],
    pagingButtonBackgroundActive: colorScales.grey["500"],
    // Dashboard
    tenantCount: colorScales.white,
    // Section
    SectionBodyText: colorScales.grey["800"],
    groupHeaderBackground: colorScales.white,
    groupHeader: colorScales.slate[900],
    statusBarBackground: colorScales.slate[100],
    statusBarItemBackground: colorScales.white,
    contextualHelpBackground: colorScales.white,
    // Backgrounds & dividers
    primaryBackground: colorScales.grey["100"],
    disabledBackground: colorScales.grey["200"],
    secondaryBackground: colorScales.grey["100"],
    secondaryBackgroundLight: colorScales.grey["200"],
    ternaryBackground: colorScales.grey["200"],
    hover: colorScales.grey["200"],
    divider: colorScales.grey["400"],
    dividerLight: colorScales.grey["200"],
    secondaryDivider: colorScales.grey["300"],
    teritaryDivider: colorScales.grey["200"],
    logoBackground: "transparent",
    stepLogoBackground: colorScales.white,
    parentStepBackground: colorScales.blue["600"],
    loadingIndicator: colorScales.blue["300"],
    loadingIndicatorBackground: colorScales.grey["300"],
    userOnboardingHelpPanelBackground: "rgb(18, 37, 54)",
    // Cards
    cardBackground: colorScales.white,
    cardHover: colorScales.navy[100],
    cardFooterBuiltIn: colorScales.blue["500"],
    cardFooterInstalled: colorScales.grey["400"],
    cardFooterCommunity: colorScales.white,
    // Form
    focus: colorScales.blue["500"],
    selectBorder: colorScales.grey["500"],
    focusIconButton: colorScales.blue["300"],
    // Chips
    chipBackground: colorScales.grey["200"],
    chipHighlight: colorScales.navy["800"],
    chipText: colorScales.grey["800"],
    chipIcon: colorScales.grey["800"],
    chipDeleteButton: colorScales.grey["500"],
    avatarBackground: colorScales.grey["300"],
    // Code Editor hints
    hintHighlight: colorScales.blue["500"],
    hintActive: colorScales.blue["300"],
    placeholder: colorScales.black,
    placeholderActive: colorScales.black,
    codeEditorBackground: colorScales.navy["900"],
    codeEditorToolbarBackground: colorScales.navy["800"],
    codeEditorGutterTextColor: colorScales.navy["500"],
    codeEditorDefaultTextColor: colorScales.navy["200"],
    codeEditorTextBright: colorScales.purple["300"],
    codeEditorTextHighlight: colorScales.blue["600"],
    codeEditorToolbarButtonHover: colorScales.navy["800"],
    codeEditorType: colorScales.yellow["300"],
    codeEditorVariable: colorScales.red["200"],
    codeEditorVariable2: colorScales.blue["200"],
    codeEditorOperator: colorScales.blue["300"],
    codeEditorBuiltIn: colorScales.orange["300"],
    codeEditorNumber: colorScales.red["200"],
    codeEditorAtom: colorScales.orange["300"],
    codeEditorDef: colorScales.blue["200"],
    codeEditorString: colorScales.green["300"],
    codeEditorQualifier: colorScales.yellow["300"],
    // Icon Editor
    iconEditorIcon: colorScales.slate["100"],
    iconEditorIconHover: colorScales.slate["200"],
    iconEditorColorPaletteInnerBorder: colorScales.white,
    iconEditorColorPaletteHover: colorScales.grey["300"],
    iconEditorColorPaletteSelected: colorScales.blue["500"],
    // Callouts
    info: colorScales.blue["500"],
    infoConstant: colorScales.blue["500"],
    infoBackground: colorScales.blue["100"],
    infoBorder: colorScales.blue["500"],
    infoHeaderText: colorScales.blue[600],
    infoText: colorScales.blue["500"],
    infoHighlight: colorScales.blue["100"],
    success: colorScales.green["500"],
    successConstant: colorScales.green["500"],
    successBackground: colorScales.green["100"],
    successCalloutBackground: colorScales.green["100"],
    successBorder: colorScales.green["500"],
    successHeaderText: colorScales.green[600],
    successText: colorScales.green[600],
    successHighlight: colorScales.green["100"],
    danger: colorScales.red["500"],
    dangerConstant: colorScales.red["500"],
    dangerBackground: colorScales.red["100"],
    dangerCalloutBackground: colorScales.red["100"],
    dangerBorder: colorScales.red["500"],
    dangerHeaderText: colorScales.red[600],
    dangerText: colorScales.red["500"],
    dangerHighlight: colorScales.red["100"],
    alert: colorScales.orange["500"],
    alertBackground: colorScales.orange["100"],
    alertCalloutBackground: colorScales.yellow[100],
    alertBorder: colorScales.orange["500"],
    alertConstant: colorScales.orange["500"],
    alertHeaderText: colorScales.yellow[600],
    alertText: colorScales.orange["500"],
    alertHighlight: colorScales.orange["100"],
    alertIcon: colorScales.orange["500"],
    sunshineText: colorScales.yellow["300"],
    darkBackground: colorScales.grey["800"],
    darkText: colorScales.white,
    auditHighlight: colorScales.grey["200"],
    codeHighlight: colorScales.navy["200"],
    codeText: colorScales.navy["600"],
    codeTextBright: colorScales.navy["600"],
    featureText: colorScales.navy[600],
    featureBackground: colorScales.blue["100"],
    featureCalloutBackground: colorScales.navy[100],
    featureBorder: "transparent",
    experimentText: colorScales.purple[700],
    experimentBackground: colorScales.purple[200],
    experimentCalloutBackground: colorScales.purple[200],
    // Task log
    successTaskLogBackground: colorScales.green[100],
    successTaskLogBorder: colorScales.green[400],
    successTaskLogText: colorScales.green[600],
    dangerTaskLogBackground: colorScales.red[100],
    dangerTaskLogBorder: colorScales.red[400],
    dangerTaskLogText: colorScales.red[600],
    alertTaskLogBackground: colorScales.yellow[100],
    alertTaskLogText: colorScales.yellow[600],
    unknownTaskLogBackground: colorScales.slate[100],
    unknownTaskLogBorder: colorScales.slate[400],
    unknownTaskLogText: colorScales.slate[600],
    runningTaskLogText: colorScales.blue[600],
    //Diffs
    diffAddedBackground: colorScales.green["200"],
    diffAddedColor: colorScales.green["700"],
    diffWordAddedBackground: colorScales.green["300"],
    diffWordAddedColor: colorScales.green["700"],
    diffRemovedBackground: colorScales.red["200"],
    diffRemovedColor: colorScales.red["700"],
    diffWordRemovedBackground: colorScales.red["300"],
    diffWordRemovedColor: colorScales.red["700"],
    diffGutterBackground: colorScales.white,
    diffGutterColor: colorScales.grey["800"],
    diffRemovedGutterBackground: colorScales.red["300"],
    diffRemovedGutterColor: colorScales.red["700"],
    diffAddedGutterBackground: colorScales.green["300"],
    diffAddedGutterColor: colorScales.green["700"],
    diffHighlight: colorScales.grey["200"],
    // Form validation
    errorText: colorScales.red["400"],
    // icons
    iconNavy: colorScales.navy["900"],
    iconDark: colorScales.navy["900"],
    iconLight: colorScales.white,
    iconNeutral: colorScales.grey["600"],
    iconLifeCycle: colorScales.grey["400"],
    // Tooltip
    tooltipText: colorScales.white,
    tooltipBackground: colorScales.grey["800"],
    // Primary color
    mainBackground: colorScales.blue["500"],
    mainText: colorScales.blue["500"],
    // Will be superceeded
    primary: colorScales.blue["500"],
    primaryLight: colorScales.blue["300"],
    primaryDark: colorScales.blue["600"],
    primaryDarkest: colorScales.navy["800"],
    primaryLightest: colorScales.slate["200"],
    secondaryDark: colorScales.blue["800"],
    // Images
    imgNavy: colorScales.navy["900"],
    imgCyan: colorScales.blue["500"],
    imgCyan50: colorScales.blue["600"],
    imgWhite: colorScales.white,
    imgBlueGrey: colorScales.slate["200"],
    imgLightGrey: colorScales.grey["200"],
    imgGrey: colorScales.grey["100"],
    imgLight: colorScales.white,
    imgDark: colorScales.navy["900"],
    imgGreen: colorScales.green["500"],
    imgDanger: colorScales.red["500"],
    wizardStepBackground: colorScales.grey["200"],
    wizardStepBackgroundActive: colorScales.blue["500"],
};
export const DarkTheme: OctopusTheme = {
    //Primary - these colours don't change between themes
    blackConstant: colorScales.black,
    whiteConstant: colorScales.slate["200"],
    cyanConstant: colorScales.blue["700"],
    transparent: "transparent",
    // Paper
    paper0: colorScales.navy["900"],
    paper1: colorScales.navy["800"],
    paper2: colorScales.navy["700"],
    paper3: colorScales.navy["900"],
    // Text
    primaryText: colorScales.blue["100"],
    secondaryText: colorScales.navy["300"],
    ternaryText: colorScales.navy["200"],
    highlightText: colorScales.yellow["200"],
    highlightTextBackground: colorScales.black,
    // Text links
    linkText: colorScales.blue["300"],
    linkTextHover: colorScales.white,
    sideMenuHover: colorScales.navy["800"],
    sideMenuNestedText: colorScales.blue["100"],
    sidebarMenuSelectedText: colorScales.blue["300"],
    // Nav
    navBackground: colorScales.navy["900"],
    navItemHover: colorScales.blue["800"],
    navItemActive: colorScales.blue["700"],
    navText: colorScales.navy["200"],
    navTextHover: colorScales.white,
    navTextActive: colorScales.slate["200"],
    spaceMenuBackground: colorScales.blue["800"],
    spaceMenuText: colorScales.white,
    // Tabs
    tabActiveIndicator: colorScales.blue["500"],
    // Buttons
    primaryButtonBackground: colorScales.green["500"],
    primaryButtonText: colorScales.white,
    secondaryButtonBackground: colorScales.navy["900"],
    secondaryButtonText: colorScales.blue["500"],
    ternaryButton: colorScales.blue["300"],
    ternaryButtonHover: colorScales.slate["200"],
    categoryButtonBackground: colorScales.blue["500"],
    disabledButtonBorder: colorScales.navy["300"],
    disabledButtonBackground: colorScales.navy["700"],
    disabledButtonText: colorScales.navy["300"],
    createReleaseBackground: colorScales.blue["700"],
    createReleaseText: colorScales.slate["200"],
    deleteButtonText: colorScales.white,
    deleteButtonBackground: colorScales.red["600"],
    cancelButtonBackground: colorScales.orange["300"],
    cancelButtonText: colorScales.white,
    subtleButtonBackground: colorScales.navy["900"],
    subtleButtonBorder: colorScales.slate["500"],
    // Paging
    pagingButtonBackground: colorScales.blue["800"],
    pagingButtonBackgroundHover: colorScales.blue["500"],
    pagingButtonBackgroundActive: colorScales.blue["500"],
    // Dashboard
    tenantCount: colorScales.grey["800"],
    // Section
    SectionBodyText: colorScales.navy["200"],
    groupHeaderBackground: colorScales.navy[800],
    groupHeader: colorScales.navy[100],
    statusBarBackground: colorScales.navy["900"],
    statusBarItemBackground: colorScales.navy["800"],
    contextualHelpBackground: colorScales.navy["900"],
    // Backgrounds & dividers
    primaryBackground: colorScales.navy["900"],
    disabledBackground: "rgba(153, 153, 153, 0.7)",
    secondaryBackground: colorScales.navy["700"],
    secondaryBackgroundLight: colorScales.navy["600"],
    ternaryBackground: colorScales.navy["900"],
    hover: "rgba(15, 36, 52, 0.9)",
    divider: colorScales.slate["500"],
    dividerLight: colorScales.navy["700"],
    secondaryDivider: colorScales.navy["300"],
    teritaryDivider: colorScales.slate["500"],
    logoBackground: colorScales.blue["100"],
    stepLogoBackground: colorScales.slate["200"],
    parentStepBackground: colorScales.blue["600"],
    loadingIndicator: colorScales.blue["700"],
    loadingIndicatorBackground: colorScales.navy["900"],
    userOnboardingHelpPanelBackground: "rgb(18, 37, 54)",
    // Cards
    cardBackground: colorScales.navy[700],
    cardHover: colorScales.navy[600],
    cardFooterBuiltIn: colorScales.blue["500"],
    cardFooterInstalled: colorScales.navy["900"], //paper0,
    cardFooterCommunity: colorScales.navy["900"], // paper3,
    // Form
    focus: colorScales.blue["500"],
    selectBorder: colorScales.grey["500"],
    focusIconButton: colorScales.blue["500"],
    // Chips
    chipBackground: colorScales.navy["700"],
    chipHighlight: colorScales.blue["300"],
    chipText: colorScales.blue["100"],
    chipIcon: colorScales.blue["100"],
    chipDeleteButton: colorScales.grey["300"],
    avatarBackground: colorScales.blue["600"],
    // Code Editor hints
    hintHighlight: colorScales.red["400"],
    hintActive: colorScales.blue["700"],
    placeholder: colorScales.white,
    placeholderActive: colorScales.white,
    codeEditorBackground: colorScales.navy["900"],
    codeEditorToolbarBackground: colorScales.navy["800"],
    codeEditorGutterTextColor: colorScales.navy["500"],
    codeEditorDefaultTextColor: colorScales.navy["200"],
    codeEditorTextBright: colorScales.purple["300"],
    codeEditorTextHighlight: colorScales.blue["600"],
    codeEditorToolbarButtonHover: colorScales.navy["800"],
    codeEditorType: colorScales.yellow["300"],
    codeEditorVariable: colorScales.red["200"],
    codeEditorVariable2: colorScales.blue["200"],
    codeEditorOperator: colorScales.blue["300"],
    codeEditorBuiltIn: colorScales.orange["300"],
    codeEditorNumber: colorScales.red["200"],
    codeEditorAtom: colorScales.orange["300"],
    codeEditorDef: colorScales.blue["200"],
    codeEditorString: colorScales.green["300"],
    codeEditorQualifier: colorScales.yellow["300"],
    // Icon Editor
    iconEditorIcon: colorScales.navy["800"],
    iconEditorIconHover: colorScales.navy["700"],
    iconEditorColorPaletteInnerBorder: colorScales.white,
    iconEditorColorPaletteHover: colorScales.grey["300"],
    iconEditorColorPaletteSelected: colorScales.blue["500"],
    // Callouts
    info: colorScales.blue["500"],
    infoConstant: colorScales.blue["500"],
    infoBackground: colorScales.blue["700"],
    infoBorder: colorScales.blue["300"],
    infoHeaderText: colorScales.blue["300"],
    infoText: colorScales.blue["300"],
    infoHighlight: "rgba(0, 0, 0, 0)",
    success: colorScales.green["500"],
    successConstant: colorScales.green["500"],
    successBackground: colorScales.green["800"],
    successCalloutBackground: colorScales.green[700],
    successBorder: colorScales.green["700"],
    successHeaderText: colorScales.green[300], //For use on successBackground
    successText: colorScales.green["400"], //For use on navy background
    successHighlight: "rgba(0, 0, 0, 0)",
    danger: colorScales.red["500"],
    dangerConstant: colorScales.red["500"],
    dangerBackground: colorScales.red["800"],
    dangerCalloutBackground: colorScales.red["700"],
    dangerBorder: colorScales.red["700"],
    dangerHeaderText: colorScales.red["300"], //For use on dangerBackground
    dangerText: colorScales.red["400"], //For use on navy background
    dangerHighlight: "rgba(0, 0, 0, 0)",
    alert: colorScales.orange["500"],
    alertBackground: colorScales.orange["800"],
    alertCalloutBackground: colorScales.yellow[700],
    alertBorder: colorScales.orange["200"],
    alertConstant: colorScales.orange["500"],
    alertHeaderText: colorScales.yellow[300], //For use on alertBackground
    alertText: colorScales.orange["300"], //For use on navy background
    alertHighlight: colorScales.yellow[700],
    alertIcon: colorScales.orange["200"],
    sunshineText: colorScales.yellow["300"],
    darkBackground: colorScales.white,
    darkText: colorScales.navy["900"],
    auditHighlight: colorScales.navy["700"],
    codeHighlight: colorScales.navy["600"],
    codeText: colorScales.navy["200"],
    codeTextBright: colorScales.navy["300"],
    featureText: colorScales.navy[300],
    featureBackground: colorScales.navy["800"],
    featureCalloutBackground: colorScales.navy[700],
    featureBorder: colorScales.slate["200"],
    experimentText: colorScales.purple[700],
    experimentBackground: colorScales.purple[400],
    experimentCalloutBackground: colorScales.purple[400],
    // Task log
    successTaskLogBackground: colorScales.green[900],
    successTaskLogBorder: colorScales.green[600],
    successTaskLogText: colorScales.green[400],
    dangerTaskLogBackground: colorScales.red[900],
    dangerTaskLogBorder: colorScales.red[600],
    dangerTaskLogText: colorScales.red[400],
    alertTaskLogBackground: colorScales.yellow[900],
    alertTaskLogText: colorScales.yellow[400],
    unknownTaskLogBackground: colorScales.slate[900],
    unknownTaskLogBorder: colorScales.slate[600],
    unknownTaskLogText: colorScales.slate[400],
    runningTaskLogText: colorScales.blue[400],
    //Audit diffs
    diffAddedBackground: colorScales.green["800"],
    diffAddedColor: colorScales.green["300"],
    diffWordAddedBackground: colorScales.green["700"],
    diffWordAddedColor: colorScales.green["300"],
    diffRemovedBackground: colorScales.red["800"],
    diffRemovedColor: colorScales.red["300"],
    diffWordRemovedBackground: colorScales.red["700"],
    diffWordRemovedColor: colorScales.red["300"],
    diffGutterBackground: colorScales.navy["900"],
    diffGutterColor: colorScales.blue["100"],
    diffRemovedGutterBackground: colorScales.red["700"],
    diffRemovedGutterColor: colorScales.red["300"],
    diffAddedGutterBackground: colorScales.green["700"],
    diffAddedGutterColor: colorScales.green["300"],
    diffHighlight: colorScales.grey["200"],
    // Form validation
    errorText: colorScales.red["400"],
    // icons
    iconNavy: colorScales.navy["900"],
    iconDark: colorScales.blue["100"],
    iconLight: colorScales.navy["900"],
    iconNeutral: colorScales.grey["300"],
    iconLifeCycle: colorScales.slate["500"],
    // Tooltip
    tooltipText: colorScales.grey["800"],
    tooltipBackground: colorScales.white,
    // Primary color
    primary: colorScales.blue["300"],
    mainBackground: colorScales.navy["600"],
    mainText: colorScales.blue["300"],
    primaryLight: colorScales.slate["500"],
    primaryDark: colorScales.blue["100"],
    primaryDarkest: colorScales.navy["900"],
    primaryLightest: colorScales.blue["100"],
    secondaryDark: colorScales.blue["500"],
    // Images
    imgNavy: colorScales.navy["900"],
    imgCyan: colorScales.blue["500"],
    imgCyan50: colorScales.blue["600"],
    imgWhite: colorScales.white,
    imgBlueGrey: colorScales.navy["800"],
    imgLightGrey: colorScales.navy["300"],
    imgGrey: colorScales.navy["900"],
    imgLight: colorScales.navy["900"],
    imgDark: colorScales.white,
    imgGreen: colorScales.green["500"],
    imgDanger: colorScales.red["500"],
    wizardStepBackground: colorScales.navy["600"],
    wizardStepBackgroundActive: colorScales.blue["300"],
};
