import { Callout, Tooltip } from "@octopusdeploy/design-system-components";
import type { PageAction } from "@octopusdeploy/design-system-components";
import { useAggregateAPIOperationStatus, useMutation, useQuery } from "@octopusdeploy/octopus-react-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import type { ServerConfigurationResource, LicenseStatusResource, OctopusServerNodeSummaryResource, OctopusServerClusterSummaryResource, Repository } from "@octopusdeploy/octopus-server-client";
import { links, TaskFilterStateValues } from "@octopusdeploy/portal-routes";
import { compact } from "lodash";
import moment from "moment";
import { useState } from "react";
import * as React from "react";
import { ChangeTaskCap } from "~/areas/configuration/components/Nodes/ChangeTaskCap";
import { EditServerConfiguration } from "~/areas/configuration/components/Nodes/EditServerConfiguration";
import OpenDialogButton from "~/components/Dialog/OpenDialogButton";
import UseLabelStrategy from "~/components/LabelStrategy/LabelStrategy";
import InternalLink from "~/components/Navigation/InternalLink/InternalLink";
import { OverflowMenu, OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import { PaperLayoutVNext } from "~/components/PaperLayout/PaperLayoutVNext";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import Section from "~/components/Section";
import SidebarLayout from "~/components/SidebarLayout/SidebarLayout";
import SimpleDataTable from "~/components/SimpleDataTable/SimpleDataTable";
import type { OctopusTheme } from "~/components/Theme";
import { useOctopusTheme } from "~/components/Theme";
import { SelectInternal } from "~/primitiveComponents/form/Select/Select";
import DateFormatter from "~/utils/DateFormatter";
import styles from "./style.module.less";
export async function nodesPageLoader(repository: Repository): Promise<NodesPageLoaderData> {
    const serverConfiguration = repository.ServerConfiguration.get();
    const licenseStatus = repository.Licenses.getCurrentStatus();
    const nodesSummary = repository.OctopusServerNodes.summary();
    return {
        serverConfiguration: await serverConfiguration,
        licenseStatus: await licenseStatus,
        nodesSummary: await nodesSummary,
    };
}
interface NodesPageLoaderData {
    serverConfiguration: ServerConfigurationResource;
    licenseStatus: LicenseStatusResource;
    nodesSummary: OctopusServerClusterSummaryResource;
}
interface NodesPageProps {
    loaderData: NodesPageLoaderData;
}
export function NodesPage({ loaderData }: NodesPageProps) {
    const [filter, setFilter] = useState<NodesFilterState>(NodesFilterState.RecentlyActive);
    const [serverConfiguration, setServerConfiguration] = useState(loaderData.serverConfiguration);
    const { result: nodesSummary, refetch: refetchClusterSummary } = useQuery((repository) => repository.OctopusServerNodes.summary(), [], "Cluster Summary", { refetchIntervalInMs: 5000, initialResult: loaderData.nodesSummary });
    const filteredNodes = filterNodes(nodesSummary.Nodes, filter, moment().add(-1, "hours"));
    const theme = useOctopusTheme();
    const { errors, isInProgress } = useAggregateAPIOperationStatus();
    const { execute: deleteNode } = useMutation({
        name: "Delete node",
        action: async (repository: Repository, node: OctopusServerNodeSummaryResource) => {
            await repository.OctopusServerNodes.del(node);
        },
        afterComplete: async () => refetchClusterSummary(),
    });
    const { execute: setIsInMaintenanceMode } = useMutation({
        name: "Set maintenance mode state",
        action: async (repository: Repository, node: OctopusServerNodeSummaryResource, newIsInMaintenanceModeValue: boolean) => {
            const freshNode = await repository.OctopusServerNodes.get(node.Id);
            freshNode.IsInMaintenanceMode = newIsInMaintenanceModeValue;
            await repository.OctopusServerNodes.modify(freshNode);
        },
        afterComplete: async () => refetchClusterSummary(),
    });
    const configurationSettingsPageAction: PageAction = {
        type: "navigate",
        buttonType: "secondary",
        hasPermissions: isAllowed({ permission: Permission.AdministerSystem }),
        label: "Server settings",
        path: links.serverSettingsPage.generateUrl(),
    };
    const onRow = (node: OctopusServerNodeSummaryResource) => rowElements(node, theme, loaderData.licenseStatus, nodesSummary.Nodes, deleteNode, setIsInMaintenanceMode, refetchClusterSummary);
    const table = <NodeTable data={filteredNodes} headerColumns={["Name", "Version", "Status", "Last Seen", "Task Cap", "Running Tasks", null]} onRow={onRow}/>;
    const sidebar = (<div>
            <h4>Server Uri</h4>
            <div className={styles.serverUri}>{serverConfiguration.ServerUri || "Not Set"}</div>
            <OpenDialogButton label="Change">
                <EditServerConfiguration onSaveDone={setServerConfiguration}/>
            </OpenDialogButton>
        </div>);
    const stateFilter = nodesSummary.Nodes.length > 1 && (<Section className={styles.filterBoxStandardWidth}>
            <SelectWithoutPrefix value={filter} onChange={(newFilter) => {
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            setFilter(newFilter as NodesFilterState);
        }} items={Object.keys(NodesFilterState).map((value) => ({ value, text: value.split(/(?=[A-Z])/).join(" ") }))} allowClear={true} fieldName="Filter by" placeholder="All nodes"/>
        </Section>);
    const clusterTaskLimitMessage = loaderData.licenseStatus.IsClusterTaskLimitControlledByLicense && (<div>
            <Callout title="Note" type={"information"}>
                The task cap for your Octopus Server cluster is controlled by your license. This means the maximum number of concurrent tasks you can run across the entire cluster will be {loaderData.licenseStatus.EffectiveClusterTaskLimit}.
            </Callout>
        </div>);
    return (<PaperLayoutVNext title={nodesPageTitle} pageActions={[configurationSettingsPageAction]} busy={isInProgress} errors={errors}>
            {stateFilter}
            {clusterTaskLimitMessage}
            <SidebarLayout sideBar={sidebar}>{table}</SidebarLayout>
        </PaperLayoutVNext>);
}
function rowElements(node: OctopusServerNodeSummaryResource, theme: OctopusTheme, licenseStatus: LicenseStatusResource, nodes: OctopusServerNodeSummaryResource[], deleteNode: (node: OctopusServerNodeSummaryResource) => Promise<void>, setIsInMaintenanceMode: (node: OctopusServerNodeSummaryResource, newIsInMaintenanceModeValue: boolean) => void, onTaskCapChanged: () => void) {
    const status = node.IsOffline ? (<Tooltip key="offline" content="This Octopus Server node is offline">
            <span style={{ color: theme.danger }}>Offline</span>
        </Tooltip>) : node.IsInMaintenanceMode ? (<Tooltip key="drain" content="New tasks are prevented from executing on this node">
            <span style={{ color: theme.alert }}>{node.RunningTaskCount ? "Draining" : "Drained"}</span>
        </Tooltip>) : (<span style={{ color: theme.success }}>Running</span>);
    const shouldRecommendMaxSqlConnectionPoolSize = node.MaxSqlConnectionPoolSize !== undefined && node.RecommendedMaxSqlConnectionPoolSize !== undefined && node.RecommendedMaxSqlConnectionPoolSize > node.MaxSqlConnectionPoolSize;
    const nodeTaskCap = licenseStatus.IsNodeTaskLimitControlledByLicense ? (<Tooltip key="nodeTaskCap" content={"The task cap for your Octopus Server node is controlled by your license. This means the maximum number of concurrent tasks you can run on this node will be " + licenseStatus.EffectiveNodeTaskLimit + "."}>
            <span style={{ color: theme.alert }}>{node.MaxConcurrentTasks}</span>
        </Tooltip>) : (<>
            <span>{node.MaxConcurrentTasks}</span>
            {shouldRecommendMaxSqlConnectionPoolSize && (<Tooltip content={<>
                            This node's maximum SQL connection pool size is currently {node.MaxSqlConnectionPoolSize}. It is recommended to raise this limit (<em>Max Pool Size</em> in this node's SQL connection string) to at least{" "}
                            {node.RecommendedMaxSqlConnectionPoolSize} to leave room for connections made by tasks.
                        </>} position="left">
                    <em className={`${styles.sqlMaxPoolSizeWarning} fa-solid fa-triangle-exclamation`} style={{ color: theme.alertText }}/>
                </Tooltip>)}
        </>);
    const lastSeen = node.LastSeen ? (node.IsOffline ? (<span style={{ color: theme.danger }}>{DateFormatter.dateToLongFormatWithSeconds(node.LastSeen)}</span>) : (DateFormatter.dateToLongFormatWithSeconds(node.LastSeen))) : (<span style={{ color: theme.danger }}>Never</span>);
    const overflowMenu = <OverflowMenu menuItems={getOverflowMenuItems(node, nodes, deleteNode, setIsInMaintenanceMode, onTaskCapChanged)}/>;
    const version = <span>{node.Version}</span>;
    return [
        node.Name,
        version,
        status,
        lastSeen,
        nodeTaskCap,
        <InternalLink key={node.Id} to={links.tasksPage.generateUrl({ serverNode: node.Id, state: TaskFilterStateValues.Running, spaces: [], includeSystem: true })}>
            {getTaskRunningText(node.RunningTaskCount)}
        </InternalLink>,
        overflowMenu,
    ];
}
function getOverflowMenuItems(node: OctopusServerNodeSummaryResource, nodes: OctopusServerNodeSummaryResource[], deleteNode: (node: OctopusServerNodeSummaryResource) => Promise<void>, setIsInMaintenanceMode: (node: OctopusServerNodeSummaryResource, newIsInMaintenanceModeValue: boolean) => void, onTaskCapChanged: () => void) {
    const changeTaskCap = OverflowMenuItems.dialogItem("Change Task Cap", <ChangeTaskCap nodeId={node.Id} onSaveDone={onTaskCapChanged}/>);
    const maintMode = OverflowMenuItems.item(node.IsInMaintenanceMode ? "Disable Node Drain" : "Drain Node", () => setIsInMaintenanceMode(node, !node.IsInMaintenanceMode));
    const deleteItem = nodes.length > 1 && OverflowMenuItems.deleteItemDefault("node", () => deleteNode(node));
    const auditTrail = OverflowMenuItems.navItem("Audit Trail", links.auditPage.generateUrl({ regardingAny: [node.Id], includeSystem: true }), {
        permission: Permission.EventView,
        wildcard: true,
    });
    return compact([changeTaskCap, maintMode, deleteItem, [auditTrail]]);
}
function getTaskRunningText(n: number) {
    switch (n) {
        case 0:
            return "No running tasks";
        case 1:
            return "1 running task";
        default:
            return n + " running tasks";
    }
}
const SelectWithoutPrefix = UseLabelStrategy(SelectInternal, (fieldName) => fieldName);
export enum NodesFilterState {
    RecentlyActive = "RecentlyActive",
    Running = "Running",
    Draining = "Draining",
    Drained = "Drained",
    Offline = "Offline"
}
class NodeTable extends SimpleDataTable<OctopusServerNodeSummaryResource> {
}
export function filterNodes(nodes: OctopusServerNodeSummaryResource[], filter: NodesFilterState, cutoffDate: moment.Moment): OctopusServerNodeSummaryResource[] {
    return nodes.filter((node) => {
        switch (filter) {
            case NodesFilterState.RecentlyActive:
                //Tuesday, September 4, 2018 12:05:12 PM
                const lastSeen: moment.Moment = moment(node.LastSeen);
                return !node.IsOffline || lastSeen > cutoffDate;
            case NodesFilterState.Running:
                return !node.IsOffline && !node.IsInMaintenanceMode;
            case NodesFilterState.Draining:
                return !node.IsOffline && node.IsInMaintenanceMode && node.RunningTaskCount;
            case NodesFilterState.Drained:
                return !node.IsOffline && node.IsInMaintenanceMode && !node.RunningTaskCount;
            case NodesFilterState.Offline:
                return node.IsOffline;
            default:
                return true;
        }
    });
}
export const nodesPageTitle = "Nodes";
