import { css } from "@emotion/css";
import { CircleCheckIcon } from "@octopusdeploy/design-system-components/src/components/Icon/CircleCheckIcon";
import { CircleXMarkIcon } from "@octopusdeploy/design-system-components/src/components/Icon/CircleXMarkIcon";
import { space, themeTokens } from "@octopusdeploy/design-system-tokens";
import { TriggerActionType } from "@octopusdeploy/octopus-server-client";
import type { TriggerExecutionResource, DiscoveredGitCommit } from "@octopusdeploy/octopus-server-client/src/resources/triggerExecutionResource";
import type { ReactElement } from "react";
import React from "react";
import { ExpandableFormSection, Summary } from "~/components/form";
import { GitRefChip } from "../../Releases/GitRefChip/GitRefChip";
interface LastTriggerExecutionSummaryProps {
    lastExecution: TriggerExecutionResource | "never-run";
}
const dateOptions: Intl.DateTimeFormatOptions = { weekday: "long", year: "numeric", month: "long", day: "2-digit", hour: "2-digit", minute: "2-digit", second: "2-digit", hour12: true };
export function LastGitTriggerExecutionSummary({ lastExecution }: LastTriggerExecutionSummaryProps) {
    if (lastExecution === "never-run") {
        return (<ExpandableFormSection errorKey="Last Trigger Execution" title="Last Trigger Execution" summary={Summary.summary("This trigger hasn\u2019t executed yet.")} help="See details about the most recent trigger execution.">
                <div className={css({ color: themeTokens.color.text.secondary })}>This trigger hasn’t executed yet. Octopus will run it every three minutes to check for new commits in the selected repositories.</div>
            </ExpandableFormSection>);
    }
    if (lastExecution.Detail.Type !== "GitTrigger")
        return null;
    const { ExecutedAt: executedAt, RanToCompletion: ranToCompletion, ActionInvoked: triggerActionInvoked, Reason: reason } = lastExecution;
    const dateString = new Date(executedAt).toLocaleString("en-US", dateOptions);
    return (<ExpandableFormSection errorKey="Last Trigger Execution" title="Last Trigger Execution" focusOnExpandAll summary={Summary.summary(dateString)} help="See details about the most recent trigger execution." isExpandedByDefault={true}>
            <div className={css({ display: "grid", gridTemplateColumns: "1fr 2fr", gap: space[8], paddingTop: space[16], paddingBottom: space[16] })}>
                <LabelGridItem text="Outcome"/>
                <ValueGridItem>
                    <Outcome ranToCompletion={ranToCompletion} triggerActionInvoked={triggerActionInvoked} actionType={TriggerActionType.CreateRelease}/>
                </ValueGridItem>
                <LabelGridItem text="Reason"/>
                <ValueGridItem>{reason}</ValueGridItem>
                <LabelGridItem text="Last executed at"/>
                <ValueGridItem>{dateString}</ValueGridItem>
                <LabelGridItem text="Discovered commits"/>
                <ValueGridItem>
                    <DiscoveredCommits commits={lastExecution.Detail.DiscoveredCommits}/>
                </ValueGridItem>
            </div>
        </ExpandableFormSection>);
}
function LabelGridItem({ text }: {
    text: string;
}) {
    return <div className={css({ color: themeTokens.color.text.secondary })}>{text}:</div>;
}
function ValueGridItem({ children }: {
    children?: ReactElement | string;
}) {
    return <div>{children}</div>;
}
function Outcome({ ranToCompletion, triggerActionInvoked, actionType }: {
    ranToCompletion: boolean;
    triggerActionInvoked: boolean;
    actionType: string;
}) {
    const result = (icon: ReactElement, color: string, text: string) => (<div className={css({ display: "flex", gap: space[8], alignItems: "center" })}>
            <div className={css({ color })}>{icon}</div> {text}
        </div>);
    if (!ranToCompletion) {
        return result(<CircleXMarkIcon size={16}/>, themeTokens.color.icon.danger, "Execution failed");
    }
    else {
        if (actionType === TriggerActionType.CreateRelease) {
            if (triggerActionInvoked) {
                return result(<CircleCheckIcon size={16}/>, themeTokens.color.icon.success, "Release created");
            }
            else {
                return result(<CircleXMarkIcon size={16}/>, themeTokens.color.icon.tertiary, "No release created");
            }
        }
        if (triggerActionInvoked) {
            return result(<CircleCheckIcon size={16}/>, themeTokens.color.icon.success, "Success");
        }
        else {
            return result(<CircleXMarkIcon size={16}/>, themeTokens.color.icon.tertiary, "No action taken");
        }
    }
}
function DiscoveredCommits({ commits }: {
    commits: DiscoveredGitCommit[];
}) {
    return (<div className={css({ display: "flex", flexDirection: "column", gap: space[8] })}>
            {commits.map((commit) => (<DiscoveredCommit commit={commit}/>))}
        </div>);
}
function DiscoveredCommit({ commit }: {
    commit: DiscoveredGitCommit;
}) {
    return (<div className={css({ display: "flex", gap: space[8], alignItems: "center" })}>
            <GitRefChip vcsRef={{
            GitRef: commit.GitRef,
            GitCommit: commit.GitCommit,
        }}/>
        </div>);
}
