import * as React from "react";
import type { GitRefQueryParamsProps } from "../BranchSelector/useProjectGitRef";
import { RunbookRunNowLayout } from "./RunbookRunNowLayout";
type RerunSnapshotProps = {
    runbookSnapshotId: string;
};
type CreateRunProps = {};
type RunbookRunNowPageProps = (RerunSnapshotProps | CreateRunProps) & GitRefQueryParamsProps;
export function RunbookRunNowPage(props: RunbookRunNowPageProps) {
    return <RunbookRunNowLayout runbookSnapshotId={isRerunSnapshotProps(props) ? props.runbookSnapshotId : undefined} goal={{}} queryParams={props.queryParams} setQueryParams={props.setQueryParams}/>;
}
function isRerunSnapshotProps(props: RunbookRunNowPageProps): props is RerunSnapshotProps & GitRefQueryParamsProps {
    return "runbookSnapshotId" in props;
}
