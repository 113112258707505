import type { GitBranchResource, GitRef, ProjectResource } from "@octopusdeploy/octopus-server-client";
import React from "react";
import { ProjectGitRefSelector } from "~/areas/projects/components/GitRefSelector/ProjectGitRefSelector";
import { repository } from "~/clientInstance";
import { useNotifyCreateBranch } from "./Analytics/useNotifyCreateBranch";
export interface BranchSelectorVNextProps {
    project: ProjectResource;
    gitRef: GitRef | undefined;
    setGitRef: (gitRef: string) => void;
}
export function BranchSelectorVNext({ project, gitRef, setGitRef }: BranchSelectorVNextProps) {
    const createBranchDispatcher = useNotifyCreateBranch();
    if (!gitRef) {
        return null;
    }
    const onCreateBranch = async (newBranchName: string, parentGitRef: GitRef): Promise<GitBranchResource> => {
        const newBranchResource = await createBranchDispatcher({
            source: "Branch switcher",
            createBranchAction: () => repository.ProjectBranches.createBranch(project.Id, newBranchName, parentGitRef),
        });
        setGitRef(newBranchResource.CanonicalName);
        return newBranchResource;
    };
    return <ProjectGitRefSelector onChange={setGitRef} project={project} initialGitRef={gitRef} onCreateBranch={onCreateBranch}/>;
}
