import type { SimpleMenuItem } from "@octopusdeploy/design-system-components";
import { nameForLink } from "@octopusdeploy/portal-routes";
import { nameForDocument, useAnalyticExternalLinkDispatch } from "~/analytics/Analytics";
export function useExternalLinksWithAnalytics(items: SimpleMenuItem[]): SimpleMenuItem[] {
    const dispatchLink = useAnalyticExternalLinkDispatch();
    return items.map((item) => instrumentExternalLinkClickWithAnalytics(item, dispatchLink, nameForDocument()));
}
export function useAnalyticsWithMenuExternalLink(item: SimpleMenuItem): SimpleMenuItem {
    const dispatchLink = useAnalyticExternalLinkDispatch();
    return instrumentExternalLinkClickWithAnalytics(item, dispatchLink, nameForLink());
}
function instrumentExternalLinkClickWithAnalytics(item: SimpleMenuItem, dispatchLink: (linkLabel: string, href: string, name: string) => void, name: string): SimpleMenuItem {
    if (item.type !== "external-link") {
        return item;
    }
    const { type, onClick, label, href } = item;
    return {
        type,
        label,
        href,
        onClick: () => {
            dispatchLink(label, href, name);
            onClick?.();
        },
    };
}
