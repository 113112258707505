import { css } from "@emotion/css";
import { text } from "@octopusdeploy/design-system-tokens";
import type { EnvironmentResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { ConfigurationMessages } from "~/areas/infrastructure/components/MachineSettings/Endpoints/KubernetesAgent/ConfigurationMessages";
import { ConfigurationPage, ConfigurationPageStorageClassHelperContent } from "~/areas/infrastructure/components/MachineSettings/Endpoints/KubernetesAgent/ConfigurationPage";
import type { TargetConfigurationResource } from "~/areas/infrastructure/components/MachineSettings/Endpoints/KubernetesAgent/KubernetesAgentTargetConfigurationDialog";
import { EndpointSelectionScope } from "~/areas/infrastructure/components/MachineSettings/Endpoints/endpointRegistry";
import { EnvironmentMultiSelect } from "~/components/MultiSelect/EnvironmentMultiSelect";
import { TargetTagMultiSelect } from "~/components/MultiSelect/TargetTagMultiSelect";
import { Text } from "~/components/form/index";
type TargetConfigurationPageProps = {
    model: TargetConfigurationResource;
    onModelChange: <K extends keyof TargetConfigurationResource>(model: Pick<TargetConfigurationResource, K>) => void;
    environments: EnvironmentResource[];
    machineRoles: string[];
    canModifyServerUrls: boolean;
    showAdvanced: boolean;
    toggleShowAdvanced: () => void;
    getFieldError: (fieldName: string) => string;
    usePersistentVolumeStorage: boolean;
    onUsePersistentVolumeStorageChanged: (value: boolean) => void;
    requiresHaConfiguration: boolean;
};
export function TargetConfigurationPage(props: TargetConfigurationPageProps) {
    const { getFieldError } = props;
    return (<ConfigurationPage configuration={props.model} onConfigurationChange={(configuration) => props.onModelChange({ ...props.model, ...configuration })} canModifyServerUrls={props.canModifyServerUrls} showAdvanced={props.showAdvanced} toggleShowAdvanced={props.toggleShowAdvanced} getFieldError={getFieldError} usePersistentVolumeStorage={props.usePersistentVolumeStorage} onUsePersistentVolumeStorageChanged={props.onUsePersistentVolumeStorageChanged} requiresHaConfiguration={props.requiresHaConfiguration} nameErrorMessages={ConfigurationMessages.Name.DeploymentTarget} storageClassHelperText={ConfigurationPageStorageClassHelperContent(EndpointSelectionScope.DeploymentTarget)} customFormComponents={<>
                    <EnvironmentMultiSelect environments={props.environments} hideFloatingLabel={false} openOnFocus={false} error={getFieldError("EnvironmentIds")} onChange={(EnvironmentIds) => props.onModelChange({ EnvironmentIds })} helperText={getFieldError("EnvironmentIds") ? "" : ConfigurationMessages.EnvironmentIds.HelperText} value={props.model.EnvironmentIds} label="Environments"/>
                    <TargetTagMultiSelect items={props.machineRoles} onChange={(MachineRoles) => props.onModelChange({ MachineRoles })} error={getFieldError("MachineRoles")} value={props.model.MachineRoles} canAdd={true} helperText={ConfigurationMessages.MachineRoles.HelperText} label="Target tags" analyticsArea="Kubernetes Agent Configuration" empty={!props.machineRoles.length && (<div className={styles.targetRolesEmptyStyles}>
                                    Create your first target tag, for example <code>acme-web-server</code>
                                </div>)}/>
                </>} customAdvancedComponents={<Text value={props.model.DefaultNamespace} onChange={(n) => props.onModelChange({ DefaultNamespace: n })} placeholder="default" error={props.getFieldError("DefaultNamespace")} label="Default Kubernetes namespace" helperText="Default namespace can be overridden by your step configuration and YAML"/>}/>);
}
const styles = {
    targetRolesEmptyStyles: css({
        font: text.regular.default.medium,
    }),
};
