import { css } from "@emotion/css";
import { space, text } from "@octopusdeploy/design-system-tokens";
import { ScriptingLanguage } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import React from "react";
import { useAnalyticsViewHelpDispatch } from "~/analytics/Analytics";
import { repository } from "~/clientInstance";
import ExternalVideoLink from "~/components/Navigation/ExternalLink/ExternalVideoLink";
import ExternalLink from "~/components/Navigation/ExternalLink/index";
import type { Placement } from "~/primitiveComponents/dataDisplay/PopoverHelp/PopoverHelp";
import PopoverHelp from "~/primitiveComponents/dataDisplay/PopoverHelp/PopoverHelp";
import InternalLink from "../Navigation/InternalLink";
import lifecyclesImage from "./assets/img-lifecycle.svg";
export const TargetTagsProcessEditorContextualHelp = ({ position = "right-start", absolutePosition = false }: {
    position?: Placement;
    absolutePosition?: boolean;
}) => {
    const dispatchAction = useAnalyticsViewHelpDispatch();
    return (<PopoverHelp trigger="click" onOpen={() => dispatchAction("View Contextual Help Popover", { context: "Target Tags" })} placement={position} absolutePosition={absolutePosition}>
            <p>
                During a deployment, this step will run on targets matching any of these <strong>target tags</strong>.
            </p>

            <p>
                Specify one or more tags here, and add them to
                {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
                <InternalLink to={links.deploymentTargetsPage.generateUrl({ spaceId: repository.spaceId! })} openInSelf={false} showOpensInNewTabIcon={true}>
                    {" "}
                    Deployment Targets
                </InternalLink>{" "}
                later.
            </p>

            <div className={linkStyles}>
                <ExternalVideoLink href="MachineRolesVideo">Video (4 min)</ExternalVideoLink>
                <ExternalLink href="TargetRoles">Docs</ExternalLink>
            </div>
        </PopoverHelp>);
};
export const TargetTagsNewDeploymentTargetContextualHelp = ({ position = "right-start", absolutePosition = false }: {
    position?: Placement;
    absolutePosition?: boolean;
}) => {
    return (<PopoverHelp trigger="click" placement={position} absolutePosition={absolutePosition}>
            <p>
                Deployment and runbooks processes uses <strong>target tags</strong> to select deployment targets at run time.
            </p>

            <div className={linkStyles}>
                <ExternalVideoLink href="MachineRolesVideo">Video (4 min)</ExternalVideoLink>
                <ExternalLink href="TargetRoles">Docs</ExternalLink>
            </div>
        </PopoverHelp>);
};
export const RollingDeploymentContextualHelp = ({ position = "right-start", absolutePosition = false }: {
    position?: Placement;
    absolutePosition?: boolean;
}) => {
    const dispatchAction = useAnalyticsViewHelpDispatch();
    return (<PopoverHelp trigger="click" onOpen={() => dispatchAction("View Contextual Help Popover", { context: "Rolling Deployment" })} placement={position} absolutePosition={absolutePosition}>
            <p>
                <strong>Rolling deployments</strong> let you slowly roll out a release by deploying to each target one-by-one, instead of all targets at once. In load balanced scenarios, this reduces overall downtime.
            </p>

            <div className={linkStyles}>
                <ExternalLink href="rolling-deployments">Docs</ExternalLink>
            </div>
        </PopoverHelp>);
};
export const WindowSizeContextualHelp = ({ position = "right-start", absolutePosition = false }: {
    position?: Placement;
    absolutePosition?: boolean;
}) => {
    const dispatchAction = useAnalyticsViewHelpDispatch();
    return (<PopoverHelp trigger="click" onOpen={() => dispatchAction("View Contextual Help Popover", { context: "Window Size" })} placement={position} absolutePosition={absolutePosition}>
            <p>
                <strong>Window size</strong> controls how many deployment targets can be deployed to at once, e.g:
            </p>

            <ul className={listStyles}>
                <li>
                    A window size of <strong>1</strong> will deploy to a single target at a time. Octopus will wait for the step to finish running on target A before starting on target B
                </li>
                <li>
                    A window size of <strong>3</strong> will deploy to three targets at a time. Octopus will wait for the step to finish running on targets A, B or C before starting on target D
                </li>
            </ul>

            <div className={linkStyles}>
                <ExternalLink href="rolling-deployments">Docs</ExternalLink>
            </div>
        </PopoverHelp>);
};
export const LifecycleContextualHelp = ({ position = "right-start", absolutePosition = false }: {
    position?: Placement;
    absolutePosition?: boolean;
}) => {
    const dispatchAction = useAnalyticsViewHelpDispatch();
    return (<PopoverHelp trigger="click" onOpen={() => dispatchAction("View Contextual Help Popover", { context: "Lifecycle" })} placement={position} absolutePosition={absolutePosition}>
            <p>
                <img src={lifecyclesImage}/>
            </p>
            <p>
                <strong>Lifecycles</strong> give you control over the way releases of your software are promoted between your environments.
            </p>
            <div className={linkStyles}>
                <ExternalVideoLink href="OnboardingLifecyclesVideo">Video (4 min)</ExternalVideoLink>
                <ExternalLink href="OnboardingLifecycles">Docs</ExternalLink>
            </div>
        </PopoverHelp>);
};
export const CloudConnectionsContextualHelp = ({ position = "right-start", absolutePosition = false }: {
    position?: Placement;
    absolutePosition?: boolean;
}) => {
    const dispatchAction = useAnalyticsViewHelpDispatch();
    return (<PopoverHelp trigger="click" onOpen={() => dispatchAction("View Contextual Help Popover", { context: "Cloud Connections" })} placement={position} absolutePosition={absolutePosition}>
            <p>
                <strong>Cloud connections</strong> are stored as project variables. This makes those credentials available across your entire project. Additionally, cloud targets can be discoverable at deployment time.
            </p>
            <p>Use project variables to scope your variables to environments and more.</p>
            <div className={linkStyles}>
                <ExternalLink href="CloudTargetDiscovery">Docs</ExternalLink>
            </div>
        </PopoverHelp>);
};
export const WorkerPoolsContextualHelp = ({ position = "right-start", absolutePosition = false }: {
    position?: Placement;
    absolutePosition?: boolean;
}) => {
    const dispatchAction = useAnalyticsViewHelpDispatch();
    return (<PopoverHelp trigger="click" onOpen={() => dispatchAction("View Contextual Help Popover", { context: "Worker Pools" })} placement={position} absolutePosition={absolutePosition}>
            <p>
                When a task is assigned to a worker pool, the task will be executed by one of the workers in the <strong>worker pool</strong> you've configured.
            </p>
            <div className={linkStyles}>
                <ExternalVideoLink href="WorkerPoolsVideo">Video (7 min)</ExternalVideoLink>
                <ExternalLink href="WorkerPools">Docs</ExternalLink>
            </div>
        </PopoverHelp>);
};
export const WorkerPoolsVariableContextualHelp = ({ position = "right-start", absolutePosition = false }: {
    position?: Placement;
    absolutePosition?: boolean;
}) => {
    const dispatchAction = useAnalyticsViewHelpDispatch();
    return (<PopoverHelp trigger="click" onOpen={() => dispatchAction("View Contextual Help Popover", { context: "Worker Pools Variable" })} placement={position} absolutePosition={absolutePosition}>
            <p>
                It’s a common practice to use different worker pools for different environments. This enables scenarios where workers are used for authentication (e.g. AWS EC2 instance roles) or network segregation (e.g. a jump box used to access a
                database).
            </p>
        </PopoverHelp>);
};
export const DeploymentTargetsContextualHelp = ({ position = "right-start", absolutePosition = false }: {
    position?: Placement;
    absolutePosition?: boolean;
}) => {
    const dispatchAction = useAnalyticsViewHelpDispatch();
    return (<PopoverHelp trigger="click" onOpen={() => dispatchAction("View Contextual Help Popover", { context: "Deployment Targets" })} placement={position} absolutePosition={absolutePosition}>
            <p>
                <strong>Deployment targets</strong> are the physical and virtual machines and cloud services that your applications are deployed to.
            </p>

            <p>You can manage your deployment targets in Infrastructure.</p>
            <div className={linkStyles}>
                <ExternalVideoLink href="OnboardingDeploymentTargetsVideo">Video (5 min)</ExternalVideoLink>
                <ExternalLink href="DeploymentTargets">Docs</ExternalLink>
            </div>
        </PopoverHelp>);
};
export const GitResourceReleaseSelectionContextualHelp = ({ position = "right-start", absolutePosition = false }: {
    position?: Placement;
    absolutePosition?: boolean;
}) => {
    const dispatchAction = useAnalyticsViewHelpDispatch();
    return (<PopoverHelp trigger="click" onOpen={() => dispatchAction("View Contextual Help Popover", { context: "Git resource release selection" })} placement={position} absolutePosition={absolutePosition}>
            <p>The commit at the tip of this branch or tag will be snapshotted and used in all deployments.</p>
        </PopoverHelp>);
};
export const GitResourceReleaseGitReferenceContextualHelp = ({ position = "right-start", absolutePosition = false }: {
    position?: Placement;
    absolutePosition?: boolean;
}) => {
    const dispatchAction = useAnalyticsViewHelpDispatch();
    return (<PopoverHelp trigger="click" onOpen={() => dispatchAction("View Contextual Help Popover", { context: "Git resource release git reference" })} placement={position} absolutePosition={absolutePosition}>
            <p>We’ve snapshotted this commit, and it will be used in all deployments of this release.</p>
        </PopoverHelp>);
};
export const GitResourcePathContextualHelp = ({ position = "right-start", absolutePosition = false }: {
    position?: Placement;
    absolutePosition?: boolean;
}) => {
    const dispatchAction = useAnalyticsViewHelpDispatch();
    return (<PopoverHelp trigger="click" onOpen={() => dispatchAction("View Contextual Help Popover", { context: "Git resource path" })} placement={position} absolutePosition={absolutePosition}>
            <p>Files included in a glob pattern will be applied with a single apply command.</p>
            <p>If you’ve used semicolons to specify multiple paths, we’ll apply each path separately and in order from left to right.</p>
            <p>Octopus won’t wait for objects to be fully created or updated before moving on to the next file.</p>
        </PopoverHelp>);
};
export const InlineScriptEditorContextualHelp = ({ position = "right-start", absolutePosition = false, isPowerShellOnly, syntax }: {
    position?: Placement;
    absolutePosition?: boolean;
    isPowerShellOnly: boolean;
    syntax: ScriptingLanguage;
}) => {
    const dispatchAction = useAnalyticsViewHelpDispatch();
    return (<PopoverHelp trigger="click" onOpen={() => dispatchAction("View Contextual Help Popover", { context: "Inline Script Editor" })} placement={position} absolutePosition={absolutePosition}>
            <p>
                <strong>Inline Source Code</strong>
            </p>
            <p>
                Scripts can contain <ExternalLink href="ScriptStepPackageReferences">package references</ExternalLink> below and <ExternalLink href="DocumentationVariables">variables</ExternalLink> can be added by the <em>Insert a variable</em>{" "}
                button. Script modules included in this project
                {isPowerShellOnly || syntax === ScriptingLanguage.PowerShell ? (<>
                        {" "}
                        will be <ExternalLink href="ImportingScriptModules">automatically imported</ExternalLink>.
                    </>) : (<>
                        {" "}
                        can be <ExternalLink href="ImportingScriptModules">imported</ExternalLink> into your script.
                    </>)}
            </p>
        </PopoverHelp>);
};
export const ExecutionContainerImageContextualHelp = ({ position = "right-start", absolutePosition = false }: {
    position?: Placement;
    absolutePosition?: boolean;
}) => {
    const dispatchAction = useAnalyticsViewHelpDispatch();
    return (<PopoverHelp trigger="click" onOpen={() => dispatchAction("View Contextual Help Popover", { context: "Execution Container Image" })} placement={position} absolutePosition={absolutePosition}>
            <p>
                To make life easier, we’ve published images to a DockerHub repository (<ExternalLink href="WorkerToolsDockerHub">octopusdeploy/worker-tools</ExternalLink>) that contain many common deployment tools. We regularly publish updates with
                the latest versions of the contained tools.
            </p>
            <p>
                Find out <ExternalLink href="ExecutionContainersWhichImage">which image you can use</ExternalLink>.
            </p>
        </PopoverHelp>);
};
export const KubernetesAgentTargetTagsContextualHelp = ({ position = "right-start", absolutePosition = false }: {
    position?: Placement;
    absolutePosition?: boolean;
}) => {
    return (<PopoverHelp trigger="click" placement={position} absolutePosition={absolutePosition}>
            <div>Your deployment process will use the same target tag to select and deploy to this Kubernetes cluster at run time.</div>
        </PopoverHelp>);
};
export const CloudTargetDiscoveryContextualHelp = ({ position = "bottom-start", absolutePosition = false }: {
    position?: Placement;
    absolutePosition?: boolean;
}) => {
    const dispatchAction = useAnalyticsViewHelpDispatch();
    return (<PopoverHelp trigger="click" onOpen={() => dispatchAction("View Contextual Help Popover", { context: "Cloud Target Discovery" })} placement={position} absolutePosition={absolutePosition}>
            <p>
                At deployment time, Octopus will dynamically create deployment targets for steps that use Target Discovery. If you haven’t already, please follow this <ExternalLink href="CloudTargetDiscovery">setup guide</ExternalLink> to make your
                targets discoverable.
            </p>
        </PopoverHelp>);
};
const linkStyles = css({
    display: "flex",
    alignItems: "center",
    font: text.regular.default.medium,
    gap: space[24],
});
const listStyles = css({
    listStyleType: "disc",
    listStylePosition: "inside",
    marginLeft: space["12"],
    color: "inherit",
});
