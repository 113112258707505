/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { links } from "@octopusdeploy/portal-routes";
import cn from "classnames";
import * as React from "react";
import { repository } from "~/clientInstance";
import { ChannelChip } from "~/components/Chips/index";
import InternalLink from "~/components/Navigation/InternalLink/InternalLink";
import DateFormatter from "~/utils/DateFormatter";
import type { TaskStatusIconPropsItem } from "../../TaskStatusIcon/TaskStatusIcon";
import { TaskStatusIcon } from "../../TaskStatusIcon/TaskStatusIcon";
import styles from "./style.module.less";
interface TaskStatusDetailsProps {
    item: TaskStatusIconPropsItem & {
        TaskId: string;
        ReleaseVersion: string;
        IsPrevious?: boolean;
        IsCurrent?: boolean;
        Name?: string;
    };
    style?: React.CSSProperties;
    showName?: boolean;
    channelName?: string;
    deploymentId?: string;
    projectSlug?: string;
    additionalDetails?: React.ReactNode;
    releaseVersionClassName?: string;
}
const TaskStatusDetails: React.StatelessComponent<TaskStatusDetailsProps> = (props) => {
    const task = props.item;
    const additional = task.IsPrevious ? styles.taskPrevious : task.IsCurrent === false ? styles.taskArchived : "";
    const link = props.deploymentId && props.projectSlug
        ? links.deploymentDetailsPage.generateUrl({ spaceId: repository.spaceId!, projectSlug: props.projectSlug, releaseVersion: task.ReleaseVersion, deploymentId: props.deploymentId })
        : links.taskPage.generateUrl({ taskId: task.TaskId });
    return (<InternalLink to={link} className={styles.taskLink} width="100%">
            <div className={cn(styles.container, additional)} style={props.style}>
                <div className={styles.statusIcon}>
                    <TaskStatusIcon item={task}/>
                </div>
                <div className={styles.details}>
                    <span className={cn(styles.version, props.releaseVersionClassName)} title={task.ReleaseVersion}>
                        {task.ReleaseVersion}
                    </span>
                    {props.showName && <div className={styles.taskName}>&#x21E2;&nbsp;{task.Name}</div>}
                    {props.channelName && <ChannelChip channelName={props.channelName} noMargin={true}/>}
                    <span title={DateFormatter.dateToLongFormat(task.StartTime || task.QueueTime)!} className={styles.date}>
                        {DateFormatter.dateToShortFormat(task.StartTime || task.QueueTime)}
                    </span>
                </div>
                {props.additionalDetails && <div className={styles.additionalDetails}>{props.additionalDetails}</div>}
            </div>
        </InternalLink>);
};
TaskStatusDetails.displayName = "TaskStatusDetails"
TaskStatusDetails.displayName = "TaskStatusDetails";
export { TaskStatusDetails };
