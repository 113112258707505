import { logger } from "@octopusdeploy/logging";
import { useState, useCallback } from "react";
function useSessionStorage<T>(key: string, initialValue: T): [
    T,
    (value: T) => void,
    () => void
] {
    const [storedValue, setStoredValue] = useState<T>(() => {
        try {
            const item = sessionStorage.getItem(key);
            return item ? JSON.parse(item) : initialValue;
        }
        catch (error) {
            logger.warn(error, "Error initializing session storage for key {key}:", { key });
            return initialValue;
        }
    });
    const setValue = useCallback((value: T) => {
        try {
            setStoredValue(value);
            sessionStorage.setItem(key, JSON.stringify(value));
        }
        catch (error) {
            logger.warn(error, "Error setting session storage for key {key}:", { key });
        }
    }, [key]);
    const clearValue = useCallback(() => {
        try {
            sessionStorage.removeItem(key);
        }
        catch (error) {
            logger.warn(error, "Error clearing session storage for key {key}:", { key });
        }
    }, [key]);
    return [storedValue, setValue, clearValue];
}
export default useSessionStorage;
