import * as React from "react";
import type { PaperLayoutProps } from "~/components/PaperLayout";
import { PaperLayoutVNext } from "~/components/PaperLayout/PaperLayoutVNext";
import { PortalPageLayout } from "~/components/PortalPageLayout/PortalPageLayout";
interface InfrastructureLayoutProps {
}
export function InfrastructureLayout({ children }: React.PropsWithChildren<InfrastructureLayoutProps>) {
    return <PortalPageLayout content={children}/>;
}
export const InfrastructureLayoutBusy: React.FC<PaperLayoutProps> = (props: PaperLayoutProps) => {
    return (<InfrastructureLayout>
            <PaperLayoutVNext {...props}/>
        </InfrastructureLayout>);
};
InfrastructureLayoutBusy.displayName = "InfrastructureLayoutBusy"
