import { themeTokens } from "@octopusdeploy/design-system-tokens";
import type { IconSvgResource } from "@octopusdeploy/octopus-server-client";
import React from "react";
interface IconEditorSvgProps {
    icon: IconSvgResource;
    isSelected: boolean;
}
const IconEditorSvg = ({ icon, isSelected }: IconEditorSvgProps) => {
    const longestSide = Math.max(icon.iconWidth, icon.iconHeight);
    const length = longestSide * 2;
    const offsetX = (length - icon.iconWidth) / 2;
    const offsetY = (length - icon.iconHeight) / 2;
    const iconPathColour = isSelected ? "white" : themeTokens.color.icon.secondary;
    return (<svg xmlns="http://www.w3.org/2000/svg" viewBox={`0 0 ${length} ${length}`} width="40" height="40">
            <path style={{ fill: iconPathColour, stroke: iconPathColour }} width={`${icon.iconWidth}`} height={`${icon.iconHeight}`} transform={`translate(${offsetX},${offsetY})`} d={`${icon.iconPath}`}/>
        </svg>);
};
export default IconEditorSvg;
