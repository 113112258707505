import type { PersistenceSettings } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { createContext } from "react";
import { useRequiredContext } from "~/hooks/index";
type PersistenceSettingsContextValue = PersistenceSettings;
export const PersistenceSettingsContext = createContext<PersistenceSettingsContextValue | undefined>(undefined);
export const usePersistenceSettingsContext = () => {
    return useRequiredContext(PersistenceSettingsContext);
};
export function PersistenceSettingsContextProvider(props: React.PropsWithChildren<PersistenceSettingsContextValue>) {
    const { children, ...persistenceSettings } = props;
    return <PersistenceSettingsContext.Provider value={persistenceSettings}>{children}</PersistenceSettingsContext.Provider>;
}
