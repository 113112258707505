/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ActionButton, ActionButtonType, EmptyStateDashboardNoPermissionIllustration, SimpleMenu, useMenuState } from "@octopusdeploy/design-system-components";
import type { PageAction, PrimaryPageAction } from "@octopusdeploy/design-system-components";
import type { GetProjectsOnboardingBffResponse, ProjectGroupResource, ProjectResource } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import type { QueryParamValuesSetter } from "@octopusdeploy/portal-routes";
import { isEqual } from "lodash";
import * as React from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import type { AnalyticActionDispatcher } from "~/analytics/Analytics";
import { Action, useAnalyticActionDispatch } from "~/analytics/Analytics";
import { LegacyDashboard } from "~/areas/dashboard/DashboardOverview/DashboardOverviewPage";
import { AddProjectGroup } from "~/areas/projects/components/Projects/AddProjectGroup";
import type { ProjectsFilter } from "~/areas/projects/components/Projects/ProjectsDashboard";
import { defaultProjectsPageSize, isProjectsPageSize, ProjectsDashboard } from "~/areas/projects/components/Projects/ProjectsDashboard";
import { repository } from "~/clientInstance";
import { DropDownIcon } from "~/components/Button/DropDownIcon/DropDownIcon";
import type { DoBusyTask, Errors } from "~/components/DataBaseComponent/index";
import { DataBaseComponent, useDoBusyTaskEffect } from "~/components/DataBaseComponent/index";
import Dialog from "~/components/Dialog/Dialog";
import { useDialogTrigger } from "~/components/Dialog/DialogTrigger";
import { FullWidthPageLayout } from "~/components/FullWidthPageLayout/FullWidthPageLayout";
import { Level1EmptyStateLayout } from "~/components/GettingStarted/Level1EmptyStateLayout";
import PermissionCheck, { hasPermission, isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import AddNewProjectDialog from "~/components/ProjectBasedActivation/AddNewProjectDialog";
import DashboardOnboardingLayout from "~/components/ProjectBasedActivation/DashboardOnboardingLayout";
import NewProjectWizard from "~/components/ProjectBasedActivation/NewProjectWizard";
import { useIsDashboardVNextEnabled } from "~/components/RootRoutes/useIsDashboardVNextEnabled";
import useLocalStorage from "~/hooks/useLocalStorage";
import { useOctopusFeatureToggle } from "~/hooks/useOctopusFeatureToggle";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
import { getImportExportMenuItems } from "../ImportExport/ImportExportMenu";
import { VerticalNavigationIntroDialog } from "./VerticalNavigationIntroDialog";
export interface ProjectsQueryParams {
    page?: number;
    pageSize?: number;
    searchValue: string;
}
interface ProjectsPageProps {
    spaceId: string;
    queryParams: ProjectsQueryParams;
    setQueryParams: QueryParamValuesSetter<ProjectsQueryParams>;
}
interface ProjectsPageInternalProps extends ProjectsPageProps {
    doBusyTask: DoBusyTask;
    busy: Promise<void> | undefined;
    errors: Errors | undefined;
}
const DashboardDispatcher = ({ dispatchAction }: {
    dispatchAction: AnalyticActionDispatcher;
}) => {
    const [dispatched, setDispatched] = React.useState(false);
    if (!dispatched) {
        dispatchAction("Dashboard Viewed", { action: Action.View, resource: "Dashboard" });
        setDispatched(true);
    }
    return <></>;
};
class ProjectsPage extends DataBaseComponent<ProjectsPageProps> {
    constructor(props: ProjectsPageProps) {
        super(props);
        this.state = {};
    }
    render() {
        return <ProjectsPageInternal {...this.props} doBusyTask={this.doBusyTask} busy={this.state.busy} errors={this.errors}/>;
    }
    static displayName = "ProjectsPage";
}
function ProjectsPageInternal({ spaceId, doBusyTask, busy, errors, queryParams, setQueryParams }: ProjectsPageInternalProps) {
    const projectsFilter = useProjectsDashboardFilter(queryParams, setQueryParams);
    const [projectGroups, setProjectGroups] = useState<ProjectGroupResource[]>([]);
    const [projectGroupIdForProjectDialog, setProjectGroupIdForProjectDialog] = useState<string | null>(null);
    const dispatchAction = useAnalyticActionDispatch();
    const onboardingData = useProjectsOnboardingData(doBusyTask);
    const { isOpen: isAddProjectGroupDialogOpen, openDialog: openAddProjectGroupDialog, closeDialog: closeAddProjectGroupDialog } = useDialogTrigger();
    const { isOpen: isAddProjectDialogOpen, openDialog: openAddProjectDialog, closeDialog: closeAddProjectDialog } = useDialogTrigger();
    const isKGSetupFeatureEnabled = useOctopusFeatureToggle("kubernetes-guided-setup", false);
    const isKGSNthProjectFeatureEnabled = useOctopusFeatureToggle("kubernetes-guided-setup-nth-project", false);
    const openAddProjectDialogWithoutProjectGroupId = useCallback(() => {
        setProjectGroupIdForProjectDialog(null);
        openAddProjectDialog();
    }, [openAddProjectDialog]);
    const openAddProjectDialogWithProjectGroupId = useCallback((groupId: string) => {
        setProjectGroupIdForProjectDialog(groupId);
        openAddProjectDialog();
    }, [openAddProjectDialog]);
    useDoBusyTaskEffect(doBusyTask, async () => {
        const hasProjectGroupViewPermissions = isAllowed({ permission: Permission.ProjectGroupView, projectGroup: "*" });
        if (!hasProjectGroupViewPermissions) {
            return;
        }
        const loadedProjectGroups = await repository.ProjectGroups.all();
        setProjectGroups(loadedProjectGroups);
    }, [], { timeOperationOptions: timeOperationOptions.forInitialLoad() });
    const importExportPageAction: PageAction = {
        type: "custom",
        key: "importExport",
        content: <ImportExportButton spaceId={spaceId}/>,
    };
    const addProjectGroupPageAction: PageAction = {
        type: "button",
        label: "Add Project Group",
        hasPermissions: isAllowed({ permission: Permission.ProjectGroupCreate, projectGroup: "*" }),
        buttonType: "secondary",
        onClick: () => {
            dispatchAction("Add Project Group", { resource: "Project Group", action: Action.Add });
            openAddProjectGroupDialog();
        },
    };
    const addProjectPageAction: PrimaryPageAction = {
        type: "button",
        label: "Add Project",
        hasPermissions: isAllowed({ permission: Permission.ProjectCreate, projectGroup: "*" }),
        onClick: () => {
            dispatchAction("Add Project", { resource: "Project", action: Action.Add });
            openAddProjectDialogWithoutProjectGroupId();
        },
        disabled: projectGroups.length === 0,
    };
    const close = (project: ProjectResource | undefined) => {
        if (project) {
            dispatchAction("Save Project", { action: Action.Save, resource: "Project" });
        }
        else {
            dispatchAction("Cancel Project Creation", { action: Action.Cancel, resource: "Project" });
        }
        closeAddProjectDialog();
    };
    const dialogs = (<>
            <Dialog open={isAddProjectGroupDialogOpen}>
                {/* After new Project Group is created in existing Projects page, it redirects to itself with the new projectGroupId as query param.
            Then the componentDidUpdate will trigger, and Project Groups will be reloaded, hence showing the newly created one.
            However, for this temporary page, how we show empty Project Group is still TBD, therefore just closing the dialog here for now.
            It is not good user experience, because user does not know whether a new Project Group is created or not, we will come back to solve this.
          */}
                <AddProjectGroup projectGroupCreated={(resource) => {
            setQueryParams({ page: 1, searchValue: resource.Name });
            closeAddProjectGroupDialog();
        }}/>
            </Dialog>
            {!isKGSetupFeatureEnabled || !isKGSNthProjectFeatureEnabled ? (<AddNewProjectDialog open={isAddProjectDialogOpen} close={close} projectGroupId={projectGroupIdForProjectDialog ?? undefined}/>) : (<NewProjectWizard open={isAddProjectDialogOpen} fullScreen={true} spaceId={spaceId} projectGroupId={projectGroupIdForProjectDialog ?? undefined} busy={busy} doBusyTask={doBusyTask} isOnboarding={false} close={close}/>)}

            <VerticalNavigationIntroDialog />
        </>);
    if (!onboardingData) {
        return <FullWidthPageLayout isInitialLoad={true} errors={errors}/>;
    }
    // If this is a fresh instance, show onboarding.
    if (onboardingData.TotalProjectsInSpace === 0) {
        return (<>
                <DashboardOnboardingLayout header={{ title: "Projects", actions: [importExportPageAction, addProjectGroupPageAction], primaryAction: addProjectPageAction }} spaceId={spaceId} canContinueOnboarding={false} onboardingProject={undefined} busy={busy} doBusyTask={doBusyTask}/>
                {dialogs}
            </>);
    }
    if (onboardingData.ContinueFirstProject && hasPermission(Permission.ProjectCreate)) {
        return (<>
                <DashboardOnboardingLayout header={{ title: "Projects", actions: [importExportPageAction, addProjectGroupPageAction], primaryAction: addProjectPageAction }} spaceId={spaceId} canContinueOnboarding={!!onboardingData.ContinueFirstProject} onboardingProject={onboardingData.ContinueFirstProject} busy={busy} doBusyTask={doBusyTask}/>
                {dialogs}
            </>);
    }
    return (<PermissionCheck permission={Permission.ProjectView} project="*" alternate={<NoProjectViewPermissionsDashboard />}>
            <FullWidthPageLayout areaTitle="Projects" pageActions={[importExportPageAction, addProjectGroupPageAction]} primaryAction={addProjectPageAction} busy={false} // TODO: Is this loading state correct?
     errors={errors}>
                {dialogs}
                <DashboardDispatcher dispatchAction={dispatchAction}/>

                <ProjectsDashboard doBusyTask={doBusyTask} spaceId={spaceId} filter={projectsFilter} setFilter={setQueryParams} onAddProjectToGroup={openAddProjectDialogWithProjectGroupId}/>
            </FullWidthPageLayout>
        </PermissionCheck>);
}
function useProjectsDashboardFilter(queryParams: ProjectsQueryParams, setQueryParams: QueryParamValuesSetter<ProjectsQueryParams>): ProjectsFilter {
    const [userDefaultProjectsPageSize, setUserDefaultProjectsPageSize] = useLocalStorage(`Octopus.ProjectsDashboard.PageSize`, defaultProjectsPageSize);
    const filter = useMemo(() => ({
        searchValue: queryParams.searchValue,
        page: queryParams.page ?? 1,
        pageSize: (isProjectsPageSize(queryParams.pageSize) ? queryParams.pageSize : undefined) ?? userDefaultProjectsPageSize,
    }), [userDefaultProjectsPageSize, queryParams.page, queryParams.pageSize, queryParams.searchValue]);
    if (userDefaultProjectsPageSize !== filter.pageSize) {
        setUserDefaultProjectsPageSize(filter.pageSize);
    }
    useEffect(() => {
        if (!isEqual(filter, queryParams))
            setQueryParams(filter);
    }, [queryParams, filter, setQueryParams]);
    return filter;
}
function useProjectsOnboardingData(doBusyTask: DoBusyTask) {
    const [projectOnboardingData, setProjectOnboardingData] = useState<GetProjectsOnboardingBffResponse | undefined>(undefined);
    useDoBusyTaskEffect(doBusyTask, async () => {
        const result = await repository.UserOnboarding.getProjectsOnboardingForCurrentUser();
        setProjectOnboardingData(result);
    }, []);
    return projectOnboardingData;
}
export const ProjectsPageWrapper = ({ spaceId, queryParams, setQueryParams }: ProjectsPageProps) => {
    const dispatchAction = useAnalyticActionDispatch();
    const isDashboardVNextEnabled = useIsDashboardVNextEnabled();
    const isKGSFeatureEnabled = useOctopusFeatureToggle("kubernetes-guided-setup", false);
    const isKGSNthProjectFeatureEnabled = useOctopusFeatureToggle("kubernetes-guided-setup-nth-project", false);
    if (!isDashboardVNextEnabled) {
        return <LegacyDashboard spaceId={spaceId} dispatchAction={dispatchAction} isKGSFeatureEnabled={isKGSFeatureEnabled} isKGSNthProjectFeatureEnabled={isKGSNthProjectFeatureEnabled} showProjectGroupEditLink={true}/>;
    }
    return <ProjectsPage spaceId={spaceId} queryParams={queryParams} setQueryParams={setQueryParams}/>;
};
export function ImportExportButton({ spaceId }: {
    spaceId: string;
}) {
    const [openMenu, menuState, buttonAriaAttributes] = useMenuState();
    return (<>
            <ActionButton type={ActionButtonType.Secondary} icon={<DropDownIcon />} iconPosition="right" label="Import/Export" onClick={openMenu} menuButtonAttributes={buttonAriaAttributes}/>
            <SimpleMenu menuState={menuState} items={getImportExportMenuItems(spaceId)} accessibleName={"Import/Export Menu"} compact={true}/>
        </>);
}
function NoProjectViewPermissionsDashboard() {
    return (<Level1EmptyStateLayout title={"Permissions"} intro={"Your current permissions don\u2019t allow you to view projects. If you require more information or believe this is in error please contact your administrator. "} image={<EmptyStateDashboardNoPermissionIllustration />}/>);
}
