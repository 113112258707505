import { css } from "@emotion/css";
import { Callout } from "@octopusdeploy/design-system-components";
import type { ReactNode } from "react";
import * as React from "react";
import ExternalLink from "~/components/Navigation/ExternalLink/ExternalLink";
import SmoothScroll from "~/components/SmoothScroll/SmoothScroll";
import Note from "~/primitiveComponents/form/Note/Note";
import styles from "./style.module.less";
type WarningPanelProps = WarningListProps & WarningTitleProps & WarningHelpProps & {
    fullWidth?: boolean;
    scrollToPanel?: boolean;
};
type WarningDetailProps = {
    warning: string | ReactNode;
    index: number;
    parsedHelpLinks?: string[];
};
const WarningDetail: React.FC<WarningDetailProps> = ({ warning, index, parsedHelpLinks }) => {
    const detailLinks = parsedHelpLinks;
    const detailLink = detailLinks && detailLinks.length > index && detailLinks[index];
    return (<li key={index}>
            {warning}
            {detailLink && (<div className={styles.detailLink}>
                    <ExternalLink href={detailLink}>More information...</ExternalLink>
                </div>)}
        </li>);
};
WarningDetail.displayName = "WarningDetail"
type WarningListProps = {
    warnings: string[] | ReactNode[];
    parsedHelpLinks?: string[];
};
const warningListStyles = css({
    margin: 0,
});
const WarningsList: React.FC<WarningListProps> = (props) => {
    return (<React.Fragment>
            {props.warnings.length > 0 && (<ul className={warningListStyles}>
                    {props.warnings.map((e, index) => (<WarningDetail key={index} index={index} warning={e} parsedHelpLinks={props.parsedHelpLinks}/>))}
                </ul>)}
        </React.Fragment>);
};
WarningsList.displayName = "WarningsList"
type WarningTitleProps = {
    canClose?: boolean;
    onWarningClose?: () => void;
    title?: string;
};
type WarningHelpProps = {
    helpText?: string;
    helpLink?: string;
};
const WarningHelp: React.FC<WarningHelpProps> = ({ helpLink, helpText }) => {
    return (<React.Fragment>
            {helpText && (<div className={styles.note}>
                    <Note>{helpText}</Note>
                </div>)}
            {helpLink && <ExternalLink href={helpLink}>More information...</ExternalLink>}
        </React.Fragment>);
};
WarningHelp.displayName = "WarningHelp"
const WarningPanel: React.FC<WarningPanelProps> = ({ fullWidth = true, scrollToPanel = true, ...props }) => {
    const panelRef = React.createRef<HTMLDivElement>();
    React.useEffect(() => {
        if (scrollToPanel && panelRef.current) {
            SmoothScroll.scrollTo(panelRef.current);
        }
    }, [panelRef, scrollToPanel]);
    return (<div ref={panelRef} style={{
            display: fullWidth ? "block" : "inline-block",
            width: fullWidth ? "100%" : "auto",
        }}>
            <Callout type={"warning"} title={props.title} hideTitle={props.title === undefined} canClose={props.canClose} onClose={props.onWarningClose}>
                {props.warnings && <WarningsList warnings={props.warnings}/>}
                <WarningHelp helpLink={props.helpLink} helpText={props.helpText}/>
            </Callout>
        </div>);
};
WarningPanel.displayName = "WarningPanel"
export default WarningPanel;
