import { useUIOnlyFeatureFlag } from "~/hooks/useUIOnlyFeatureFlag";
export function useIsSystemFontEnabled(): boolean {
    const { isFeatureEnabled } = useSystemFontFeature();
    return isFeatureEnabled;
}
export function useSystemFontFeature() {
    const [isSystemFontEnabled, setIsSystemFontEnabled] = useUIOnlyFeatureFlag("Use System Font", { isInitiallyEnabled: false, scope: "machine" });
    return {
        isFeatureEnabled: isSystemFontEnabled,
        isUIFeatureFlagEnabled: isSystemFontEnabled,
        showUIFeatureFlagToggle: true,
        setUIFeatureFlagEnabled: setIsSystemFontEnabled,
    };
}
