import React from "react";
import { PaperLayoutVNext } from "~/components/PaperLayout/PaperLayoutVNext";
import type { Level2PageLayoutProps } from "~/routing/pageRegistrations/Level2PageLayoutProps";
import { ProjectPageTitleAccessory } from "./ProjectPageTitleAccessory";
export function ProjectPaperLayout(props: Level2PageLayoutProps) {
    if (!props.titleAccessory) {
        props = { ...props, titleAccessory: <ProjectPageTitleAccessory /> };
    }
    return <PaperLayoutVNext {...props}/>;
}
