import { css } from "@emotion/css";
import { ArrowLeftIcon, Callout, ExternalLink } from "@octopusdeploy/design-system-components";
import { space, text } from "@octopusdeploy/design-system-tokens";
import type { ProjectResource } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import React from "react";
import { ProjectStatusItem } from "~/areas/projects/components/ProjectStatus/ProjectStatusItem";
import { StatusBar } from "~/areas/projects/components/ProjectStatus/StatusBar";
import type { ProjectCreatedOption } from "~/areas/projects/components/Projects/AddProject";
import AddProject from "~/areas/projects/components/Projects/AddProject";
import { session } from "~/clientInstance";
import useLocalStorage from "~/hooks/useLocalStorage";
import type { DoBusyTask } from "../DataBaseComponent";
import InternalLink from "../Navigation/InternalLink";
import { GuidedSetupFrame } from "../OnboardingDialog/GuidedSetupFrame";
import addProjectImageDark from "./assets/img-addproject-dark.svg";
import addProjectImageLight from "./assets/img-addproject-light.svg";
interface CreateNewProjectProps {
    spaceId: string;
    projectGroupId?: string;
    isOnboarding?: boolean;
    next: (project: ProjectResource, option: ProjectCreatedOption) => void;
    back: () => void;
    close: () => void;
    doBusyTask: DoBusyTask;
}
function CreateNewProject(props: CreateNewProjectProps) {
    const [feedbackDismissed, setFeedbackDismissed] = useLocalStorage<boolean>("Octopus.Callout.KubernetesGuidedSetup.FeedbackDismissed", false);
    const onBackClicked = () => {
        props.back();
    };
    const onProjectCreated = (project: ProjectResource, option: ProjectCreatedOption) => {
        props.next(project, option);
    };
    const feedbackLink = "full-screen-project-creation-feedback";
    const helpPanelContent = (<div>
            <div>
                A <strong>project</strong> represents a specific software component, service, or database that you want to deploy and manage. Each project has its own deployment process.
            </div>
            {!props.isOnboarding && !feedbackDismissed && new Date() < new Date("2025-01-01") && (<div className={createNewProjectStyles.feedbackContainer}>
                    <Callout type="information" title="Project Setup Feedback" canClose={true} onClose={() => setFeedbackDismissed(true)}>
                        We’d love to <ExternalLink href={session.currentUser?.EmailAddress ? `${feedbackLink}#email=${session.currentUser?.EmailAddress}` : feedbackLink} label="hear your feedback"/> about using the new project setup experience in
                        Octopus Deploy.
                    </Callout>
                </div>)}
        </div>);
    return (<GuidedSetupFrame helpPanelImage={{ src: addProjectImageLight, altText: "Add New Project", darkThemeSrc: addProjectImageDark }} helpPanelContent={helpPanelContent} onClose={props.close} showThemeSelector={props.isOnboarding}>
            <div className={createNewProjectStyles.projectStatusContainer}>
                {!props.isOnboarding && (<InternalLink className={createNewProjectStyles.projectsLink} to={links.projectsPage.generateUrl({ spaceId: props.spaceId })} onClick={props.close}>
                        <ArrowLeftIcon />
                        <span>Projects</span>
                    </InternalLink>)}
                <StatusBar>
                    <ProjectStatusItem name="Project" state="Current"/>
                    <ProjectStatusItem name="Environments" state="Pending"/>
                    <ProjectStatusItem name="Deployment process" state="Pending"/>
                    <ProjectStatusItem name="Release and deploy" state="Pending"/>
                </StatusBar>
            </div>
            <div className={createNewProjectStyles.projectContainer}>
                <AddProject spaceId={props.spaceId} projectCreated={onProjectCreated} hideEnvironmentConfigWarning={true} projectNameTextBoxLabel="Project name" groupId={props.projectGroupId} title="Add New Project" saveButtonLable={props.isOnboarding ? "Next" : "Create Project"} cancelButtonLabel={props.isOnboarding ? "Back" : "Cancel"} onCancelled={props.isOnboarding ? onBackClicked : props.close} isKubernetesGuidedSetup={true} isOnboarding={props.isOnboarding}/>
            </div>
        </GuidedSetupFrame>);
}
const createNewProjectStyles = {
    projectContainer: css({
        flexBasis: "100%",
        "& .MuiDialogContent-root, & .MuiDialogActions-root, & .MuiDialogTitle-root .MuiTypography-root div": {
            padding: "0 !important",
        },
        "& .MuiDialogContent-root div[role='alert'] + div": {
            marginTop: space["32"],
        },
        "& .MuiDialogTitle-root .MuiTypography-root": {
            padding: `${space["48"]} 0 ${space["32"]} 0 !important`,
            "& .MuiLinearProgress-root[role='progressbar']": {
                display: "none",
            },
        },
    }),
    projectStatusContainer: css({
        paddingTop: space["40"],
    }),
    projectsLink: css({
        paddingBottom: space[16],
        display: "flex",
        font: text.regular.default.small,
        gap: space["4"],
    }),
    feedbackContainer: css({
        marginTop: space[24],
    }),
};
export default CreateNewProject;
