import { css, cx } from "@emotion/css";
import { CircularProgress, Callout } from "@octopusdeploy/design-system-components";
import { lineHeight, space, text, themeTokens } from "@octopusdeploy/design-system-tokens";
import type { ProjectResource, SpaceResource } from "@octopusdeploy/octopus-server-client";
import type { UserOnboardingResource } from "@octopusdeploy/octopus-server-client/dist/src/resources/userOnboardingResource";
import { links } from "@octopusdeploy/portal-routes";
import React, { useState } from "react";
import { useAnalyticSampleProjectDispatch } from "~/analytics/Analytics";
import { generateGuid } from "~/areas/projects/components/Process/generation/index";
import { InitializeSampleProjectTour } from "~/areas/projects/components/ProjectLayout/SampleProjectTour/SampleProjectTourUtils";
import { repository, session } from "~/clientInstance";
import type { Errors } from "~/components/DataBaseComponent/index";
import DataBaseComponent from "~/components/DataBaseComponent/index";
import { useSpaceAwareNavigation } from "~/components/Navigation/SpaceAwareNavigation/useSpaceAwareNavigation";
const style = {
    disabledLink: css({
        pointerEvents: "none",
        cursor: "default",
        color: themeTokens.color.text.disabled,
    }),
    loadingText: css({
        color: themeTokens.color.text.primary,
        font: text.regular.default.medium,
    }),
    loadingCallout: css({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        paddingTop: 10,
        paddingBottom: 10,
        gap: space[12],
        opacity: 0,
    }),
    opacityAnimation: css({
        opacity: 1,
        transition: "opacity 300ms ease-in",
    }),
};
const calloutStyles = css({
    lineHeight: lineHeight.xSmall,
});
const containerStyles = css({
    margin: `${space[12]} 0`,
});
const calloutLoadingStyle = cx(style.loadingCallout, style.opacityAnimation);
interface CreateSampleProjectCalloutInternalProps {
    userOnboarding: UserOnboardingResource | undefined;
    currentSpace: SpaceResource | undefined;
    busy: Promise<void> | undefined;
    errors: Errors | undefined;
    onCreateSampleProject: (sampleProjectReference: string) => Promise<ProjectResource | undefined>;
    isInHelpSidebar?: boolean;
}
function CreateSampleProjectCalloutInternal(props: CreateSampleProjectCalloutInternalProps) {
    const { userOnboarding, currentSpace, busy, errors, onCreateSampleProject, isInHelpSidebar = false } = props;
    const navigate = useSpaceAwareNavigation();
    const sampleProjectDispatchAction = useAnalyticSampleProjectDispatch();
    const [loadingStyle, setLoadingStyle] = useState(style.loadingCallout);
    const clickSampleProject = async (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        if (busy) {
            return;
        }
        const sampleProjectReference = generateGuid();
        sampleProjectDispatchAction("Request Sample Project", {
            sampleProjectReference,
            source: isInHelpSidebar ? "Help Sidebar" : "Welcome Dialog",
        });
        setTimeout(() => setLoadingStyle(calloutLoadingStyle), 10);
        const project = await onCreateSampleProject(sampleProjectReference);
        if (project) {
            InitializeSampleProjectTour(project.Slug);
            navigate.navigate(links.projectOverviewRedirect.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug }));
        }
    };
    if (!currentSpace || !session.currentPermissions?.isSpaceManager(currentSpace)) {
        return <></>;
    }
    return (<div className={containerStyles}>
            <Callout type={"new-feature"} hideTitle={true}>
                {busy ? (<div className={loadingStyle}>
                        <CircularProgress size="large"/>
                        <div className={style.loadingText}>Loading sample project</div>
                    </div>) : (<div className={calloutStyles}>
                        <a href={busy ? undefined : "#"} role="link" onClick={clickSampleProject} aria-disabled={!!busy} className={busy ? style.disabledLink : undefined}>
                            Create a sample project
                        </a>{" "}
                        to learn about key Octopus features.
                    </div>)}
            </Callout>
        </div>);
}
interface CreateSampleProjectCalloutState {
    currentSpace?: SpaceResource;
    userOnboarding?: UserOnboardingResource;
}
type CreateSampleProjectCalloutProps = {
    isInHelpSidebar?: boolean;
};
class CreateSampleProjectCallout extends DataBaseComponent<CreateSampleProjectCalloutProps, CreateSampleProjectCalloutState> {
    constructor(props: CreateSampleProjectCalloutProps) {
        super(props);
        this.state = {};
    }
    async componentDidMount() {
        await this.doBusyTask(async () => {
            if (!repository.spaceId)
                return;
            const [response, space] = await Promise.all([repository.UserOnboarding.getForCurrentUser(), repository.Spaces.get(repository.spaceId)]);
            this.setState({ userOnboarding: response?.UserOnboardingResource, currentSpace: space });
        });
    }
    async handleCreateSampleProject(sampleProjectReference: string): Promise<ProjectResource | undefined> {
        let project: ProjectResource | undefined = undefined;
        await this.doBusyTask(async () => {
            const sampleProject = await repository.Projects.createSampleProject("Sample Project", sampleProjectReference);
            project = sampleProject.Project;
        });
        return project;
    }
    render() {
        return (<CreateSampleProjectCalloutInternal busy={this.state.busy} userOnboarding={this.state.userOnboarding} currentSpace={this.state.currentSpace} onCreateSampleProject={(sampleProjectReference: string) => this.handleCreateSampleProject(sampleProjectReference)} errors={this.errors} isInHelpSidebar={this.props.isInHelpSidebar}/>);
    }
    static displayName = "CreateSampleProjectCallout";
}
export default CreateSampleProjectCallout;
