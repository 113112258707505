import React from "react";
import { useTrackThemeChanged } from "~/components/Theme/analytics/useTrackThemeChanged";
import useLocalStorage from "~/hooks/useLocalStorage";
import type { ThemePaletteType } from "~/theme/index";
export type SetThemeValue = (value: ThemePaletteType | "unassigned") => void;
export type ThemeValue = ThemePaletteType | "unassigned";
export function useThemePaletteType(): ThemePaletteType {
    const { coercedPaletteType } = useThemeFromLocalStorage();
    return coercedPaletteType;
}
export function useThemeType(): ThemeValue {
    const { paletteType } = useThemeFromLocalStorage();
    return paletteType;
}
export function useSetThemeType(): SetThemeValue {
    const { coercedPaletteType, setPaletteType } = useThemeFromLocalStorage();
    const trackThemeChanged = useTrackThemeChanged();
    return React.useCallback((value) => {
        setPaletteType(value);
        const browserPreferredPalette = getBrowserPreferredPalette("light");
        const selectedTheme = value === "unassigned" ? browserPreferredPalette : value;
        trackThemeChanged({ previousTheme: coercedPaletteType, selectedTheme: selectedTheme });
    }, [coercedPaletteType, trackThemeChanged, setPaletteType]);
}
interface ThemeFromLocalStorageResult {
    paletteType: ThemeValue;
    coercedPaletteType: ThemePaletteType;
    setPaletteType: SetThemeValue;
}
function useThemeFromLocalStorage(): ThemeFromLocalStorageResult {
    const [paletteFromStorage, setPaletteType] = useLocalStorage<ThemeValue>("theme", "unassigned");
    const browserPreferredPalette = getBrowserPreferredPalette("light");
    return {
        paletteType: paletteFromStorage,
        coercedPaletteType: paletteFromStorage === "unassigned" ? browserPreferredPalette : paletteFromStorage,
        setPaletteType,
    };
}
function getBrowserPreferredPalette(fallback: ThemePaletteType): ThemePaletteType {
    if (!window.matchMedia) {
        return fallback;
    }
    else if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
        return "dark";
    }
    else if (window.matchMedia("(prefers-color-scheme: light)").matches) {
        return "light";
    }
    else {
        return fallback;
    }
}
