import { useMenuState, SimpleMenu } from "@octopusdeploy/design-system-components";
import type { SimpleMenuItem, MenuTargetAriaAttributes } from "@octopusdeploy/design-system-components";
import classNames from "classnames";
import * as React from "react";
import { useCallback } from "react";
import type { MenuNode } from "~/components/LinksMenu/MenuNode";
import { isMenuGroup } from "~/components/LinksMenu/MenuNode";
import styles from "./LinksMenu.module.less";
interface LinksMenuProps {
    items: MenuNode[];
    label?: string | JSX.Element;
    className?: string;
}
export function LinksMenu(props: LinksMenuProps) {
    const [openMenu, menuState, buttonAriaAttributes] = useMenuState();
    // TODO: Pass a better accessible name in
    const accessibleName = "links";
    return (<>
            <LinksMenuButton onClick={openMenu} label={props.label} className={props.className} ariaAttributes={buttonAriaAttributes}/>
            <SimpleMenu menuState={menuState} items={props.items.map(convertMenuNodeToSimpleMenuItem)} accessibleName={accessibleName}/>
        </>);
}
function convertMenuNodeToSimpleMenuItem(menuNode: MenuNode): SimpleMenuItem {
    if (isMenuGroup(menuNode)) {
        return { type: "nested-menu", children: menuNode.children.map(convertMenuNodeToSimpleMenuItem), label: menuNode.label };
    }
    return { type: "internal-link", path: menuNode.url, label: menuNode.text, showAsActive: menuNode.exact ? "if path matches exactly" : "if path partially matches" };
}
interface LinksMenuButtonProps {
    onClick: (event: React.MouseEvent) => void;
    label?: string | JSX.Element;
    className?: string;
    ariaAttributes: MenuTargetAriaAttributes;
}
function LinksMenuButton({ label, onClick, className, ariaAttributes }: LinksMenuButtonProps) {
    const onClickCallback = useCallback((event: React.MouseEvent<HTMLAnchorElement>) => {
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        if (event.currentTarget.contains(event.target as HTMLElement)) {
            event.preventDefault();
            onClick(event);
        }
    }, [onClick]);
    return (<a onClick={onClickCallback} href="#" className={className} {...ariaAttributes}>
            <span>{label}</span>
            <em className={classNames(styles.caretIcon, "fa-solid fa-caret-down")}/>
        </a>);
}
