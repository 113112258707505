/* eslint-disable @octopusdeploy/custom-portal-rules/no-restricted-imports */
import { css, cx } from "@emotion/css";
import type { ChipClassKey, ChipProps as MaterialChipProps } from "@material-ui/core/Chip";
import MaterialChip from "@material-ui/core/Chip";
import type { ClassNameMap } from "@material-ui/styles/withStyles/withStyles";
import { Tooltip } from "@octopusdeploy/design-system-components";
import classNames from "classnames";
import type { ReactNode } from "react";
import * as React from "react";
import styles from "./StyledMaterialChip.module.less";
type ChipStyleProps = {
    backgroundColor?: string;
    labelColor?: string;
    markAsRemoved?: boolean;
    borderColor?: string;
    fullWidth?: boolean;
    noMargin?: boolean;
    accessibilityRole?: string;
    backgroundColorOnHover?: string;
    tooltipContent?: ReactNode;
    tooltipDisplay?: "block" | "inline-block" | "grid";
};
type ExposedMaterialChipProps = Pick<MaterialChipProps, "onClick" | "onDelete" | "deleteIcon" | "label" | "variant" | "tabIndex" | "icon" | "className">;
type StyledMaterialChipProps = ChipStyleProps & ExposedMaterialChipProps;
export function StyledMaterialChip(props: StyledMaterialChipProps) {
    const rootMarginClass = props.noMargin ? styles.rootNoMargin : styles.rootMargin;
    const classes: Partial<ClassNameMap<ChipClassKey>> = {
        root: classNames(styles.root, rootMarginClass),
    };
    const rootBackgroundColorStyle = props.backgroundColor ? { backgroundColor: props.backgroundColor } : undefined;
    const rootLabelColorStyle = props.labelColor ? { color: props.labelColor } : undefined;
    const rootStyleProps = rootBackgroundColorStyle || rootLabelColorStyle
        ? {
            style: {
                ...rootBackgroundColorStyle,
                ...rootLabelColorStyle,
            },
        }
        : {};
    //We can't just pass role through as undefined as it's a primitive aria attribute which will end up overriding anything material-ui provides
    //we therefore omit this prop when it hasn't been specified to allow the role to be changed if needed.
    const ariaProps = props.accessibilityRole ? { role: props.accessibilityRole } : {};
    const styledLabel = <span className={cx(labelStyles, { [labelWithLineThroughStyles]: props.markAsRemoved }, { [labelWithLimitedWidthStyles]: !props.fullWidth })}>{props.label}</span>;
    const labelWithTooltip = props.tooltipContent ? (<Tooltip content={props.tooltipContent} display={props.tooltipDisplay}>
            {styledLabel}
        </Tooltip>) : (styledLabel);
    return (<MaterialChip classes={classes} icon={props.icon} label={labelWithTooltip} onClick={props.onClick} tabIndex={props.tabIndex} variant={props.variant} onDelete={props.onDelete} deleteIcon={props.deleteIcon} {...rootStyleProps} {...ariaProps}/>);
}
const labelStyles = css({
    lineHeight: "1.5rem",
    fontSize: "0.75rem",
    fontWeight: "normal",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
});
const labelWithLineThroughStyles = css({
    textDecoration: "line-through",
});
const labelWithLimitedWidthStyles = css({
    maxWidth: "12.5rem",
});
