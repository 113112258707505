import { css } from "@emotion/css";
import { CoffeeSpiltIllustration, QuestionIllustration } from "@octopusdeploy/design-system-components";
import { space, text, themeTokens } from "@octopusdeploy/design-system-tokens";
import type { ValidateGitRefV2Response } from "@octopusdeploy/octopus-server-client";
import { ValidateGitRefV2ResponseType } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import React from "react";
import InternalLink from "~/components/Navigation/InternalLink";
import { PaperLayoutVNext } from "~/components/PaperLayout/PaperLayoutVNext";
import { BranchSelector } from "../BranchSelector/BranchSelector";
interface VcsErrorPageProps {
    spaceId: string;
    error: ValidateGitRefV2Response;
    projectSlug: string;
}
export function VcsErrorPage({ spaceId, error, projectSlug }: VcsErrorPageProps) {
    const errorIllustration = getErrorIllustration(error);
    const errorTitle = getErrorTitle(error);
    const errorMessage = getErrorMessage(error);
    const errorInfoText = getErrorInfoText(error, projectSlug, spaceId);
    const showBranchSelector = error.Type !== ValidateGitRefV2ResponseType.ConnectionFailed;
    return (<PaperLayoutVNext fullWidth>
            <div className={containerStyles}>
                {errorIllustration}
                <h2 className={headerStyles}>{errorTitle}</h2>
                <p className={paragraphStyles}>
                    {errorMessage}
                    <br />
                    {errorInfoText}
                </p>
                {showBranchSelector && (<div>
                        <BranchSelector />
                    </div>)}
            </div>
        </PaperLayoutVNext>);
}
function getErrorIllustration(error: ValidateGitRefV2Response) {
    switch (error.Type) {
        case ValidateGitRefV2ResponseType.ConnectionFailed:
            return <QuestionIllustration />;
        default:
            return <CoffeeSpiltIllustration />;
    }
}
function getErrorTitle(error: ValidateGitRefV2Response) {
    switch (error.Type) {
        case ValidateGitRefV2ResponseType.GitRefNotFound:
            return "Could not load the selected git ref";
        default:
            return "Could not access version control";
    }
}
function getErrorMessage(error: ValidateGitRefV2Response) {
    switch (error.Type) {
        case ValidateGitRefV2ResponseType.ConnectionFailed:
            return "We're unable to establish a connection to the git instance.";
        default:
            return `${error.Message}.`;
    }
}
function getErrorInfoText(error: ValidateGitRefV2Response, projectSlug: string, spaceId: string) {
    switch (error.Type) {
        case ValidateGitRefV2ResponseType.ConnectionFailed:
            return (<>
                    This could be due to an outage or check your <InternalLink to={links.projectVersionControlSettingsPage.generateUrl({ spaceId, projectSlug })}>Version Control Settings</InternalLink>.
                </>);
        default:
            return (<>
                    <span className={infoTextStyles}>Try selecting a different branch below</span>.
                </>);
    }
}
const containerStyles = css({
    display: "flex",
    flexDirection: "column",
    padding: space[32],
    gap: space[16],
    alignItems: "center",
});
const headerStyles = css({
    margin: 0,
    font: text.heading.large,
});
const paragraphStyles = css({
    margin: 0,
    font: text.regular.default.large,
    textAlign: "center",
});
const infoTextStyles = css({
    color: themeTokens.color.text.info,
});
