/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { css } from "@emotion/css";
import { Callout } from "@octopusdeploy/design-system-components";
import { fontSize, lineHeight, space, themeTokens } from "@octopusdeploy/design-system-tokens";
import { links } from "@octopusdeploy/portal-routes";
import pluralize from "pluralize";
import React from "react";
import { repository } from "~/clientInstance";
import { CopyToClipboardChip } from "~/components/Chips/CopyToClipboardChip";
import InternalLink from "~/components/Navigation/InternalLink";
interface TargetTagsWarningCalloutProps {
    unassignedTargetTags: string[];
}
export const UnassignedTargetTagsWarningCallout = ({ unassignedTargetTags }: TargetTagsWarningCalloutProps) => {
    if (unassignedTargetTags.length === 0) {
        return <></>;
    }
    return (<Callout hideTitle={true} type="warning">
            <div className={containerStyles}>
                <UnassignedTargetTagsWarningCalloutContent unassignedTargetTags={unassignedTargetTags}/>
            </div>
        </Callout>);
};
export const UnassignedTargetTagsWarningCalloutContent = ({ unassignedTargetTags }: TargetTagsWarningCalloutProps) => {
    return (<>
            <div>{`${unassignedTargetTags.length} target ${pluralize("tag", unassignedTargetTags.length)} ${unassignedTargetTags.length === 1 ? "isn't" : "aren't"} assigned to deployment targets:`}</div>
            <div className={targetTagContainerStyles}>
                {unassignedTargetTags.map((targetTag) => (<CopyToClipboardChip key={targetTag} text={targetTag} noMargin={true}/>))}
            </div>
            <div>
                Assign in{" "}
                <InternalLink to={links.deploymentTargetsPage.generateUrl({ spaceId: repository.spaceId! })} openInSelf={false} showOpensInNewTabIcon={true}>
                    Deployment Targets
                </InternalLink>
                .
            </div>
            <div className={secondaryContentStyles}>If this is intentional, no action is required.</div>
        </>);
};
const TOTAL_CALLOUT_WIDTH = 732;
const CALLOUT_PADDING = 24;
const containerStyles = css({
    width: `${TOTAL_CALLOUT_WIDTH - CALLOUT_PADDING}px`,
});
const targetTagContainerStyles = css({
    display: "flex",
    gap: "6px",
    marginTop: space["8"],
    marginBottom: space["8"],
});
const secondaryContentStyles = css({
    marginTop: space["8"],
    color: themeTokens.color.text.secondary,
    fontSize: fontSize.small,
    lineHeight: lineHeight.xSmall,
});
