/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { css } from "@emotion/css";
import type { ActionProperties, DeploymentActionResource, DeploymentStepResource, BlueprintResource, GitRefResource } from "@octopusdeploy/octopus-server-client";
import { Permission, TenantedDeploymentMode, StartTrigger, SpecialVariables } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import React, { useState } from "react";
import { isRunbookProcessState } from "~/areas/projects/components/Process/Common/CommonProcessHelpers";
import { useProjectContext } from "~/areas/projects/context";
import Roles from "~/components/Actions/Roles";
import type { ActionPlugin } from "~/components/Actions/pluginRegistry";
import pluginRegistry from "~/components/Actions/pluginRegistry";
import Chip from "~/components/Chips/Chip";
import type { DoBusyTask } from "~/components/DataBaseComponent/index";
import { useDoBusyTaskEffect } from "~/components/DataBaseComponent/index";
import { StepRolling } from "~/components/Images/Process/StepRolling";
import InternalLink from "~/components/Navigation/InternalLink/index";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import getActionLogoUrl from "../../getActionLogoUrl";
import { useProcessContext } from "../Contexts/ProcessContext";
import { useProcessErrorSelectors } from "../Contexts/ProcessErrors/ProcessErrorsContext";
import { useProcessWarningSelectors } from "../Contexts/ProcessWarnings/ProcessWarningsContext";
import type { ProcessListLayoutLoaderLookupData } from "../ProcessListLayoutLoader";
import type { StoredStep, StoredAction, ProcessState } from "../types";
import ProcessListItem from "./ProcessListItem";
import processListItemStyles from "./ProcessListItem.module.less";
interface ProcessListPageListItemForBlueprintProps {
    lookups: ProcessListLayoutLoaderLookupData;
    step: StoredStep;
    action: StoredAction;
    doBusyTask: DoBusyTask;
    blueprint: BlueprintResource;
    gitRefResource: GitRefResource | undefined;
}
const ProcessListPageListItemForBlueprint: React.FC<ProcessListPageListItemForBlueprintProps> = ({ lookups, step, action, doBusyTask, blueprint, gitRefResource }) => {
    const processContext = useProcessContext();
    const { selectors } = processContext;
    const { state: { model: project }, } = useProjectContext();
    const processErrorSelectors = useProcessErrorSelectors();
    const errors = processErrorSelectors.getActionErrors(action.Id, selectors);
    const processWarningSelectors = useProcessWarningSelectors();
    const warnings = processWarningSelectors.getActionWarnings(action.Id, selectors);
    const feedViewPermissionGranted = isAllowed({ permission: Permission.FeedView, project: processContext.state.ownerId, wildcard: true });
    const environmentsById = lookups.environmentsById;
    const channelsById = lookups.channelsById;
    const stepNumber = selectors.getStepNumber(step.Id);
    const maxParallelism = step.Properties[SpecialVariables.Action.MaxParallelism];
    const showWindowSize = typeof maxParallelism === "string" && maxParallelism ? maxParallelism.length > 0 : false;
    const parentStepLabel = React.useMemo(() => showWindowSize ? (<span>Rolling deployment</span>) : (<span>
                    Multi-step deployment across
                    <br />
                    deployment targets
                </span>), [showWindowSize]);
    const rollingStepIcon = React.useMemo(() => <StepRolling height="2.6rem" className={processListItemStyles.stepIcon}/>, []);
    const stepErrors = processErrorSelectors.getStepErrors(step.Id);
    const stepWarnings = processWarningSelectors.getStepWarnings(step.Id);
    function isParentDisabled(step: DeploymentStepResource): boolean {
        const disabledSteps: DeploymentActionResource[] = [];
        step.Actions.map((a: DeploymentActionResource) => {
            if (a.IsDisabled) {
                disabledSteps.push(a);
            }
        });
        return disabledSteps.length === step.Actions.length;
    }
    function getTags(action: DeploymentActionResource): string[] {
        if (project.TenantedDeploymentMode === TenantedDeploymentMode.Untenanted) {
            return [];
        }
        return action.TenantTags;
    }
    return (<div className={styles.blueprintContainer} key={step.Id}>
            <InternalLink to={selectors.getStepDetailsUrl(processContext.state.ownerSlug, gitRefResource, step.Id, action.Id)}>
                <div className={styles.blueprintHeading}>{`Blueprint: ${blueprint.Name}`}</div>
            </InternalLink>
            {blueprint.Steps.map((deploymentStep, index) => {
            const actionNumber = index + 1;
            if (deploymentStep.Actions.length === 1) {
                const blueprintAction = deploymentStep.Actions[0];
                return (<ProcessListItem actionType={blueprintAction.ActionType} logoUrl={getActionLogoUrl(blueprintAction)} environmentsLookup={environmentsById
                        ? blueprintAction.Environments.map((key) => ({
                            Key: key,
                            Environment: environmentsById[key],
                        }))
                        : undefined} excludedEnvironmentsLookup={environmentsById
                        ? blueprintAction.ExcludedEnvironments.map((key) => ({
                            Key: key,
                            Environment: environmentsById[key],
                        }))
                        : undefined} channelsLookup={channelsById
                        ? blueprintAction.Channels.map((key) => ({
                            Key: key,
                            Channel: channelsById[key],
                        }))
                        : undefined} tags={getTags(blueprintAction)} isDisabled={blueprintAction.IsDisabled || selectors.isActionDisabled(action.Id)} notes={blueprintAction.Notes} actionErrors={errors} actionWarnings={warnings} index={`${stepNumber}.${actionNumber}.`} detailsUrl={getDetailsUrl(processContext.state.model.process, processContext.state.ownerSlug, gitRefResource, blueprint.Id, undefined, blueprintAction.Id)} isParentGroup={false} name={blueprintAction.Name} isRunInParallelWithLast={deploymentStep.StartTrigger === StartTrigger.StartWithPrevious && !(actionNumber === 1)} key={deploymentStep.Id}>
                            <div className={processListItemStyles.summaryContainer}>
                                <StepSummary action={blueprintAction} lookups={lookups} doBusyTask={doBusyTask} isChildAction={false}/>
                                {blueprintAction.Container.Image && blueprintAction.Container.FeedId && blueprintAction.Properties["Octopus.Action.RunOnServer"] === "true" ? (<span style={{ paddingLeft: "0.1em" }}>
                                        <Chip description={feedViewPermissionGranted ? `Container Image: ${blueprintAction.Container.Image}` : undefined}>Runs in a container</Chip>
                                    </span>) : ("")}
                            </div>
                        </ProcessListItem>);
            }
            else {
                return (<div key={deploymentStep.Id} className={processListItemStyles.group}>
                            <ProcessListItem actionType={parentStepLabel} icon={rollingStepIcon} actionErrors={stepErrors} actionWarnings={stepWarnings} detailsUrl={getDetailsUrl(processContext.state.model.process, processContext.state.ownerSlug, gitRefResource, blueprint.Id, deploymentStep.Id, undefined)} isParentGroup={true} index={`${stepNumber}.${actionNumber}.`} name={deploymentStep.Name} isRunInParallelWithLast={deploymentStep.StartTrigger === StartTrigger.StartWithPrevious && !selectors.isFirstStep(deploymentStep.Id)} isDisabled={isParentDisabled(deploymentStep) || selectors.isActionDisabled(action.Id)} notes={null} key={deploymentStep.Id}>
                                {deploymentStep.Properties[SpecialVariables.Action.MaxParallelism] ? <span>Rolling deployment</span> : <span>Multi-step deployment</span>}
                                &nbsp;across deployment targets with&nbsp;
                                <Roles rolesAsCSV={deploymentStep.Properties[SpecialVariables.Action.TargetRoles] as string}/>
                            </ProcessListItem>
                            {deploymentStep.Actions.map((blueprintAction, childIndex) => {
                        const childNumber = childIndex + 1;
                        return (<ProcessListItem key={blueprintAction.Id} actionType={blueprintAction.ActionType} logoUrl={getActionLogoUrl(blueprintAction)} environmentsLookup={environmentsById
                                ? blueprintAction.Environments.map((key) => ({
                                    Key: key,
                                    Environment: environmentsById[key],
                                }))
                                : undefined} excludedEnvironmentsLookup={environmentsById
                                ? blueprintAction.ExcludedEnvironments.map((key) => ({
                                    Key: key,
                                    Environment: environmentsById[key],
                                }))
                                : undefined} channelsLookup={channelsById
                                ? blueprintAction.Channels.map((key) => ({
                                    Key: key,
                                    Channel: channelsById[key],
                                }))
                                : undefined} tags={getTags(blueprintAction)} isDisabled={blueprintAction.IsDisabled || selectors.isActionDisabled(action.Id)} notes={blueprintAction.Notes} actionErrors={errors} actionWarnings={warnings} index={`${stepNumber}.${actionNumber}.${childNumber}.`} detailsUrl={getDetailsUrl(processContext.state.model.process, processContext.state.ownerSlug, gitRefResource, blueprint.Id, undefined, blueprintAction.Id)} isParentGroup={false} name={blueprintAction.Name} isRunInParallelWithLast={false}>
                                        <div className={processListItemStyles.summaryContainer}>
                                            <StepSummary action={blueprintAction} lookups={lookups} doBusyTask={doBusyTask} isChildAction={true}/>
                                            {blueprintAction.Container.Image && blueprintAction.Container.FeedId && blueprintAction.Properties["Octopus.Action.RunOnServer"] === "true" ? (<span style={{ paddingLeft: "0.1em" }}>
                                                    <Chip description={feedViewPermissionGranted ? `Container Image: ${blueprintAction.Container.Image}` : undefined}>Runs in a container</Chip>
                                                </span>) : ("")}
                                        </div>
                                    </ProcessListItem>);
                    })}
                        </div>);
            }
        })}
        </div>);
};
ProcessListPageListItemForBlueprint.displayName = "ProcessListPageListItemForBlueprint"
const getDetailsUrl = (process: ProcessState, ownerSlug: string, gitRef: GitRefResource | undefined, blueprintId: string, blueprintParentStepId: string | undefined, blueprintActionId: string | undefined) => {
    if (isRunbookProcessState(process)) {
        return links.projectRunbookProcessStepsPage.generateUrl({ spaceId: process.SpaceId, projectSlug: ownerSlug, runbookId: process.RunbookId, processId: process.Id }, { blueprintId, blueprintParentStepId, blueprintActionId });
    }
    if (process) {
        return gitRef
            ? links.branchDeploymentProcessStepsPage.generateUrl({ spaceId: process.SpaceId, projectSlug: ownerSlug, branchName: gitRef.CanonicalName }, { blueprintId, blueprintParentStepId, blueprintActionId })
            : links.deploymentProcessStepsPage.generateUrl({ spaceId: process.SpaceId, projectSlug: ownerSlug }, { blueprintId, blueprintParentStepId, blueprintActionId });
    }
    else {
        throw new Error("Process is not defined");
    }
};
const styles = {
    blueprintHeading: css({
        display: "flex",
        gap: "1rem",
        padding: "1rem",
        color: "var(--linkText)",
        fontWeight: "500",
        fontSize: "1rem",
        borderTop: "0.1rem solid var(--dividerLight)",
        borderBottom: "0.0625rem solid var(--dividerLight)",
        position: "relative",
        zIndex: "0",
        backgroundColor: "var(--secondaryBackground)",
        ":hover": {
            backgroundColor: "var(--hover)",
        },
    }),
    blueprintContainer: css({
        display: "flex",
        flexDirection: "column",
        backgroundColor: "var(--secondaryBackground)",
        borderBottom: "0.1rem solid var(--dividerLight)",
    }),
};
interface StepSummaryProps {
    action: DeploymentActionResource;
    doBusyTask: DoBusyTask;
    lookups: ProcessListLayoutLoaderLookupData;
    isChildAction: boolean;
}
function StepSummary({ action, doBusyTask, lookups, isChildAction }: StepSummaryProps) {
    const [actionPlugin, setActionPlugin] = useState<ActionPlugin<ActionProperties, Partial<Record<string, string>>> | undefined>();
    useDoBusyTaskEffect(doBusyTask, async () => {
        const actionPlugin = await pluginRegistry.getAction(action.ActionType, action.StepPackageVersion);
        setActionPlugin(actionPlugin);
    }, []);
    if (!actionPlugin) {
        return null;
    }
    return (<>
            {actionPlugin.summary(action.Properties, isChildAction ? "" : (action.Properties[SpecialVariables.Action.TargetRoles] as string), action.Packages, action.WorkerPoolId ? lookups.workerPoolsById[action.WorkerPoolId] : undefined, action.WorkerPoolVariable ?? undefined, action.GitDependencies ?? undefined)}
        </>);
}
export default ProcessListPageListItemForBlueprint;
