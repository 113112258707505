import { css } from "@emotion/css";
import { Callout } from "@octopusdeploy/design-system-components";
import { text } from "@octopusdeploy/design-system-tokens";
import type { UserOnboardingResource } from "@octopusdeploy/octopus-server-client/src/resources/userOnboardingResource";
import { useTrackEvent } from "@octopusdeploy/portal-analytics";
import { links } from "@octopusdeploy/portal-routes";
import { useState, useRef } from "react";
import * as React from "react";
import type { StepEditorEvent } from "~/analytics/Analytics";
import { Action } from "~/analytics/Analytics";
import styles from "~/areas/projects/components/Process/Common/style.module.less";
import type { RunOn } from "~/areas/projects/components/Process/types";
import { ExecutionLocation } from "~/areas/projects/components/Process/types";
import { repository } from "~/clientInstance";
import { RoleChip } from "~/components/Chips/index";
import { TargetTagsProcessEditorContextualHelp } from "~/components/ContextualHelp/ContextualHelpSnippets";
import { isFeatureToggleEnabled } from "~/components/FeatureToggle/New/FeatureToggleContext";
import { TargetTagMultiSelect } from "~/components/MultiSelect/TargetTagMultiSelect";
import ExternalLink from "~/components/Navigation/ExternalLink/index";
import InternalLink from "~/components/Navigation/InternalLink";
import type { FocusableComponent } from "~/components/VirtualListWithKeyboard/FocusableComponent";
import { ExpandableFormSection, Note, Summary } from "~/components/form/index";
import ParseHelper from "~/utils/ParseHelper/index";
import { createDismissTargetTagsExplainerEvent } from "./TargetRolesFormSection.analytics";
import { TargetTagsExplainer } from "./TargetTagsExplainer";
export type TargetRolesFormSectionProps = {
    projectSlug: string;
    expandedByDefault: boolean;
    title?: string;
    availableRoles: string[];
    targetRoles: string | undefined;
    onTargetRolesChanged: (roles: string[]) => void;
    runOn: RunOn;
    errorMessage: string | undefined;
    analyticsStepEditorDispatch: (name: string, event: Omit<StepEditorEvent, "stepTemplate">) => void;
    isKubernetesStep: boolean;
    userOnboarding: UserOnboardingResource | null;
    doBusyTask: (action: () => Promise<void>) => Promise<boolean>;
    analyticsArea: string;
    stepTemplateName: string;
    isGuidedSetup: boolean;
};
const RunOnRolesHelp = (props: {
    isKubernetesStep: boolean;
    isGuidedSetup: boolean;
    executionLocation: ExecutionLocation;
}) => {
    const isClearerTargetRoleConfigurationFeatureToggleEnabled = isFeatureToggleEnabled("ClearerTargetRoleConfigurationFeatureToggle");
    if (props.isGuidedSetup && props.isKubernetesStep) {
        return <span>Use target tags to select the Kubernetes clusters this step will deploy to.</span>;
    }
    if (isClearerTargetRoleConfigurationFeatureToggleEnabled) {
        return <span>This step will deploy to {props.isKubernetesStep ? "Kubernetes clusters" : "deployment targets"} with any of these matching tags.</span>;
    }
    return (<span>
            Target tags are used to select the deployment targets this step will execute {props.executionLocation === ExecutionLocation.DeploymentTarget ? " on" : " on behalf of"}. Deployment targets are configured in{" "}
            {/*eslint-disable-next-line @typescript-eslint/no-non-null-assertion*/}
            <InternalLink to={links.infrastructureRootRedirect.generateUrl({ spaceId: repository.spaceId! })} openInSelf={false}>
                Infrastructure
            </InternalLink>
            .
        </span>);
};
const TargetTagsHelp = (props: {
    userOnboarding: UserOnboardingResource | null;
    isKubernetesStep: boolean;
    isGuidedSetup: boolean;
    stepTemplateName: string;
    analyticsArea: string;
    doBusyTask: (action: () => Promise<void>) => Promise<boolean>;
}) => {
    const [explainerDismissed, setExplainerDismissed] = useState<boolean>(false);
    const [guidedSetupDismissed, setGuidedSetupDismissed] = useState<boolean>(false);
    const isClearerTargetRoleConfigurationFeatureToggleEnabled = isFeatureToggleEnabled("ClearerTargetRoleConfigurationFeatureToggle");
    const trackEvent = useTrackEvent();
    if (props.isGuidedSetup && props.isKubernetesStep) {
        return !guidedSetupDismissed ? (<Callout type="information" title="" canClose onClose={() => {
                setGuidedSetupDismissed(true);
            }}>
                We selected the target tag you created earlier.
            </Callout>) : null;
    }
    if (isClearerTargetRoleConfigurationFeatureToggleEnabled && props.userOnboarding) {
        return (<TargetTagsExplainer isKubernetesStep={props.isKubernetesStep} hide={props.userOnboarding?.TargetTagNewConceptCalloutDismissedDate !== null || explainerDismissed} onDismiss={() => {
                trackEvent(createDismissTargetTagsExplainerEvent({
                    stepTemplateName: props.stepTemplateName,
                    isKubernetesStep: props.isKubernetesStep,
                    area: props.analyticsArea,
                }));
                props.doBusyTask(async () => {
                    if (props.userOnboarding) {
                        await repository.UserOnboarding.modifyForCurrentUser({ ...props.userOnboarding, TargetTagNewConceptCalloutDismissedDate: new Date() });
                        setExplainerDismissed(true);
                    }
                });
            }}/>);
    }
    return (<Note>
            Learn more about <ExternalLink href="TargetRoles">target tags</ExternalLink>.
        </Note>);
};
type SuggestedTargetRolesProps = Pick<TargetRolesFormSectionProps, "targetRoles" | "onTargetRolesChanged" | "analyticsStepEditorDispatch"> & {
    projectSlug: string;
    isKubernetesStep: boolean;
    onClick: () => void;
};
const SuggestedTargetRoles = (props: SuggestedTargetRolesProps) => {
    const { targetRoles, onTargetRolesChanged, analyticsStepEditorDispatch, projectSlug, isKubernetesStep, onClick } = props;
    const suggestedRoles = getSuggestedTargetTags(projectSlug, isKubernetesStep);
    const onSelectSuggestedRole = (e: React.MouseEvent<HTMLAnchorElement>, targetName: string) => {
        e.preventDefault();
        const prevTargets = ParseHelper.parseCSV(targetRoles || "");
        if (prevTargets.includes(targetName)) {
            return;
        }
        analyticsStepEditorDispatch?.("Select Suggested Target", { action: Action.Select, resource: "Target Role", value: targetName });
        onTargetRolesChanged(prevTargets.concat(targetName));
        onClick();
    };
    return (<ul className={styles.suggestedTargetRoles}>
            <li key={suggestedRoles}>
                <a href="#" onClick={(e) => onSelectSuggestedRole(e, suggestedRoles)}>
                    {suggestedRoles}
                </a>
            </li>
        </ul>);
};
export const TargetRolesFormSection = (props: TargetRolesFormSectionProps) => {
    const { projectSlug, title, expandedByDefault, targetRoles, runOn, onTargetRolesChanged, errorMessage, availableRoles, isKubernetesStep } = props;
    //const [explainerDismissed, setExplainerDismissed] = useState<boolean>(false);
    const multiSelectRef = useRef<FocusableComponent | null>(null);
    //const trackEvent = useTrackEvent();
    const isClearerTargetRoleConfigurationFeatureToggleEnabled = isFeatureToggleEnabled("ClearerTargetRoleConfigurationFeatureToggle");
    const roleSummary = () => {
        if (!targetRoles) {
            return Summary.placeholder("No target tags selected");
        }
        const list = ParseHelper.parseCSV(targetRoles);
        const roleChips = list.map((r) => <RoleChip role={r} key={`role-${r}`} showContextualHelp/>);
        return Summary.summary(roleChips);
    };
    return (<>
            <div>
                <ExpandableFormSection isExpandedByDefault={expandedByDefault} summary={roleSummary()} title={title || "Target Tags"} errorKey="Octopus.Action.TargetRoles" help={<RunOnRolesHelp isKubernetesStep={props.isKubernetesStep} isGuidedSetup={props.isGuidedSetup} executionLocation={runOn.executionLocation}></RunOnRolesHelp>} contextualHelp={<TargetTagsProcessEditorContextualHelp />}>
                    <TargetTagsHelp isGuidedSetup={props.isGuidedSetup} isKubernetesStep={props.isKubernetesStep} userOnboarding={props.userOnboarding} stepTemplateName={props.stepTemplateName} analyticsArea={props.analyticsArea} doBusyTask={props.doBusyTask}></TargetTagsHelp>
                    <TargetTagMultiSelect onChange={onTargetRolesChanged} value={ParseHelper.parseCSV(targetRoles || "")} label={runOn.executionLocation === ExecutionLocation.DeploymentTarget ? `Runs on targets with tags (type to add new)` : `On behalf of target tags (type to add new)`} validate={(roles) => (roles.length === 0 ? "Please enter one or more tags" : "")} error={errorMessage} items={availableRoles} canAdd={true} empty={isClearerTargetRoleConfigurationFeatureToggleEnabled && (<div className={targetRolesEmptyStyles}>
                                    {targetRoles ? (<>Add a new Target Tag</>) : (<>
                                            Create your first target tag, for example:{" "}
                                            {<SuggestedTargetRoles {...props} projectSlug={projectSlug} isKubernetesStep={isKubernetesStep} onClick={() => {
                        if (multiSelectRef.current)
                            multiSelectRef.current.focus();
                    }}/>}
                                        </>)}
                                </div>)} accessibleName={"Target tags selector"} analyticsArea="Deployment Process Editor" multiSelectRef={(component) => (multiSelectRef.current = component)}/>
                    {isClearerTargetRoleConfigurationFeatureToggleEnabled ? <Note>You need at least one tag to select matching targets.</Note> : <Note>This step will run on all deployment targets with these tags.</Note>}
                </ExpandableFormSection>
            </div>
        </>);
};
export const getSuggestedTargetTags = (projectSlug: string, isKubernetesStep: boolean) => {
    return `${projectSlug}-${isKubernetesStep ? "clusters" : "targets"}`;
};
const targetRolesEmptyStyles = css({
    font: text.regular.default.medium,
});
