import { css } from "@emotion/css";
import { lineHeight, space } from "@octopusdeploy/design-system-tokens";
export const paragraphStyle = css({
    lineHeight: lineHeight.small,
    marginBottom: space["16"],
});
export const contentContainerStyles = css({
    "& > p": paragraphStyle,
    "> *": {
        marginBottom: space["16"],
    },
    ".helmButtonContainer": {
        marginTop: "1rem",
    },
});
