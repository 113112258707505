/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Callout, NavigationButton, NavigationButtonType } from "@octopusdeploy/design-system-components";
import type { PrimaryPageAction } from "@octopusdeploy/design-system-components";
import { Permission } from "@octopusdeploy/octopus-server-client";
import type { ProjectResource, ReleaseResource, ChannelResource, ResourceCollection, ProjectContextRepository } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import type { AnalyticActionDispatcher } from "~/analytics/Analytics";
import { Action, useProjectScopedAnalyticActionDispatch } from "~/analytics/Analytics";
import { ProjectPaperLayout } from "~/areas/projects/components/ProjectPaperLayout";
import { useProjectContext } from "~/areas/projects/context/ProjectContext";
import { repository } from "~/clientInstance";
import { DataBaseComponent } from "~/components/DataBaseComponent";
import type { DataBaseComponentState } from "~/components/DataBaseComponent";
import InternalLink from "~/components/Navigation/InternalLink/InternalLink";
import PermissionCheck, { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import useLocalStorage from "~/hooks/useLocalStorage";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
import { RecentProjects } from "~/utils/RecentProjects/RecentProjects";
import Onboarding from "./Onboarding";
import ReleasesTable from "./ReleasesTable";
import styles from "./style.module.less";
interface ReleasesState extends DataBaseComponentState {
    releases: ResourceCollection<ReleaseResource>;
    channels: ChannelResource[];
    hasDeploymentProcess: boolean;
    selectedChannel: string;
    versionFilter: string;
    pageSize: number;
}
interface ReleasesPageInternalProps extends ReleasesPageProps {
    project: ProjectResource;
    projectContextRepository: ProjectContextRepository;
    dispatchAction: AnalyticActionDispatcher;
    storedPageSize?: number;
    setStoredPageSize(pageSize?: number): void;
}
const pageSizeControlKey = "Octopus.PageSize.Releases";
const defaultPageSize = 30;
class ReleasesPageInternal extends DataBaseComponent<ReleasesPageInternalProps, ReleasesState> {
    constructor(props: ReleasesPageInternalProps) {
        super(props);
        this.state = {
            releases: null!,
            channels: null!,
            hasDeploymentProcess: false,
            selectedChannel: null!,
            versionFilter: null!,
            pageSize: props.storedPageSize && props.storedPageSize > 0 ? props.storedPageSize : defaultPageSize,
        };
    }
    async componentDidMount() {
        await this.doBusyTask(async () => {
            const { project, projectContextRepository } = this.props;
            RecentProjects.getInstance().UpdateAccessedProjectIntoLocalStorage(project.Id);
            const [releases, channels] = await Promise.all([repository.Projects.getReleases(project, { take: this.state.pageSize }), repository.Projects.getChannels(project)]);
            const deploymentProc = !project.IsVersionControlled && (await projectContextRepository.DeploymentProcesses.get());
            const hasDeploymentProcess: boolean = deploymentProc && deploymentProc.Steps.length > 0;
            this.setState({
                releases,
                channels: channels.Items,
                hasDeploymentProcess,
            });
        }, { timeOperationOptions: timeOperationOptions.forInitialLoad() });
    }
    isFiltering() {
        return !!this.state.selectedChannel || !!this.state.versionFilter;
    }
    onPageSizeChange = (pageSize: number | undefined) => {
        if (pageSize === undefined || pageSize <= 0) {
            pageSize = defaultPageSize;
        }
        this.setState({ pageSize }, async () => {
            this.props.setStoredPageSize(pageSize);
            await this.refreshReleases();
        });
    };
    renderBody() {
        const createReleaseIsAllowed = this.state.hasDeploymentProcess || this.props.project?.IsVersionControlled;
        return createReleaseIsAllowed && (this.isFiltering() || this.state.releases.Items.length > 0) ? (<ReleasesTable releases={this.state.releases} channels={this.state.channels} onChannelFilterChange={async (selectedChannel) => {
                this.setState({ selectedChannel }, async () => {
                    await this.refreshReleases();
                });
            }} onVersionFilterChange={async (versionFilter) => {
                this.setState({ versionFilter }, async () => {
                    await this.refreshReleases();
                });
            }} pageSize={this.state.pageSize} setPageSize={this.onPageSizeChange} {...this.props}/>) : (<>
                <div className={styles.noProcessCallout}>
                    {!createReleaseIsAllowed && (<Callout title="Deployment process required." type={"information"}>
                            <div>
                                <PermissionCheck permission={Permission.ProcessEdit} project={this.props.project.Id} tenant="*">
                                    <span>
                                        Before you can release and deploy your software, you will need to{" "}
                                        <strong>
                                            <InternalLink to={links.deploymentProcessPage.generateUrl({ spaceId: this.props.project.SpaceId, projectSlug: this.props.project.Slug })}>create a deployment process</InternalLink>
                                        </strong>
                                        .
                                    </span>
                                </PermissionCheck>
                            </div>
                        </Callout>)}
                </div>
                <Onboarding project={this.props.project} actionButtons={createReleaseIsAllowed && (<PermissionCheck permission={Permission.ReleaseCreate} project={this.props.project.Id} tenant="*">
                                <NavigationButton type={NavigationButtonType.Primary} label="Create Release" href={links.createReleasePage.generateUrl({ spaceId: this.props.project.SpaceId, projectSlug: this.props.project.Slug })} disabled={this.props.project.IsDisabled} onClick={() => this.props.dispatchAction("Create Release", { action: Action.Add, resource: "Create Release" })}/>
                            </PermissionCheck>)}/>
            </>);
    }
    render() {
        const createReleasePageAction: PrimaryPageAction = {
            type: "navigate",
            label: "Create Release",
            path: links.createReleasePage.generateUrl({ spaceId: this.props.project.SpaceId, projectSlug: this.props.project.Slug }),
            disabled: this.props.project.IsDisabled,
            onClick: () => this.props.dispatchAction("Create a release", { action: Action.Add, resource: "Create Release" }),
            hasPermissions: isAllowed({
                permission: Permission.ReleaseCreate,
                project: this.props.project.Id,
                tenant: "*",
            }),
        };
        return (<ProjectPaperLayout primaryAction={this.isCreateReleaseButtonVisible() ? createReleasePageAction : undefined} busy={this.state.busy} errors={this.errors} title="Releases">
                {this.props.project.IsDisabled && (<Callout type={"warning"} title="Warning">
                        This project is currently disabled, so releases cannot be created.
                    </Callout>)}
                {this.state.releases && this.renderBody()}
            </ProjectPaperLayout>);
    }
    private isCreateReleaseButtonVisible() {
        const hasReleases = this.state.releases && this.state.releases.Items.length > 0;
        return (this.state.hasDeploymentProcess || this.props.project.IsVersionControlled) && hasReleases;
    }
    private refreshReleases = async () => {
        const selectedChannel = this.state.selectedChannel;
        const searchByVersion = this.state.versionFilter;
        const take = this.state.pageSize;
        await this.doBusyTask(async () => {
            const releases = await (selectedChannel
                ? repository.Channels.getReleases(this.state!.channels.find((c) => c.Id === selectedChannel)!, { searchByVersion, take })
                : repository.Projects.getReleases(this.props.project, { searchByVersion, take }));
            this.setState({ releases });
        }, { timeOperationOptions: timeOperationOptions.forRefresh() });
    };
    static displayName = "ReleasesPageInternal";
}
type ReleasesPageProps = {};
export function ReleasesPage(props: ReleasesPageProps) {
    const projectContext = useProjectContext();
    const dispatchAction = useProjectScopedAnalyticActionDispatch(projectContext.state.model.Id);
    const { model: project, projectContextRepository } = projectContext.state;
    const [storedPageSize, setStoredPageSize] = useLocalStorage<number | undefined>(pageSizeControlKey, undefined);
    return <ReleasesPageInternal {...props} storedPageSize={storedPageSize} setStoredPageSize={setStoredPageSize} project={project} projectContextRepository={projectContextRepository} dispatchAction={dispatchAction}/>;
}
