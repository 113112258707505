import { css, cx } from "@emotion/css";
import { Button } from "@octopusdeploy/design-system-components";
import { colorScales, fontSize, space, themeTokens, borderRadius, lineHeight, fontWeight } from "@octopusdeploy/design-system-tokens";
import * as React from "react";
import Chip from "~/components/Chips/Chip";
import Logo from "~/components/Logo/Logo";
import Markdown from "~/components/Markdown";
import { useThemePaletteType } from "~/components/Theme/useThemePaletteType";
type StepCardProps = {
    heading: string;
    subHeading?: string;
    logo: string | React.ReactNode;
    chipType?: "primary" | "info" | "success";
    chipLabel?: string;
    description?: string;
    dotpoints?: string[];
    primaryButtonLabel?: string;
    primaryButtonIcon?: React.ReactNode;
    onPrimaryButtonClick?: () => void;
    secondaryButtonLabel?: string;
    onSecondaryButtonClick?: () => void;
};
export function StepCard({ chipType = "primary", chipLabel, ...props }: StepCardProps) {
    const isDarkMode = useThemePaletteType() === "dark";
    return (<div className={styles.base}>
            <div>
                <div className={styles.header}>
                    {typeof props.logo === "string" ? <Logo url={props.logo} size={space[40]} isSquare={true}/> : props.logo}
                    {chipLabel && (<Chip backgroundColor={chipType === "primary" && isDarkMode ? colorScales.navy["800"] : themeTokens.color.chip.filled.background[chipType]} labelColor={themeTokens.color.chip.filled.text[chipType]} noMargin={true} noTooltip={true}>
                            {chipLabel}
                        </Chip>)}
                </div>
                <div className={styles.heading}>{props.heading}</div>
                {props.subHeading && <div className={styles.subHeading}>{props.subHeading}</div>}
                {props.description && (<div className={styles.description}>
                        <Markdown markup={props.description} noMargins mutedTextColor/>
                    </div>)}
                {props.dotpoints?.map((dotpoint) => (<div key={dotpoint} className={styles.featureContainer}>
                        <div className={styles.featureIcon}>
                            <i className="fa-regular fa-circle-check"/>
                        </div>
                        <div className={styles.featureText}>{dotpoint}</div>
                    </div>))}
            </div>

            <div className={styles.buttonsContainer}>
                {props.primaryButtonLabel && props.onPrimaryButtonClick && <Button label={props.primaryButtonLabel} onClick={props.onPrimaryButtonClick} rightIcon={props.primaryButtonIcon} importance={"secondary"} size={"small"}/>}

                {props.secondaryButtonLabel && props.onSecondaryButtonClick && (<div className={cx(styles.secondaryActionButton)}>
                        <Button label={props.secondaryButtonLabel} rightIcon={<i className="fa-solid fa-external-link"/>} onClick={props.onSecondaryButtonClick} importance={"quiet"} size={"small"}/>
                    </div>)}
            </div>
        </div>);
}
StepCard.displayName = "StepCard";
const secondaryActionButtonLabel = "step-tile-secondary-action-button";
const styles = {
    base: css({
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
        width: "100%",
        padding: space[16],
        backgroundColor: themeTokens.color.card.background.default,
        borderRadius: borderRadius.small,
        boxShadow: themeTokens.shadow.small,
        WebkitFontSmoothing: "antialiased",
    }),
    header: css({
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
    }),
    heading: css({
        display: "-webkit-box",
        lineHeight: lineHeight.small,
        WebkitLineClamp: 3,
        WebkitBoxOrient: "vertical",
        overflow: "hidden",
        fontSize: fontSize.base,
        fontWeight: fontWeight["700"],
        color: themeTokens.color.text.primary,
        marginTop: space[8],
    }),
    subHeading: css({
        fontSize: fontSize.xSmall,
        color: themeTokens.color.text.secondary,
    }),
    description: css({
        display: "-webkit-box",
        WebkitLineClamp: 4,
        WebkitBoxOrient: "vertical",
        overflow: "hidden",
        fontSize: fontSize.small,
        color: themeTokens.color.text.secondary,
        marginTop: space[8],
        marginBottom: space[8],
    }),
    featureContainer: css({
        display: "flex",
        alignItems: "baseline",
        marginTop: space[8],
        marginBottom: space[8],
    }),
    featureIcon: css({
        marginRight: space[4],
        position: "relative",
        top: "1px",
        color: themeTokens.color.icon.secondary,
    }),
    featureText: css({
        fontSize: fontSize.small,
        color: themeTokens.color.text.secondary,
    }),
    buttonsContainer: css({
        display: "flex",
        alignItems: "center",
        marginTop: space[12],
        justifyContent: "flex-start",
        flexWrap: "wrap",
        gap: space[8],
    }),
    secondaryActionButton: css({
        label: secondaryActionButtonLabel,
        i: {
            color: themeTokens.color.button.icon.quiet,
            fontSize: fontSize.large,
        },
    }),
};
