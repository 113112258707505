import { css } from "@emotion/css";
import { space, text, themeTokens } from "@octopusdeploy/design-system-tokens";
export const cardStyles = {
    // The card component has the link inside the main card, which messes with the `gap` spacing
    // To address this, we ensure there will always be an extra 4px gap and knock if off the card's top padding
    card: css({
        "&&": {
            flex: 1,
            minWidth: "100%",
            width: "auto",
            gap: space[4],
            padding: space[16],
            paddingTop: space[12],
            backgroundColor: themeTokens.color.card.background.default,
            "@media (min-width: 600px)": {
                minWidth: "auto",
            },
        },
    }),
    logo: (cardHasLink: boolean) => css({ marginBottom: space[4], marginTop: cardHasLink ? 0 : space[4] }),
    header: css({
        font: text.heading.medium,
        margin: 0,
    }),
    content: css({
        "&&": {
            width: "auto",
            font: text.regular.default.medium,
            margin: 0,
            // The card component hides the content on small screen sizes by default
            display: "block",
        },
    }),
    link: css({ opacity: 0 }),
};
