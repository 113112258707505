import type { ActionProperties, GitDependencyReference, IProcessResource, PackageReference, StepPackageInputs } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { useOptionalProcessBlueprintContext } from "~/areas/projects/components/Process/Blueprints/BlueprintContext";
import type { RunOn } from "~/areas/projects/components/Process/types";
import type { ActionTemplateStepInputDependencies, ProjectStepInputDependencies } from "~/components/StepPackageEditor/StepInputDependencies";
import { isProjectStepInputDependencies } from "~/components/StepPackageEditor/StepInputDependencies";
import type { ActionEditProps, ActionPlugin, AdditionalActions } from "../Actions/pluginRegistry";
import type { FieldErrors } from "../DataBaseComponent/Errors";
type ActionPropertiesEditorProps = ProjectScopedActionPropertiesEditorProps | ActionTemplateScopedActionPropertiesEditorProps;
interface ProjectScopedActionPropertiesEditorProps {
    inputDependencies: ProjectStepInputDependencies;
    plugin: ActionPlugin;
    inputs: StepPackageInputs;
    properties: ActionProperties;
    packages: Array<PackageReference>;
    gitDependencies: GitDependencyReference[] | undefined;
    runOn: RunOn;
    additionalActions: AdditionalActions;
    errors: FieldErrors | undefined;
    busy: Promise<unknown> | boolean | undefined;
    expandedByDefault: boolean;
    editor: React.ComponentType<ActionEditProps>;
    getFieldError(field: string): string;
    setInputs(inputs: StepPackageInputs, callback?: () => void): void;
    setProperties(properties: Partial<ActionProperties>, initialise?: boolean, callback?: () => void): void;
    setPackages(packages: Array<PackageReference>, initialise?: boolean): void;
    setGitDependencies?(gitDependencies: GitDependencyReference[]): void;
    doBusyTask(action: () => Promise<void>): Promise<boolean>;
    refreshRunOn(): void;
    getProcessResource(): Readonly<IProcessResource>;
    isNew: boolean;
}
interface ActionTemplateScopedActionPropertiesEditorProps {
    inputDependencies: ActionTemplateStepInputDependencies;
    inputs?: StepPackageInputs;
    plugin: ActionPlugin;
    properties: ActionProperties;
    packages: Array<PackageReference>;
    gitDependencies: GitDependencyReference[] | undefined;
    errors: FieldErrors | undefined;
    busy: Promise<unknown> | boolean | undefined;
    expandedByDefault: boolean;
    editor: React.ComponentType<ActionEditProps>;
    getFieldError(field: string): string;
    setInputs?(inputs: StepPackageInputs, callback?: () => void): void;
    setProperties(properties: Partial<ActionProperties>, initialise?: boolean, callback?: () => void): void;
    setPackages(packages: Array<PackageReference>, initialise?: boolean): void;
    setGitDependencies?(gitDependencies: GitDependencyReference[]): void;
    doBusyTask(action: () => Promise<void>): Promise<boolean>;
}
export function ActionPropertiesEditor(props: ActionPropertiesEditorProps) {
    const Edit: React.ComponentType<ActionEditProps> = props.editor;
    const processBlueprintContext = useOptionalProcessBlueprintContext();
    if (isProjectScopedProps(props)) {
        const localNamesProps = processBlueprintContext?.localNames ? { localNames: processBlueprintContext.localNames } : {};
        return (<Edit plugin={props.plugin} projectId={props.inputDependencies.projectId} gitRef={props.inputDependencies.gitRef} inputs={props.inputs} properties={props.properties} packages={props.packages} gitDependencies={props.gitDependencies} doBusyTask={props.doBusyTask} busy={props.busy} runOn={props.runOn} setInputs={props.setInputs} setProperties={props.setProperties} setPackages={props.setPackages} setGitDependencies={props.setGitDependencies} additionalActions={props.additionalActions} getFieldError={props.getFieldError} errors={props.errors} expandedByDefault={props.expandedByDefault} refreshRunOn={props.refreshRunOn} getProcessResource={props.getProcessResource} inputDependencies={props.inputDependencies} isNew={props.isNew} {...localNamesProps}/>);
    }
    return (<Edit plugin={props.plugin} gitRef={undefined} localNames={props.inputDependencies.localNames} inputs={props.inputs} properties={props.properties} packages={props.packages} gitDependencies={props.gitDependencies} doBusyTask={props.doBusyTask} busy={props.busy} setInputs={props.setInputs} setProperties={props.setProperties} setPackages={props.setPackages} setGitDependencies={props.setGitDependencies} getFieldError={props.getFieldError} errors={props.errors} expandedByDefault={props.expandedByDefault} parameters={props.inputDependencies.parameters} inputDependencies={props.inputDependencies}/>);
}
function isProjectScopedProps(props: ActionPropertiesEditorProps): props is ProjectScopedActionPropertiesEditorProps {
    return isProjectStepInputDependencies(props.inputDependencies);
}
