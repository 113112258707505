import { Checkbox, Callout } from "@octopusdeploy/design-system-components";
import type { FeatureToggle } from "@octopusdeploy/octopus-server-client";
import React from "react";
import { session } from "~/clientInstance";
import FormComponent from "~/components/FormComponent/FormComponent";
import { Text } from "~/components/form";
import styles from "./style.module.less";
interface AvailableToggles {
    [name: string]: boolean;
}
interface ToggleControlState {
    AvailableToggles: AvailableToggles;
    NewToggleName: string;
}
class ToggleControls<Props = {}> extends React.Component<Props, ToggleControlState> {
    constructor(props: Props) {
        super(props);
        if (!session.featureToggles) {
            return;
        }
        const availableToggles: {
            [key: string]: boolean;
        } = {};
        for (const toggle of session.featureToggles) {
            availableToggles[toggle.Name] = toggle.IsEnabled;
        }
        this.state = {
            NewToggleName: "",
            AvailableToggles: availableToggles,
        };
    }
    setToggleState(name: string, enabled: boolean) {
        const updatedToggles = { ...this.state.AvailableToggles };
        updatedToggles[name] = enabled;
        this.setState({ AvailableToggles: updatedToggles });
        this.updateGlobalToggleState(updatedToggles);
    }
    addAvailableToggle() {
        if (this.state.NewToggleName !== null && this.state.NewToggleName !== "") {
            const toggles = { ...this.state.AvailableToggles };
            toggles[this.state.NewToggleName] = true;
            this.setState({
                AvailableToggles: toggles,
                NewToggleName: "",
            });
            this.updateGlobalToggleState(toggles);
        }
    }
    updateGlobalToggleState(toggles: AvailableToggles) {
        const newToggles: FeatureToggle[] = [];
        for (const name in toggles) {
            if (toggles[name]) {
                // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                newToggles.push({ Name: name, IsEnabled: true } as FeatureToggle);
            }
        }
        session.setFeatureToggles(newToggles);
    }
    renderToggles(toggles: AvailableToggles) {
        const toggleRows = [];
        for (const name in toggles) {
            const isEnabled = toggles[name];
            toggleRows.push(<div className={styles.toggleRow} key={name}>
                    <Checkbox label={name} value={isEnabled} onChange={(enabled) => this.setToggleState(name, enabled)}/>
                </div>);
        }
        return toggleRows;
    }
    render() {
        const currentToggles = this.renderToggles(this.state.AvailableToggles);
        return (<div className={styles.toggleContainer}>
                <Callout title="Feature toggle controls" type={"warning"}>
                    This only controls the toggle values in the UI (not the values on the Server)
                </Callout>
                <FormComponent onFormSubmit={() => this.addAvailableToggle()}>
                    <Text label={"Add toggle"} value={this.state.NewToggleName} onChange={(NewToggleName) => this.setState({ NewToggleName })} hideUnderline={true}/>
                </FormComponent>
                {currentToggles}
            </div>);
    }
    static displayName = "ToggleControls";
}
export default ToggleControls;
