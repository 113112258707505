import { Callout } from "@octopusdeploy/design-system-components";
import type { DataContext, MetadataTypeCollection } from "@octopusdeploy/octopus-server-client";
import React from "react";
import { repository } from "~/clientInstance";
import { TextFormat } from "~/components/CodeEditor/CodeEditor";
import OpenDialogButton from "~/components/Dialog/OpenDialogButton";
import SourceCodeDialog from "~/components/SourceCodeDialog/index";
import Note from "~/primitiveComponents/form/Note/Note";
type EditResourceYamlProps = {
    yaml: string;
    onSave: (value: string) => void;
};
export function EditResourceYaml(props: EditResourceYamlProps) {
    const { yaml, onSave } = props;
    const getMetadata = (value: string): Promise<{
        Metadata: MetadataTypeCollection;
        Values: DataContext;
    }> => {
        return repository.CloudTemplates.getMetadata(value, "Kubernetes");
    };
    const validateTemplate = async (value: string) => {
        try {
            await getMetadata(value);
        }
        catch (err) {
            return err;
        }
        return null;
    };
    return (<>
            <div>
                <Note>Directly edit the raw Kubernetes resource in YAML. This is intended to populated the step from existing YAML, or for the resource created by this step to be exported.</Note>
                <Note>
                    Be aware that not every valid Kubernetes property is reflected in the UI, and unrecognized properties are ignored. Also note that the YAML here is parsed literally, and does not implement variable replacements. The{" "}
                    <code>Deploy raw Kubernetes YAML</code> step can be used to deploy raw YAML without these restrictions.
                </Note>
            </div>
            <OpenDialogButton label={"Edit YAML"} dialogWidth={"1000px"} renderDialog={(openProps) => (<SourceCodeDialog title={"Edit YAML"} open={openProps.open} close={openProps.closeDialog} value={yaml} autocomplete={[]} validate={validateTemplate} onSave={onSave} language={TextFormat.YAML} description={<Callout title={"This editor only supports YAML fields defined in the UI form"} type={"warning"}>
                                This step supports a specific subset of Kubernetes properties, and unrecognized properties are ignored during import.
                                <br />
                                If you need additional properties, we recommend using the <strong>Deploy raw Kubernetes YAML</strong> step.
                            </Callout>} confirmationOptions={{
                headerCallout: <p>YAML properties that aren’t supported in the UI form will be ignored during import.</p>,
            }}/>)}/>
        </>);
}
