import { css, cx } from "@emotion/css";
import { borderRadius, borderWidth, space, text, themeTokens } from "@octopusdeploy/design-system-tokens";
import type { DeploymentFreezeSummary, PagingCollection } from "@octopusdeploy/octopus-server-client";
import React from "react";
import { DeploymentFreezeDataTableEnvironmentsCell } from "~/areas/configuration/components/DeploymentFreezes/DataTable/Cells/DeploymentFreezeDataTableEnvironmentsCell";
import { DeploymentFreezeDataTableNameCell } from "~/areas/configuration/components/DeploymentFreezes/DataTable/Cells/DeploymentFreezeDataTableNameCell";
import { DeploymentFreezeDataTableProjectsCell } from "~/areas/configuration/components/DeploymentFreezes/DataTable/Cells/DeploymentFreezeDataTableProjectsCell";
import { DeploymentFreezeDataTableStatusCell } from "~/areas/configuration/components/DeploymentFreezes/DataTable/Cells/DeploymentFreezeDataTableStatusCell";
import NumberedPagingBar from "~/components/PagingBaseComponent/NumberedPagingBar";
import { DataTable, DataTableBody, DataTableFooter, DataTableHeader, DataTableHeaderColumn, DataTableRow } from "~/primitiveComponents/dataDisplay/DataTable/index";
interface DeploymentFreezeTableContentProps {
    deploymentFreezes: PagingCollection<DeploymentFreezeSummary>;
    currentPage: number;
    onPageSelected: (pageNumber: number) => void;
}
export function DeploymentFreezeTableContent({ deploymentFreezes, currentPage, onPageSelected }: DeploymentFreezeTableContentProps) {
    const findUniqueEnvironments = (deploymentFreeze: DeploymentFreezeSummary): string[] => {
        const allEnvironments = deploymentFreeze.Projects.Items.flatMap((p) => p.Environments.Items);
        const uniqueIds: string[] = [];
        const uniqueEnvironments: string[] = [];
        if (allEnvironments.length > 0) {
            allEnvironments.forEach((e) => {
                if (e && !uniqueIds.includes(e.Id)) {
                    uniqueIds.push(e.Id);
                    uniqueEnvironments.push(e.Name);
                }
            });
        }
        return uniqueEnvironments;
    };
    return (<DataTable className={cx(tableStyles)} title={"Deployment Freezes Overview"}>
            <DataTableHeader>
                <DataTableRow>
                    <DataTableHeaderColumn>Deployment Freezes</DataTableHeaderColumn>
                    <DataTableHeaderColumn>Status</DataTableHeaderColumn>
                    <DataTableHeaderColumn>Projects</DataTableHeaderColumn>
                    <DataTableHeaderColumn>Environments</DataTableHeaderColumn>
                </DataTableRow>
            </DataTableHeader>
            <DataTableBody>
                {deploymentFreezes.Items.map((df) => {
            return (<DataTableRow key={df.Id}>
                            <DeploymentFreezeDataTableNameCell id={df.Id} name={df.Name} start={df.Start} end={df.End} status={df.Status}/>
                            <DeploymentFreezeDataTableStatusCell status={df.Status}/>
                            <DeploymentFreezeDataTableProjectsCell projects={df.Projects}/>
                            <DeploymentFreezeDataTableEnvironmentsCell environments={findUniqueEnvironments(df)}/>
                        </DataTableRow>);
        })}
            </DataTableBody>
            <DataTableFooter>
                <NumberedPagingBar currentPageIndex={currentPage} totalItems={deploymentFreezes.TotalResults} pageSize={deploymentFreezes.ItemsPerPage} onPageSelected={(_, currentPageIndex) => onPageSelected(currentPageIndex)}/>
            </DataTableFooter>
        </DataTable>);
}
const tableBorder = `${borderWidth[1]} solid ${themeTokens.color.border.primary}`;
const tableStyles = css({
    width: "100%",
    backgroundColor: themeTokens.color.background.primary.default,
    border: tableBorder,
    borderRadius: borderRadius.large,
    borderSpacing: 0,
    th: {
        padding: `${space[12]} ${space[16]}`,
        paddingRight: 0,
        textAlign: "left",
        font: text.regular.default.medium,
        position: "relative",
        ":not(:last-child)": {
            ":after": {
                content: "''",
                position: "absolute",
                height: "50%",
                right: 0,
                top: "25%",
                borderRight: tableBorder,
            },
        },
    },
    td: {
        padding: `${space[8]} ${space[12]}`,
        borderTop: tableBorder,
        verticalAlign: "middle",
    },
});
