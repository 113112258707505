import { css, cx } from "@emotion/css";
import { space, text, themeTokens } from "@octopusdeploy/design-system-tokens";
import { throttle } from "lodash";
import * as React from "react";
import { BaseComponent } from "~/components/BaseComponent/BaseComponent";
import { LinksMenu } from "~/components/LinksMenu/LinksMenu";
import { hasActiveDescendants, isMenuGroup } from "~/components/LinksMenu/MenuNode";
import type { MenuNode } from "~/components/LinksMenu/MenuNode";
import InternalNavLink from "../Navigation/InternalNavLink/InternalNavLink";
import { useIsPortalUrlActive } from "../Navigation/useIsPortalUrlActive";
interface PriorityNavigationProps {
    navigationItems: MenuNode[];
    maxNavigationItems: number;
}
interface PriorityNavigationState {
    itemWidths: number[] | undefined;
    priorityItems: MenuNode[];
    moreItems: MenuNode[];
    isMoreMenuOpen: boolean;
}
class PriorityNavigation extends BaseComponent<PriorityNavigationProps, PriorityNavigationState> {
    lastKnownContainerWidth: number | undefined = undefined;
    resizeObserver: ResizeObserver;
    constructor(props: PriorityNavigationProps) {
        super(props);
        this.resizeObserver = new ResizeObserver(throttle(this.onResize, 100));
        this.state = {
            itemWidths: undefined,
            isMoreMenuOpen: false,
            priorityItems: this.props.navigationItems,
            moreItems: [],
        };
    }
    UNSAFE_componentWillReceiveProps() {
        if (this.lastKnownContainerWidth !== undefined && this.state.itemWidths !== undefined) {
            this.updateNavigation(this.lastKnownContainerWidth, this.state.itemWidths);
        }
    }
    componentWillUnmount() {
        this.resizeObserver.disconnect();
    }
    render() {
        const hasMeasuredItemWidths = this.state.itemWidths !== undefined;
        const priorityItems = hasMeasuredItemWidths ? this.state.priorityItems : this.props.navigationItems;
        const moreItems = hasMeasuredItemWidths ? this.state.moreItems : [];
        const showMoreItems = !hasMeasuredItemWidths || moreItems.length > 0;
        return (<nav className={styles.container}>
                <ul ref={this.setNavigation} className={styles.ul}>
                    {priorityItems.map((item: MenuNode, index: number) => (<NavItem key={`navItem-${index}`} item={item}/>))}
                    {showMoreItems && <MoreNavItem moreItems={moreItems}/>}
                </ul>
            </nav>);
    }
    private howManyItemsInMenuArray = (array: number[], outerWidth: number, initialWidth: number, maximumNumberInNav: number) => {
        let total = initialWidth;
        for (let i = 0; i < array.length; i++) {
            if (i > maximumNumberInNav) {
                return maximumNumberInNav;
            }
            total += array[i];
            if (total > (outerWidth || 0)) {
                return i;
            }
        }
        return array.length;
    };
    private updateNavigation = (containerWidth: number, itemWidths: number[]) => {
        const arrayAmount = this.howManyItemsInMenuArray(itemWidths, containerWidth, itemWidths[itemWidths.length - 1], this.props.maxNavigationItems);
        const navItemsCopy = this.props.navigationItems;
        const priorityItems = navItemsCopy.slice(0, arrayAmount);
        const moreItems = priorityItems.length !== navItemsCopy.length ? navItemsCopy.slice(arrayAmount, navItemsCopy.length) : [];
        this.setState({
            priorityItems,
            moreItems,
        });
    };
    private onResize = (entries: ResizeObserverEntry[]) => {
        const navigation = entries[0].target;
        if (this.state.itemWidths === undefined) {
            const itemWidths = Array.prototype.slice.call(navigation.children).map((item: HTMLElement) => item.getBoundingClientRect().width);
            this.setState({ itemWidths }, () => {
                this.updateNavigation(navigation.clientWidth, itemWidths);
            });
        }
        else if (navigation.clientWidth !== this.lastKnownContainerWidth) {
            this.updateNavigation(navigation.clientWidth, this.state.itemWidths);
        }
        this.lastKnownContainerWidth = navigation.clientWidth;
    };
    private setNavigation = (el: HTMLUListElement) => {
        this.resizeObserver.disconnect();
        if (el !== null) {
            this.resizeObserver.observe(el);
        }
    };
    static displayName = "PriorityNavigation";
}
interface NavItemProps {
    item: MenuNode;
}
const NavItem = (props: NavItemProps) => {
    const item = props.item;
    const isUrlActive = useIsPortalUrlActive();
    if (isMenuGroup(item)) {
        const isActive = hasActiveDescendants(item, isUrlActive);
        return (<li className={styles.li}>
                <LinksMenu className={isActive ? cx(styles.link, styles.selectedLink) : styles.link} items={item.children} label={item.label}/>
            </li>);
    }
    return (<li className={styles.li}>
            <InternalNavLink activeClassName={styles.selectedLink} to={item.url} exact={item.exact} title={item.title} className={styles.link}>
                {item.icon}
                <span>{item.text}</span>
                {item.accessoryView}
            </InternalNavLink>
        </li>);
};
interface MoreNavItemProps {
    moreItems: MenuNode[];
}
function MoreNavItem(props: MoreNavItemProps) {
    return (<li key="navItem-more" className={styles.li}>
            <LinksMenu className={styles.link} items={props.moreItems} label="More"/>
        </li>);
}
const styles = {
    container: css({
        overflow: "hidden",
        whiteSpace: "nowrap",
        height: "2.5rem",
        margin: 0,
        paddingBottom: 0,
    }),
    ul: css({
        margin: 0,
    }),
    li: css({
        display: "inline-block",
    }),
    link: css({
        font: text.regular.default.medium,
        color: themeTokens.color.navList.text.default,
        display: "flex",
        alignItems: "center",
        padding: `0 ${space["16"]}`,
        height: "2.5rem",
        ":hover": {
            color: themeTokens.color.navList.text.active,
        },
        ":active": {
            color: themeTokens.color.navList.text.active,
        },
    }),
    selectedLink: css({
        color: themeTokens.color.navList.text.active,
        ":hover": {
            // We need to re-apply the same styles here because we have global css trying to style this hover state
            color: themeTokens.color.navList.text.active,
        },
    }),
};
export default PriorityNavigation;
