import * as React from "react";
import type { GitRefQueryParamsProps } from "../../BranchSelector/useProjectGitRef";
import type { RunGoal } from "../RunbookRunNowLayout";
import { RunbookRunNowLayout } from "../RunbookRunNowLayout";
interface CreateRunbookRunPageProps extends GitRefQueryParamsProps {
    goal: RunGoal;
    runbookSnapshotId: string;
}
export function CreateRunbookRunForSnapshotPage({ goal, runbookSnapshotId, queryParams, setQueryParams }: CreateRunbookRunPageProps) {
    return (<>
            <RunbookRunNowLayout runbookSnapshotId={runbookSnapshotId} goal={goal} queryParams={queryParams} setQueryParams={setQueryParams}/>
        </>);
}
