import { Divider } from "@octopusdeploy/design-system-components";
import type { ActionTemplateCategoryResource, ActionTemplateSearchResource, GitRefResource } from "@octopusdeploy/octopus-server-client";
import { ActionHandlerCategory, ProcessType } from "@octopusdeploy/octopus-server-client";
import type { LinkHref } from "@octopusdeploy/portal-routes";
import { links } from "@octopusdeploy/portal-routes";
import { Environment } from "@octopusdeploy/utilities";
import * as React from "react";
import { ProcessSubPageLayout } from "~/areas/projects/components/Process/ProcessSubPageLayout";
import type { WithProjectContextInjectedProps } from "~/areas/projects/context";
import { withProjectContext } from "~/areas/projects/context";
import { repository } from "~/clientInstance";
import { CommunityActionTemplateList } from "~/components/ActionTemplates/CommunityActionTemplateList";
import filterActionTemplates from "~/components/ActionTemplates/filterActionTemplates";
import pluginRegistry from "~/components/Actions/pluginRegistry";
import { createSampleStepActionTemplateResource } from "~/components/Actions/sample/SampleStepPackage";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import type { Errors } from "~/components/DataBaseComponent/Errors";
import FilterSearchBox from "~/components/FilterSearchBox/FilterSearchBox";
import Section from "~/components/Section";
import type { WithProcessContextInjectedProps } from "../Contexts/ProcessContext";
import { withProcessContext } from "../Contexts/ProcessContext";
import { withProcessQueryStringContext, type WithProcessQueryStringContextInjectedProps } from "../Contexts/ProcessQueryString/ProcessQueryStringContext";
import CategoryInfo from "./CategoryInfo";
import CategorySelector from "./CategorySelector";
import { InstalledActionTemplateList } from "./InstalledActionTemplateList";
import styles from "./style.module.less";
interface ActionTemplateSelectorState extends DataBaseComponentState {
    isLoaded: boolean;
    templates: ActionTemplateSearchResource[];
    categories: ActionTemplateCategoryResource[];
    childActionTypes: string[];
    filterText?: string;
    showCommunityTemplates: boolean;
    selectedCategory?: ActionHandlerCategory;
    communityStepCategories: ActionHandlerCategory[];
}
export type ActionTemplateSelectorProps = {
    runbookId?: string;
    processId: string;
    parentStepId?: string;
    busy: Promise<void> | undefined;
    errors: Errors | undefined;
    gitRefResource: GitRefResource | undefined;
} & WithProcessContextInjectedProps & WithProjectContextInjectedProps & WithProcessQueryStringContextInjectedProps;
class ActionTemplateSelector extends DataBaseComponent<ActionTemplateSelectorProps, ActionTemplateSelectorState> {
    constructor(props: ActionTemplateSelectorProps) {
        super(props);
        this.state = {
            isLoaded: false,
            templates: [],
            categories: [],
            childActionTypes: [],
            showCommunityTemplates: false,
            selectedCategory: this.getDefaultCategory(),
            communityStepCategories: [
                ActionHandlerCategory.Aws,
                ActionHandlerCategory.Azure,
                ActionHandlerCategory.Certificate,
                ActionHandlerCategory.Docker,
                ActionHandlerCategory.GoogleCloud,
                ActionHandlerCategory.JavaAppServer,
                ActionHandlerCategory.Kubernetes,
                ActionHandlerCategory.Package,
                ActionHandlerCategory.Script,
                ActionHandlerCategory.Terraform,
                ActionHandlerCategory.WindowsServer,
            ],
        };
    }
    async componentDidMount() {
        await this.doBusyTask(async () => {
            const actionsPlugins = pluginRegistry.getAllActions();
            const categories = await repository.ActionTemplates.categories();
            let templates = await fetchTemplates();
            let updatedCommunityStepCategories = this.state.communityStepCategories;
            const childActionTypes = actionsPlugins.filter((action) => action.canBeChild).map((action) => action.actionType);
            if (this.state.selectedCategory) {
                templates = this.getTemplatesWithUpdatedCategories(templates, this.state.selectedCategory);
                updatedCommunityStepCategories = this.state.communityStepCategories.filter((x) => x != this.state.selectedCategory);
            }
            this.setState({
                isLoaded: true,
                categories: this.getAllowedCategories(categories),
                templates: templates,
                childActionTypes,
                communityStepCategories: updatedCommunityStepCategories,
            });
        });
    }
    render() {
        const communityTemplates = this.state.templates.filter((at) => !at.IsInstalled);
        return (<ProcessSubPageLayout title={<div className={styles.pageTitle}>Add Step Template</div>} busy={this.state.busy} errors={this.errors}>
                {this.state.isLoaded && (<div className={styles.container}>
                        <Section>
                            <FilterSearchBox placeholder="Filter by name, category or description..." value={this.state.filterText} onChange={this.filterChanged} fullWidth={true} containerClassName={styles.filterSearchBoxContainer} autoFocus={true} debounceDelay={500}/>
                        </Section>
                        <Divider />
                        <div className={styles.stepSelectionContainer}>
                            <div className={styles.categoriesContainer}>
                                <CategorySelector categories={this.state.categories} selectedCategory={this.state.selectedCategory} steps={this.state.templates} onCategorySelected={this.categoryChanged}/>
                            </div>
                            <Divider />
                            <div className={styles.stepsContainer}>
                                {this.state.selectedCategory ? <CategoryInfo categories={this.state.categories} selectedCategory={this.state.selectedCategory}/> : <></>}
                                {this.state.filterText ? <div className={styles.searchHeader}>Results for "{this.state.filterText}"</div> : <></>}
                                <InstalledActionTemplateList categories={this.state.categories} templates={this.installedTemplates()} communityTemplates={communityTemplates} onDetailsUrlRequested={this.getDetailsUrl} onPostSelectionUrlRequested={this.getNewStepUrl} filter={this.state.filterText?.toLowerCase()} activeCategory={this.state.selectedCategory}/>
                                {this.state.selectedCategory !== ActionHandlerCategory.Featured && this.shouldShowCommunityTemplateList(this.state.selectedCategory) && (<CommunityActionTemplateList spaceId={this.props.projectContext.state.model.SpaceId} templates={communityTemplates} filter={this.state.filterText?.toLowerCase()} installationActionName="Install and Add Step" onDetailsUrlRequested={this.getDetailsUrl} onPostSelectionUrlRequested={this.getNewStepUrl} activeCategory={this.state.selectedCategory !== ActionHandlerCategory.Community ? this.state.selectedCategory : undefined}/>)}
                            </div>
                        </div>
                    </div>)}
            </ProcessSubPageLayout>);
    }
    private shouldShowCommunityTemplateList(category: ActionHandlerCategory | undefined): boolean {
        if (this.state.filterText) {
            return true;
        }
        return !!category && category !== ActionHandlerCategory.BuiltInStep && category !== ActionHandlerCategory.StepTemplate && category !== ActionHandlerCategory.Other;
    }
    private installedTemplates(): ActionTemplateSearchResource[] {
        const installed = this.state.templates.filter((at) => at.IsInstalled);
        const parentStepId = this.props.parentStepId;
        if (!parentStepId) {
            return installed;
        }
        return installed.filter((at) => this.state.childActionTypes.includes(at.Type));
    }
    private getDetailsUrl = (template: ActionTemplateSearchResource): LinkHref => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return links.communityStepTemplatePage.generateUrl({ spaceId: this.props.projectContext.state.model.SpaceId, templateId: template.CommunityActionTemplateId! });
    };
    private getNewStepUrl = (template: {
        Type: string;
        Id: string | null;
    }): LinkHref => {
        //TODO: ActionTemplateSelector can be made agnostic if we make getNewStepUrl a callback
        const projectSlug = this.props.projectContext.state.model.Slug;
        const { processId } = this.props;
        const spaceId = this.props.projectContext.state.model.SpaceId;
        const runbookId = this.props.runbookId;
        const gitRef = this.props.gitRefResource;
        return this.props.processContext.selectors.getProcessType() === ProcessType.Runbook && !!runbookId
            ? links.projectRunbookProcessStepsPage.generateUrl({ spaceId, projectSlug, runbookId, processId }, { actionType: template.Type, new: true, parentStepId: this.props.parentStepId, templateId: template.Id ?? undefined })
            : generateNewDeploymentProcessStepUrl({ spaceId, projectSlug, gitRef, template, parentStepId: this.props.parentStepId });
    };
    private getDefaultCategory = (): ActionHandlerCategory => {
        const runbookId = this.props.runbookId;
        // Runbook
        if (this.props.processContext.selectors.getProcessType() === ProcessType.Runbook && !!runbookId) {
            return ActionHandlerCategory.Script;
        }
        // Process
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        const actionCategory = ActionHandlerCategory[this.props.processQueryStringContext.state.queryFilter.actionCategory as keyof typeof ActionHandlerCategory];
        return actionCategory ? actionCategory : ActionHandlerCategory.Featured;
    };
    private getAllowedCategories = (categories: ActionTemplateCategoryResource[]) => {
        const runbookId = this.props.runbookId;
        return this.props.processContext.selectors.getProcessType() === ProcessType.Runbook && !!runbookId ? categories.filter((x) => x.Id !== ActionHandlerCategory.Featured) : categories;
    };
    private categoryChanged = (newCategory: ActionHandlerCategory | undefined) => {
        if (newCategory && this.state.communityStepCategories.some((x) => x === newCategory)) {
            const updatedTemplates = this.getTemplatesWithUpdatedCategories(this.state.templates, newCategory);
            const updatedCategoriesToFindInCommunitySteps = this.state.communityStepCategories.filter((x) => x != newCategory);
            this.setState({ filterText: undefined, selectedCategory: newCategory, templates: updatedTemplates, communityStepCategories: updatedCategoriesToFindInCommunitySteps });
        }
        else {
            this.setState({ filterText: undefined, selectedCategory: newCategory });
        }
    };
    private filterChanged = (newFilterText: string) => {
        const filterStr = newFilterText?.trim().replace(/  +/g, " ");
        this.setState({ filterText: filterStr ? filterStr : undefined, selectedCategory: filterStr ? undefined : this.getDefaultCategory() });
    };
    private getTemplatesWithUpdatedCategories(templates: ActionTemplateSearchResource[], newCategory: ActionHandlerCategory) {
        const communitySteps = templates.filter((x) => x.Categories.includes(ActionHandlerCategory.StepTemplate) && !x.Categories.includes(newCategory));
        const communityStepsToUpdateCategories = filterActionTemplates(communitySteps, newCategory.toLocaleLowerCase()).map((x) => x.Id);
        const updatedTemplates = templates.map((x) => (!communityStepsToUpdateCategories.includes(x.Id) ? x : { ...x, Categories: [...x.Categories, newCategory] }));
        return updatedTemplates;
    }
    static displayName = "ActionTemplateSelector";
}
interface GenerateNewDeploymentProcessStepUrlOptions {
    spaceId: string;
    projectSlug: string;
    parentStepId: string | undefined;
    gitRef: GitRefResource | undefined;
    template: {
        Type: string;
        Id: string | null;
    };
}
function generateNewDeploymentProcessStepUrl({ spaceId, projectSlug, parentStepId, gitRef, template }: GenerateNewDeploymentProcessStepUrlOptions) {
    if (gitRef) {
        return links.branchDeploymentProcessStepsPage.generateUrl({ spaceId, projectSlug, branchName: gitRef.CanonicalName }, { new: true, actionType: template.Type, parentStepId, templateId: template.Id ?? undefined });
    }
    return links.deploymentProcessStepsPage.generateUrl({ spaceId, projectSlug }, { new: true, actionType: template.Type, parentStepId, templateId: template.Id ?? undefined });
}
async function fetchTemplates(): Promise<ActionTemplateSearchResource[]> {
    const templates = await repository.ActionTemplates.search();
    if (Environment.isInDevelopmentMode()) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const inDevelopmentTemplates = [createSampleStepActionTemplateResource(repository.spaceId!)];
        const templateIds = new Set(templates.map((t) => t.Type));
        const inDevelopmentTemplatesThatArentReturnedByServer = inDevelopmentTemplates.filter((t) => !templateIds.has(t.Type));
        return [...templates, ...inDevelopmentTemplatesThatArentReturnedByServer];
    }
    return templates;
}
const EnhancedActionTemplateSelector = withProjectContext(withProcessContext(withProcessQueryStringContext(ActionTemplateSelector)));
export default EnhancedActionTemplateSelector;
