import { css } from "@emotion/css";
import { Button, Callout, Divider } from "@octopusdeploy/design-system-components";
import type { MachineResource } from "@octopusdeploy/octopus-server-client";
import { MachineModelHealthStatus, ScriptingLanguage } from "@octopusdeploy/octopus-server-client";
import { noOp } from "@octopusdeploy/utilities";
import * as React from "react";
import { useEffect, useState } from "react";
import type { BaseConfiguration } from "~/areas/infrastructure/components/MachineSettings/Endpoints/KubernetesAgent/ConfigurationPage";
import { DiscoveryProgress } from "~/areas/infrastructure/components/MachineSettings/Endpoints/KubernetesAgent/DiscoveryProgress";
import { contentContainerStyles } from "~/areas/infrastructure/components/MachineSettings/Endpoints/KubernetesAgent/Styles";
import { EndpointSelectionScope } from "~/areas/infrastructure/components/MachineSettings/Endpoints/endpointRegistry";
import { CodeEditor, type Language, type TextFormat } from "~/components/CodeEditor/CodeEditor";
import ExternalLink from "~/components/Navigation/ExternalLink/ExternalLink";
import TimeFromNowLabel from "~/components/TimeLabels/TimeFromNowLabel";
import Note from "~/primitiveComponents/form/Note/Note";
type InstallationPageProps<T extends MachineResource> = {
    model: BaseConfiguration;
    generateHelmCommand: (selectedLanguage: ScriptingLanguage | Language | TextFormat) => string;
    onAgentFound: (agent: T) => void;
    onAgentHealthStatusUpdated: (agent: T) => void;
    fetchAgent: (name: string) => Promise<T | undefined>;
    hasTokenExpired: boolean;
    accessTokenExpiry: moment.Moment | undefined;
    machineType: EndpointSelectionScope;
};
export function InstallationPage<T extends MachineResource>(props: InstallationPageProps<T>) {
    const [hideTargetSearch, setHideTargetSearch] = useState(true);
    const [isFound, setIsFound] = useState(false);
    const [agentHealth, setAgentHealth] = useState(MachineModelHealthStatus.Unknown);
    const [hideHelmCommand, setHideHelmCommand] = useState(false);
    useEffect(() => {
        // Set a timeout to show the component after 2000 milliseconds (2 seconds)
        const timeoutId = setTimeout(() => {
            setHideTargetSearch(false);
        }, 2000);
        // Clean up the timeout when the component unmounts
        return () => clearTimeout(timeoutId);
    }, []);
    useEffect(() => {
        const pollingInterval = 2000; // Two seconds
        const intervalId = setInterval(async () => {
            const agent = await props.fetchAgent(props.model.Name);
            // TODO: Handle not finding it at all - extra timeout?
            //Agent has been found
            if (agent !== null && agent !== undefined) {
                if (!isFound) {
                    setIsFound(true);
                    setHideHelmCommand(true);
                    props.onAgentFound(agent);
                }
                // Only stop polling once we have the machine health
                if (agent.HealthStatus !== MachineModelHealthStatus.Unknown) {
                    clearInterval(intervalId);
                    setAgentHealth(agent.HealthStatus);
                    if (agentHealth != agent.HealthStatus) {
                        props.onAgentHealthStatusUpdated(agent);
                    }
                }
            }
        }, pollingInterval);
        return () => clearInterval(intervalId);
    }, [isFound, props, props.model.Name, agentHealth]);
    const [selectedLanguage, setSelectedLanguage] = useState<ScriptingLanguage | Language | TextFormat>(ScriptingLanguage.Bash);
    const helmCommand = props.generateHelmCommand(selectedLanguage);
    const tokenExpiredAndHaveGivenUp = !isFound && props.hasTokenExpired;
    const expiryTimeInfo = !isFound && props.accessTokenExpiry && (<>
            Your token expires{" "}
            <b>
                <TimeFromNowLabel time={props.accessTokenExpiry.toISOString()}/>
            </b>
            .
        </>);
    const helmCommandLineCount = helmCommand.split(/\r\n|\r|\n/).length;
    //adjust the height of the code editor based on the number of lines in the helm command
    const codeEditorContainer = css({
        // 1.25rem per line (line height) and roughly 3rem of padding/margins/scrollbars
        minHeight: `calc((${helmCommandLineCount} * 1.25rem) + 3rem)`,
    });
    return (<div className={contentContainerStyles}>
            {!tokenExpiredAndHaveGivenUp && (<>
                    <p>We've generated a custom Helm command for you which includes a short-lived bearer token for Octopus Server. {expiryTimeInfo}</p>
                    <p>Copy and run this Helm command in a terminal connected to your Kubernetes cluster.</p>
                </>)}
            {tokenExpiredAndHaveGivenUp && (<Callout title={"Your bearer token has expired"} type={"danger"}>
                    <p>Regenerate the token and copy the updated Helm command.</p>
                </Callout>)}
            {isFound && (<div className="helmButtonContainer">
                    <Button importance="tertiary" label={hideHelmCommand ? "Show Helm command" : "Hide Helm command"} onClick={() => setHideHelmCommand(!hideHelmCommand)}></Button>
                </div>)}
            {!hideHelmCommand && (<>
                    <CodeEditor value={helmCommand} readOnly lineWrapping={false} showCopyButton showLineNumbers={false} language={selectedLanguage} allowFullScreen containerClassName={codeEditorContainer} settingsOverride={{ theme: "default", wordWrap: false }} onChange={noOp} scriptingLanguageSelectorOptions={{
                supportedLanguages: [ScriptingLanguage.Bash, ScriptingLanguage.PowerShell],
                onScriptingLanguageChanged: (syntax: ScriptingLanguage | Language | TextFormat) => setSelectedLanguage(syntax),
            }}/>
                    <Note>
                        Use of the {props.machineType === EndpointSelectionScope.Worker ? "worker" : "Kubernetes agent"} is subject to our <ExternalLink href={"https://octopus.com/company/legal"}>Customer Agreement</ExternalLink> with you.
                    </Note>
                </>)}
            {isFound && <Divider />}
            {!hideTargetSearch && !tokenExpiredAndHaveGivenUp && <DiscoveryProgress name={props.model.Name} isFound={isFound} healthStatus={agentHealth}/>}
        </div>);
}
