import type { ChannelResource, DeploymentActionResource, ProjectResource } from "@octopusdeploy/octopus-server-client";
import { TenantedDeploymentMode } from "@octopusdeploy/octopus-server-client";
import type { Lifecycles } from "app/areas/projects/components/Channels/ChannelsPage";
import * as React from "react";
import { DefaultOptionChip } from "~/components/Chips";
import MarkdownDescription from "~/components/MarkdownDescription";
import TenantTagsList from "~/components/TenantTagsList/TenantTagsList";
import type { TagIndex } from "~/components/tenantTagsets";
import ListTitle from "~/primitiveComponents/dataDisplay/ListTitle/ListTitle";
import { GitReferenceRuleDescription } from "./GitProtectionRules/GitReferenceRuleDescription";
import { GitResourceRulesDescriptionList } from "./GitProtectionRules/GitResourceRulesDescriptionList";
import RulesDescriptionList from "./RulesDescriptionList";
import styles from "./style.module.less";
interface ChannelProps {
    channel: ChannelResource;
    project: ProjectResource;
    lifecycles: Lifecycles;
    tagIndex: TagIndex;
    deploymentActions?: DeploymentActionResource[];
}
const Channel: React.StatelessComponent<ChannelProps> = (props) => {
    const getLifecycleName = () => {
        if (props.channel.LifecycleId) {
            return props.lifecycles[props.channel.LifecycleId].Name;
        }
        const projectLifecycle = props.lifecycles[props.project.LifecycleId].Name;
        return `${projectLifecycle} (inherited from project)`;
    };
    const channel = props.channel;
    return (<div>
            <ListTitle>
                {channel.Name}
                {channel.IsDefault && <DefaultOptionChip />}
            </ListTitle>
            <div className={styles.description}>
                <MarkdownDescription markup={channel.Description}/>
            </div>
            <div className={styles.grid}>
                <div className={styles.row}>
                    <div className={styles.header}>Lifecycle</div>
                    <div className={styles.value}>{getLifecycleName()}</div>
                </div>
                {channel.TenantTags.length > 0 && props.project.TenantedDeploymentMode !== TenantedDeploymentMode.Untenanted && (<div className={styles.row}>
                        <div className={styles.header}>Limited to these tenants</div>
                        <div className={styles.value}>
                            <TenantTagsList tags={channel.TenantTags}/>
                        </div>
                    </div>)}
                {channel.Rules.length > 0 && (<div className={styles.row}>
                        <div className={styles.header}>Package version rules</div>
                        <div className={styles.value}>
                            <RulesDescriptionList rules={channel.Rules}/>
                        </div>
                    </div>)}
                {channel.GitReferenceRules.length > 0 && (<div className={styles.row}>
                        <div className={styles.header}>Project repository</div>
                        <div className={styles.value}>
                            <GitReferenceRuleDescription rules={channel.GitReferenceRules}/>
                        </div>
                    </div>)}
                {channel.GitResourceRules.length > 0 && (<div className={styles.row}>
                        <div className={styles.header}>External repository rules</div>
                        <div className={styles.value}>
                            <GitResourceRulesDescriptionList rules={channel.GitResourceRules} actions={props.deploymentActions}/>
                        </div>
                    </div>)}
            </div>
        </div>);
};
Channel.displayName = "Channel"
export default Channel;
