import type { ProjectResource } from "@octopusdeploy/octopus-server-client";
import { getURISafeGitRef, HasGitPersistenceSettings, toGitBranch } from "@octopusdeploy/octopus-server-client";
import React from "react";
import { useLocation, useParams } from "react-router";
import type { ProjectRouteParams } from "~/areas/projects/components/ProjectsRoutes/ProjectRouteParams";
import type { WithProjectContextInjectedProps } from "~/areas/projects/context";
import { useProjectContext } from "~/areas/projects/context";
import InternalRedirect from "~/components/Navigation/InternalRedirect";
import type { WithOptionalRunbookContextInjectedProps } from "../Runbooks/RunbookContext";
import { useOptionalRunbookContext } from "../Runbooks/RunbookContext";
type BranchAwareRedirectProps = {
    children?: React.ReactNode;
    condition?: (context: ProjectResource) => boolean;
    mustMatch?: string[];
};
type BranchAwareRedirectInternalProps = BranchAwareRedirectProps & WithProjectContextInjectedProps & WithOptionalRunbookContextInjectedProps;
const BranchAwareRedirectInternal: React.FC<BranchAwareRedirectInternalProps> = (props) => {
    const location = useLocation();
    const { projectSlug, branchName } = useParams<ProjectRouteParams>();
    if (!projectSlug) {
        throw new Error("Cannot use a BranchAwareRedirect where a projectslug route parameter does not exist");
    }
    let redirectTo = undefined;
    if (!branchName) {
        if (HasGitPersistenceSettings(props.projectContext.state.model.PersistenceSettings)) {
            const shouldRedirect = props.condition ? props.condition(props.projectContext.state.model) : true;
            const pageIsIncluded = props.mustMatch ? props.mustMatch.some((s) => location.pathname.match(s)) : true;
            if (shouldRedirect && pageIsIncluded) {
                const gitRef = props.projectContext.state.gitRef?.CanonicalName ?? toGitBranch(props.projectContext.state.model.PersistenceSettings.DefaultBranch);
                if (gitRef === undefined) {
                    throw new Error("Cannot use BranchAwareRedirect without a GitRef loaded into ProjectContext");
                }
                const path = location.pathname;
                const projectIdPart = `/projects/${projectSlug}`;
                const insertionIndex = path.indexOf(projectIdPart) + projectIdPart.length;
                const prefix = path.slice(0, insertionIndex);
                const branchPart = `/branches/${getURISafeGitRef(gitRef)}`;
                const suffix = path.slice(insertionIndex);
                const pathWithBranch = [prefix, branchPart, suffix].join("");
                redirectTo = { ...location, pathname: pathWithBranch };
            }
        }
    }
    return redirectTo ? <InternalRedirect from={location.pathname} to={redirectTo} push={false}/> : <>{props.children}</>;
};
BranchAwareRedirectInternal.displayName = "BranchAwareRedirectInternal"
export const BranchAwareRedirect: React.FC<BranchAwareRedirectProps> = (props) => {
    const projectContext = useProjectContext();
    const runbookContext = useOptionalRunbookContext();
    return <BranchAwareRedirectInternal {...props} projectContext={projectContext} runbookContext={runbookContext}/>;
};
BranchAwareRedirect.displayName = "BranchAwareRedirect"
export default BranchAwareRedirect;
