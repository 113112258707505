import type { PrimaryPageAction } from "@octopusdeploy/design-system-components";
import { useAggregateAPIOperationStatus } from "@octopusdeploy/octopus-react-client";
import type { ProjectResource, ChannelResource, ResourceCollection, LifecycleResource, DeploymentProcessRepository, DeploymentActionResource, ValidateGitRefV2Response, Repository } from "@octopusdeploy/octopus-server-client";
import { HasGitPersistenceSettings, OctopusError, Permission } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import { ProjectPaperLayout } from "app/areas/projects/components/ProjectPaperLayout";
import { flatMap } from "lodash";
import { useEffect, useMemo } from "react";
import * as React from "react";
import type { ProjectContextProps } from "~/areas/projects/context/index";
import List from "~/components/PagingList";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import * as tenantTagsets from "~/components/tenantTagsets";
import type { TagIndex } from "~/components/tenantTagsets";
import { RecentProjects } from "~/utils/RecentProjects/RecentProjects";
import Channel from "./Channel";
export async function channelsPageLoader(repository: Repository, projectContext: Promise<ProjectContextProps>): Promise<ChannelsPageLoaderData> {
    const lifecycles = repository.Lifecycles.all();
    const tagIndex = tenantTagsets.getTagIndex();
    const { state: { model: project, projectContextRepository, gitRefValidationError }, } = await projectContext;
    const channelsFirstPage = repository.Projects.getChannels(project, 0, 30);
    const deploymentActions = getDeploymentActionsWithErrorHandlingForGitProjects(project, projectContextRepository.DeploymentProcesses, gitRefValidationError);
    return {
        channelsFirstPage: await channelsFirstPage,
        deploymentActions: await deploymentActions,
        lifecycles: await lifecycles,
        tagIndex: await tagIndex,
    };
}
interface ChannelsPageLoaderData {
    channelsFirstPage: ResourceCollection<ChannelResource>;
    lifecycles: LifecycleResource[];
    tagIndex: TagIndex;
    deploymentActions: DeploymentActionResource[];
}
interface ChannelsPageProps {
    loaderData: ChannelsPageLoaderData;
    project: ProjectResource;
}
export function ChannelsPage({ loaderData, project }: ChannelsPageProps) {
    useEffect(() => RecentProjects.getInstance().UpdateAccessedProjectIntoLocalStorage(project.Id), [project.Id]);
    const lifecycles = useMemo(() => {
        const lifecycles: Lifecycles = {};
        loaderData.lifecycles.forEach((lifecycle) => {
            lifecycles[lifecycle.Id] = lifecycle;
        });
        return lifecycles;
    }, [loaderData.lifecycles]);
    const primaryPageAction: PrimaryPageAction = {
        type: "navigate",
        label: "Add Channel",
        path: links.createChannelPage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug }),
        hasPermissions: isAllowed({ permission: Permission.ProcessEdit, project: project.Id, tenant: "*" }),
    };
    const { errors, isInProgress } = useAggregateAPIOperationStatus();
    return (<ProjectPaperLayout busy={isInProgress} errors={errors} title={channelsPageTitle} primaryAction={primaryPageAction}>
            <ChannelsList initialData={loaderData.channelsFirstPage} onRow={(item) => buildRow(item, project, lifecycles, loaderData.tagIndex, loaderData.deploymentActions)} onRowRedirectUrl={(channel: ChannelResource) => links.editChannelPage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug, channelId: channel.Id })} onRowAccessibleName={(channel: ChannelResource) => `${channel.Name}`} onFilter={filter} filterSearchEnabled={true} apiSearchParams={["partialName"]} filterHintText="Filter by name..."/>
        </ProjectPaperLayout>);
}
async function getDeploymentActionsWithErrorHandlingForGitProjects(project: ProjectResource, repository: DeploymentProcessRepository, gitRefValidationError: ValidateGitRefV2Response | undefined) {
    try {
        if (gitRefValidationError) {
            return [];
        }
        else {
            const process = await repository.get();
            return flatMap(process.Steps, (step) => step.Actions);
        }
    }
    catch (exception) {
        if (HasGitPersistenceSettings(project.PersistenceSettings) && OctopusError.isOctopusError(exception)) {
            // We only want to catch the error if it's a Git project and an Octopus exception. Otherwise, error
            // as usual
            return [];
        }
        else {
            throw exception;
        }
    }
}
function filter(filter: string, resource: ChannelResource) {
    return !filter || filter.length === 0 || !resource || resource.Name.toLowerCase().includes(filter.toLowerCase());
}
function buildRow(channel: ChannelResource, project: ProjectResource, lifecycles: Lifecycles, tagIndex: TagIndex, deploymentActions: DeploymentActionResource[]) {
    return <Channel key={channel.Id} channel={channel} project={project} lifecycles={lifecycles} tagIndex={tagIndex} deploymentActions={deploymentActions}/>;
}
export interface Lifecycles {
    [name: string]: LifecycleResource;
}
class ChannelsList extends List<ChannelResource> {
}
export const channelsPageTitle = "Channels";
