import React, { useState } from "react";
import { useAnalyticActionDispatch, useAnalyticTrackedActionDispatch } from "~/analytics/Analytics";
import { CustomDialog } from "~/components/Dialog/CustomDialog";
import { CreateEnvironmentsPage } from "~/components/ProjectBasedActivation/CreateEnvironmentsDialog";
export const NewlyCreatedProjectWizardDialog: React.FC<{
    spaceId: string;
    open: boolean;
    close: () => void;
    showCreateEnvironments: boolean;
}> = ({ spaceId, open, close, showCreateEnvironments }) => {
    const [page, setPage] = useState<number>(0);
    const trackAction = useAnalyticTrackedActionDispatch();
    const dispatchAction = useAnalyticActionDispatch();
    const nextPage = () => {
        const hasNextPage = page + 1 < pages.length;
        if (hasNextPage) {
            setPage(page + 1);
        }
        else {
            close();
        }
    };
    const pages: JSX.Element[] = [];
    if (showCreateEnvironments)
        pages.push(<CreateEnvironmentsPage spaceId={spaceId} next={nextPage} trackAction={trackAction} dispatchAction={dispatchAction} close={() => { }}/>);
    if (!pages.length)
        return null;
    return <CustomDialog open={open} close={close} render={() => pages[page]}/>;
};
NewlyCreatedProjectWizardDialog.displayName = "NewlyCreatedProjectWizardDialog"
