import { ProcessType } from "@octopusdeploy/octopus-server-client";
import React from "react";
import { ProcessPaperLayout } from "~/areas/projects/components/Process/CustomPaperLayouts/ProcessPaperLayout";
import { useRunbookContext } from "~/areas/projects/components/Runbooks/RunbookContext";
import { useProjectContext } from "../../context";
import { PageHeaderBranchSelectorVNext } from "../BranchSelector/PageHeaderBranchSelector";
import { useProjectRunbooksGitRef, type GitRefQueryParamsProps } from "../BranchSelector/useProjectGitRef";
import ProcessStepsLayoutLoader from "../Process/ProcessStepsLayoutLoader";
export function RunbookProcessStepsPage({ queryParams, setQueryParams }: GitRefQueryParamsProps) {
    const projectContext = useProjectContext();
    const context = useRunbookContext();
    const [gitRef, setGitRef, gitRefValidationResult] = useProjectRunbooksGitRef(projectContext.state.model, queryParams, setQueryParams);
    const branchSelector = <PageHeaderBranchSelectorVNext project={projectContext.state.model} gitRef={gitRef} setGitRef={setGitRef}/>;
    if (!context.state.runbook) {
        return <ProcessPaperLayout processType={ProcessType.Runbook} busy={true}/>;
    }
    return (<ProcessStepsLayoutLoader processId={context.state.runbook.RunbookProcessId} processType={ProcessType.Runbook} project={projectContext.state.model} gitRef={gitRef} gitRefResource={gitRefValidationResult?.GitRef} titleAccessory={branchSelector}/>);
}
