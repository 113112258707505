import { ActionButton, ActionButtonType } from "@octopusdeploy/design-system-components";
import type { GitRefResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { GitCallouts } from "~/areas/projects/components/VersionControl/GitCallouts";
import OnboardingPage from "~/components/GettingStarted/OnboardingPage";
import ExternalLink from "~/components/Navigation/ExternalLink/ExternalLink";
interface NoProjectVariablesOnboardingPageProps {
    onClick: () => void;
    gitRefResource: GitRefResource | undefined;
}
export default function NoProjectVariablesOnboardingPage({ onClick, gitRefResource }: NoProjectVariablesOnboardingPageProps) {
    return (<>
            <GitCallouts isVariablesPage gitRefResource={gitRefResource}/>
            <OnboardingPage title="Create project variables" intro={<div>Variables are a powerful tool that lets you securely store and manage sensitive keys and configuration values across your infrastructure.</div>} actionButtons={<ActionButton title="Create Variables" label={"Create Variables"} accessibleName={"Create Variables"} type={ActionButtonType.Primary} onClick={onClick}/>} learnMore={<ExternalLink href="DocumentationVariables">Learn more</ExternalLink>}/>
        </>);
}
