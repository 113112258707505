/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { SigningKeyConfigurationResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { repository } from "~/clientInstance";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import SaveDialogLayout from "~/components/DialogLayout/SaveDialogLayout";
import NumberPicker from "~/primitiveComponents/form/Number/NumberPicker";
interface EditSigningKeyConfigurationProps {
    onSaveDone(config: SigningKeyConfigurationResource): void;
}
interface EditSigningKeyConfigurationState extends DataBaseComponentState {
    loaded: boolean;
    expireAfterDays?: number;
    revokeAfterDays?: number;
}
export class EditSigningKeyConfiguration extends DataBaseComponent<EditSigningKeyConfigurationProps, EditSigningKeyConfigurationState> {
    constructor(props: EditSigningKeyConfigurationProps) {
        super(props);
        this.state = { loaded: false };
    }
    async componentDidMount() {
        return this.doBusyTask(async () => {
            const configuration = await repository.SigningKeyConfiguration.get();
            this.setState({
                expireAfterDays: configuration.ExpireAfterDays,
                revokeAfterDays: configuration.RevokeAfterDays,
                loaded: true,
            });
        });
    }
    async save() {
        return this.doBusyTask(async () => {
            const configuration = await repository.SigningKeyConfiguration.get();
            configuration.ExpireAfterDays = this.state.expireAfterDays!;
            configuration.RevokeAfterDays = this.state.revokeAfterDays!;
            await repository.SigningKeyConfiguration.modify(configuration);
            this.props.onSaveDone(configuration);
        });
    }
    render() {
        const { expireAfterDays, revokeAfterDays, busy, loaded } = this.state;
        return (<SaveDialogLayout title="Edit Signing Key Configuration" busy={busy} errors={this.errors} onSaveClick={() => this.save()}>
                {loaded ? (<>
                        <NumberPicker value={expireAfterDays} label="Rotate after days" onChange={(val) => {
                    if (val === undefined || val <= 0) {
                        val = 0;
                    }
                    this.setState({ expireAfterDays: val });
                }} note={`The active key will be automatically rotated ${expireAfterDays} days after it's created`} unitOfMeasure="days"/>
                        <NumberPicker value={revokeAfterDays} label="Revoke after days" onChange={(val) => {
                    if (val === undefined || val <= 0) {
                        val = 0;
                    }
                    this.setState({ revokeAfterDays: val });
                }} note={`Expired signing keys will be automatically revoked ${revokeAfterDays} days after they expire`} unitOfMeasure="days"/>
                    </>) : null}
            </SaveDialogLayout>);
    }
    static displayName = "EditSigningKeyConfiguration";
}
