import { css } from "@emotion/css";
import { space, text, themeTokens } from "@octopusdeploy/design-system-tokens";
export const styles = {
    loading: css({
        display: "flex",
        justifyContent: "center",
    }),
    templateInfo: css({
        display: "flex",
        flexDirection: "column",
        gap: space[2],
    }),
    templateName: css({
        display: "flex",
        gap: space[6],
        alignItems: "center",
    }),
    value: css({
        color: themeTokens.color.text.disabled,
        display: "flex",
        alignItems: "center",
        gap: space[8],
    }),
    missingValue: css({
        display: "flex",
        alignItems: "center",
        gap: space[8],
        color: themeTokens.color.text.secondary,
    }),
    sidebarContainer: css({
        paddingTop: space[12],
        overflowY: "auto",
    }),
    sidebar30Height: css({
        maxHeight: "137rem",
    }),
    sidebar50Height: css({
        maxHeight: "222rem",
    }),
    sidebar100Height: css({
        maxHeight: "430rem",
    }),
    sidebarRow: css({
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: `${space[12]} 0 ${space[12]} ${space[16]}`,
        cursor: "pointer",
        "&:hover": {
            backgroundColor: themeTokens.color.background.primary.hovered,
        },
        font: text.regular.bold.large,
    }),
    sidebarFilter: css({
        display: "flex",
        paddingLeft: space[16],
        flexDirection: "row",
        alignItems: "center",
    }),
    sidebarNameContainer: css({
        display: "flex",
        gap: space[8],
    }),
    sidebarName: css({
        wordBreak: "break-word",
    }),
    sidebarSelectedName: css({
        color: themeTokens.color.text.selected,
    }),
    sidebarLabel: css({
        font: text.regular.default.small,
        color: themeTokens.color.text.tertiary,
    }),
    filterContainer: css({
        display: "flex",
        gap: space[8],
        padding: space[16],
    }),
    actionButtonsContainer: css({
        paddingRight: space[4],
    }),
    filterSidebar: css({
        whiteSpace: "nowrap",
        padding: `0 ${space[4]}`,
    }),
    cellLabel: css({
        font: text.regular.default.small,
        color: themeTokens.color.text.tertiary,
    }),
    toolbarIconsContainer: css({
        display: "inline-flex",
        alignItems: "center",
        gap: "1rem",
    }),
};
export const pagingBarStyles = css({
    padding: "0 1rem",
});
