import { css } from "@emotion/css";
import { ActionButton, ActionButtonType, Callout, RadioButton, RadioButtonGroup } from "@octopusdeploy/design-system-components";
import type { GitDependencyReference } from "@octopusdeploy/octopus-server-client";
import { isArray, isString } from "lodash";
import React, { useEffect, useMemo, useRef, useState } from "react";
import type { RunOn } from "~/areas/projects/components/Process/types";
import ActionList from "~/components/ActionList";
import { ChartTemplateValuesSection } from "~/components/Actions/helmChartUpgrade/ChartTemplateValuesSection";
import { GitRepositoryTemplateValuesSection } from "~/components/Actions/helmChartUpgrade/GitRepositoryTemplateValuesSection";
import { InlineYamlTemplateValuesSection } from "~/components/Actions/helmChartUpgrade/InlineYamlTemplateValuesSection";
import { KeyValuesTemplateValuesSection } from "~/components/Actions/helmChartUpgrade/KeyValuesTemplateValuesSection";
import { PackageTemplateValuesSection } from "~/components/Actions/helmChartUpgrade/PackageTemplateValuesSection";
import type { PackagedHelmValuesReference } from "~/components/Actions/helmChartUpgrade/PackagedHelmValuesDialog";
import type { ChartTemplateValuesSource, GitRepositoryTemplateValuesSource, InlineYamlTemplateValuesSource, KeyValuesTemplateValuesSource, PackageTemplateValuesSource, TemplateValuesSource, TemplateValuesSources, TemplateValuesSourceType, } from "~/components/Actions/helmChartUpgrade/TemplateValuesSource";
import { TemplateValuesSourceTypes } from "~/components/Actions/helmChartUpgrade/TemplateValuesSource";
import type { BoundFieldProps } from "~/components/Actions/pluginRegistry";
import { useNonPrimaryGitDependencySupport } from "~/components/GitRepositorySource/useNonPrimaryGitDependencySupport";
import { OverflowMenu, OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import { ExpandableFormSection, Summary } from "~/components/form/index";
import { DrawerWithForm } from "~/primitiveComponents/dataDisplay/DrawerWithForm/DrawerWithForm";
import type { CodeEditorVariable } from "~/utils/ScriptIntellisense/scriptIntellisense";
type HelmTemplateValuesDrawerProps = BoundFieldProps & {
    isOpen: boolean;
    onClose: () => void;
    onSave: (result: HelmTemplateValuesSaveResult) => void;
    onDelete: (tvs: TemplateValuesSource) => void;
    templateValuesSources: TemplateValuesSources;
    editingTemplateValuesSource: TemplateValuesSource | undefined;
    editingPackageReference: PackagedHelmValuesReference | undefined;
    editingGitDependency: GitDependencyReference | undefined;
    projectId: string;
    runOn: RunOn | undefined;
    yamlAutocompleteResults: CodeEditorVariable[];
};
type SimpleTemplateValuesSourceSaveResult = Exclude<TemplateValuesSource, "PackageTemplateValuesSource" | "GitRepositoryValuesSource">;
export type HelmTemplateValuesSaveResult = SimpleTemplateValuesSourceSaveResult | PackageReferenceTemplateValuesSaveResult | GitDependencyTemplateValuesSaveResult;
export type PackageReferenceTemplateValuesSaveResult = {
    valuesSource: PackageTemplateValuesSource;
    packageReference: PackagedHelmValuesReference;
};
export type GitDependencyTemplateValuesSaveResult = {
    valuesSource: GitRepositoryTemplateValuesSource;
    gitDependency: GitDependencyReference;
};
export function isPackageReferenceTemplateValuesSaveResult(obj: unknown): obj is PackageReferenceTemplateValuesSaveResult {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return (obj as PackageReferenceTemplateValuesSaveResult)?.packageReference !== undefined;
}
export function isGitDependencyTemplateValuesSaveResult(obj: unknown): obj is GitDependencyTemplateValuesSaveResult {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return (obj as GitDependencyTemplateValuesSaveResult)?.gitDependency !== undefined;
}
export type TemplateValuesSectionProps<T> = Omit<HelmTemplateValuesDrawerProps, "isOpen" | "onClose" | "editingTemplateValuesSource" | "onSave" | "onDelete" | "templateValuesSources"> & {
    editingTemplateValuesSource: T;
};
export type TemplateValuesSectionComponent = {
    validateAndSave: () => HelmTemplateValuesSaveResult | string | string[];
};
const bulletStyle = css({
    listStyleType: "disc",
});
const isTemplateValuesSourceEnabled = (type: TemplateValuesSourceType, templateValuesSources: TemplateValuesSources, editingTemplateValuesSource: TemplateValuesSource | undefined): boolean => {
    //if we are editing, enable the one we are editing
    if (editingTemplateValuesSource) {
        return editingTemplateValuesSource.Type === type;
    }
    //if this type not the Chart type, then we are always enabled (as we can have multiple of everything but the Chart type)
    if (type !== "Chart") {
        return true;
    }
    //Check to see if there are any unique sources already configured for this type
    return !templateValuesSources.some((tvs) => tvs.Type === type);
};
export const HelmTemplateValuesDrawer = (props: HelmTemplateValuesDrawerProps) => {
    const enableGitRepositorySource = useNonPrimaryGitDependencySupport();
    const enabledTemplateValuesSourcesMap = useMemo(() => {
        const map: {
            [type: TemplateValuesSourceType]: boolean;
        } = {};
        for (const templateValuesSourceType of TemplateValuesSourceTypes) {
            map[templateValuesSourceType] = isTemplateValuesSourceEnabled(templateValuesSourceType, props.templateValuesSources, props.editingTemplateValuesSource);
        }
        return map;
    }, [props.editingTemplateValuesSource, props.templateValuesSources]);
    const defaultSelectedTemplateValuesSource = useMemo(() => {
        if (props.editingTemplateValuesSource) {
            return props.editingTemplateValuesSource.Type;
        }
        //if the chart is disabled, then pick git/package as they are the first multi-option
        return enabledTemplateValuesSourcesMap["Chart"] ? "Chart" : enableGitRepositorySource ? "GitRepository" : "Package";
    }, [props.editingTemplateValuesSource, enabledTemplateValuesSourcesMap, enableGitRepositorySource]);
    const [templateValuesSourceType, setTemplateValuesSourceType] = useState<TemplateValuesSourceType>(defaultSelectedTemplateValuesSource);
    const [validationErrors, setValidationErrors] = useState<string[]>([]);
    const ref = useRef<TemplateValuesSectionComponent>(null);
    useEffect(() => setTemplateValuesSourceType(defaultSelectedTemplateValuesSource), [defaultSelectedTemplateValuesSource]);
    useEffect(() => {
        if (props.isOpen) {
            setValidationErrors([]);
        }
    }, [props.isOpen]);
    const isEditing = !!props.editingTemplateValuesSource;
    const getSection = () => {
        /* eslint-disable @typescript-eslint/consistent-type-assertions */
        switch (templateValuesSourceType) {
            case "Chart":
                return <ChartTemplateValuesSection {...props} editingTemplateValuesSource={props.editingTemplateValuesSource as ChartTemplateValuesSource} ref={ref}/>;
            case "Package":
                return <PackageTemplateValuesSection {...props} editingTemplateValuesSource={props.editingTemplateValuesSource as PackageTemplateValuesSource} ref={ref}/>;
            case "KeyValues":
                return <KeyValuesTemplateValuesSection {...props} editingTemplateValuesSource={props.editingTemplateValuesSource as KeyValuesTemplateValuesSource} ref={ref}/>;
            case "InlineYaml":
                return <InlineYamlTemplateValuesSection {...props} editingTemplateValuesSource={props.editingTemplateValuesSource as InlineYamlTemplateValuesSource} ref={ref}/>;
            case "GitRepository":
                return enableGitRepositorySource && <GitRepositoryTemplateValuesSection {...props} editingTemplateValuesSource={props.editingTemplateValuesSource as GitRepositoryTemplateValuesSource} ref={ref}/>;
            default:
                throw new Error(`Unknown TemplateValueType ${templateValuesSourceType}`);
        }
        /* eslint-enable @typescript-eslint/consistent-type-assertions */
    };
    const getSummary = () => {
        let message: string = "";
        switch (templateValuesSourceType) {
            case "Chart":
                message = "Files in the chart";
                break;
            case "Package":
                message = "Files in a package";
                break;
            case "KeyValues":
                message = "Key values";
                break;
            case "InlineYaml":
                message = "Inline YAML";
                break;
            case "GitRepository":
                message = "Files in a Git repository";
                break;
            default:
                throw new Error(`Unknown TemplateValueType ${templateValuesSourceType}`);
        }
        return Summary.summary(message);
    };
    const onSubmit = () => {
        if (!ref.current) {
            return;
        }
        const result = ref.current.validateAndSave();
        if (isString(result)) {
            setValidationErrors([result]);
        }
        else if (isArray(result)) {
            setValidationErrors(result);
        }
        else {
            props.onSave(result);
        }
    };
    const onDelete = async () => {
        if (!props.editingTemplateValuesSource) {
            return;
        }
        props.onDelete(props.editingTemplateValuesSource);
        props.onClose();
    };
    const getDrawerActions = () => {
        const actions = [<ActionButton onClick={props.onClose} type={ActionButtonType.Secondary} label="Cancel"/>, <ActionButton onClick={onSubmit} type={ActionButtonType.Save} label="Save"/>];
        if (isEditing) {
            actions.push(<OverflowMenu menuItems={[OverflowMenuItems.deleteItemDefault("template values source", onDelete)]}/>);
        }
        return [<ActionList actions={actions}/>];
    };
    return (<DrawerWithForm isOpen={props.isOpen} onClose={props.onClose} title={isEditing ? "Edit Template Values" : "Add Template Values"} busy={undefined} onSubmit={onSubmit} variant="customActions" customActions={getDrawerActions()} errors={undefined}>
            <ExpandableFormSection title={"Source"} summary={getSummary()} errorKey={"HelmTemplateValuesSourcesList"} help={<span>Choose your template values source</span>} isExpandedByDefault={!isEditing}>
                <RadioButtonGroup value={templateValuesSourceType} onChange={(x) => setTemplateValuesSourceType(x)}>
                    <RadioButton value={"Chart"} label="Files in the chart" disabled={!enabledTemplateValuesSourcesMap["Chart"]}/>
                    {enableGitRepositorySource && <RadioButton value={"GitRepository"} label="Files in a Git repository" disabled={!enabledTemplateValuesSourcesMap["GitRepository"]}/>}
                    <RadioButton value={"Package"} label="Files in a package" disabled={!enabledTemplateValuesSourcesMap["Package"]}/>
                    <RadioButton value={"KeyValues"} label="Key values" disabled={!enabledTemplateValuesSourcesMap["KeyValues"]}/>
                    <RadioButton value={"InlineYaml"} label="Inline YAML" disabled={!enabledTemplateValuesSourcesMap["InlineYaml"]}/>
                </RadioButtonGroup>
            </ExpandableFormSection>
            {validationErrors.length > 0 && (<Callout type={"danger"} title={"Validation issues"}>
                    <ul className={bulletStyle}>
                        {validationErrors.map((error) => (<li key={error}>{error}</li>))}
                    </ul>
                </Callout>)}
            {getSection()}
        </DrawerWithForm>);
};
