import { css } from "@emotion/css";
import { fontSize, fontFamily } from "@octopusdeploy/design-system-tokens";
export const rulesTesterCodeMirrorStyles = css({
    height: "100%",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    fontSize: fontSize["base"],
    position: "absolute",
    ".cm-editor.cm-editor": {
        height: "inherit",
        fontFamily: fontFamily.code,
        fontSize: "1rem",
    },
    ".cm-line.cm-activeLine": {
        background: "transparent",
    },
    ".cm-focused .cm-line.cm-activeLine": {
        background: "#6699FF0A",
    },
    ".cm-gutters": {
        background: "transparent",
        ".cm-foldGutter": {
            width: 0,
        },
        ".cm-gutterElement.cm-activeLineGutter": {
            background: "transparent",
        },
    },
});
