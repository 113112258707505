import { css } from "@emotion/css";
import { ChevronDownIcon, ChevronUpIcon, MenuItemButton, MenuList } from "@octopusdeploy/design-system-components";
import { ThemeDarkIcon } from "@octopusdeploy/design-system-components/src/components/Icon/ThemeDarkIcon";
import { ThemeLightIcon } from "@octopusdeploy/design-system-components/src/components/Icon/ThemeLightIcon";
import { ThemeSystemIcon } from "@octopusdeploy/design-system-components/src/components/Icon/ThemeSystemIcon";
import { borderRadius, fontSize, space, themeTokens } from "@octopusdeploy/design-system-tokens";
import classNames from "classnames";
import React, { useCallback, useEffect, useState } from "react";
import { Popover } from "~/primitiveComponents/dataDisplay/Popover/Popover";
import type { ThemeValue } from "./useThemePaletteType";
import { useSetThemeType, useThemeType } from "./useThemePaletteType";
export interface ThemeItem {
    id: ThemeValue;
    name: string;
    icon: JSX.Element;
}
const themes: Array<ThemeItem> = [
    {
        id: "light",
        name: "Light",
        icon: <ThemeLightIcon />,
    },
    {
        id: "dark",
        name: "Dark",
        icon: <ThemeDarkIcon />,
    },
    {
        id: "unassigned",
        name: "System",
        icon: <ThemeSystemIcon />,
    },
];
export function ThemeSelector() {
    const themeType = useThemeType();
    const setThemeType = useSetThemeType();
    const setPalette = useCallback((theme) => setThemeType(theme), [setThemeType]);
    const [theme, setTheme] = useState<ThemeItem>();
    const [open, setOpen] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
    const ref = React.createRef<HTMLDivElement>();
    useEffect(() => {
        setTheme(themes.find((x) => x.id === themeType));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        setAnchorEl(ref.current);
    }, [ref]);
    const closeMenu = () => {
        setOpen(false);
    };
    const changeTheme = (theme: ThemeItem) => {
        setPalette(theme.id);
        setTheme(theme);
        closeMenu();
    };
    return (<div>
            <div className={classNames({ [styles.selectedThemeContainer]: true, ["active"]: open })} ref={ref} onClick={() => setOpen(!open)}>
                <div className="content">
                    {theme?.icon}
                    <span>{theme?.name}</span>
                    <span className="chevronIconContainer">{open ? <ChevronUpIcon /> : <ChevronDownIcon />}</span>
                </div>
            </div>
            <Popover anchorEl={anchorEl} open={open} onClose={closeMenu} anchorOrigin={{ horizontal: "left", vertical: "bottom" }}>
                <div className={styles.menuContainer}>
                    <MenuList accessibleName={"Theme Selector"}>
                        {themes.map((item) => {
            return (<MenuItemButton key={item.id} isSelected={themeType === item.id} onClick={() => changeTheme(item)} compact={true}>
                                    <span>{item.icon}</span>
                                    <span>{item.name}</span>
                                </MenuItemButton>);
        })}
                    </MenuList>
                </div>
            </Popover>
        </div>);
}
const styles = {
    selectedThemeContainer: css({
        width: "128px",
        padding: `${space[6]} ${space[12]}`,
        borderRadius: borderRadius["small"],
        border: `1px solid ${themeTokens.color.border.primary}`,
        cursor: "pointer",
        color: themeTokens.color.text.secondary,
        fontSize: fontSize["medium"],
        "& .content": {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: space[8],
            svg: {
                width: "20px",
                height: "20px",
            },
        },
        "&.active": {
            border: `1px solid ${themeTokens.color.border.selected}`,
        },
        "& .chevronIconContainer": {
            marginLeft: "auto",
            svg: {
                width: "16px",
                height: "16px",
            },
        },
    }),
    menuContainer: css({
        width: "128px",
        backgroundColor: themeTokens.color.menuList.background.default,
        "& .MuiButtonBase-root.MuiListItem-root.MuiMenuItem-root": {
            display: "flex",
            gap: space[8],
            fontSize: fontSize["medium"],
            paddingLeft: space[12],
            paddingRight: space[12],
            svg: {
                width: "20px",
                height: "20px",
            },
        },
    }),
};
