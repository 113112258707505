import { css } from "@emotion/css";
import { space, text, themeTokens } from "@octopusdeploy/design-system-tokens";
import * as React from "react";
interface InputLabelProps {
    label: string | JSX.Element;
}
const InputLabel: React.FC<InputLabelProps> = (props) => {
    return <div className={formFieldTitleStyles}>{props.label}</div>;
};
InputLabel.displayName = "InputLabel"
const formFieldTitleStyles = css({
    color: themeTokens.color.text.secondary,
    font: text.regular.default.small,
    marginBottom: space[4],
});
export default InputLabel;
