import type { LinkHref } from "@octopusdeploy/portal-routes";
import type { PermissionCheckProps } from "~/components/PermissionCheck/PermissionCheck";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
export class Navigation {
    static navItem(label: string | JSX.Element, path: LinkHref, exact?: boolean, permission?: PermissionCheckProps): NavLink | null {
        return !permission || isAllowed(permission) ? { label, path, exact: !!exact } : null;
    }
}
export interface NavLink {
    label: string | JSX.Element;
    path: LinkHref;
    exact: boolean;
}
