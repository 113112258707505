import { css } from "@emotion/css";
import { CircularProgress, UnavailableIcon, WarningIcon } from "@octopusdeploy/design-system-components";
import { CircleCheckIcon } from "@octopusdeploy/design-system-components/src/components/Icon/CircleCheckIcon";
import { CircleXMarkIcon } from "@octopusdeploy/design-system-components/src/components/Icon/CircleXMarkIcon";
import { themeTokens } from "@octopusdeploy/design-system-tokens";
import type { KubernetesLiveStatusResourceObject } from "@octopusdeploy/octopus-server-client/dist/src/resources/kubernetesLiveStatusResource";
import * as React from "react";
import { repository } from "~/clientInstance";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/index";
import { DataBaseComponent } from "~/components/DataBaseComponent/index";
import { DataTable, DataTableBody, DataTableHeader, DataTableHeaderColumn, DataTableRow, DataTableRowColumn } from "~/primitiveComponents/dataDisplay/DataTable/index";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
interface KubernetesLiveStatusState extends DataBaseComponentState {
    Resources: KubernetesLiveStatusResourceObject[];
}
type KubernetesLiveObjectStatusProps = {
    projectId: string;
    environmentId: string;
    tenantIdOrUntenanted: string;
};
class InternalKubernetesLiveObjectStatus extends DataBaseComponent<KubernetesLiveObjectStatusProps, KubernetesLiveStatusState> {
    constructor(props: KubernetesLiveObjectStatusProps) {
        super(props);
        this.state = {
            Resources: [],
        };
    }
    async refresh(): Promise<KubernetesLiveStatusState> {
        const result = await repository.KubernetesLiveStatus.getForApplicationInstance(this.props.projectId, this.props.environmentId, this.props.tenantIdOrUntenanted);
        return { Resources: result.Resources };
    }
    async componentDidMount() {
        await this.doBusyTask(async () => {
            const result = await repository.KubernetesLiveStatus.getForApplicationInstance(this.props.projectId, this.props.environmentId, this.props.tenantIdOrUntenanted);
            this.setState({ Resources: result.Resources }, () => this.doBusyTask(async () => await this.startRefreshLoop(() => this.refresh(), 1000, false, timeOperationOptions.forRefresh())));
        });
    }
    // TODO #project-md-k8s-live-object-status
    // implement proper icons
    healthyStatuses = ["Healthy", "Running", "InSync"];
    getTrafficLight(resource: KubernetesLiveStatusResourceObject) {
        if (resource.Status === "Progressing") {
            return (<div>
                    <CircularProgress size={"small"}/>
                </div>);
        }
        else if (this.healthyStatuses.includes(resource.Status) || resource.Status.includes("Available") || resource.Status.includes("Ready")) {
            return (<div className={css({ color: themeTokens.color.icon.success })}>
                    <CircleCheckIcon size={24}/>
                </div>);
        }
        else if (resource.Status === "OutOfSync" || resource.Status == "Pending") {
            return (<div className={css({ color: themeTokens.color.icon.warning })}>
                    <WarningIcon />
                </div>);
        }
        else if (resource.Status === "Unhealthy") {
            return (<div className={css({ color: themeTokens.color.icon.danger })}>
                    <CircleXMarkIcon size={24}/>
                </div>);
        }
        return <UnavailableIcon />;
    }
    row(resource: KubernetesLiveStatusResourceObject, key: string, level: number = 0): React.ReactElement {
        level = level > 4 ? 4 : level;
        return (<>
                <DataTableRow key={key}>
                    <DataTableRowColumn>{resource.MachineId}</DataTableRowColumn>
                    <DataTableRowColumn className={css({ "&&": { paddingLeft: 20 + 16 * level + "px" } })}>{resource.Name}</DataTableRowColumn>
                    <DataTableRowColumn>{resource.Kind}</DataTableRowColumn>
                    <DataTableRowColumn>{resource.Namespace}</DataTableRowColumn>
                    <DataTableRowColumn>{resource.Status}</DataTableRowColumn>
                    <DataTableRowColumn>{this.getTrafficLight(resource)}</DataTableRowColumn>
                </DataTableRow>
                {resource.Children.map((resource, index) => this.row(resource, key + "child" + index, level + 1))}
            </>);
    }
    // TODO #project-md-k8s-live-object-status
    // render proper tree view with expandable bits
    render() {
        return (<DataTable title="Live Objects">
                <DataTableHeader>
                    <DataTableRow>
                        <DataTableHeaderColumn>Machine</DataTableHeaderColumn>
                        <DataTableHeaderColumn>Name</DataTableHeaderColumn>
                        <DataTableHeaderColumn>Kind</DataTableHeaderColumn>
                        <DataTableHeaderColumn>Namespace</DataTableHeaderColumn>
                        <DataTableHeaderColumn>Conditions/Phase</DataTableHeaderColumn>
                        <DataTableHeaderColumn></DataTableHeaderColumn>
                    </DataTableRow>
                </DataTableHeader>
                <DataTableBody>{this.state.Resources.map((resource, index) => this.row(resource, "parent" + index))}</DataTableBody>
            </DataTable>);
    }
    static displayName = "InternalKubernetesLiveObjectStatus";
}
export function KubernetesLiveObjectStatus(props: KubernetesLiveObjectStatusProps) {
    return <InternalKubernetesLiveObjectStatus {...props}/>;
}
