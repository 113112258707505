import type { ApiOperationStatus } from "@octopusdeploy/octopus-react-client";
import { OctopusError } from "@octopusdeploy/octopus-server-client";
import { useCallback, useMemo, useState } from "react";
import type { DoBusyTask } from "~/components/DataBaseComponent/DataBaseComponent";
import { createErrorsFromOctopusError } from "~/components/DataBaseComponent/Errors";
import { useCurrentError, useErrorActions } from "~/components/ErrorContext/ErrorContext";
import { timeOperation } from "~/utils/OperationTimer/timeOperation";
import { PromiseCancelledError } from "~/utils/PromiseCancelledError";
interface LegacyDoBusyTaskReturnValue {
    doBusyTask: DoBusyTask;
    status: ApiOperationStatus;
}
/**
 * Get access to a doBusyTask function, along with the busy and error state
 * Used primarily to help transition to the new data access building blocks, by allowing incremental migrations.
 * @returns {{doBusyTask: DoBusyTask, busy: Promise<void> | undefined, errors: Errors | undefined}} Provides access to doBusyTask, the busy state, and any current errors
 * @deprecated This is not our preferred pattern, and should only be used to help aid in a migration to our preferred data access building blocks (loaders, useMutation, useQuery, useForm, etc.)
 */
export function useLegacyDoBusyTask(): LegacyDoBusyTaskReturnValue {
    const { clearErrors, setOctopusError } = useErrorActions();
    const [busies, setBusies] = useState<Array<Promise<void>>>([]);
    const doBusyTask = useCallback<DoBusyTask>(async (action, options = {}) => {
        // eslint-disable-next-line @typescript-eslint/init-declarations
        let busy: Promise<void>;
        try {
            // Sometimes child components will load some lookup data while a parent component
            // is displaying an error. The child uses the parent's doBusyTask so that the busy
            // indicator and errors display correctly. But we shouldn't clear existing errors
            // from that child load.
            if (!options.preserveCurrentErrors) {
                clearErrors();
            }
            busy = options.timeOperationOptions ? timeOperation(options.timeOperationOptions, action) : action();
            setBusies((prev) => [busy, ...prev]);
            await busy;
            // There were no errors in this case.
            if (options.onSuccess) {
                options.onSuccess();
            }
            return true;
        }
        catch (e) {
            if (e instanceof OctopusError) {
                setOctopusError(e);
                if (options.onError) {
                    options.onError(createErrorsFromOctopusError(e));
                }
                return false;
            }
            if (e instanceof PromiseCancelledError) {
                // swallow it, no point bubbling this up any further since we intentionally cancelled the promise
                return false;
            }
            throw e;
        }
        finally {
            setBusies((prev) => prev.filter((b) => b !== busy));
        }
    }, [clearErrors, setOctopusError]);
    const isInProgress = busies.length > 0;
    const currentError = useCurrentError();
    const errors = useMemo(() => {
        if (currentError === null) {
            return [];
        }
        return [currentError];
    }, [currentError]);
    return {
        doBusyTask,
        status: {
            isInProgress,
            errors,
        },
    };
}
