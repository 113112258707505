import { css } from "@emotion/css";
import { Button, CircularProgress, Divider } from "@octopusdeploy/design-system-components";
import { fontSize, space, text, themeTokens } from "@octopusdeploy/design-system-tokens";
import type { GitHubAppInstallation, GitHubRepository } from "@octopusdeploy/octopus-server-client";
import React, { useRef, useState } from "react";
import { repository } from "~/clientInstance";
import type { DoBusyTask, Errors } from "~/components/DataBaseComponent";
import DataBaseComponent, { useDoBusyTaskEffect } from "~/components/DataBaseComponent";
import ExternalLink from "~/components/Navigation/ExternalLink";
import GitHubAppRepositoryList from "./GitHubAppRepositoryList";
export interface GitHubAppNewRepositorySelectorInternalProps extends GitHubAppNewRepositorySelectorProps {
    doBusyTask: DoBusyTask;
    busy?: Promise<unknown> | boolean;
    errors?: Errors;
}
export function GitHubAppNewRepositorySelectorInternal({ installation, doBusyTask, busy, errors, selectedRepositories, setRepositorySelected, startingSelectedRepositories }: GitHubAppNewRepositorySelectorInternalProps) {
    const [page, setPage] = useState<number>(0);
    const [totalRepositories, setTotalRepositories] = useState<number | undefined>(undefined);
    const [hasMoreRepositories, setHasMoreRepositories] = useState<boolean | undefined>(undefined);
    const [repositories, setRepositories] = useState<GitHubRepository[] | undefined>(undefined);
    const installationUrl = useRef(repository.GitHubApp.getInstallationUrl(window.location.href));
    const pageSize = 50;
    const styles = {
        configureGitHubAppInstructions: css({
            marginLeft: space[16],
            marginTop: space[16],
            marginBottom: space[16],
        }),
        loading: css({
            display: "flex",
            justifyContent: "center",
            padding: space[16],
        }),
        showMoreConatiner: css({
            marginTop: space[16],
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
        }),
        showMoreLabel: css({
            margin: "0",
            font: text.regular.default.medium,
            fontSize: fontSize["small"],
            color: themeTokens.color.text.secondary,
        }),
    };
    useDoBusyTaskEffect(doBusyTask, async () => {
        await loadMoreRepositories();
    }, []);
    async function loadMoreRepositories() {
        await doBusyTask(async () => {
            const newPage = page + 1;
            const moreRepositories = await repository.GitHubApp.getRepositories(installation.InstallationId, (newPage - 1) * pageSize, pageSize);
            setRepositories((existing) => {
                if (existing) {
                    return [...existing, ...moreRepositories.Repositories];
                }
                return moreRepositories.Repositories;
            });
            setTotalRepositories(moreRepositories.TotalResults);
            setHasMoreRepositories(newPage * pageSize < moreRepositories.TotalResults);
            setPage(newPage);
        });
    }
    if (!repositories) {
        // If we haven't loaded the repositories yet, don't show anything
        return (<div className={styles.loading}>
                <CircularProgress size="large"/>
            </div>);
    }
    const processedRepositories = [];
    for (const r of repositories) {
        if (!r.IsAdmin) {
            processedRepositories.push({ ...r, disabled: true, disabledText: "You are not an administrator on this repository" });
        }
        else if (startingSelectedRepositories?.includes(r.RepositoryId)) {
            processedRepositories.push({ ...r, disabled: true, disabledText: "This repository is already selected" });
        }
        else {
            processedRepositories.push(r);
        }
    }
    return (<>
            <GitHubAppRepositoryList repositories={processedRepositories} selectedRepositories={selectedRepositories} setRepositorySelected={setRepositorySelected}/>
            <Divider />
            {hasMoreRepositories && (<div className={styles.showMoreConatiner}>
                    <LoadingOrLoadMore busy={busy} loadMore={loadMoreRepositories}/>
                    <p className={styles.showMoreLabel}>
                        Showing {repositories.length} of {totalRepositories} repositories
                    </p>
                </div>)}
            <p className={styles.configureGitHubAppInstructions}>
                If you cannot find your repository{" "}
                <ExternalLink openInSelf href={installationUrl.current}>
                    configure repository access in the Octopus Deploy app on GitHub.
                </ExternalLink>
            </p>
        </>);
}
export interface GitHubAppNewRepositorySelectorProps {
    installation: GitHubAppInstallation;
    startingSelectedRepositories?: string[];
    selectedRepositories: string[];
    setRepositorySelected: (selected: boolean, repositoryId: string) => void;
}
export class GitHubAppNewRepositorySelector extends DataBaseComponent<GitHubAppNewRepositorySelectorProps> {
    constructor(props: GitHubAppNewRepositorySelectorProps) {
        super(props);
        this.state = {};
    }
    componentDidMount() {
        this.clearErrors();
    }
    render() {
        return <GitHubAppNewRepositorySelectorInternal {...this.props} doBusyTask={this.doBusyTask} busy={this.state.busy} errors={this.errors}/>;
    }
    static displayName = "GitHubAppNewRepositorySelector";
}
interface LoadingOrLoadMoreProps {
    busy?: Promise<unknown> | boolean;
    loadMore: () => Promise<void>;
}
function LoadingOrLoadMore({ busy, loadMore }: LoadingOrLoadMoreProps) {
    if (!!busy) {
        return (<div>
                <CircularProgress size="small"/>
            </div>);
    }
    return (<div>
            <Button label="Load more" importance="secondary" onClick={loadMore}/>
        </div>);
}
