/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { RadioButtonGroup, RadioButton } from "@octopusdeploy/design-system-components";
import type { HelmChartUpgradeProperties } from "@octopusdeploy/legacy-action-properties";
import type { PackageReference } from "@octopusdeploy/octopus-server-client";
import { GetPrimaryPackageReferenceOfT, ActionExecutionLocation, FeedType, GetNamedPackageReferences, InitialisePrimaryGitDependency, InitialisePrimaryPackageReference, PackageAcquisitionLocation, RemovePrimaryGitDependency, RemovePrimaryPackageReference, SetPrimaryPackageReference, } from "@octopusdeploy/octopus-server-client";
import { noOp } from "@octopusdeploy/utilities";
import { flatten, isEqual } from "lodash";
import * as React from "react";
import { useOptionalProcessContext } from "~/areas/projects/components/Process/Contexts/ProcessContext";
import { useGitCredentialsFromContext, useRefreshGitCredentialsFromContext } from "~/areas/projects/components/Process/Contexts/ProcessGitCredentialsContextProvider";
import { TargetRoles } from "~/areas/projects/components/Process/types";
import { buildRelatedTriggerDependencies } from "~/areas/projects/components/Triggers/ProcessCallouts/triggerPackageRelations";
import { useOptionalProjectContext } from "~/areas/projects/context";
import { ClearHelmExePackage, GetHelmExePackageReference, IsHelmExePackageReference } from "~/components/Actions/helmChartUpgrade/helmPackageHelpers";
import { HelmTemplateValuesSection } from "~/components/Actions/helmChartUpgrade/helmTemplateValuesSection";
import { HelmTemplateValuesSectionV2 } from "~/components/Actions/helmChartUpgrade/helmTemplateValuesSectionV2";
import { useImprovedHelmTemplateValuesUI } from "~/components/Actions/helmChartUpgrade/useImprovedHelmTemplateValuesUI";
import { kubernetesMixedExecutionLocationConfig } from "~/components/Actions/kubernetes/kubernetesMixedExecutionLocationConfig";
import { BaseComponent } from "~/components/BaseComponent/BaseComponent";
import { WarningChip } from "~/components/Chips/index";
import { isFeatureToggleEnabled } from "~/components/FeatureToggle/New/FeatureToggleContext";
import { GitRepositorySourceSelector, isProjectRepositoryGitSourceSupported } from "~/components/GitRepositorySource/GitRepositorySourceSelector";
import DeferredPackageSelector from "~/components/PackageSelector/DeferredPackageSelector";
import PackageSelector from "~/components/PackageSelector/PackageSelector";
import ExpanderSectionHeading from "~/components/form/Sections/FormSectionHeading";
import { codeEditorVariablesList } from "~/utils/ScriptIntellisense/scriptIntellisense";
import { useFeedsFromContext, useRefreshFeedsFromContext } from "../../../areas/projects/components/Process/Contexts/ProcessFeedsContextProvider";
import StringCheckbox from "../../../primitiveComponents/form/Checkbox/StringCheckbox";
import { DebounceText } from "../../../primitiveComponents/form/Text/Text";
import CommonSummaryHelper from "../../../utils/CommonSummaryHelper/CommonSummaryHelper";
import ExternalLink from "../../Navigation/ExternalLink/ExternalLink";
import { Note, Summary } from "../../form";
import ExpandableFormSection, { CardFill } from "../../form/Sections/ExpandableFormSection";
import { VariableLookupText } from "../../form/VariableLookupText";
import type { ActionSummaryProps } from "../actionSummaryProps";
import type { ActionWithDirtyState, ActionWithFeeds, ActionWithGitRepositorySource } from "../commonActionHelpers";
import { getChangesToPackageReference } from "../getChangesToPackageReference";
import { getKubernetesTargetDiscoveryCloudProviders } from "../kubernetes/getKubernetesTargetDiscoveryCloudProviders";
import { DockerReferenceListFormSection } from "../packageReferences";
import type { ActionEditProps } from "../pluginRegistry";
import pluginRegistry from "../pluginRegistry";
import { useScriptActionContext } from "../script/ScriptActionContext";
import type { WithScriptActionContextInjectedProps } from "../script/withScriptActionContext";
import type { PackagedHelmValuesProperties, PackagedHelmValuesReference } from "./PackagedHelmValuesDialog";
class HelmChartUpgradeActionSummary extends BaseComponent<ActionSummaryProps, never> {
    constructor(props: ActionSummaryProps) {
        super(props);
    }
    render() {
        return <div>Upgrade a Helm chart</div>;
    }
    static displayName = "HelmChartUpgradeActionSummary";
}
enum HelmClientVersion {
    V3 = "V3",
    V2 = "V2"
}
enum HelmLocation {
    Default = "default",
    Local = "local",
    Package = "package"
}
interface HelmChartUpgradeActionEditState {
    customHelmExecutable: HelmLocation;
    variableServerNames: string[];
}
type HelmChartUpgradeActionEditProps = ActionEditProps<HelmChartUpgradeProperties, PackagedHelmValuesProperties>;
type HelmChartUpgradeActionEditInternalProps = HelmChartUpgradeActionEditProps & ActionWithFeeds & WithScriptActionContextInjectedProps & ActionWithGitRepositorySource & ActionWithDirtyState & {
    useImprovedTemplateValuesUI: boolean;
};
class HelmChartUpgradeActionEditInternal extends BaseComponent<HelmChartUpgradeActionEditInternalProps, HelmChartUpgradeActionEditState> {
    constructor(props: HelmChartUpgradeActionEditInternalProps) {
        super(props);
        this.state = {
            customHelmExecutable: HelmLocation.Default,
            variableServerNames: [],
        };
    }
    async componentDidMount() {
        if (this.getChartSource() === "Package") {
            this.props.setProperties({ "Octopus.Action.Script.ScriptSource": "Package" });
            this.props.setPackages(InitialisePrimaryPackageReference(this.props.packages, this.props.feeds), true);
        }
        await this.props.doBusyTask(async () => {
            if (GetHelmExePackageReference(this.props.packages)) {
                this.setState({ customHelmExecutable: HelmLocation.Package });
            }
            else if (this.props.properties["Octopus.Action.Helm.CustomHelmExecutable"]) {
                this.setState({ customHelmExecutable: HelmLocation.Local });
            }
            this.setState({ variableServerNames: (await this.props.scriptActionContext?.loadVariables()) ?? [] });
        });
        if (this.props.properties["Octopus.Action.Helm.ResetValues"] === undefined) {
            this.props.setProperties({ ["Octopus.Action.Helm.ResetValues"]: "True" }, true);
        }
        if (this.props.properties["Octopus.Action.Helm.ClientVersion"] === HelmClientVersion.V2 && isFeatureToggleEnabled("PreventHelmV2DeploymentsFeatureToggle")) {
            this.props.setProperties({ "Octopus.Action.Helm.ClientVersion": HelmClientVersion.V3, "Octopus.Action.Helm.WasAutoUpdatedToV3": true });
        }
        else if (this.props.properties["Octopus.Action.Helm.ClientVersion"] === undefined || isFeatureToggleEnabled("PreventHelmV2DeploymentsFeatureToggle")) {
            this.props.setProperties({ "Octopus.Action.Helm.ClientVersion": HelmClientVersion.V3 });
        }
    }
    getChartSource() {
        return this.props.properties["Octopus.Action.Script.ScriptSource"] ?? "Package";
    }
    render() {
        const localNames = (this.props.localNames || []).concat(this.packageVariableNames());
        const pkg = GetPrimaryPackageReferenceOfT(this.props.packages) || { Properties: {}, Name: undefined };
        const helmExepkg = GetHelmExePackageReference(this.props.packages)!;
        const yamlAutocompleteResults = codeEditorVariablesList(this.state.variableServerNames, localNames ?? []);
        const chartSource = this.getChartSource();
        const relatedTriggersDependencies = buildRelatedTriggerDependencies(this.props.inputDependencies);
        return (<div>
                <ExpandableFormSection errorKey="Octopus.Action.Script.ScriptSource" isExpandedByDefault={this.props.expandedByDefault} title="Chart Source" fillCardWidth={CardFill.FillRight} summary={this.chartSourceSummary()} help={"Select the source of the chart."}>
                    <Note>Charts can be contained in a package or a Git repository.</Note>
                    <RadioButtonGroup value={chartSource} onChange={(val) => this.onChangeChartSource(val)} error={this.props.getFieldError("Octopus.Action.Script.ScriptSource")}>
                        <RadioButton value={"Package"} label="Package"/>
                        <RadioButton value={"GitRepository"} label="Git repository"/>
                    </RadioButtonGroup>
                </ExpandableFormSection>
                {chartSource === "Package" && (<ExpandableFormSection errorKey="package" isExpandedByDefault={this.props.expandedByDefault} title="Chart Package" summary={CommonSummaryHelper.deferredPackageSummary(pkg, this.props.feeds)} help={<span>Choose the package containing the chart you want to deploy</span>}>
                        <DeferredPackageSelector packageId={pkg.PackageId} feedId={pkg.FeedId} packageVersion={pkg?.Version} onPackageIdChange={(packageId) => this.props.setPackages(SetPrimaryPackageReference({ PackageId: packageId }, this.props.packages))} onFeedIdChange={(feedId) => this.props.setPackages(SetPrimaryPackageReference({ FeedId: feedId }, this.props.packages))} onPackageVersionChange={(version) => this.props.setPackages(SetPrimaryPackageReference({ Version: version }, this.props.packages))} packageIdError={this.props.getFieldError("Octopus.Action.Package.PackageId")} feedIdError={this.props.getFieldError("Octopus.Action.Package.FeedId")} projectId={this.props.projectId} feeds={this.props.feeds} localNames={this.props.localNames} feedType={[FeedType.Helm, FeedType.OciRegistry, FeedType.BuiltIn, FeedType.Maven, FeedType.ArtifactoryGeneric]} refreshFeeds={this.loadFeeds} parameters={this.props.parameters} packageSelectionMode={pkg.Properties["SelectionMode"]!} packageSelectionModeError={this.props.getFieldError("SelectionMode")} onPackageSelectionModeChange={(value) => this.props.setPackages(SetPrimaryPackageReference(getChangesToPackageReference(value), this.props.packages))} packageParameterName={pkg.Properties["PackageParameterName"]!} packageParameterError={this.props.getFieldError("PackageParameterName")} onPackageParameterChange={(packageParameter) => this.props.setPackages(SetPrimaryPackageReference({ Properties: { ...pkg.Properties, PackageParameterName: packageParameter } }, this.props.packages))} relatedTriggersDependencies={relatedTriggersDependencies} modelDirty={this.props.modelDirty}/>
                    </ExpandableFormSection>)}
                {chartSource === "GitRepository" && (<GitRepositorySourceSelector properties={this.props.properties} gitCredentials={this.props.gitCredentials} gitDependencies={this.props.gitDependencies} expandedByDefault={this.props.expandedByDefault} getFieldError={this.props.getFieldError} setProperties={this.props.setProperties} setGitDependencies={this.props.setGitDependencies} refreshGitCredentials={this.props.refreshGitCredentials} localNames={this.props.localNames} processType={this.props.processType} project={this.props.project} relatedTriggerDependencies={buildRelatedTriggerDependencies(this.props.inputDependencies)} showAvailableTriggerCallout={!this.props.isNew}/>)}
                <ExpandableFormSection errorKey="ChartDirectory" isExpandedByDefault={this.props.expandedByDefault} title="Chart Directory" summary={this.chartDirectorySummary()} help={`Optionally specify the directory inside the ${chartSource === "Package" ? "package" : "Git repository"} where the chart is located`}>
                    <VariableLookupText localNames={this.props.localNames} value={this.props.properties["Octopus.Action.Helm.ChartDirectory"]} onChange={(x) => this.props.setProperties({ ["Octopus.Action.Helm.ChartDirectory"]: x })} label="Chart Directory"/>
                </ExpandableFormSection>
                <ExpanderSectionHeading title="Upgrade Options"/>
                <ExpandableFormSection errorKey="ReleaseName" isExpandedByDefault={this.props.expandedByDefault} title="Kubernetes Release" summary={this.releaseNameSummary()} help="if a release by this name doesn't already exist, run an install">
                    <VariableLookupText localNames={this.props.localNames} value={this.props.properties["Octopus.Action.Helm.ReleaseName"]} placeholder="#{Octopus.Action.Name | ToLower}-#{Octopus.Environment.Name | ToLower}" onChange={(x) => this.props.setProperties({ ["Octopus.Action.Helm.ReleaseName"]: x })} label="Kubernetes Release Name"/>
                    <Note>
                        Due to Helm limitations, the release name must be unique across a cluster as the name is shared across namespace boundaries.
                        <br />
                        The Octopus variable syntax is supported, however the final release name must consist of only lower case alphanumeric and dash characters.
                    </Note>
                </ExpandableFormSection>
                <ExpandableFormSection title="Namespace" summary={this.namespaceSummary()} errorKey="Namespace" help="Specify the namespace the chart will be installed into">
                    <VariableLookupText label="Namespace" value={this.props.properties["Octopus.Action.Helm.Namespace"]} onChange={(x) => this.props.setProperties({ ["Octopus.Action.Helm.Namespace"]: x })}/>
                    <Note>
                        By default the chart will be installed into the namespace from the Kubernetes deployment target. If set, this value will override the target namespace and be passed as the <code>--namespace</code> option to the Helm client.
                    </Note>
                    <Note>Setting this option only works for the initial installation of a Helm release. Subsequent deploys attempt to upgrade the release, and will fail if the namespace does not match.</Note>
                </ExpandableFormSection>
                <ExpandableFormSection errorKey="ResetValues" title="Reset Values" summary={this.resetSummary()} help="When upgrading, reset the values to the ones built into the chart with those provided in the current deployment">
                    <StringCheckbox value={this.props.properties["Octopus.Action.Helm.ResetValues"]} onChange={(x) => this.props.setProperties({ ["Octopus.Action.Helm.ResetValues"]: x })} label="Reset Values" note={<span>
                                Corresponds to <code>--reset-values</code> Helm argument
                            </span>}/>
                </ExpandableFormSection>
                <ExpandableFormSection errorKey="Timeout" title="Timeout" summary={this.summaryTimeout()} help={<span>Duration to wait for any individual Kubernetes operation (like Jobs for hooks)</span>}>
                    <VariableLookupText localNames={this.props.localNames} value={this.props.properties["Octopus.Action.Helm.Timeout"]} onChange={(x) => this.props.setProperties({ ["Octopus.Action.Helm.Timeout"]: x })} label="Timeout"/>
                    {isFeatureToggleEnabled("PreventHelmV2DeploymentsFeatureToggle") ? (<Note>
                            By default Helm uses a default timeout of <code>300 seconds</code>. When specifying the timeout, Helm expects a duration like <code>300s</code>.
                        </Note>) : (<Note>
                            By default Helm uses a default timeout of <code>300 seconds</code>. Helm 2 expects an integer like <code>300</code> and Helm 3 expects a duration like <code>300s</code>.
                        </Note>)}
                </ExpandableFormSection>

                {this.props.useImprovedTemplateValuesUI && (<HelmTemplateValuesSectionV2 properties={this.props.properties} setProperties={this.props.setProperties} packages={this.props.packages} setPackages={this.props.setPackages} yamlAutocompleteResults={yamlAutocompleteResults} runOn={this.props.runOn} feeds={this.props.feeds} projectId={this.props.projectId} localNames={localNames} gitRef={this.props.gitRef} gitDependencies={this.props.gitDependencies || []} setGitDependencies={this.props.setGitDependencies || noOp}/>)}

                <ExpandableFormSection errorKey="Octopus.Action.Helm.AdditionalArgs" title="Additional Arguments" summary={this.summaryAdditionalArguments()} help={<span>
                            Provide additional arguments that will be passed to the <code>helm upgrade</code> command.
                        </span>}>
                    <VariableLookupText localNames={this.props.localNames} label="Additional arguments" multiline={true} value={this.props.properties["Octopus.Action.Helm.AdditionalArgs"]} onChange={(val) => this.props.setProperties({ ["Octopus.Action.Helm.AdditionalArgs"]: val })}/>
                    <Note>
                        A complete list of the additional arguments which can be supplied can be found in the <ExternalLink href="HelmUpgradeOptions">Helm upgrade documentation</ExternalLink>.
                    </Note>
                    <Note>
                        E.g: <code>--recreate-pods</code>
                    </Note>
                </ExpandableFormSection>
                {!this.props.useImprovedTemplateValuesUI && (<HelmTemplateValuesSection chartSource={chartSource} properties={this.props.properties} setProperties={this.props.setProperties} primaryPackageReference={pkg} packages={this.props.packages} setPackages={this.props.setPackages} yamlAutocompleteResults={yamlAutocompleteResults} runOn={this.props.runOn} feeds={this.props.feeds} loadFeeds={this.loadFeeds} projectId={this.props.projectId} localNames={localNames} gitRef={this.props.gitRef}/>)}
                <ExpanderSectionHeading title="Connection"/>
                {!isFeatureToggleEnabled("PreventHelmV2DeploymentsFeatureToggle") && (<>
                        <ExpandableFormSection errorKey="Octopus.Action.Helm.ClientVersion" title="Helm Client Version" summary={this.summaryHelmClientVersion()} help={"Select the Helm client version"}>
                            <Note>Please ensure this matches the version of the Helm tool below.</Note>
                            <RadioButtonGroup value={this.props.properties["Octopus.Action.Helm.ClientVersion"]} onChange={(val) => this.props.setProperties({ "Octopus.Action.Helm.ClientVersion": val as HelmClientVersion })}>
                                <RadioButton value={HelmClientVersion.V2} label={<span>
                                            V2 <WarningChip title="Deprecated" description={"Helm V2 is deprecated and will be removed in Octopus Server 2024.3."}/>
                                        </span>}/>
                                <RadioButton value={HelmClientVersion.V3} label={"V3"}/>
                            </RadioButtonGroup>
                        </ExpandableFormSection>
                    </>)}
                <ExpandableFormSection errorKey="HelmExePackage" title="Helm Client Tool" summary={this.summaryCustomHelmExePackage(helmExepkg)} help={<span>Select the Helm client tool to invoke</span>}>
                    {!isFeatureToggleEnabled("PreventHelmV2DeploymentsFeatureToggle") && this.props.properties["Octopus.Action.Helm.ClientVersion"] === HelmClientVersion.V2 && (<Note>Helm requires that the client tool be the same minor version as the Tiller service installed in the kubernetes cluster.</Note>)}
                    <RadioButtonGroup value={this.state.customHelmExecutable} onChange={(val) => {
                this.setState({ customHelmExecutable: val as HelmLocation }, () => {
                    if (val !== HelmLocation.Package) {
                        this.props.setPackages(ClearHelmExePackage(this.props.packages));
                    }
                    if (val === HelmLocation.Default) {
                        this.props.setProperties({ ["Octopus.Action.Helm.CustomHelmExecutable"]: null! });
                    }
                });
            }}>
                        <RadioButton value={HelmLocation.Default} label={<span>
                                    Invoke <code>helm</code> command
                                </span>}/>
                        <Note>
                            The Helm client will be available on the worker as the <code>helm</code> command.
                        </Note>
                        <RadioButton value={HelmLocation.Local} label="Helm client tool available from another path"/>
                        <Note>The Helm client is available via a specific path.</Note>
                        {this.state.customHelmExecutable === HelmLocation.Local && (<div>
                                <DebounceText label="Helm executable location" value={this.props.properties["Octopus.Action.Helm.CustomHelmExecutable"]} onChange={(x) => this.props.setProperties({ ["Octopus.Action.Helm.CustomHelmExecutable"]: x })}/>
                                <Note>The path to the Helm executable.</Note>
                            </div>)}
                        <RadioButton value={HelmLocation.Package} label="Custom packaged Helm client tool"/>
                        <Note>
                            Upload a custom Helm client to use during deployment. See our <ExternalLink href="HelmClient">documentation</ExternalLink> for more information on client requirements.
                        </Note>
                        {this.state.customHelmExecutable === HelmLocation.Package && (<PackageSelector packageId={helmExepkg?.PackageId} feedId={helmExepkg?.FeedId} packageVersion={helmExepkg?.Version} onPackageIdChange={(packageId) => this.props.setPackages(SetHelmExePackage({ PackageId: packageId }, this.props.packages))} onFeedIdChange={(feedId) => this.props.setPackages(SetHelmExePackage({ FeedId: feedId }, this.props.packages))} onPackageVersionChange={(version) => this.props.setPackages(SetHelmExePackage({ Version: version }, this.props.packages))} projectId={this.props.projectId} feeds={this.props.feeds} localNames={this.props.localNames} feedType={[FeedType.Nuget, FeedType.BuiltIn, FeedType.ArtifactoryGeneric]} refreshFeeds={this.loadFeeds}/>)}
                        {this.state.customHelmExecutable === HelmLocation.Package && (<div>
                                <DebounceText label="Helm executable location" value={this.props.properties["Octopus.Action.Helm.CustomHelmExecutable"]} onChange={(x) => this.props.setProperties({ ["Octopus.Action.Helm.CustomHelmExecutable"]: x })}/>
                                <Note>The path to the Helm executable. This can be a path relative to the package root.</Note>
                            </div>)}
                    </RadioButtonGroup>
                </ExpandableFormSection>
                {!isFeatureToggleEnabled("PreventHelmV2DeploymentsFeatureToggle") && this.props.properties["Octopus.Action.Helm.ClientVersion"] === HelmClientVersion.V2 && (<React.Fragment>
                        <ExpandableFormSection errorKey="TillerNamespace" title="Tiller Namespace" summary={this.summaryTillerNamespace()} help={<span>Namespace of Tiller</span>}>
                            <VariableLookupText localNames={this.props.localNames} value={this.props.properties["Octopus.Action.Helm.TillerNamespace"]} onChange={(x) => this.props.setProperties({ ["Octopus.Action.Helm.TillerNamespace"]: x })} label="Tiller Namespace"/>
                            <Note>
                                By default Helms looks for Tiller in the <code>kube-system</code> namespace.
                            </Note>
                        </ExpandableFormSection>

                        <ExpandableFormSection errorKey="TillerConnectionTimeout" title="Connection Timeout" summary={this.summaryTillerTimeout()} help={<span>Duration (in seconds) Helm will wait to establish a connection to tiller</span>}>
                            <VariableLookupText localNames={this.props.localNames} value={this.props.properties["Octopus.Action.Helm.TillerTimeout"]} onChange={(x) => this.props.setProperties({ ["Octopus.Action.Helm.TillerTimeout"]: x })} label="Tiller Connection Timeout"/>
                            <Note>
                                By default Helms uses a default timeout of <code>300 seconds</code>.
                            </Note>
                        </ExpandableFormSection>
                    </React.Fragment>)}
                <DockerReferenceListFormSection projectId={this.props.projectId} gitRef={this.props.gitRef} packages={this.props.packages} plugin={this.props.plugin} setPackages={this.props.setPackages} doBusyTask={this.props.doBusyTask} busy={this.props.busy} getFieldError={this.props.getFieldError} errors={this.props.errors} expandedByDefault={this.props.expandedByDefault} feeds={this.props.feeds} refreshFeeds={this.props.refreshFeeds} setProperties={this.props.setProperties} properties={this.props.properties} parameters={this.props.parameters} relatedTriggersDependencies={relatedTriggersDependencies} modelDirty={this.props.modelDirty}/>
            </div>);
    }
    chartSourceSummary() {
        const chartSource = this.getChartSource();
        if (chartSource === "Package") {
            return Summary.summary("Chart inside a package");
        }
        else if (chartSource === "GitRepository") {
            return Summary.summary("Chart inside a Git repository");
        }
        return Summary.placeholder("Chart source not specified");
    }
    onChangeChartSource(value: string) {
        this.props.setProperties({
            ["Octopus.Action.Script.ScriptSource"]: value,
        });
        if (value === "Package") {
            this.props.setPackages(InitialisePrimaryPackageReference(this.props.packages, this.props.feeds));
        }
        else {
            this.props.setPackages(RemovePrimaryPackageReference(this.props.packages));
        }
        if (value === "GitRepository") {
            if (isProjectRepositoryGitSourceSupported(this.props.project, this.props.processType)) {
                this.props.setProperties({ ["Octopus.Action.GitRepository.Source"]: "Project" });
                this.props.setGitDependencies?.(RemovePrimaryGitDependency(this.props.gitDependencies), false);
            }
            else {
                this.props.setProperties({ ["Octopus.Action.GitRepository.Source"]: "External" });
                this.props.setGitDependencies?.(InitialisePrimaryGitDependency(this.props.gitDependencies), false);
            }
        }
        else {
            this.props.setProperties({ ["Octopus.Action.GitRepository.Source"]: "" });
            this.props.setGitDependencies?.(RemovePrimaryGitDependency(this.props.gitDependencies), false);
        }
    }
    packageVariableNames = (): string[] => {
        return flatten(GetNamedPackageReferences(this.props.packages).map((pkg) => [
            `Octopus.Action.Package[${pkg.Name}].PackageId`,
            `Octopus.Action.Package[${pkg.Name}].FeedId`,
            `Octopus.Action.Package[${pkg.Name}].PackageVersion`,
            `Octopus.Action.Package[${pkg.Name}].Path`,
        ]));
    };
    summaryHelmClientVersion = () => {
        const version = this.props.properties["Octopus.Action.Helm.ClientVersion"];
        if (version) {
            switch (version) {
                case HelmClientVersion.V2:
                    return Summary.summary("V2");
                case HelmClientVersion.V3:
                    return Summary.summary("V3");
            }
        }
        else {
            return Summary.placeholder("No Helm Client version has been supplied");
        }
    };
    summaryTillerNamespace = () => {
        const tillerNamespace = this.props.properties["Octopus.Action.Helm.TillerNamespace"];
        if (tillerNamespace) {
            return Summary.summary(tillerNamespace);
        }
        else {
            return Summary.placeholder("No Tiller namespace override supplied");
        }
    };
    summaryTimeout = () => {
        const timeout = this.props.properties["Octopus.Action.Helm.Timeout"];
        if (timeout) {
            return Summary.summary(<span>{timeout} second(s)</span>);
        }
        else {
            return Summary.placeholder("No timeout override supplied");
        }
    };
    summaryAdditionalArguments = () => {
        const additionalArgs = this.props.properties["Octopus.Action.Helm.AdditionalArgs"];
        if (additionalArgs) {
            return Summary.summary(<span>{additionalArgs}</span>);
        }
        else {
            return Summary.placeholder("No additional arguments");
        }
    };
    summaryTillerTimeout = () => {
        const tillerTimeout = this.props.properties["Octopus.Action.Helm.TillerTimeout"];
        if (tillerTimeout) {
            return Summary.summary(<span>{tillerTimeout} second(s)</span>);
        }
        else {
            return Summary.placeholder("No timeout override supplied");
        }
    };
    summaryCustomHelmExePackage = (pkg: PackagedHelmValuesReference) => {
        const helmPkg = GetHelmExePackageReference(this.props.packages);
        const helmPath = this.props.properties["Octopus.Action.Helm.CustomHelmExecutable"];
        if (helmPkg) {
            return Summary.summary(<span>
                    Helm client available at <strong>{helmPath}</strong> will be invoked from inside package <strong>{helmPkg.PackageId}</strong>
                </span>);
        }
        else if (helmPath) {
            return Summary.summary(<span>
                    Helm client available at <strong>{helmPath}</strong> will be invoked
                </span>);
        }
        else {
            return Summary.default(<span>
                    Default Helm client available via <code>helm</code> path will be invoked
                </span>);
        }
    };
    private releaseNameSummary = () => {
        const releaseName = this.props.properties["Octopus.Action.Helm.ReleaseName"];
        if (this.props.properties["Octopus.Action.Helm.ReleaseName"]) {
            return Summary.summary(<span>
                    The release name will be <strong>{releaseName}</strong>
                </span>);
        }
        return Summary.default(<span>
                The default release name is{" "}
                <strong>
                    #{`{Octopus.Action.Name}`}-#{`{Octopus.Environment}`}
                </strong>
            </span>);
    };
    private chartDirectorySummary = () => {
        if (this.props.properties["Octopus.Action.Helm.ChartDirectory"]) {
            const chartDirectory = this.props.properties["Octopus.Action.Helm.ChartDirectory"];
            return Summary.summary(<span>
                    The chart is located in <strong>{chartDirectory}</strong>
                </span>);
        }
        return Summary.placeholder("No chart directory specified");
    };
    private resetSummary = () => {
        if (this.props.properties["Octopus.Action.Helm.ResetValues"] === "True") {
            return Summary.default(<span>
                    Previous deployment variables <strong>will</strong> be replaced
                </span>);
        }
        return Summary.summary(<span>
                Previous deployment variables <strong>will not</strong> be replaced if no new values are provided
            </span>);
    };
    private namespaceSummary = () => {
        const namespace = this.props.properties["Octopus.Action.Helm.Namespace"];
        if (!!namespace) {
            return Summary.summary(<span>
                    The chart will be installed into the namespace <strong>{namespace}</strong>
                </span>);
        }
        return Summary.default(<span>The chart will be installed into the namespace specified on the Kubernetes deployment target, or the default namespace if none is specified on the target.</span>);
    };
    private loadFeeds = async () => {
        await this.props.refreshFeeds();
    };
    static displayName = "HelmChartUpgradeActionEditInternal";
}
function SetHelmExePackage(updated: Partial<PackageReference<PackagedHelmValuesProperties>>, packages: Array<PackageReference<PackagedHelmValuesProperties>>): Array<PackageReference<PackagedHelmValuesProperties>> {
    const helmExePkg = {
        FeedId: null!,
        PackageId: null!,
        AcquisitionLocation: PackageAcquisitionLocation.ExecutionTarget,
        Properties: { PerformVariableReplace: "False", Extract: "True" } as unknown as PackagedHelmValuesProperties, // This cast doesn't look right - what should this actually be?
        ...GetHelmExePackageReference(packages),
        ...updated,
        Name: "HelmExe",
    } as PackageReference<PackagedHelmValuesProperties>;
    const newPackages = packages.map((pkg) => {
        if (!IsHelmExePackageReference(pkg)) {
            return pkg;
        }
        return helmExePkg;
    });
    if (!newPackages.includes(GetHelmExePackageReference(newPackages)!)) {
        newPackages.push(helmExePkg);
    }
    return newPackages;
}
export function HelmChartUpgradeActionEdit(props: React.PropsWithChildren<HelmChartUpgradeActionEditProps>) {
    const feeds = useFeedsFromContext();
    const refreshFeeds = useRefreshFeedsFromContext();
    const scriptActionContext = useScriptActionContext();
    const gitCredentials = useGitCredentialsFromContext();
    const refreshGitCredentials = useRefreshGitCredentialsFromContext();
    const projectContext = useOptionalProjectContext();
    const processContext = useOptionalProcessContext();
    const useImprovedTemplateValues = useImprovedHelmTemplateValuesUI();
    return (<HelmChartUpgradeActionEditInternal {...props} feeds={feeds} refreshFeeds={refreshFeeds} scriptActionContext={scriptActionContext} project={projectContext?.state.model} processType={processContext?.selectors.getProcessType()} modelDirty={!isEqual(processContext?.state.model, processContext?.state.cleanModel)} gitCredentials={gitCredentials} refreshGitCredentials={refreshGitCredentials} useImprovedTemplateValuesUI={useImprovedTemplateValues}/>);
}
pluginRegistry.registerAction({
    executionLocation: ActionExecutionLocation.AlwaysOnServer,
    actionType: "Octopus.HelmChartUpgrade",
    summary: (properties, targetRolesAsCSV) => <HelmChartUpgradeActionSummary properties={properties} targetRolesAsCSV={targetRolesAsCSV}/>,
    canHaveChildren: () => true,
    canBeChild: true,
    editSections: {
        top: (props: HelmChartUpgradeActionEditProps) => <HelmChartUpgradeActionEdit {...props}/>,
        default: (props: HelmChartUpgradeActionEditProps) => <></>,
    },
    targetRoleOption: () => TargetRoles.Required,
    hasPackages: () => true,
    features: {
        optional: ["Octopus.Features.CustomScripts"],
    },
    targetDiscoveryCloudConnectionProviders: getKubernetesTargetDiscoveryCloudProviders,
    mixedExecutionLocations: kubernetesMixedExecutionLocationConfig,
    disableInlineExecutionContainers: true,
    docsLink: "deployHelmChart",
});
