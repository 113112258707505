import { css } from "@emotion/css";
import { space } from "@octopusdeploy/design-system-tokens";
import React from "react";
import { useProjectContext } from "../../context";
import { ProjectCreatePullRequestLink } from "../ProjectCurrentBranchDisplay/ProjectCreatePullRequestLink";
import { BranchSelector } from "./BranchSelector";
import type { BranchSelectorVNextProps } from "./BranchSelectorVNext";
import { BranchSelectorVNext } from "./BranchSelectorVNext";
export function PageHeaderBranchSelector() {
    const projectContext = useProjectContext();
    return (<div className={branchSelectorContainer}>
            <BranchSelector />
            <ProjectCreatePullRequestLink project={projectContext.state.model} gitRef={projectContext.state.gitRef?.CanonicalName}/>
        </div>);
}
export function PageHeaderBranchSelectorVNext(props: BranchSelectorVNextProps) {
    return (<div className={branchSelectorContainer}>
            <BranchSelectorVNext {...props}/>
            <ProjectCreatePullRequestLink project={props.project} gitRef={props.gitRef}/>
        </div>);
}
const branchSelectorContainer = css({
    display: "flex",
    gap: space["16"],
    alignItems: "center",
});
