/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { css } from "@emotion/css";
import { fontWeight, space } from "@octopusdeploy/design-system-tokens";
import React, { useState } from "react";
import TransitionAnimation from "~/components/TransitionAnimation/TransitionAnimation";
import { ControlledTabsContainer, TabItem } from "~/primitiveComponents/navigation/Tabs/index";
export enum LogoTypeSelection {
    NotSet = "NotSet",
    Icon = "Icon",
    CustomImage = "CustomImage"
}
interface IconAndLogoEditLayoutProps {
    iconEditor: React.ReactElement;
    logoEditor: React.ReactElement;
    onTabChange?: (logoType: LogoTypeSelection) => void;
}
const IconAndLogoEditLayout = ({ iconEditor, logoEditor, onTabChange }: IconAndLogoEditLayoutProps) => {
    const [selectedTab, setSelectedTab] = useState<string>(LogoTypeSelection.Icon);
    const onTabSelect = (tab: string) => {
        setSelectedTab(tab);
        const selectedLogoType: LogoTypeSelection = LogoTypeSelection[tab as keyof typeof LogoTypeSelection];
        onTabChange?.(selectedLogoType);
    };
    return (<div className={styles.content}>
            <ControlledTabsContainer value={selectedTab} onChange={(tab) => onTabSelect(tab)}>
                <TabItem label="Icon" value={LogoTypeSelection.Icon} key={LogoTypeSelection.Icon}>
                    <TransitionAnimation> {iconEditor} </TransitionAnimation>{" "}
                </TabItem>
                <TabItem label="Custom Image" value={LogoTypeSelection.CustomImage} key={LogoTypeSelection.CustomImage}>
                    <TransitionAnimation> {logoEditor} </TransitionAnimation>{" "}
                </TabItem>
            </ControlledTabsContainer>
        </div>);
};
export default IconAndLogoEditLayout;
const styles = {
    content: css({
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        overflow: "visible",
        width: "348px",
        "@media (max-width: @screen-sm)": {
            width: "100%",
            gridAutoRows: "auto",
        },
        "& .MuiTabs-scroller.MuiTabs-scrollable": {
            "& .MuiTabs-indicator": {
                height: "3px",
            },
            "& .MuiButtonBase-root.MuiTab-root": {
                padding: `${space["12"]} ${space["24"]}`,
            },
            "& .MuiButtonBase-root.MuiTab-root .MuiTab-wrapper": {
                fontWeight: fontWeight["700"],
            },
        },
    }),
};
