import { logger } from "@octopusdeploy/logging";
import type { AccountResource, ActionTemplateSearchResource, BlueprintResource, FeedResource, GitCredentialResource, GitRef, IProcessResource, ModifyDeploymentProcessCommand, ProcessType, ProjectResource } from "@octopusdeploy/octopus-server-client";
import { HasRunbooksInGit, isBlueprintProcessResource, isDeploymentProcessResource, isRunbookProcessResource, Permission, Repository } from "@octopusdeploy/octopus-server-client";
import flatMap from "lodash/flatMap";
import uniq from "lodash/uniq";
import React, { useMemo } from "react";
import { getBlueprintIdFromAction } from "~/areas/projects/components/Process/Blueprints/blueprintId";
import { ProcessAccountsContextProvider } from "~/areas/projects/components/Process/Contexts/ProcessAccountsContextProvider";
import { ProcessBlueprintsContextProvider } from "~/areas/projects/components/Process/Contexts/ProcessBlueprintsContextProvider";
import { ProcessGitCredentialsContextProvider } from "~/areas/projects/components/Process/Contexts/ProcessGitCredentialsContextProvider";
import { useProjectContext } from "~/areas/projects/context";
import { usePersistenceSettingsContext } from "~/areas/projects/context/PersistenceSettingsContext";
import { repository } from "~/clientInstance";
import type { DoBusyTask, Errors } from "~/components/DataBaseComponent";
import { useDoBusyTaskEffect } from "~/components/DataBaseComponent";
import { DevToolsTab } from "~/components/DevTools/DevToolsContext";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import { useOctopusFeatureToggle } from "~/hooks/useOctopusFeatureToggle";
import pluginRegistry from "../../../../../components/Actions/pluginRegistry";
import type { ProcessContextModelState, ProcessPageSupportedActions } from "../types";
import { DevToolbarProcessUpload } from "./DeploymentProcessUpload";
import { ProcessActionTemplatesContextProvider } from "./ProcessActionTemplatesContextProvider";
import type { ProcessContextLookupState, ProcessContextProps, ProcessContextProviderSetupActions } from "./ProcessContext";
import { loadProcess, ProcessContext, useBoundProcessActions } from "./ProcessContext";
import type { ProcessStateSelectors } from "./ProcessContextState";
import { getProcessContextModelInitialState, getSelectors, processContextModelStateReducer } from "./ProcessContextState";
import { ProcessErrorsController } from "./ProcessErrors/ProcessErrorsContext";
import { ProcessFeedsContextProvider } from "./ProcessFeedsContextProvider";
import { ProcessSearchFilterController } from "./ProcessSearchFilter/ProcessSearchFilterContext";
import { ProcessWarningsController } from "./ProcessWarnings/ProcessWarningsContext";
interface ProcessControllerProps {
    id: string;
    doBusyTask: DoBusyTask;
    children: (renderProps: ProcessContextProps) => React.ReactNode;
    project: Readonly<ProjectResource>;
    ownerSlug: string;
    gitRef: GitRef | undefined;
    layoutActions: ProcessPageSupportedActions;
    errors?: Errors;
    processType: ProcessType;
    ownerId: string;
}
const useProcessState = () => {
    return React.useState<ProcessContextLookupState>({
        actionTemplates: "NotLoaded",
        feeds: "NotLoaded",
        accounts: [],
        gitCredentials: [],
        blueprints: "NotLoaded",
    });
};
const useLoadProcessEffect = (processType: ProcessType, id: string, project: ProjectResource, gitRef: GitRef | undefined, doBusyTask: DoBusyTask, onLoaded: (process: IProcessResource) => Promise<void>) => {
    return useDoBusyTaskEffect(doBusyTask, async () => {
        if (!id) {
            return;
        }
        const result: IProcessResource = await loadProcess(processType, id, project, gitRef);
        if (onLoaded) {
            await onLoaded(result);
        }
    }, [id, processType, gitRef]);
};
const getStateUpdaters = (setState: React.Dispatch<React.SetStateAction<ProcessContextLookupState>>) => {
    return {
        onActionTemplatesUpdated: (actionTemplates: ActionTemplateSearchResource[]) => setState((current) => ({ ...current, actionTemplates })),
        onFeedsUpdated: (feeds: FeedResource[]) => setState((current) => ({ ...current, feeds })),
        onAccountsUpdated: (accounts: AccountResource[]) => setState((current) => ({ ...current, accounts })),
        onGitCredentialsUpdated: (gitCredentials: GitCredentialResource[]) => setState((current) => ({ ...current, gitCredentials })),
        onBlueprintsUpdated: (blueprints: BlueprintResource[]) => setState((current) => ({ ...current, blueprints })),
    };
};
const useSelectors = (state: ProcessContextModelState): ProcessStateSelectors => {
    return React.useMemo(() => getSelectors(state), [state]);
};
export const ProcessController: React.FC<ProcessControllerProps> = (props: ProcessControllerProps) => {
    const { children, doBusyTask, id, processType, project, layoutActions, ownerId, ownerSlug } = props;
    const [state, dispatch] = React.useReducer(processContextModelStateReducer, getProcessContextModelInitialState(processType, ownerId, ownerSlug));
    const selectors = useSelectors(state);
    const [lookupsState, setState] = useProcessState();
    const projectContext = useProjectContext();
    const persistenceSettings = usePersistenceSettingsContext();
    const { setProcess: setProcessDispatchAction, conflictDetected: conflictDetectedDispatchAction, ...boundDispatchActions } = useBoundProcessActions(dispatch);
    const boundActions = {
        ...boundDispatchActions,
        setProcess: async (process: IProcessResource, updateCleanModel: boolean) => {
            const allPlugins = await Promise.all(uniq(flatMap(process.Steps, (step) => step.Actions).map((action) => pluginRegistry.getAction(action.ActionType, action.StepPackageVersion))));
            setProcessDispatchAction(process, updateCleanModel, allPlugins);
        },
        conflictDetected: async (serverProcess: IProcessResource, stagedProcess: IProcessResource) => {
            const allPlugins = await Promise.all(uniq(flatMap([...serverProcess.Steps, ...stagedProcess.Steps], (step) => step.Actions).map((action) => pluginRegistry.getAction(action.ActionType, action.StepPackageVersion))));
            conflictDetectedDispatchAction(serverProcess, stagedProcess, allPlugins);
        },
    };
    const { model } = projectContext.state;
    const refreshFromServer = useLoadProcessEffect(processType, id, model, props.gitRef, doBusyTask, async (process) => {
        await boundActions.setProcess(process, true);
    });
    const stateUpdaters = React.useMemo(() => getStateUpdaters(setState), [setState]);
    const isBlueprintsEnabled = useOctopusFeatureToggle("blueprints", false);
    const refreshAccounts = useDoBusyTaskEffect(doBusyTask, async () => {
        const accounts = await repository.Accounts.all();
        stateUpdaters.onAccountsUpdated(accounts);
    }, []);
    const refreshActionTemplates = useDoBusyTaskEffect(doBusyTask, async () => {
        const templates = await repository.ActionTemplates.search();
        stateUpdaters.onActionTemplatesUpdated(templates);
    }, []);
    const refreshFeeds = useDoBusyTaskEffect(doBusyTask, async () => {
        const feeds = isAllowed({ permission: Permission.FeedView, project: project.Id, wildcard: true }) ? await repository.Feeds.all() : [];
        stateUpdaters.onFeedsUpdated(feeds);
    }, []);
    const refreshGitCredentials = useDoBusyTaskEffect(doBusyTask, async () => {
        const gitCredentials = isAllowed({ permission: Permission.GitCredentialView, project: project.Id, wildcard: true }) ? (await repository.GitCredentials.list({ take: Repository.takeAll })).Items : [];
        stateUpdaters.onGitCredentialsUpdated(gitCredentials);
    }, []);
    const blueprintIds = useMemo(() => {
        if (state.model.process?.Id) {
            return new Set(Object.values(state.model.actions.byId)
                .flatMap((a) => getBlueprintIdFromAction(a))
                .filter((id) => id !== undefined)
                .sort());
        }
        return "WaitingForProcess";
    }, [state.model.actions.byId, state.model.process?.Id]);
    const blueprintIdsString = blueprintIds === "WaitingForProcess" ? "WaitingForProcess" : JSON.stringify([...blueprintIds.values()]);
    const refreshBlueprintsForProcess = useDoBusyTaskEffect(doBusyTask, async () => {
        if (isBlueprintsEnabled && blueprintIds !== "WaitingForProcess") {
            const blueprints: BlueprintResource[] = [];
            for (const blueprintId of blueprintIds) {
                try {
                    const blueprint = await repository.Blueprints.get(blueprintId);
                    blueprints.push(blueprint);
                }
                catch (e) {
                    logger.warn("Couldn't load blueprint {blueprintId}", { blueprintId });
                }
            }
            stateUpdaters.onBlueprintsUpdated(blueprints);
        }
        else if (!isBlueprintsEnabled) {
            stateUpdaters.onBlueprintsUpdated([]);
        }
    }, [isBlueprintsEnabled, blueprintIdsString]);
    const saveOnServer = async (process: ModifyDeploymentProcessCommand, onError: (errors: Errors) => void, onSuccess: () => void, gitRef?: string): Promise<IProcessResource | null> => {
        let processResult: IProcessResource | null = null;
        await doBusyTask(async () => {
            if (isDeploymentProcessResource(process)) {
                processResult = await repository.DeploymentProcesses.modify(process, props.ownerId, gitRef);
            }
            else if (isRunbookProcessResource(process)) {
                if (HasRunbooksInGit(persistenceSettings) && gitRef) {
                    processResult = await repository.Runbooks.modifyRunbookProcess(process, gitRef);
                }
                else {
                    processResult = await repository.Runbooks.modifyRunbookProcess(process);
                }
            }
            else if (isBlueprintProcessResource(process)) {
                logger.info("Saving blueprint: {blueprint}", { blueprint: process });
            }
            if (processResult && id === processResult.Id) {
                await boundActions.setProcess(processResult, true);
            }
        }, {
            onError,
            onSuccess,
        });
        return processResult;
    };
    const actions: ProcessContextProviderSetupActions = {
        ...boundActions,
        saveOnServer,
        refreshFromServer,
        ...layoutActions,
    };
    const contextValue: ProcessContextProps = {
        state,
        actions: actions,
        selectors,
    };
    return (<ProcessFeedsContextProvider feeds={lookupsState.feeds} refreshFeeds={refreshFeeds}>
            <ProcessActionTemplatesContextProvider templates={lookupsState.actionTemplates} refreshActionTemplates={refreshActionTemplates}>
                <ProcessAccountsContextProvider accounts={lookupsState.accounts} refreshAccounts={refreshAccounts}>
                    <ProcessGitCredentialsContextProvider gitCredentials={lookupsState.gitCredentials} refreshGitCredentials={refreshGitCredentials}>
                        <ProcessBlueprintsContextProvider blueprints={lookupsState.blueprints} refreshBlueprints={refreshBlueprintsForProcess}>
                            <ProcessContext.Provider value={contextValue}>
                                <DevToolsTab name={`Upload ${contextValue.state.processType} Process`}>
                                    <DevToolbarProcessUpload processContext={contextValue} projectContext={projectContext}/>
                                </DevToolsTab>
                                <ProcessSearchFilterController processType={contextValue.state.processType} selectors={contextValue.selectors}>
                                    {() => (<ProcessErrorsController>
                                            <ProcessWarningsController>{children(contextValue)}</ProcessWarningsController>
                                        </ProcessErrorsController>)}
                                </ProcessSearchFilterController>
                            </ProcessContext.Provider>
                        </ProcessBlueprintsContextProvider>
                    </ProcessGitCredentialsContextProvider>
                </ProcessAccountsContextProvider>
            </ProcessActionTemplatesContextProvider>
        </ProcessFeedsContextProvider>);
};
ProcessController.displayName = "ProcessController"
