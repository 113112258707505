import { Environment } from "@octopusdeploy/utilities";
import { useOctopusFeatureToggle } from "~/hooks/useOctopusFeatureToggle";
import { useUIOnlyFeatureFlag } from "~/hooks/useUIOnlyFeatureFlag";
export function useIsDashboardVNextEnabled(): boolean {
    const { isFeatureEnabled } = useDashboardVNextFeature();
    return isFeatureEnabled;
}
export function useDashboardVNextFeature() {
    const [isDashboardVNextDisabled, setIsDashboardVNextDisabled] = useUIOnlyFeatureFlag("Disable DashboardVNext", { isInitiallyEnabled: false });
    const isOctopusFeatureToggleEnabled = useOctopusFeatureToggle("dashboard-vnext", false);
    const featureConfiguredAsEnabled = Environment.isInDevelopmentMode() || isOctopusFeatureToggleEnabled;
    const isFeatureEnabled = !isDashboardVNextDisabled && featureConfiguredAsEnabled;
    const showUIFeatureFlagToggle = featureConfiguredAsEnabled;
    return {
        isFeatureEnabled,
        isDashboardVNextDisabled,
        showUIFeatureFlagToggle,
        setUIFeatureFlagEnabled: setIsDashboardVNextDisabled,
    };
}
