import * as React from "react";
import { StyledMaterialChip } from "./StyledMaterialChip";
import type { CommonChipProps } from "./types";
interface ChipProps extends CommonChipProps {
    onClick?: (event: object) => void;
    backgroundColorOnHover?: string;
}
export const Chip: React.FC<ChipProps> = (props) => {
    const tooltipContent = props.noTooltip ? undefined : props.description ?? props.children;
    return (<StyledMaterialChip backgroundColor={props.backgroundColor} labelColor={props.labelColor} noMargin={props.noMargin} markAsRemoved={props.markAsRemoved} borderColor={props.borderColor} backgroundColorOnHover={props.backgroundColorOnHover} fullWidth={props.fullWidth} icon={props.icon} label={props.children} tooltipContent={tooltipContent} tooltipDisplay={props.tooltipDisplay} onClick={props.onClick} tabIndex={props.tabIndex} variant={props.variant} accessibilityRole={props.accessibilityRole}/>);
};
Chip.displayName = "Chip"
export default Chip;
