import { Button, Callout } from "@octopusdeploy/design-system-components";
import { links } from "@octopusdeploy/portal-routes";
import classNames from "classnames";
import type { ReactNode } from "react";
import React, { useState } from "react";
import { v4 } from "uuid";
import { LifecycleContextualHelp } from "~/components/ContextualHelp/ContextualHelpSnippets";
import { CustomDialogContent } from "~/components/DialogLayout/Custom";
import CustomSaveDialogLayout, { CustomSaveDialogTitleBar } from "~/components/DialogLayout/Custom/CustomSaveDialogLayout";
import SortableList from "~/primitiveComponents/dataDisplay/SortableList";
import type { SortableItemModel } from "~/primitiveComponents/dataDisplay/SortableList/SortableList";
import InternalLink from "../Navigation/InternalLink";
import styles from "./styles.module.less";
interface CreateEnvironmentsDialogLayoutProps {
    spaceId: string;
    title?: ReactNode;
    fullScreen?: boolean;
    skipButtonTitle?: string;
    backBttonTitle?: string;
    saveButtonTitle?: string;
    hideBackButton?: boolean;
    onSaveClick: (environments: Array<string>) => Promise<boolean>;
    onSkipClick: () => boolean;
}
const initialEnvironmentNames = ["Development", "Staging", "Production"];
export default function CreateEnvironmentsDialogLayout(props: CreateEnvironmentsDialogLayoutProps) {
    const initialEnvironmens = initialEnvironmentNames.map((envName) => ({ Name: envName, Id: v4() }));
    const [selectedEnvironments, setSelectedEnvironments] = useState<Array<SortableItemModel>>(initialEnvironmens);
    const [environmentsAreValid, setEnvironmentsAreValid] = useState<boolean | undefined>(undefined);
    const onEnvironmentDeleted = (remainingItems: SortableItemModel[]) => {
        setSelectedEnvironments(remainingItems);
    };
    const onEnvironmentUpdated = (updatedItems: SortableItemModel[]) => {
        setSelectedEnvironments(updatedItems);
    };
    const onEnvironmentAdded = () => {
        const newEnv: SortableItemModel = { Name: "", Id: v4() };
        setSelectedEnvironments([...selectedEnvironments, newEnv]);
    };
    const onSaveClicked = async () => {
        const saveResult = await props.onSaveClick(selectedEnvironments.map((x) => x.Name));
        setEnvironmentsAreValid(saveResult);
        return saveResult;
    };
    return (<CustomSaveDialogLayout frame={NoFrame} onSaveClick={onSaveClicked} close={props.onSkipClick} open={true} renderTitle={() => <CreateEnvironmentsDialogTitle title={props.title} fullScreen={props.fullScreen}/>} renderActions={(renderProps) => (<div className={classNames({ [styles.buttonContainer]: true, [styles.buttonsContainerFullScreen]: props.fullScreen })}>
                    <Button importance={!props.fullScreen ? "secondary" : "quiet"} label={props.skipButtonTitle ?? "Skip"} onClick={() => {
                props.onSkipClick?.();
            }}/>
                    {props.fullScreen && !props.hideBackButton && <Button importance="primary" label="Back" disabled={true} onClick={() => { }}/>}
                    <Button importance="primary" label={props.saveButtonTitle ?? "Save"} onClick={renderProps.onSaveClick}/>
                </div>)} renderContent={() => (<CustomDialogContent className={classNames({ [styles.customDialogContentFullScreen]: props.fullScreen })}>
                    {environmentsAreValid === false && (<Callout type="danger" title="Environments couldn’t be saved">
                            <ol className={styles.savingError}>
                                <li>One or more environment names do not meet the criteria. Please review these guidelines and correct the names before trying again:</li>
                                <li>Ensure each environment name is unique.</li>
                                <li>Each name should be 50 characters or less.</li>
                                <li>Use only letters (A-Z), numbers (0-9), periods (.), commas (,), dashes (-), underscores (_), and hashes (#).</li>
                            </ol>
                        </Callout>)}

                    <div className={styles.lifecycle}>
                        Environments will appear in your <strong>Lifecycle</strong> <LifecycleContextualHelp /> in this order:
                    </div>
                    {(!selectedEnvironments || !selectedEnvironments.length) && (<Callout type="warning" title="No environments added">
                            Add environments now or later in{" "}
                            <InternalLink to={links.infrastructureEnvironmentsPage.generateUrl({ spaceId: props.spaceId })} openInSelf={false}>
                                Environments
                            </InternalLink>
                            .
                        </Callout>)}
                    <div className={classNames({ [styles.environmentsContainerFullScreen]: props.fullScreen })}>
                        <SortableList items={selectedEnvironments} label="Environment" onOrderChanged={setSelectedEnvironments} onItemDeleted={onEnvironmentDeleted} onItemUpdated={onEnvironmentUpdated}/>
                    </div>
                    <div className={classNames({ [styles.addEnvironmentContainer]: true, [styles.addEnvironmentContainerFullScreen]: props.fullScreen })}>
                        <Button importance={!props.fullScreen ? "quiet" : "tertiary"} label="Add Environment" onClick={onEnvironmentAdded}/>
                    </div>
                </CustomDialogContent>)}></CustomSaveDialogLayout>);
}
const NoFrame: React.FC<{}> = ({ children }) => <div className={styles.noFrameFlex}>{children}</div>;
NoFrame.displayName = "NoFrame"
interface CreateEnvironmentsDialogTitleProps {
    fullScreen?: boolean;
    title: ReactNode;
}
function CreateEnvironmentsDialogTitle(props: CreateEnvironmentsDialogTitleProps) {
    return !props.fullScreen ? (<CustomSaveDialogTitleBar title="You need at least one environment to deploy to" className={styles.dialogTitle}/>) : (<div>
            <div>{props.title}</div>
            <CustomSaveDialogTitleBar title="You need at least one environment to deploy to" className={styles.dialogTitleFullScreen}/>
        </div>);
}
