import { css, cx } from "@emotion/css";
import { LinearProgress, MenuItemButton, MenuList } from "@octopusdeploy/design-system-components";
import { borderRadius, space, themeTokens } from "@octopusdeploy/design-system-tokens";
import { logger } from "@octopusdeploy/logging";
import type { ListElement } from "@octopusdeploy/main-navigation";
import { ProjectsPanel, useProjectPanelState } from "@octopusdeploy/main-navigation";
import type { IId } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { client } from "~/clientInstance";
import BusyFromPromise from "~/components/BusyFromPromise";
import Chip from "~/components/Chips/Chip";
import type { DoBusyTask, Errors } from "~/components/DataBaseComponent";
import CustomDialog from "~/components/Dialog/CustomDialog";
import { useUrlResolver } from "~/components/Navigation/useUrlResolver";
import AddNewProjectDialog from "~/components/ProjectBasedActivation/AddNewProjectDialog";
import NewProjectWizard from "~/components/ProjectBasedActivation/NewProjectWizard";
import { ErrorPanel } from "~/components/form";
import { useTrackGlobalSearchItemSelected } from "~/globalSearch/analytics/useTrackGlobalSearchItemSelected";
import useLocalStorage from "~/hooks/useLocalStorage";
import { useOctopusFeatureToggle } from "~/hooks/useOctopusFeatureToggle";
import type { TabsRef } from "~/primitiveComponents/navigation/Tabs/ControlledTabsContainer";
import { ControlledTabsContainer, TabItem } from "~/primitiveComponents/navigation/Tabs/index";
import type { IRecentSpaceProjects } from "~/utils/RecentProjects/IRecentProjects";
import { RecentProjects } from "~/utils/RecentProjects/RecentProjects";
import styles from "./GlobalSearch.module.less";
import type { GlobalSearchResult } from "./GlobalSearchHelpers";
import { getRedirectUrlForSpaceSearchResult, IsPageSearchResult, NoKeywordGuidance, PageSearchResultListItem, SearchItemCount, SpaceSearchResultListItem, usePageSearch, useSpaceSearch } from "./GlobalSearchHelpers";
interface GlobalSearchListProps {
    items: GlobalSearchResult[];
    keyword: string;
    onClick: (item: GlobalSearchResult) => void;
    busy?: Promise<unknown> | boolean;
    doBusyTask: DoBusyTask;
    onRequestClose?: () => void;
    showServerResultsOnly: boolean;
    setShowServerResultsOnly: (value: boolean) => void;
}
export interface GlobalSearchRef {
    focusFirstItem: () => void;
}
const GlobalSearchList = forwardRef<GlobalSearchRef, GlobalSearchListProps>(({ items, keyword, onClick, busy, doBusyTask, onRequestClose, showServerResultsOnly, setShowServerResultsOnly }, ref) => {
    return (<div className={styles.listContainer}>
            <GlobalSearchListForVerticalNavigation onRequestClose={onRequestClose} keyword={keyword} items={items} onClick={onClick} busy={busy} doBusyTask={doBusyTask} showServerResultsOnly={showServerResultsOnly} setShowServerResultsOnly={setShowServerResultsOnly} ref={ref}/>
        </div>);
});
const GlobalSearchListForVerticalNavigation = forwardRef<GlobalSearchRef, GlobalSearchListProps>(({ items, keyword, onClick, busy, doBusyTask, onRequestClose, showServerResultsOnly, setShowServerResultsOnly }, ref) => {
    const tabsRef = React.useRef<TabsRef>(null);
    const [selectedTab, setSelectedTab] = useLocalStorage("global-search/selected-tab", "everything");
    const [recentProjectIds, setRecentProjectIds] = useState(() => getRecentProjectIds(RecentProjects.getInstance().GetRecentProjectListInSpace()));
    const [addNewProjectDialogOpen, setAddNewProjectDialogOpen] = useState(false);
    const listElementRef = React.useRef<ListElement>(null);
    const legacySearchRef = React.useRef<GlobalSearchRef>(null);
    const isKGSFeatureEnabled = useOctopusFeatureToggle("kubernetes-guided-setup", false);
    const isKGSNthProjectFeatureEnabled = useOctopusFeatureToggle("kubernetes-guided-setup-nth-project", false);
    const onAddNewProjectRequested = React.useCallback(() => {
        setAddNewProjectDialogOpen(true);
    }, []);
    const { projectsState, projectGroupMap, projectsError, isLoadingProjects, onFetchProjectsRequested } = useProjectPanelState({ filter: keyword, recentProjectIds, onAddNewProjectRequested });
    useEffect(() => {
        return RecentProjects.getInstance().SubscribeToRecentSpaceProjects((recentSpaceProjects) => {
            const updatedRecentProjectIds = getRecentProjectIds(recentSpaceProjects);
            setRecentProjectIds(updatedRecentProjectIds);
        });
    }, []);
    useImperativeHandle(ref, () => ({
        focusFirstItem: () => {
            //One of these elements will be defined when the associated element
            //is mounted.
            if (listElementRef.current) {
                listElementRef.current.focus();
            }
            else if (legacySearchRef.current && items.length > 0) {
                legacySearchRef.current.focusFirstItem();
            }
            else if (tabsRef.current) {
                tabsRef.current.focusActiveTab();
            }
        },
    }));
    return (<>
            <ControlledTabsContainer ref={tabsRef} value={selectedTab} onChange={setSelectedTab}>
                <TabItem label={<>
                            Everything <Chip noTooltip>{items.length}</Chip>
                        </>} value={"everything"}>
                    <LegacyGlobalSearchListWithGuidance items={items} onClick={onClick} busy={busy} doBusyTask={doBusyTask} keyword={keyword} ref={legacySearchRef} showServerResultsOnly={showServerResultsOnly} setShowServerResultsOnly={setShowServerResultsOnly}/>
                </TabItem>
                <TabItem label={<>
                            Projects <Chip noTooltip>{projectsState.type === "filtered" ? projectsState.filteredProjects.length : projectsState.type === "recently-viewed-and-all" ? projectsState.allProjectsPageOne.length : 0}</Chip>
                        </>} value={"projects"}>
                    <ProjectsPanel ref={listElementRef} projectsState={projectsState} projectGroupMap={projectGroupMap} getProjectHref={getProjectHref} isLoading={isLoadingProjects} errorAlert={projectsError} onProjectClick={() => onRequestClose?.()} filter={keyword}/>
                </TabItem>
            </ControlledTabsContainer>
            {!isKGSFeatureEnabled || !isKGSNthProjectFeatureEnabled ? (<AddNewProjectDialog open={addNewProjectDialogOpen} close={() => {
                setAddNewProjectDialogOpen(false);
            }}/>) : (<div>
                    <CustomDialog open={addNewProjectDialogOpen} close={() => { }} noTransition className={newProjectDialogStyles} 
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        render={() => <NewProjectWizard open={addNewProjectDialogOpen} fullScreen={true} spaceId={client.spaceId!} busy={undefined} doBusyTask={doBusyTask} isOnboarding={false} close={() => setAddNewProjectDialogOpen(false)}/>}/>
                </div>)}
        </>);
});
const LegacyGlobalSearchListWithGuidance = forwardRef<GlobalSearchRef, GlobalSearchListProps>((props, ref) => {
    const { showServerResultsOnly, setShowServerResultsOnly, keyword, items } = props;
    return (<div className={legacyGlobalSearchListWithGuidanceStyles}>
            {keyword ? <SearchItemCount items={items} showServerResultsOnly={showServerResultsOnly} setShowServerResultsOnly={setShowServerResultsOnly}/> : <NoKeywordGuidance />}
            <LegacyGlobalSearchList ref={ref} {...props}/>
        </div>);
});
const legacyGlobalSearchListWithGuidanceStyles = css({
    paddingTop: space[8],
});
function getProjectHref(project: IId) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return links.projectRootRedirect.generateUrl({ spaceId: client.spaceId!, projectSlug: project.Id });
}
function getRecentProjectIds(recentSpaceProjects: IRecentSpaceProjects): string[] {
    const recentProjects = recentSpaceProjects.Projects;
    recentProjects
        .sort((a, b) => {
        return RecentProjects.SortByScoreThenTime(a.Score, b.Score, a.Timestamps, b.Timestamps);
    })
        .reverse();
    const recentProjectIds = recentProjects.map((p) => p.ProjectId);
    return recentProjectIds;
}
const LegacyGlobalSearchList = forwardRef<GlobalSearchRef, GlobalSearchListProps>(({ items, keyword, onClick, busy, showServerResultsOnly, setShowServerResultsOnly }, ref) => {
    const urlResolver = useUrlResolver();
    const trackGlobalSearchItemSelected = useTrackGlobalSearchItemSelected();
    const firstItemRef = useRef<HTMLButtonElement | null>(null);
    useImperativeHandle(ref, () => ({
        focusFirstItem: () => firstItemRef.current?.focus(),
    }));
    return (<>
            <MenuList accessibleName={"Global search"}>
                {items.map((item, index) => {
            if (IsPageSearchResult(item)) {
                const id = item.page.Id(urlResolver);
                return (<MenuItemButton key={id} ref={index === 0 ? firstItemRef : undefined} onClick={() => {
                        trackGlobalSearchItemSelected(item);
                        window.location.href = `#${item.page.resolvedUrl(urlResolver)}`;
                        onClick(item);
                    }}>
                                <PageSearchResultListItem result={item}/>
                            </MenuItemButton>);
            }
            return (<MenuItemButton key={item.Id} ref={index === 0 ? firstItemRef : undefined} onClick={() => {
                    trackGlobalSearchItemSelected(item);
                    const redirectTo = getRedirectUrlForSpaceSearchResult(item, urlResolver);
                    if (typeof redirectTo === "string") {
                        const goTo = `#${redirectTo}`;
                        window.location.href = goTo;
                        onClick(item);
                    }
                    else {
                        logger.error("Attempted to link search result of non-linkable document type for '{item}' in global search.", { item });
                    }
                }}>
                            <SpaceSearchResultListItem result={item} keyword={keyword}/>
                        </MenuItemButton>);
        })}
            </MenuList>
        </>);
});
interface GlobalSearchProps {
    keyword: string;
    doBusyTask: DoBusyTask;
    busy?: Promise<void>;
    errors?: Errors;
    handleClose: () => void;
    onBlur?: () => void;
}
type Props = GlobalSearchProps;
const GlobalSearchInternal = forwardRef<GlobalSearchRef, Props>(({ keyword, doBusyTask, busy, errors, handleClose, onBlur }, ref) => {
    const containerRef = React.useRef<HTMLDivElement>(null);
    const pageSearchResults = usePageSearch(keyword);
    const spaceSearchResults = useSpaceSearch(keyword, doBusyTask);
    const [showServerResultsOnly, setShowServerResultsOnly] = useLocalStorage("GlobalSearch.showServerResultsOnly", false);
    const isLargerThanIpad = useMediaQuery({ query: `(min-width: 811px)` });
    const handleBlur: React.FocusEventHandler<HTMLDivElement> = useCallback((e) => {
        if (containerRef.current && !containerRef.current.contains(e.relatedTarget)) {
            onBlur?.();
        }
    }, [onBlur]);
    const allResults = React.useMemo(() => {
        if (showServerResultsOnly) {
            return [...spaceSearchResults];
        }
        // We put page search results first because they are in-memory searches and won't cause
        // jumping-of-the-UI (like the spaceSearchResults will if they go first).
        return [...pageSearchResults, ...spaceSearchResults];
    }, [pageSearchResults, spaceSearchResults, showServerResultsOnly]);
    return (<div onBlur={handleBlur} ref={containerRef} className={cx(searchResultContainerStyles, {
            [searchResultContainerSmallStyles]: !isLargerThanIpad,
            [searchResultContainerLargeStyles]: isLargerThanIpad,
        })}>
            {errors && <ErrorPanel message={errors.message} errors={errors.errors} scrollToPanel={false}/>}
            <BusyFromPromise promise={busy}>{(isBusy: boolean) => <LinearProgress variant={"indeterminate"} show={isBusy}/>}</BusyFromPromise>
            <div className={styles.tabContainer}>
                <GlobalSearchList ref={ref} keyword={keyword} items={allResults} onClick={(item) => {
            handleClose();
        }} onRequestClose={handleClose} busy={busy} doBusyTask={doBusyTask} showServerResultsOnly={showServerResultsOnly} setShowServerResultsOnly={setShowServerResultsOnly}/>
            </div>
        </div>);
});
const GlobalSearch = GlobalSearchInternal;
const searchResultContainerStyles = css({
    width: "27.5rem",
    minHeight: "12.5rem",
    background: themeTokens.color.background.primary.default,
    overflow: "auto",
    boxShadow: themeTokens.shadow.medium,
    borderRadius: borderRadius.small,
});
const searchResultContainerSmallStyles = css({
    width: `calc(100vw - ${space[32]})`,
});
const searchResultContainerLargeStyles = css({
    width: "38.5rem",
});
const newProjectDialogStyles = css({
    zIndex: "9999 !important",
});
export default GlobalSearch;
