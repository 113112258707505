import { css } from "@emotion/css";
/* eslint-disable @octopusdeploy/custom-portal-rules/no-restricted-imports */
import { Fade } from "@material-ui/core";
import { IconButton, Stepper } from "@octopusdeploy/design-system-components";
import { space, text, themeTokens } from "@octopusdeploy/design-system-tokens";
import * as React from "react";
import { useState } from "react";
import { Action } from "~/analytics/Analytics";
import { SampleProjectTourContext } from "~/areas/projects/components/ProjectLayout/SampleProjectTour/SampleProjectTour";
import { useRequiredContext } from "~/hooks/index";
function SampleProjectTourResume(): JSX.Element {
    const sampleProjectTourContext = useRequiredContext(SampleProjectTourContext);
    const [isHovered, setIsHovered] = useState<boolean>();
    const styles = {
        container: css({
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            padding: space[8],
            borderRadius: "4px",
            border: "0px",
            cursor: "pointer",
            color: themeTokens.color.callout.text.info,
            backgroundColor: themeTokens.color.callout.background.info.default,
            "&:hover": {
                backgroundColor: themeTokens.color.callout.background.info.hover,
            },
            "&:active": {
                backgroundColor: themeTokens.color.callout.background.info.pressed,
            },
        }),
        textContainer: css({
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
        }),
        text: css({
            marginLeft: space[8],
            font: text.regular.bold.large,
        }),
    };
    const onResumeTourClicked = () => {
        sampleProjectTourContext.resumeTour();
        sampleProjectTourContext.dispatchAnalyticsEventForCurrentState("Sample Project Tour: Click Resume Tour", Action.Resume, "SampleProjectTourResume");
    };
    const onDismissTourClicked = () => {
        sampleProjectTourContext.dismissTour();
        sampleProjectTourContext.dispatchAnalyticsEventForCurrentState("Sample Project Tour: Click 'X' to Dismiss Resume Tour", Action.Dismiss, "SampleProjectTourResume");
    };
    const shouldRender = sampleProjectTourContext.tourState === "Suspended";
    return (<Fade in={shouldRender} unmountOnExit timeout={300}>
            <div role="button" className={styles.container} onClick={onResumeTourClicked} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
                <Stepper variant="circular" currentStep={sampleProjectTourContext.stepIndex + 1} totalSteps={sampleProjectTourContext.totalStepCount}/>
                <div className={styles.textContainer}>
                    <div className={styles.text}>Resume Tour</div>
                    {isHovered && <DismissPrompt onDismissClicked={onDismissTourClicked}/>}
                </div>
            </div>
        </Fade>);
}
export interface DismissPromptProps {
    onDismissClicked: () => void;
}
export function DismissPrompt(props: DismissPromptProps) {
    const onDismissClicked = (event: React.MouseEvent) => {
        event.stopPropagation();
        props.onDismissClicked();
    };
    return (<div>
            <IconButton accessibleName="CloseSampleProjectTour" icon={"Cancel"} onClick={onDismissClicked}/>
        </div>);
}
export default SampleProjectTourResume;
