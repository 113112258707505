/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { css } from "@emotion/css";
import { space, text } from "@octopusdeploy/design-system-tokens";
import type { TriggerResource } from "@octopusdeploy/octopus-server-client";
import { triggerIdIs, TriggerActionType, triggerActionTypeIs, triggerFilterTypeIs, TriggerFilterType } from "@octopusdeploy/octopus-server-client";
import type { ReactElement } from "react";
import React from "react";
import { BuiltInFeedIcon } from "./Icons/BuiltInFeedIcon";
import { DeploymentIcon } from "./Icons/DeploymentIcon";
import { ExternalFeedIcon } from "./Icons/ExternalFeedIcon";
import { GitIcon } from "./Icons/GitIcon";
import { ScheduledIcon } from "./Icons/ScheduledIcon";
interface TriggerTypeProps {
    trigger: TriggerResource;
}
export function TriggerType({ trigger }: TriggerTypeProps) {
    switch (true) {
        // Temporary explicit condition while we store ARC triggers differently in the backend
        case triggerIdIs(trigger, "built-in-feed"):
            // &#8209; = non breaking hyphen
            return <TriggerTypeIcon icon={<BuiltInFeedIcon size={22}/>} label="Built&#8209;in&nbsp;package&nbsp;repository"/>;
        case triggerActionTypeIs(trigger, TriggerActionType.CreateRelease) && triggerFilterTypeIs(trigger, TriggerFilterType.FeedFilter):
            return <TriggerTypeIcon icon={<ExternalFeedIcon size={22}/>} label="External&nbsp;feed"/>;
        case triggerActionTypeIs(trigger, TriggerActionType.CreateRelease) && triggerFilterTypeIs(trigger, TriggerFilterType.GitFilter):
            return <TriggerTypeIcon icon={<GitIcon size={22}/>} label="Git&nbsp;repository"/>;
        case triggerActionTypeIs(trigger, TriggerActionType.AutoDeploy):
            return <TriggerTypeIcon icon={<DeploymentIcon size={22}/>} label="Deployment&nbsp;target"/>;
        default:
            // Both scheduled deployments and runbooks
            return <TriggerTypeIcon icon={<ScheduledIcon size={22}/>} label="Scheduled"/>;
    }
}
interface TriggerTypeIconProps {
    icon: ReactElement;
    label: string;
}
function TriggerTypeIcon({ icon, label }: TriggerTypeIconProps) {
    return (<div className={iconStyles}>
            {icon}
            {label}
        </div>);
}
const iconStyles = css({
    display: "flex",
    alignItems: "center",
    verticalAlign: "middle",
    gap: space[8],
    font: text.regular.default.medium,
});
