/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { PageAction, PrimaryPageAction, SimpleMenuItem } from "@octopusdeploy/design-system-components";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { logger } from "@octopusdeploy/logging";
import { Permission, canCommitTo, HasVariablesInGit, isGitBranchResource, TenantedDeploymentMode, VariableType } from "@octopusdeploy/octopus-server-client";
import type { GitBranchResource, GitRefResource, ProjectResource, VariableResource, VariableSetResource } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import type { Dictionary } from "lodash";
import * as React from "react";
import type { ActionEvent, AnalyticActionDispatcher, AnalyticTrackedActionDispatcher } from "~/analytics/Analytics";
import { Action, useProjectScopedAnalyticActionDispatch, useProjectScopedAnalyticTrackedActionDispatch } from "~/analytics/Analytics";
import { ProjectGitHubAppAuthCheck } from "~/areas/library/components/GitConnections/GitHubAppAuthCheck";
import { ProjectContextPaperLayout } from "~/areas/projects/components/Process/CustomPaperLayouts/ProjectContextPaperLayout";
import { SampleProjectTourContext } from "~/areas/projects/components/ProjectLayout/SampleProjectTour/SampleProjectTour";
import type { SampleProjectTourContextProps } from "~/areas/projects/components/ProjectLayout/SampleProjectTour/SampleProjectTour";
import NoProjectVariablesOnboardingPage from "~/areas/projects/components/Variables/ProjectVariables/NoProjectVariablesOnboardingPage";
import { GitCallouts } from "~/areas/projects/components/VersionControl/GitCallouts";
import type { ProjectContextProps } from "~/areas/projects/context";
import { useProjectContext } from "~/areas/projects/context";
import VariableEditor from "~/areas/variables/VariableEditor/VariableEditor";
import { default as VariableSaveConfirmationDialog } from "~/areas/variables/VariableSaveConfirmationDialog/VariableSaveConfirmationDialog";
import { repository } from "~/clientInstance";
import type { PrimarySavePageAction } from "~/components/FormPaperLayout/Form";
import { Form } from "~/components/FormPaperLayout/Form";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import TransitionAnimation from "~/components/TransitionAnimation/TransitionAnimation";
import { useRequiredContext } from "~/hooks/index";
import DateFormatter from "~/utils/DateFormatter/DateFormatter";
import IdHelper from "~/utils/IdHelper/index";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
import FormBaseComponent from "../../../../../components/FormBaseComponent";
import type { OptionalFormBaseComponentState } from "../../../../../components/FormBaseComponent/FormBaseComponent";
import type { ReadonlyVariableResource } from "../../../../variables/ReadonlyVariableResource";
import { createDialogContent, createViewModel, getVariableResources } from "../../../../variables/VariableEditor/conversions";
import type { VariableSaveConfirmationContent } from "../../../../variables/VariableSaveConfirmationDialog/VariableSaveConfirmationDialog";
import type { VariableModel } from "../../../../variables/VariablesModel/VariablesModel";
import groupVariablesByName from "../../../../variables/groupVariablesByName";
import type { GetCommitButtonProps } from "../../VersionControl/CommitButton";
import { GetCommitButton } from "../../VersionControl/CommitButton";
import type { CommitMessageWithDetails } from "../../VersionControl/CommitMessageWithDetails";
import { getFormattedCommitMessage } from "../../VersionControl/CommitMessageWithDetails";
import MigrateProjectVariablesBanner from "./MigrateProjectVariablesToGit/MigrateProjectVariablesBanner";
import styles from "./style.module.less";
interface ProjectVariablesState extends OptionalFormBaseComponentState<ProjectVariablesModel> {
    variableSet?: VariableSetResource;
    sensitiveVariableSet?: VariableSetResource;
    project?: ProjectResource;
    model?: ProjectVariablesModel;
    dialogContent?: VariableSaveConfirmationContent;
    newBranch?: GitBranchResource;
    initialVariables?: ReadonlyArray<VariableModel>;
    groupedVariableResources?: Dictionary<ReadonlyVariableResource[]>;
    cellFocusResetKey: string;
    disableDirtyFormChecking?: boolean;
    commitMessage: CommitMessageWithDetails;
    hasSteps: boolean;
    showOnboardingPage: boolean;
}
interface ProjectVariablesModel {
    readonly variables: ReadonlyArray<VariableModel>;
}
type ProjectVariablesInternalProps = {
    projectContext: ProjectContextProps;
    trackAction: AnalyticTrackedActionDispatcher;
    onboardingContext?: SampleProjectTourContextProps;
    dispatchAction: AnalyticActionDispatcher;
};
class ProjectVariablesInternal extends FormBaseComponent<ProjectVariablesInternalProps, ProjectVariablesState, ProjectVariablesModel> {
    private openCommitDialog?: () => void;
    constructor(props: ProjectVariablesInternalProps) {
        super(props);
        this.state = {
            cellFocusResetKey: DateFormatter.timestamp(),
            commitMessage: { summary: "", details: "" },
            hasSteps: false,
            showOnboardingPage: true,
        };
    }
    async componentDidMount() {
        await this.reload();
    }
    async componentDidUpdate(prevProps: ProjectVariablesInternalProps) {
        if (prevProps.projectContext.state.gitRef !== this.props.projectContext.state.gitRef) {
            await this.reload();
        }
    }
    private reload = async () => await this.doBusyTask(async () => {
        const { model: project, projectContextRepository } = this.props.projectContext.state;
        const variableSet = await this.props.projectContext.state.projectContextRepository.Variables.get();
        let hasSteps = false;
        try {
            const deploymentProcess = await projectContextRepository.DeploymentProcesses.get();
            hasSteps = deploymentProcess.Steps.length > 0;
        }
        catch (exception) {
            logger.error(exception, "Failed to get deployment processes for {project}", { project });
        }
        if (HasVariablesInGit(project.PersistenceSettings)) {
            const sensitiveVariableSet = await this.props.projectContext.state.projectContextRepository.Variables.getSensitive();
            this.resetState(project, hasSteps, variableSet, sensitiveVariableSet);
        }
        else {
            this.resetState(project, hasSteps, variableSet);
        }
    }, { timeOperationOptions: timeOperationOptions.forInitialLoad() });
    private getCommitButtonProps(primarySaveAction: PrimarySavePageAction): GetCommitButtonProps {
        return {
            project: this.props.projectContext.state.model,
            gitRef: this.props.projectContext.state.gitRef?.CanonicalName,
            canCommitToGitRef: canCommitTo(this.props.projectContext.state.gitRef),
            defaultCommitMessage: "Update variables",
            commitMessage: this.state.commitMessage,
            updateCommitMessage: (commitMessage: CommitMessageWithDetails) => this.setState({ commitMessage }),
            commitMessageAccessibleName: "Commit message for saving the variables",
            commitDetailsAccessibleName: "Commit details for saving the variables",
            commitButtonAccessibleName: "Commit changes to the variables",
            onNewBranchCreating: (branchName) => this.saveVariablesToNewBranch(branchName),
            onInitializing: (openCommitDialog: () => void) => (this.openCommitDialog = openCommitDialog),
            label: primarySaveAction.label,
            busyLabel: primarySaveAction.busyLabel,
            onClick: primarySaveAction.onClick,
            disabled: primarySaveAction.disabled,
        };
    }
    private getOverflowActions(project?: ProjectResource): SimpleMenuItem[] {
        if (!project) {
            return [];
        }
        if (HasVariablesInGit(project.PersistenceSettings)) {
            return [
                {
                    type: "download-link",
                    label: "Download Text as JSON",
                    href: this.props.projectContext.state.projectContextRepository.Variables.resolveResourceLink(),
                    downloadFileName: `${project.Slug}-text-variables.json`,
                },
                {
                    type: "download-link",
                    label: "Download Sensitive as JSON",
                    href: this.props.projectContext.state.projectContextRepository.Variables.resolveSensitiveResourceLink(),
                    downloadFileName: `${project.Slug}-sensitive-variables.json`,
                },
            ];
        }
        return [
            {
                type: "download-link",
                label: "Download as JSON",
                href: this.props.projectContext.state.projectContextRepository.Variables.resolveResourceLink(),
                downloadFileName: `${project.Slug}-variables.json`,
            },
        ];
    }
    render() {
        const overflowActions = this.getOverflowActions(this.state.project);
        const gitVariables = this.state.project ? HasVariablesInGit(this.state.project.PersistenceSettings) : false;
        const onSaveClick = async (isNavigationConfirmation?: boolean | undefined, onSavedCallback?: (() => void) | undefined, newBranch?: GitRefResource | undefined, commitMessage?: CommitMessageWithDetails | undefined) => {
            if (newBranch !== undefined && !isGitBranchResource(newBranch)) {
                return;
            }
            const newBranchResource: GitBranchResource | undefined = newBranch;
            if (isNavigationConfirmation && this.openCommitDialog && gitVariables) {
                this.openCommitDialog();
            }
            else {
                const dialogContent = createDialogContent(this.state.model!.variables, this.state.initialVariables!, this.state.variableSet!.Variables);
                if (dialogContent && dialogContent.hasContent) {
                    this.setState({ dialogContent, newBranch: newBranchResource });
                }
                else {
                    await this.doBusyTask(() => this.saveVariables(newBranchResource));
                    if (onSavedCallback) {
                        onSavedCallback();
                    }
                }
            }
        };
        const pageActions: PageAction[] = [];
        const { dispatchAction } = this.props;
        const createReleaseIsAllowed = this.state.hasSteps || this.state.project?.IsVersionControlled;
        if (this.state.project && createReleaseIsAllowed) {
            const createReleaseButton: PageAction = {
                type: "navigate",
                buttonType: "secondary",
                label: "Create Release",
                path: links.createReleasePage.generateUrl({ spaceId: this.state.project.SpaceId, projectSlug: this.state.project.Slug }),
                onClick: () => dispatchAction("Create a release", { resource: "Create Release", action: Action.Add }),
                hasPermissions: isAllowed({ permission: Permission.ReleaseCreate, project: this.state.project.Id, tenant: "*" }),
            };
            pageActions.push(createReleaseButton);
        }
        return (<Form model={this.state.model} cleanModel={this.state.cleanModel} savePermission={{
                permission: Permission.VariableEdit,
                project: this.state.project && this.state.project.Id,
                wildcard: true,
            }} onSaveClick={onSaveClick} disableDirtyFormChecking={this.state.disableDirtyFormChecking}>
                {({ FormContent, createSaveAction }) => (<ProjectContextPaperLayout busy={this.state.busy} errors={this.errors} fullWidth={true} title={"Project Variables"} overflowActions={this.state.showOnboardingPage ? [] : overflowActions} primaryAction={!this.state.showOnboardingPage
                    ? gitVariables
                        ? this.getCommitButtonPrimaryAction(createSaveAction({ saveButtonLabel: "Commit", saveButtonBusyLabel: "Committing" }))
                        : createSaveAction({ saveButtonLabel: "Save", saveButtonBusyLabel: "Saving" })
                    : undefined} pageActions={!this.state.showOnboardingPage ? pageActions : []} className={styles.fullHeight}>
                        <ProjectGitHubAppAuthCheck project={this.state.project} permission={Permission.VariableEdit}/>
                        {this.state.showOnboardingPage && this.state.initialVariables && this.state.initialVariables.length === 0 ? (<NoProjectVariablesOnboardingPage onClick={() => {
                        this.setState({ showOnboardingPage: false });
                    }} gitRefResource={this.props.projectContext.state.gitRef}/>) : (<FormContent>
                                <MigrateProjectVariablesBanner />
                                <GitCallouts isVariablesPage gitRefResource={this.props.projectContext.state.gitRef}/>
                                {this.state.model && (<TransitionAnimation>
                                        <VariableEditor initialVariables={this.state.initialVariables!} scopeValues={this.state.variableSet!.ScopeValues} isTenanted={this.isProjectTenanted()} doBusyTask={this.doBusyTask} onVariablesChanged={(variables: any) => this.setState({ model: { variables } })} cellFocusResetKey={this.state.cellFocusResetKey} scope="Project" gitVariables={gitVariables}/>
                                        <VariableSaveConfirmationDialog key={"VariableEditorConfirmDialogs"} content={this.state.dialogContent} onClosed={() => this.setState({ dialogContent: null! })} onSaveClick={() => this.saveVariables(this.state.newBranch)}/>
                                    </TransitionAnimation>)}
                            </FormContent>)}
                    </ProjectContextPaperLayout>)}
            </Form>);
    }
    private getCommitButtonPrimaryAction = (defaultPrimaryAction: PrimarySavePageAction): PrimaryPageAction => {
        return { type: "custom", content: <GetCommitButton {...this.getCommitButtonProps(defaultPrimaryAction)}/>, key: "Get Commit" };
    };
    private isProjectTenanted() {
        return this.state.project ? this.state.project.TenantedDeploymentMode !== TenantedDeploymentMode.Untenanted : false;
    }
    private resetState(project: ProjectResource, hasSteps: boolean, variableSet: VariableSetResource, sensitiveVariableSet?: VariableSetResource) {
        const allVariables = [...variableSet.Variables];
        if (sensitiveVariableSet) {
            allVariables.push(...sensitiveVariableSet.Variables);
        }
        const groupedVariableResources = groupVariablesByName(allVariables, (v) => v.Name);
        const variables = createViewModel(groupedVariableResources);
        const model: ProjectVariablesModel = { variables };
        this.setState({
            project,
            hasSteps,
            variableSet,
            sensitiveVariableSet,
            groupedVariableResources,
            initialVariables: [...variables],
            model,
            cleanModel: { ...model },
            cellFocusResetKey: DateFormatter.timestamp(),
            showOnboardingPage: variables.length === 0,
        });
        setTimeout(() => {
            if (allVariables.length <= 0) {
                this.props.onboardingContext?.variablePageLoadedWithNoDataLeft();
                return;
            }
            this.props.onboardingContext?.continueSampleProjectTour();
        }, 300);
    }
    async saveVariablesToNewBranch(branchName: string) {
        if (!this.state.project) {
            throw "Can not save if there is no project configured";
        }
        this.setState({ disableDirtyFormChecking: true });
        const newBranch = await repository.ProjectBranches.createBranch(this.props.projectContext.state.model.Id, branchName, this.props.projectContext.state.gitRef?.CanonicalName ?? "");
        await this.saveVariables(newBranch);
        this.props.projectContext.actions.changeGitRef(newBranch.Name);
        this.setState({ disableDirtyFormChecking: false });
    }
    async saveVariables(newBranch?: GitBranchResource) {
        const oldVariableCount = this.state.variableSet!.Variables.length;
        const variables = getVariableResources(this.state.model!.variables, this.state.groupedVariableResources!);
        const newVariableCount = variables.length;
        const actionMeta = oldVariableCount < newVariableCount ? "Added variables" : oldVariableCount === newVariableCount ? "Modified variables" : "Delete variables";
        const actionEvent: ActionEvent = {
            action: Action.Save,
            resource: "Variables",
            actionMeta: actionMeta,
        };
        const project = this.props.projectContext.state.model;
        if (HasVariablesInGit(project.PersistenceSettings)) {
            await this.saveVariablesGit(project, variables, actionEvent, newBranch);
        }
        else {
            await this.saveVariablesDatabase(project, variables, actionEvent);
        }
    }
    private async saveVariablesDatabase(project: ProjectResource, variables: VariableResource[], actionEvent: ActionEvent) {
        actionEvent.action = Action.Save;
        await this.props.trackAction("Save Project Variables", actionEvent, async () => {
            const repository = this.props.projectContext.state.projectContextRepository;
            const variableSet = await repository.Variables.modify({ ...this.state.variableSet!, Variables: variables });
            this.resetState(project, this.state.hasSteps, variableSet);
        });
    }
    private async saveVariablesGit(project: ProjectResource, variables: VariableResource[], actionEvent: ActionEvent, newBranch?: GitBranchResource) {
        actionEvent.action = Action.Commit;
        actionEvent.isDefaultBranch = this.props.projectContext.state.isDefaultBranch;
        actionEvent.commitMessage = this.state.commitMessage.summary.length > 0;
        await this.props.trackAction("Save Project Variables", actionEvent, async () => {
            const textVariables = variables.filter((variable) => variable.Type !== VariableType.Sensitive);
            const sensitiveVariables = variables.filter((variable) => variable.Type === VariableType.Sensitive);
            this.createNewIdForUpdatedToSensitive(sensitiveVariables);
            let variableSet = this.state.variableSet!;
            if (newBranch) {
                variableSet = {
                    ...variableSet,
                    Links: {
                        ...variableSet.Links,
                        Self: newBranch.Links.Variables,
                    },
                };
            }
            const repository = this.props.projectContext.state.projectContextRepository;
            const formattedCommitMessage = getFormattedCommitMessage(this.state.commitMessage, "Update variables");
            variableSet = await repository.Variables.modify({ ...variableSet, Variables: textVariables, ChangeDescription: formattedCommitMessage });
            const sensitiveVariableSet = await repository.Variables.modify({ ...this.state.sensitiveVariableSet!, Variables: sensitiveVariables });
            this.resetState(project, this.state.hasSteps, variableSet, sensitiveVariableSet);
        });
    }
    private createNewIdForUpdatedToSensitive(sensitiveVariables: VariableResource[]) {
        // Create new ID for variables that have been changed to sensitive so that they can be saved to the server without conflicts.
        // See https://app.shortcut.com/octopusdeploy/story/75424/conflicts-with-cac-variables-and-sensitive-variables-requested-by-finnian-dempsey
        // and https://github.com/OctopusDeploy/Issues/issues/8733
        const initialVariableIdsAndType: Record<string, string> = {};
        this.state?.initialVariables?.forEach((variable) => {
            variable.values.forEach((value) => {
                initialVariableIdsAndType[value.Id] = value.Type;
            });
        });
        sensitiveVariables.forEach((variable) => {
            if (initialVariableIdsAndType[variable.Id] !== undefined && initialVariableIdsAndType[variable.Id] !== VariableType.Sensitive) {
                variable.Id = IdHelper.newId();
            }
        });
    }
    static displayName = "ProjectVariablesInternal";
}
function ProjectVariables() {
    const projectContext = useProjectContext();
    const onboardingContext = useRequiredContext(SampleProjectTourContext);
    const trackAction = useProjectScopedAnalyticTrackedActionDispatch(projectContext.state.model.Id);
    const dispatchAction = useProjectScopedAnalyticActionDispatch(projectContext.state.model.Id);
    return <ProjectVariablesInternal onboardingContext={onboardingContext} projectContext={projectContext} trackAction={trackAction} dispatchAction={dispatchAction}/>;
}
export default ProjectVariables;
