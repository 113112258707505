/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { ProjectResource, ProjectSummary } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import type { LinkHref } from "@octopusdeploy/portal-routes";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import { useProjectContext } from "~/areas/projects/context/ProjectContext";
import InternalRedirect from "~/components/Navigation/InternalRedirect";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
export type ProjectRedirectOption = {
    vcsRedirect?: boolean;
};
export const ProjectToOverviewRedirect: React.FC = (): React.ReactElement => {
    const projectContext = useProjectContext();
    const redirectOption = {
        vcsRedirect: true,
    };
    const projectOverviewPath = getOverviewRedirectPathForProject(projectContext.state.model, projectContext.state.summary, redirectOption);
    return <InternalRedirect to={projectOverviewPath}/>;
};
ProjectToOverviewRedirect.displayName = "ProjectToOverviewRedirect"
const getRedirectPathBaseForProject = (project: ProjectResource, summary: ProjectSummary | undefined, redirectOption: ProjectRedirectOption | undefined = {}) => {
    if (IsAllowedToSeeOperationsOverview(project.Id) && !IsAllowedToSeeDeploymentsOverview(project.Id)) {
        return links.projectOperationsPage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug });
    }
    if (redirectOption.vcsRedirect && !summary) {
        return links.projectVersionControlSettingsPage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug });
    }
    if (summary) {
        if (!summary.HasDeploymentProcess) {
            return summary.HasRunbooks ? links.projectOperationsPage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug }) : links.deploymentProcessPage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug });
        }
        else if (!summary.HasBeenSuccessfullyDeployed) {
            return summary.HasRunbooks ? links.deploymentsPage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug }) : links.releasesPage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug });
        }
    }
    return links.deploymentsPage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug });
};
export const getOverviewRedirectPathForProject = (project: ProjectResource, summary: ProjectSummary | undefined, redirectOption: ProjectRedirectOption | undefined = {}): LinkHref => {
    if (!project) {
        return null!;
    }
    return getRedirectPathBaseForProject(project, summary, redirectOption);
};
export const IsAllowedToSeeDeploymentsOverview = (projectId: string | undefined | null): boolean => {
    if (!projectId) {
        return false;
    }
    const isAllowedToSeeDeploymentsOverview = isAllowed({
        permission: [Permission.ReleaseView, Permission.ProcessView],
        project: projectId,
        tenant: "*",
    });
    return isAllowedToSeeDeploymentsOverview;
};
export const IsAllowedToSeeOperationsOverview = (projectId: string): boolean => {
    if (!projectId) {
        return false;
    }
    const isAllowedToSeeDeploymentsOverview = isAllowed({
        permission: Permission.RunbookView,
        project: projectId,
        wildcard: true,
    });
    return isAllowedToSeeDeploymentsOverview;
};
