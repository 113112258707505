import type { ReactNode } from "react";
import React from "react";
import WarningPanel from "~/components/WarningPanel/WarningPanel";
export const WarningsForAction: React.FC<{
    actionWarnings: (string | ReactNode)[];
}> = ({ actionWarnings }) => {
    if (actionWarnings.length === 0) {
        return null;
    }
    return <WarningPanel title={"Potential issues were found"} warnings={actionWarnings} parsedHelpLinks={undefined} helpText={undefined} helpLink={undefined} scrollToPanel={false}/>;
};
WarningsForAction.displayName = "WarningsForAction"
