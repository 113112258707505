import React from "react";
import { useErrorActions, useErrors, useGetFieldError } from "~/components/ErrorContext/ErrorContext";
import type { Errors } from "./Errors";
type DatabaseComponentContextProps = {
    actions: ReturnType<typeof useErrorActions>;
    getFieldError: ReturnType<typeof useGetFieldError>;
    errors: Errors | undefined;
};
export const DataBaseComponentContext = React.createContext<DatabaseComponentContextProps | undefined>(undefined);
DataBaseComponentContext.displayName = "DataBaseComponentContext";
export const DatabaseComponentContextProvider: React.FC = (props) => {
    const errorActions = useErrorActions();
    const getFieldError = useGetFieldError();
    const errors = useErrors();
    const value: DatabaseComponentContextProps = React.useMemo(() => ({
        actions: errorActions,
        getFieldError,
        errors,
    }), [errorActions, errors, getFieldError]);
    return <DataBaseComponentContext.Provider value={value}>{props.children}</DataBaseComponentContext.Provider>;
};
DatabaseComponentContextProvider.displayName = "DatabaseComponentContextProvider"
