import type { AnalyticsEvent, AnalyticsEventPageProperties, ErrorProperties } from "@octopusdeploy/portal-analytics";
import { getErrorProperties, useAnonymizeEventProperty, useTrackEvent } from "@octopusdeploy/portal-analytics";
import type { PageDefinition } from "@octopusdeploy/portal-routes";
import { usePage } from "@octopusdeploy/portal-routes";
import { useCallback } from "react";
interface SaveChannelEvent extends AnalyticsEvent<"Save Channel">, AnalyticsEventPageProperties, ErrorProperties {
    "Resource Type": "Channel";
    "Project Id": string;
    "Action Type": "Save";
    Status: "Success" | "Failed";
}
interface SaveChannelEventArguments {
    projectId: string;
    page: PageDefinition;
    error: Error | undefined;
}
export function createSaveChannelEvent({ projectId, page, error }: SaveChannelEventArguments): SaveChannelEvent {
    const errorProperties = getErrorProperties(error);
    return {
        eventName: "Save Channel",
        "Action Type": "Save",
        "Resource Type": "Channel",
        "Project Id": projectId,
        "Page Name": page.Name,
        "Page Area": page.Area,
        Status: error === undefined ? "Success" : "Failed",
        ...errorProperties,
    };
}
export function useTrackSaveChannelEvent() {
    const trackEvent = useTrackEvent();
    const page = usePage();
    const anonymize = useAnonymizeEventProperty();
    return useCallback((projectId: string, error?: Error) => trackEvent(createSaveChannelEvent({
        projectId: anonymize(projectId),
        error,
        page,
    })), [anonymize, page, trackEvent]);
}
