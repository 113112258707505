import { type GitDependencyRequestContext, type GitRef } from "@octopusdeploy/octopus-server-client";
import React, { useCallback, useMemo, useState } from "react";
import { RefTypes } from "~/areas/projects/components/GitRefDropDown/GitRefDropDown";
import type { GitRefOption } from "~/areas/projects/components/GitRefDropDown/GitRefOption";
import type { GitRefSelectorProps } from "~/areas/projects/components/GitRefSelector/GitRefSelector";
import { GitRefSelector } from "~/areas/projects/components/GitRefSelector/GitRefSelector";
import { useProjectContext } from "~/areas/projects/context/index";
import { repository } from "~/clientInstance";
import type { DoBusyTask } from "~/components/DataBaseComponent/index";
import { DataBaseComponent } from "~/components/DataBaseComponent/index";
import { getExternalBranchesList, getFilteredBranchesList } from "~/utils/BranchHelpers/ExternalBranchHelpers";
type ExternalGitRefSelectorProps = Omit<GitRefSelectorProps, "busy" | "items" | "totalItemCount" | "loadItems" | "searchItems"> & {
    actionName: string;
    channelId: string | undefined;
    releaseId: string | undefined;
    runbookId: string | undefined;
    runbookSnapshotId: string | undefined;
    gitDependencyName: string | undefined;
};
type ExternalGitRefSelectorInternalProps = ExternalGitRefSelectorProps & {
    doBusyTask: DoBusyTask;
    busy: Promise<void> | undefined;
};
const ExternalGitRefSelectorInternal = (props: ExternalGitRefSelectorInternalProps) => {
    const { doBusyTask, actionName, gitDependencyName, defaultBranch, channelId, releaseId, runbookId, runbookSnapshotId, ...rest } = props;
    const [items, setItems] = useState<GitRefOption[]>([]);
    const [totalItemCount, setTotalItemCount] = useState<number>(0);
    const { state: { model: project, gitRef: projectGitRef }, } = useProjectContext();
    const requestContext: GitDependencyRequestContext = useMemo(() => ({
        project,
        projectGitRef,
        actionName,
        gitDependencyName,
        channelId,
        releaseId,
        runbookId,
        runbookSnapshotId,
    }), [actionName, channelId, gitDependencyName, project, projectGitRef, releaseId, runbookId, runbookSnapshotId]);
    //we do this inside a callback so the doBusyTask state change doesn't force a re-render
    const loadItems = useCallback((refType: RefTypes, selectedGitRef: GitRef | undefined): void => {
        //We clear these before loading more
        setItems([]);
        setTotalItemCount(0);
        doBusyTask(async () => {
            let items: GitRefOption[] = [];
            let totalCount: number = 0;
            if (refType === RefTypes.Branches) {
                const [branchItems, total] = await getExternalBranchesList(requestContext, selectedGitRef, defaultBranch);
                items = branchItems;
                totalCount = total;
            }
            else if (refType === RefTypes.Tags) {
                const tagResources = await repository.Projects.getTagsForGitDependency(requestContext);
                items = tagResources.Items.map((t) => ({
                    value: t.CanonicalName,
                    isDefault: false,
                    canWrite: false,
                }));
                totalCount = tagResources.TotalResults;
            }
            setItems(items);
            setTotalItemCount(totalCount);
        });
    }, [defaultBranch, doBusyTask, requestContext]);
    const searchItems = useCallback(async (refType: RefTypes, searchTerm: string): Promise<GitRefOption[]> => {
        let items: GitRefOption[] = [];
        await doBusyTask(async () => {
            if (refType === RefTypes.Tags) {
                const tagResources = await repository.Projects.getTagsForGitDependency(requestContext, searchTerm);
                items = tagResources.Items.map((t) => ({
                    value: t.CanonicalName,
                    isDefault: false,
                    canWrite: false,
                }));
            }
            else {
                items = await getFilteredBranchesList(requestContext, defaultBranch, searchTerm);
            }
        });
        return items;
    }, [defaultBranch, doBusyTask, requestContext]);
    return <GitRefSelector items={items} totalItemCount={totalItemCount} loadItems={loadItems} searchItems={searchItems} defaultBranch={defaultBranch} {...rest}/>;
};
//We wrap this in a DataBaseComponent, so we can take advantage of the existing logic for use of the doBusyTask
export class ExternalGitRefSelector extends DataBaseComponent<ExternalGitRefSelectorProps> {
    constructor(props: ExternalGitRefSelectorProps) {
        super(props);
        this.state = {};
    }
    render() {
        return <ExternalGitRefSelectorInternal doBusyTask={this.doBusyTask} busy={this.state.busy} {...this.props}/>;
    }
    static displayName = "ExternalGitRefSelector";
}
