/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { css } from "@emotion/css";
import { fontWeight, space } from "@octopusdeploy/design-system-tokens";
import type { DeploymentTargetResource, EnvironmentResource } from "@octopusdeploy/octopus-server-client";
import type { ReactNode } from "react";
import React from "react";
import { Action, useProjectScopedAnalyticActionDispatch } from "~/analytics/Analytics";
import { useOptionalProjectContext } from "~/areas/projects/context/index";
import Chip from "~/components/Chips/Chip";
import PopoverHelp from "~/primitiveComponents/dataDisplay/PopoverHelp/PopoverHelp";
import { DeploymentTargetsTable } from "../Common/DeploymentTargetsTable";
import { UnassignedTargetTagsWarningCallout } from "./UnassignedTargetTagsWarningCallout";
interface ConnectedDeploymentTargetsPopoverProps {
    targetTags: string[];
    triggerElement: ReactNode;
    deploymentTargetsTotal: number;
    deploymentTargets: DeploymentTargetResource[];
    environments: EnvironmentResource[];
}
export const getUnassignedTargetTags = (targetTags: string[], deploymentTargets: DeploymentTargetResource[]) => {
    return targetTags.filter((tag) => {
        const isTargetTagAssigned = deploymentTargets.some((target) => target.Roles.includes(tag));
        return !isTargetTagAssigned;
    });
};
export const ConnectedDeploymentTargetsPopover = (props: ConnectedDeploymentTargetsPopoverProps) => {
    const projectContext = useOptionalProjectContext();
    const dispatchAction = useProjectScopedAnalyticActionDispatch(projectContext?.state.model.Id);
    const container = css({
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
    });
    const titleContainer = css({
        display: "flex",
        alignItems: "center",
    });
    const titleStyles = css({
        fontWeight: fontWeight["700"],
    });
    const chipStyles = css({
        marginLeft: space["8"],
        ".MuiChip-label": {
            paddingRight: space[8],
            paddingLeft: space[8],
        },
    });
    const chipTextStyles = css({
        fontWeight: 600,
    });
    const unassignedTargetTags = getUnassignedTargetTags(props.targetTags, props.deploymentTargets);
    return (<PopoverHelp trigger="click" placement={"bottom-start"} absolutePosition={false} label={props.triggerElement} size={"large"} onOpen={() => dispatchAction("Role Chip Contextual Help Opened", { action: Action.Toggle, resource: "Role Chip Contextual Help" })} noLabelMargin={true}>
            <div className={container}>
                <div className={titleContainer}>
                    <div className={titleStyles}>Deployment Targets</div>
                    <div className={chipStyles}>
                        <Chip noMargin={true} noTooltip={true}>
                            <span className={chipTextStyles}>{props.deploymentTargetsTotal}</span>
                        </Chip>
                    </div>
                </div>
                <UnassignedTargetTagsWarningCallout unassignedTargetTags={unassignedTargetTags}/>
                {props.deploymentTargetsTotal > 0 && props.environments && props.deploymentTargets && <div>Based on the selected target tags, this step can deploy to:</div>}
                {props.deploymentTargetsTotal > 0 && props.environments && props.deploymentTargets && (<DeploymentTargetsTable targetTags={props.targetTags} deploymentTargetsTotal={props.deploymentTargetsTotal} deploymentTargets={props.deploymentTargets} environments={props.environments} showTargetTagColumn={true} targetTagColumnSecondaryTitle="(used by this step)" size="large"/>)}
            </div>
        </PopoverHelp>);
};
