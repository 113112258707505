import type { Event as AnalyticsEvent } from "@amplitude/analytics-types";
import { css } from "@emotion/css";
import { Callout } from "@octopusdeploy/design-system-components";
import { borderRadius, fontFamily, fontSize, lineHeight, space, text, themeTokens } from "@octopusdeploy/design-system-tokens";
import React, { useState } from "react";
import { ExpandableContainer } from "~/components/Expandable/index";
import ExternalLink from "~/components/Navigation/ExternalLink";
import { SimplePagingList } from "~/components/PagingList/index";
import Section from "~/components/Section/index";
import SimpleExpander from "~/components/SimpleExpander/SimpleExpander";
class EventsPagingList extends SimplePagingList<AnalyticsEvent> {
}
export function AmplitudeEventsTab(props: {
    analyticsEvents: AnalyticsEvent[];
}) {
    // We want to show the most recent events at the top
    const reversedEvents = [...props.analyticsEvents].reverse();
    const mostRecentDeviceId = reversedEvents[0]?.device_id;
    return (<>
            <TestingEventsCallout mostRecentDeviceId={mostRecentDeviceId}/>
            <ExpandableContainer containerKey="Amplitude events tab">
                <EventsPagingList onFilter={(filter, event) => event.event_type.toLowerCase().includes(filter.toLowerCase())} filterHintText={"Filter by event type"} pageSize={20} items={reversedEvents} hideItemPadding={true} onRowKey={getEventKey} onRow={(e) => <EventRow event={e}/>}/>
            </ExpandableContainer>
        </>);
}
function TestingEventsCallout({ mostRecentDeviceId }: {
    mostRecentDeviceId: string | undefined;
}) {
    const [showCallout, setShowCallout] = useState(true);
    const linkText = `filtering on your device_id`;
    const link = mostRecentDeviceId === undefined ? (linkText) : (<ExternalLink trackAnalytics={false} href={getAmplitudeUserLookupLink(mostRecentDeviceId)}>
                {linkText}
            </ExternalLink>);
    return (<>
            {showCallout && (<Callout title={"Frontend Amplitude Events"} type={"information"} canClose={true} onClose={() => setShowCallout(false)}>
                    <span>
                        <b>This only shows events generated in the frontend.</b>
                        <br />
                        To test these events in Amplitude, first set the <code>Octopus__Telemetry__DebugEnabled</code> environment variable to <code>true</code>.<br />
                        You can then find your events in the 'Octopus Deploy - Development/Test' project by {link} ({mostRecentDeviceId === undefined ? "Trigger an event to find your device_id" : <code>{mostRecentDeviceId}</code>}).
                        <br />
                        For more info, read the{" "}
                        <ExternalLink trackAnalytics={false} href="https://docs.octopushq.com/docs/frontend/analytics">
                            docs
                        </ExternalLink>
                        .
                    </span>
                </Callout>)}
        </>);
}
function getAmplitudeUserLookupLink(deviceId: string) {
    // 323896 is the Development/Test project in amplitude
    return `https://app.amplitude.com/analytics/OctopusDeploy/project/323896/search/${deviceId}`;
}
function getEventKey(event: AnalyticsEvent): string {
    if (event.insert_id === undefined) {
        throw new Error("Every event should have an insert_id");
    }
    return event.insert_id;
}
function EventRow({ event }: {
    event: AnalyticsEvent;
}) {
    return (<SimpleExpander title={<EventRowTitle event={event}/>} errorKey={getEventKey(event)}>
            <Section sectionHeader={"Event properties (properties supplied by Octopus)"}>
                <CodeBlock value={JSON.stringify(event.event_properties ?? {}, null, 4)}/>
            </Section>
            <Section sectionHeader={"Full event (including Amplitude supplied properties)"}>
                <CodeBlock value={JSON.stringify(organiseEvent(event), null, 4)}/>
            </Section>
        </SimpleExpander>);
}
// Re-arrange properties on the event so that the more important ones appear at the top
function organiseEvent(event: AnalyticsEvent): AnalyticsEvent {
    const { event_type, device_id, session_id, insert_id, event_id, user_id, time, event_properties, ...rest } = event;
    return {
        event_type,
        device_id,
        insert_id,
        session_id,
        event_id,
        user_id,
        time,
        ...rest,
        event_properties,
    };
}
function EventRowTitle({ event }: {
    event: AnalyticsEvent;
}) {
    if (event.time === undefined) {
        throw new Error("Every event should have a time");
    }
    return (<div className={containerClass}>
            <div className={eventIdClass}>{event.event_id}</div>
            <div className={eventTypeClass}>{event.event_type}</div>
            <div>{new Date(event.time).toLocaleTimeString()}</div>
        </div>);
}
function CodeBlock({ value }: {
    value: string;
}) {
    return (<code className={codeBlockStyles}>
            <pre className={preStyles}>{value}</pre>
        </code>);
}
const containerClass = css({
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    width: "100%",
    padding: space["2"],
    lineHeight: lineHeight.xSmall,
    fontFamily: fontFamily.default,
    fontSize: fontSize.medium,
});
const eventIdClass = css({
    width: "100px",
});
const eventTypeClass = css({
    width: "400px",
});
const codeBlockStyles = css({
    font: text.regular.default.large,
    fontFamily: fontFamily.code,
    display: "block",
    padding: `${space[4]} ${space[8]}`,
    backgroundColor: themeTokens.color.background.secondary.default,
    borderRadius: borderRadius.small,
});
const preStyles = css({
    margin: 0,
    padding: `${space[12]} ${space[12]}`,
});
