/* eslint-disable @typescript-eslint/consistent-type-assertions */
import type { Completion } from "@codemirror/autocomplete";
import { EditorSelection } from "@codemirror/state";
// eslint-disable-next-line @octopusdeploy/custom-portal-rules/no-restricted-imports
import { ClickAwayListener } from "@material-ui/core";
import { ActionButton, ActionButtonType, IconButton, Switch, Theme, Tooltip } from "@octopusdeploy/design-system-components";
import { OctopusError, ScriptingLanguage } from "@octopusdeploy/octopus-server-client";
import type { ReactCodeMirrorRef } from "@uiw/react-codemirror";
import ReactCodeMirror from "@uiw/react-codemirror";
import cn from "classnames";
import type { EditorView } from "codemirror";
import { merge } from "lodash";
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from "react";
import getCodeEditorExtensions from "~/components/CodeEditor/CodeEditorExtensions";
import { CodeEditorSelect } from "~/components/CodeEditor/CodeEditorSelect";
import { ArrowDownLeftUpRightIcon } from "~/components/CodeEditor/Icons/ArrowDownLeftUpRightIcon";
import { ArrowUpRightDownLeftIcon } from "~/components/CodeEditor/Icons/ArrowUpRightDownLeftIcon";
import { codeEditorAutocompleteNoteContainerStyles, codeEditorAutocompleteNoteStyles, codeEditorCodeMirrorStyles, codeEditorContainerFocusedStyles, codeEditorContainerInADialogStyles, codeEditorContainerStyles, codeEditorCustomDialogActionsStyles, codeEditorCustomDialogStyles, codeEditorFuzzySearchTooltipStyles, codeEditorNoToolbarStyles, codeEditorOuterContainerStyles, codeEditorSettingsPopoverStyles, codeEditorSettingsSwitchStyles, codeEditorTitleSectionStyles, codeEditorToolbarButtonsContainerStyles, codeEditorToolbarButtonStyles, codeEditorToolbarIconButtonNoLabelStyles, codeEditorToolbarIconButtonStyles, codeEditorToolbarStyles, } from "~/components/CodeEditor/codeEditorStyles";
import CopyToClipboardButton from "~/components/CopyToClipboardButton/index";
import { CustomDialog } from "~/components/Dialog/CustomDialog";
import CustomSaveDialogLayout from "~/components/DialogLayout/Custom/CustomSaveDialogLayout";
import { CustomDialogActions, CustomFlexDialogContent, LargeDialogFrame } from "~/components/DialogLayout/Custom/index";
import type { IconButtonWithTooltipProps } from "~/components/IconButtonWithTooltip/index";
import { useIsSystemFontEnabled } from "~/components/RootRoutes/useIsSystemFontEnabled";
import { SupportedLanguage } from "~/components/ScriptingLanguageSelector/ScriptingLanguageSelector";
import { useThemePaletteType } from "~/components/Theme/useThemePaletteType";
import InputLabel from "~/components/form/InputLabel/index";
import InsertVariableButton from "~/components/form/InsertVariableButton/InsertVariableButton";
import type { VariableLookupProps } from "~/components/form/VariableLookup/VariableLookup";
import type { FormFieldProps } from "~/components/form/index";
import { Note } from "~/components/form/index";
import useLocalStorage from "~/hooks/useLocalStorage";
import { InsertVariableIcon } from "~/primitiveComponents/dataDisplay/Icon/index";
import { Popover } from "~/primitiveComponents/dataDisplay/Popover/Popover";
import PopoverHelp from "~/primitiveComponents/dataDisplay/PopoverHelp/PopoverHelp";
import type { ThemePaletteType } from "~/theme/index";
import { isTooltipHoverable } from "~/utils/TooltipHelper/isTooltipHoverable";
export type CodeEditorLanguage = ScriptingLanguage[keyof ScriptingLanguage] | Language[keyof Language] | TextFormat[keyof TextFormat];
interface CodeEditorProps extends VariableLookupProps, FormFieldProps<string> {
    containerClassName?: string;
    onToggleFullScreen?: () => void;
    language: CodeEditorLanguage;
    allowFullScreen?: boolean;
    readOnly?: boolean;
    label?: string | JSX.Element;
    autoComplete?: Array<{
        display: string;
        code: string;
    }>;
    autoExpand?: boolean;
    fullHeight?: boolean;
    showToolbar?: boolean;
    scriptingLanguageSelectorOptions?: ScriptingLanguageSelectorOptions;
    showCopyButton?: boolean;
    showInsertVariableButton?: boolean;
    showLineNumbers?: boolean;
    lineWrapping?: boolean;
    onEscPressed?(): void;
    validate?(value: string): Promise<OctopusError> | OctopusError | Error | null;
    settingsOverride?: Partial<CodeEditorSettings>;
    showButtonLabels?: boolean;
}
export enum Language {
    HTML = "HTML",
    CSS = "CSS",
    Markdown = "Markdown",
    DockerFile = "DockerFile",
    INI = "INI",
    CoffeeScript = "CoffeeScript"
}
export enum TextFormat {
    JSON = "JSON",
    PlainText = "PlainText",
    XML = "XML",
    YAML = "YAML"
}
type ScriptingLanguageSelectorOptions = {
    onScriptingLanguageChanged: (scriptingLanguage: ScriptingLanguage | Language | TextFormat) => void;
    supportedLanguages: (ScriptingLanguage | Language | TextFormat)[] | SupportedLanguage;
};
interface CodeEditorSettings {
    wordWrap: boolean;
    theme: ThemePaletteType | "default";
}
interface CommonToolbarButtonProps {
    onToggleFullScreen?: (value: boolean) => void;
    isInFullScreen?: boolean;
    insert?: (value: string) => void;
    onToolbarClick?: () => void;
    onToolbarButtonClick?: () => void;
    settings: CodeEditorSettings;
    onUpdateSettings: (settings: CodeEditorSettings) => void;
    showLabel: boolean;
    showHoverTooltip?: boolean;
}
const autoCompleteNote = () => {
    return (<Note className={codeEditorAutocompleteNoteStyles}>
            Insert variables with <code>control</code> + <code>i</code>.&nbsp; Fuzzy search supported.
            <PopoverHelp trigger="click" placement={"top-end"}>
                <div style={{ textAlign: "left" }} className={codeEditorFuzzySearchTooltipStyles}>
                    You can type things like <code>machineid</code> followed by <code>control</code> + <code>i</code> to quickly narrow down to <code>Octopus.Machine.Id</code>.
                    <br />
                    Or try <code>ospn</code> followed by <code>control</code> + <code>i</code> to insert <code>Octopus.Space.Name</code>.
                    <br />
                    You can also narrow the selection by typing in while the selection list is opened.
                </div>
            </PopoverHelp>
        </Note>);
};
const DialogFrame = ({ children }: {
    children?: React.ReactNode;
}) => {
    return <LargeDialogFrame className={codeEditorCustomDialogStyles}>{children}</LargeDialogFrame>;
};
export interface CodeEditorElement {
    blur(): void;
}
export const CodeEditor = forwardRef<CodeEditorElement, CodeEditorProps>(({ containerClassName: containerClassNameProp, language, allowFullScreen, readOnly = false, label, autoComplete = [], onEscPressed, value, validate, onChange, autoExpand = false, syntax, localNames, showToolbar = true, showCopyButton = false, showInsertVariableButton = false, showLineNumbers = true, lineWrapping = true, scriptingLanguageSelectorOptions, settingsOverride, showButtonLabels = true, fullHeight = false, }, ref) => {
    const themePalette = useThemePaletteType();
    const [localStorageSettings, setLocalStorageSettings] = useLocalStorage<CodeEditorSettings>("Octopus.CodeEditor.Settings", { theme: themePalette, wordWrap: lineWrapping });
    const settings = settingsOverride === undefined ? localStorageSettings : merge(localStorageSettings, settingsOverride); // this way the override settings will "win" over the stored settings
    const theme = settings.theme === "default" ? themePalette : settings.theme;
    const textThemeName = useIsSystemFontEnabled() ? "textV2" : "text";
    const lineWrap = settings.wordWrap;
    const [showToolbarButtonLabelsToggle, setShowToolbarButtonLabelsToggle] = React.useState<boolean>(true);
    const [isInFullScreen, setIsInFullScreen] = useState(false);
    const [focused, setFocused] = useState(false);
    const [protectFocus, setProtectFocus] = useState(false);
    const [errors, setErrors] = React.useState<OctopusError | Error | null>(null);
    const [sourceCode, setSourceCode] = React.useState<string>(value);
    const val = value ? value : "";
    const codeEditorToolbarRef = React.useRef<HTMLDivElement>(null);
    let codeMirrorRef: ReactCodeMirrorRef | undefined = undefined;
    const basicSetup = useMemo(() => ({
        lineNumbers: showLineNumbers,
        searchKeymap: false, // disabling the CodeMirror search extension due to known bug https://github.com/uiwjs/react-codemirror/issues/280
        completionKeymap: false,
    }), [showLineNumbers]);
    const completionSource: Completion[] = useMemo(() => autoComplete.map((v) => ({
        label: v.display,
        apply: (view: EditorView, _: Completion, from: number, to: number) => {
            view.dispatch({
                changes: { from, to, insert: v.code },
                selection: { anchor: from + v.code.length },
            });
        },
    })), [autoComplete]);
    const onChangeFn = useCallback((value: string, _) => {
        if (onChange) {
            onChange(value);
        }
    }, [onChange]);
    const toggleFullScreen = useCallback(() => setIsInFullScreen(!isInFullScreen), [isInFullScreen]);
    const onFocusChange = (focused: boolean) => {
        if (focused) {
            setFocused(focused);
        }
    };
    const extensions = useMemo(() => getCodeEditorExtensions({
        onFocusChange,
        onEscPressed,
        isInFullScreen,
        toggleFullScreen,
        language,
        theme,
        lineWrap,
        completionSource,
    }), [completionSource, isInFullScreen, language, lineWrap, onEscPressed, theme, toggleFullScreen]);
    useImperativeHandle(ref, () => ({
        blur: () => {
            blur();
        },
    }));
    const save = async () => {
        if (validate !== undefined) {
            const validationErrors = await validate(sourceCode);
            if (validationErrors) {
                setErrors(validationErrors);
                return false;
            }
            else {
                return true;
            }
        }
        else {
            return true;
        }
    };
    const fullScreenDialog = () => {
        return (<CustomDialog open={isInFullScreen} close={toggleFullScreen} render={(customDialogRenderProps) => (<Theme themeName={theme} textThemeName={textThemeName}>
                            <div className={codeEditorCustomDialogStyles}>
                                <CustomSaveDialogLayout {...customDialogRenderProps} renderTitle={() => <></>} {...(errors && OctopusError.isOctopusError(errors) && errors.Errors ? { errors: { errors: errors.Errors.map((x: string) => x.toString()) ?? [], message: errors.ErrorMessage, fieldErrors: {}, details: {} } } : {})} onSaveClick={() => save()} frame={DialogFrame} renderActions={(renderProps) => (<CustomDialogActions className={codeEditorCustomDialogActionsStyles} actions={<ActionButton label="Close" onClick={async () => ((await renderProps.onSaveClick()) ? renderProps.close() : {})} type={ActionButtonType.Secondary}/>} additionalActions={<>
                                                    {autoComplete?.length > 0 && (<div style={{ flexGrow: 0 }} className={codeEditorAutocompleteNoteContainerStyles}>
                                                            {autoCompleteNote()}
                                                        </div>)}
                                                </>}/>)} renderContent={() => <CustomFlexDialogContent>{mainBody({ isInADialog: true })}</CustomFlexDialogContent>}/>
                            </div>
                        </Theme>)}/>);
    };
    const handleClickAway = () => {
        if (focused) {
            if (codeMirrorRef?.view?.hasFocus) {
                // do nothing
            }
            else if (!protectFocus) {
                setFocused(false);
            }
        }
    };
    const blur = () => codeMirrorRef?.view?.contentDOM?.blur();
    const insertAtCursor = (value: string) => {
        if (codeMirrorRef?.view) {
            const transaction = codeMirrorRef.view.state.changeByRange((range) => ({
                changes: { from: range.from, to: range.to, insert: value },
                range: EditorSelection.range(range.from, range.from + value.length),
            }));
            codeMirrorRef.view.dispatch(transaction);
        }
    };
    const mainBody = ({ isInADialog = false }) => {
        const childProps = {
            // Your IDE is lying, these ARE used!
            isInFullScreen,
            onToggleFullScreen: toggleFullScreen,
            insert: insertAtCursor,
            onToolbarClick: () => {
                if (codeMirrorRef?.editor) {
                    codeMirrorRef.editor.focus();
                    setFocused(true);
                }
            },
            onToolbarButtonClick: () => setFocused(true),
            settings: localStorageSettings,
            onUpdateSettings: (settings: CodeEditorSettings) => setLocalStorageSettings(settings),
            showLabel: showButtonLabels,
        };
        return (<React.Fragment>
                    {label && <InputLabel label={label}/>}
                    {showToolbar && (<CodeEditorToolbar {...childProps} scriptingLanguageSelector={scriptingLanguageSelectorOptions &&
                    ((Array.isArray(scriptingLanguageSelectorOptions.supportedLanguages) && scriptingLanguageSelectorOptions.supportedLanguages.length > 1) ||
                        scriptingLanguageSelectorOptions.supportedLanguages === SupportedLanguage.All) ? (<CodeEditorScriptingLanguageSelector scriptingLanguage={syntax ? (syntax as ScriptingLanguage) : (language as ScriptingLanguage | Language | TextFormat)} supportedLanguages={scriptingLanguageSelectorOptions.supportedLanguages} onScriptingLanguageChanged={scriptingLanguageSelectorOptions.onScriptingLanguageChanged} onClose={() => setProtectFocus(false)} onOpen={() => setProtectFocus(true)} {...childProps}/>) : undefined} ref={codeEditorToolbarRef}>
                            {/* For the below buttons, we use dynamic values for `key` to ensure the buttons re-render if the values of `showHoverTooltip` change */}
                            {showCopyButton && <CodeEditorCopyToClipboardButton key={`showClipboardButtonLabel-${showToolbarButtonLabelsToggle}`} value={value} {...childProps} showHoverTooltip={!showToolbarButtonLabelsToggle}/>}
                            {showInsertVariableButton && (<CodeEditorInsertVariableButton key={`showInsertVariableButtonLabel-${showToolbarButtonLabelsToggle}`} syntax={syntax as ScriptingLanguage} localNames={localNames} {...childProps} showHoverTooltip={!showToolbarButtonLabelsToggle}/>)}
                            {allowFullScreen && <CodeEditorToggleFullScreenButton key={`showToggleFullScreenButtonLabel-${showToolbarButtonLabelsToggle}`} {...childProps} showHoverTooltip={!showToolbarButtonLabelsToggle}/>}
                            {!settingsOverride && <CodeEditorSettingsToolbarButton {...childProps}/>}
                        </CodeEditorToolbar>)}
                    <div className={cn(codeEditorContainerStyles, containerClassNameProp, { [codeEditorContainerInADialogStyles]: isInADialog, [codeEditorContainerFocusedStyles]: focused && autoExpand, [codeEditorNoToolbarStyles]: !showToolbar })}>
                        <ReactCodeMirror ref={(ref: ReactCodeMirrorRef) => {
                if (!isInADialog || isInFullScreen)
                    codeMirrorRef = ref;
            }} className={cn(codeEditorCodeMirrorStyles)} theme={theme} basicSetup={basicSetup} indentWithTab={false} // don't use the Codemirror indentWithTab extension as it causes the current line to be indented regardless of where the cursor is
         readOnly={readOnly} value={val} onChange={onChangeFn} placeholder={"Type here..."} extensions={extensions}/>
                    </div>
                </React.Fragment>);
    };
    return (<Theme themeName={theme} textThemeName={textThemeName} isFullHeight={fullHeight}>
                {isInFullScreen ? (fullScreenDialog()) : (<ClickAwayListener onClickAway={handleClickAway}>
                        <div className={codeEditorOuterContainerStyles}>
                            {mainBody({ isInADialog: false })} {autoComplete.length > 0 && <div className={codeEditorAutocompleteNoteContainerStyles}>{autoCompleteNote()}</div>}
                        </div>
                    </ClickAwayListener>)}
            </Theme>);
});
type ScriptingLanguageSelectorProps = CommonToolbarButtonProps & ScriptingLanguageSelectorOptions & {
    scriptingLanguage: ScriptingLanguage | Language | TextFormat;
    onClose?: () => void;
    onOpen?: () => void;
};
const CodeEditorScriptingLanguageSelector = (props: ScriptingLanguageSelectorProps) => {
    const getSupportedLanguages = (enumValue: SupportedLanguage): ScriptingLanguage[] => {
        return enumValue === SupportedLanguage.All
            ? [ScriptingLanguage.PowerShell, ScriptingLanguage.Bash, ScriptingLanguage.CSharp, ScriptingLanguage.FSharp, ScriptingLanguage.Python]
            : enumValue === SupportedLanguage.PowerShellAndBash
                ? [ScriptingLanguage.PowerShell, ScriptingLanguage.Bash]
                : [ScriptingLanguage.PowerShell];
    };
    const supportedLanguages = Array.isArray(props.supportedLanguages) ? props.supportedLanguages : getSupportedLanguages(props.supportedLanguages);
    return (<CodeEditorSelect supportedLanguages={supportedLanguages} scriptingLanguage={props.scriptingLanguage} onChange={(value) => {
            props.onScriptingLanguageChanged?.(value);
            props.onToolbarButtonClick?.();
        }} onFocus={() => props.onToolbarButtonClick?.()} onClose={props.onClose} onOpen={props.onOpen}></CodeEditorSelect>);
};
interface ToolbarProps {
    children: React.ReactNode;
    scriptingLanguageSelector?: React.ReactElement<typeof CodeEditorScriptingLanguageSelector>;
}
const CodeEditorToolbar = forwardRef<HTMLDivElement, ToolbarProps & CommonToolbarButtonProps>((props, ref) => (<div className={cn({ [codeEditorToolbarStyles]: true, [codeEditorContainerInADialogStyles]: props.isInFullScreen })} onClick={props.onToolbarClick} ref={ref}>
        {(props.isInFullScreen || props.scriptingLanguageSelector) && (<div className={codeEditorTitleSectionStyles}>
                {props.isInFullScreen && <div>Edit Source Code</div>}
                {props.scriptingLanguageSelector && <div>{props.scriptingLanguageSelector}</div>}
            </div>)}
        <div className={codeEditorToolbarButtonsContainerStyles}>{props.children}</div>
    </div>));
const CodeEditorCopyToClipboardButton = (props: {
    value: string;
} & CommonToolbarButtonProps) => {
    return (<CopyToClipboardButton value={props.value} showHoverTooltip={props.showHoverTooltip}>
            <div className={codeEditorToolbarButtonStyles}>
                <ActionButton className={cn(codeEditorToolbarIconButtonStyles, { [codeEditorToolbarIconButtonNoLabelStyles]: !props.showLabel })} label={props.showLabel ? "Copy to clipboard" : ""} icon={<em className={cn("fa", `fa-clone`)} aria-hidden="true"/>} iconPosition="left" aria-label={`Copy to clipboard`} type={ActionButtonType.Ternary}/>
            </div>
        </CopyToClipboardButton>);
};
const CodeEditorToggleFullScreenButton = (props: CommonToolbarButtonProps) => {
    if (props.isInFullScreen) {
        return <></>;
    }
    return (<Tooltip content={`${props.isInFullScreen ? "Exit" : "Enter"} Full Screen`} open={isTooltipHoverable(props.showHoverTooltip)}>
            <div className={codeEditorToolbarButtonStyles} onClick={() => props.onToggleFullScreen?.(!props.isInFullScreen)}>
                <ActionButton className={cn(codeEditorToolbarIconButtonStyles, { [codeEditorToolbarIconButtonNoLabelStyles]: !props.showLabel })} label={props.showLabel ? `${props.isInFullScreen ? "Exit" : "Enter"} full screen` : ""} icon={props.isInFullScreen ? <ArrowDownLeftUpRightIcon /> : <ArrowUpRightDownLeftIcon />} iconPosition="left" aria-label={`${props.isInFullScreen ? "Exit" : "Enter"} full screen`} type={ActionButtonType.Ternary}/>
            </div>
        </Tooltip>);
};
const CodeEditorSettingsToolbarButton = (props: CommonToolbarButtonProps) => {
    const [showPopover, setShowPopover] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLDivElement>(null);
    const ref = React.createRef<HTMLDivElement>();
    useEffect(() => {
        setAnchorEl(ref.current);
    }, [ref]);
    const handleSettingsChange = (settings: CodeEditorSettings) => {
        props.onUpdateSettings?.(settings);
    };
    return (<>
            <Tooltip content={"Settings"}>
                <div ref={ref} className={codeEditorToolbarButtonStyles} onClick={() => {
            setShowPopover(!showPopover);
        }}>
                    <IconButton className={codeEditorToolbarIconButtonStyles} icon="Settings" accessibleName={`Settings`}/>
                </div>
            </Tooltip>
            <Popover open={showPopover} anchorEl={anchorEl} onClose={() => setShowPopover(false)} anchorOrigin={{ horizontal: "right", vertical: "bottom" }} transformOrigin={{ horizontal: "right", vertical: "top" }}>
                <div className={codeEditorSettingsPopoverStyles}>
                    <div className={codeEditorSettingsSwitchStyles}>
                        <div>Dark Theme</div>
                        <Switch value={props.settings.theme === "dark"} onChange={(value) => handleSettingsChange({ ...props.settings, theme: value ? "dark" : "light" })}/>
                    </div>
                    <div className={codeEditorSettingsSwitchStyles}>
                        <div>Word Wrap</div>
                        <Switch value={props.settings.wordWrap} onChange={(value) => handleSettingsChange({ ...props.settings, wordWrap: value })}/>
                    </div>
                </div>
            </Popover>
        </>);
};
const InsertVariableButtonInternal = ({ onClick, showLabel }: IconButtonWithTooltipProps & {
    showLabel: boolean;
}) => {
    return (<div className={codeEditorToolbarButtonStyles} onClick={onClick}>
            <ActionButton className={cn(codeEditorToolbarIconButtonStyles, { [codeEditorToolbarIconButtonNoLabelStyles]: !showLabel })} label={showLabel ? "Insert variable" : ""} icon={<InsertVariableIcon />} iconPosition="left" aria-label={`Insert variable`} type={ActionButtonType.Ternary}/>
        </div>);
};
interface InsertVariableButtonProps {
    localNames?: string[];
    syntax?: ScriptingLanguage;
}
const CodeEditorInsertVariableButton = (props: InsertVariableButtonProps & CommonToolbarButtonProps) => {
    return (<InsertVariableButton syntax={props.syntax} anchorOrigin={{ horizontal: "right", vertical: "bottom" }} transformOrigin={{ horizontal: "right", vertical: "top" }} localNames={props.localNames} onSelected={(v) => props.insert?.(v)} button={(buttonProps) => (<InsertVariableButtonInternal {...buttonProps} showLabel={props.showLabel ?? true} onClick={(e) => {
                props.onToolbarButtonClick?.();
                buttonProps.onClick?.(e);
            }}/>)} prompt={autoCompleteNote()} showHoverTooltip={props.showHoverTooltip}/>);
};
