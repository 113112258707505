import type { ProjectResource, CloudConnectionType, VariableSetResource, GitRef } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import React, { useEffect, useState } from "react";
import { Action, useAnalyticCloudConnectionsDispatch } from "~/analytics/Analytics";
import AwsCloudConnectionDrawer from "~/areas/projects/components/CloudConnection/AwsCloudConnectionDrawer";
import AwsCloudConnectionTile, { AwsTileStatus } from "~/areas/projects/components/CloudConnection/AwsCloudConnectionTile";
import AzureCloudConnectionDrawer from "~/areas/projects/components/CloudConnection/AzureCloudConnectionDrawer";
import AzureCloudConnectionTile, { AzureTileStatus } from "~/areas/projects/components/CloudConnection/AzureCloudConnectionTile";
import type { LoadedLibraryVariableSets } from "~/areas/projects/components/Variables/AllVariables/AllVariables";
import type { CommitMessageWithDetails } from "~/areas/projects/components/VersionControl/CommitMessageWithDetails";
import { CloudConnectionsContextualHelp } from "~/components/ContextualHelp/ContextualHelpSnippets";
import ExternalLink from "~/components/Navigation/ExternalLink/index";
import { useSpaceAwareNavigation } from "~/components/Navigation/SpaceAwareNavigation/useSpaceAwareNavigation";
import { RemoveItemsList } from "~/components/RemoveItemsList/RemoveItemsList";
import ExpandableFormSection from "~/components/form/Sections/ExpandableFormSection";
import { Summary } from "~/components/form/index";
import Note from "~/primitiveComponents/form/Note/Note";
class CloudConnectionsList extends RemoveItemsList<CloudConnectionItem> {
}
export enum CloudConnectionUsageType {
    TargetDiscovery
}
export type ConfigurationStatus = "Configure Now" | "Configured" | "Region Required";
export type CloudConnectionProps = {
    providers: CloudConnectionType[];
    connectionType: CloudConnectionUsageType;
    sectionErrorKey: string;
    sectionHelpText: string;
    isExpandedByDefault: boolean;
    projectVariables: VariableSetResource;
    libraryVariableSets: LoadedLibraryVariableSets[] | undefined;
    refreshVariables: () => void;
    saveVariables: (variables: VariableSetResource, commitMessage: CommitMessageWithDetails | undefined) => Promise<VariableSetResource>;
    haveVariablesChanged: (variables: VariableSetResource) => Promise<boolean>;
    project: ProjectResource;
    isDefaultBranch?: boolean;
    gitRef?: GitRef;
};
export interface CloudConnectionItem {
    provider: CloudConnectionType;
    connectionType: CloudConnectionUsageType;
    tileDetails: ConfigurationStatus;
}
const cloudConnectionItem = (connection: CloudConnectionItem) => {
    if (connection.provider === "Aws") {
        return <AwsCloudConnectionTile {...connection}></AwsCloudConnectionTile>;
    }
    else if (connection.provider === "Azure") {
        return <AzureCloudConnectionTile {...connection}></AzureCloudConnectionTile>;
    }
};
const getStatus = (variables: VariableSetResource | undefined, libraryVariableSets: LoadedLibraryVariableSets[] | undefined, provider: CloudConnectionType, connectionType: CloudConnectionUsageType): ConfigurationStatus => {
    if (provider === "Azure") {
        return AzureTileStatus(connectionType, variables, libraryVariableSets);
    }
    else if (provider === "Aws") {
        return AwsTileStatus(connectionType, variables, libraryVariableSets);
    }
    return "Configure Now";
};
export const getCloudConnections = (providers: CloudConnectionType[], connectionType: CloudConnectionUsageType, variables: VariableSetResource | undefined, libraryVariableSets: LoadedLibraryVariableSets[] | undefined): CloudConnectionItem[] => {
    return providers.map((t) => ({
        provider: t,
        tileDetails: getStatus(variables, libraryVariableSets, t, connectionType),
        connectionType: connectionType,
    }));
};
export const filterForConfiguredCloudConnections = (connections: CloudConnectionItem[]) => {
    return connections.filter((item) => item.tileDetails === "Configured");
};
export const isCloudTargetDiscoveryEnabled = (connections: CloudConnectionItem[], connectionType: CloudConnectionUsageType) => {
    const configuredCloudConnections = filterForConfiguredCloudConnections(connections);
    return connectionType === CloudConnectionUsageType.TargetDiscovery && configuredCloudConnections.length > 0;
};
export const CloudConnections: React.FC<CloudConnectionProps> = (props) => {
    const [connectionToEdit, setConnectionToEdit] = useState<CloudConnectionItem | null>(null);
    const [connections, setConnections] = useState<CloudConnectionItem[]>([]);
    const [showAzureDrawer, setShowAzureDrawer] = useState<boolean>(false);
    const [showAwsDrawer, setShowAwsDrawer] = useState<boolean>(false);
    const dispatchAction = useAnalyticCloudConnectionsDispatch();
    const { navigate } = useSpaceAwareNavigation();
    const getSummary = (connections: CloudConnectionItem[]) => {
        const configuredCloudConnections = filterForConfiguredCloudConnections(connections);
        if (isCloudTargetDiscoveryEnabled(connections, props.connectionType)) {
            return Summary.summary(<span>Cloud target discovery is enabled using cloud connections</span>);
        }
        else {
            if (props.connectionType === CloudConnectionUsageType.TargetDiscovery) {
                return Summary.summary(<span>Cloud target discovery is not enabled</span>);
            }
            else {
                return Summary.summary(<span>Cloud connections have {configuredCloudConnections.length > 0 ? "been" : "not been"} configured</span>);
            }
        }
    };
    useEffect(() => {
        const c = getCloudConnections(props.providers, props.connectionType, props.projectVariables, props.libraryVariableSets);
        setConnections(c);
    }, [props.connectionType, props.providers, props.projectVariables, props.libraryVariableSets]);
    return (<>
            {connectionToEdit && (<AwsCloudConnectionDrawer cloudConnectionItem={connectionToEdit} connectionType={props.connectionType} variables={props.projectVariables} saveVariables={props.saveVariables} showDrawer={showAwsDrawer} setShowDrawer={setShowAwsDrawer} refreshVariables={props.refreshVariables} haveVariablesChanged={props.haveVariablesChanged} project={props.project} gitRef={props.gitRef}/>)}
            {connectionToEdit && (<AzureCloudConnectionDrawer cloudConnectionItem={connectionToEdit} variables={props.projectVariables} saveVariables={props.saveVariables} showDrawer={showAzureDrawer} setShowDrawer={setShowAzureDrawer} refreshVariables={props.refreshVariables} haveVariablesChanged={props.haveVariablesChanged} project={props.project} gitRef={props.gitRef}/>)}
            <ExpandableFormSection title={props.connectionType === CloudConnectionUsageType.TargetDiscovery ? "Cloud Target Discovery" : "Cloud Connections"} isExpandedByDefault={props.isExpandedByDefault} errorKey={props.sectionErrorKey} summary={getSummary(connections)} help={props.sectionHelpText} contextualHelp={props.connectionType === CloudConnectionUsageType.TargetDiscovery ? undefined : <CloudConnectionsContextualHelp />}>
                {props.connectionType === CloudConnectionUsageType.TargetDiscovery && (<Note>
                        Learn more about <ExternalLink href="CloudTargetDiscovery">cloud target discovery</ExternalLink>
                    </Note>)}
                <CloudConnectionsList data={connections} onRow={cloudConnectionItem} onRowTouch={(item) => {
            if (item.tileDetails === "Configure Now") {
                dispatchAction(`Open Cloud Connections Drawer`, {
                    action: Action.Select,
                    resource: "Cloud Connections",
                    cloudProvider: item.provider,
                });
                setConnectionToEdit(item);
                if (item.provider === "Aws") {
                    setShowAwsDrawer(true);
                }
                if (item.provider === "Azure") {
                    setShowAzureDrawer(true);
                }
            }
            else {
                dispatchAction(`Link To Cloud Connections Variables`, {
                    action: Action.Select,
                    resource: "Cloud Connections",
                    cloudProvider: item.provider,
                });
                navigate(links.variablesPage.generateUrl({ spaceId: props.project.SpaceId, projectSlug: props.project.Slug }));
            }
        }}/>
            </ExpandableFormSection>
        </>);
};
CloudConnections.displayName = "CloudConnections"
