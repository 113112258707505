/* eslint-disable no-eq-null */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { BooleanRadioButton, BooleanRadioButtonGroup, RadioButton, RadioButtonGroup, Callout } from "@octopusdeploy/design-system-components";
import { logger } from "@octopusdeploy/logging";
import type { AccountResource, DeploymentTargetResource, MachinePolicyResource, MachineResource, ResourceCollection, ServerTimezoneResource, WorkerMachineResource } from "@octopusdeploy/octopus-server-client";
import { KubernetesAgentUpdateBehavior, AccountType, CalamariUpdateBehavior, DeleteMachinesBehavior, HealthCheckType, MachineConnectivityBehavior, MachineScriptPolicyRunType, Permission, ScriptingLanguage, TentacleUpdateBehavior, } from "@octopusdeploy/octopus-server-client";
import type { MachineRpcCallRetryPolicy } from "@octopusdeploy/octopus-server-client/src/resources/machineRpcCallRetryPolicy";
import { links } from "@octopusdeploy/portal-routes";
import { noOp } from "@octopusdeploy/utilities";
import { cloneDeep } from "lodash";
import * as React from "react";
import type { MachinePolicyUpdatedDispatcher } from "~/areas/infrastructure/components/MachinePolicyLayout/Analytics/useNotifyRpcRetryPolicyUpdate";
import { useNotifyMachinePolicyUpdate } from "~/areas/infrastructure/components/MachinePolicyLayout/Analytics/useNotifyRpcRetryPolicyUpdate";
import { repository } from "~/clientInstance";
import { CodeEditor } from "~/components/CodeEditor/CodeEditor";
import FormBaseComponent from "~/components/FormBaseComponent";
import type { FormBaseComponentState } from "~/components/FormBaseComponent/FormBaseComponent";
import FormPage from "~/components/FormPage/FormPage";
import { Form } from "~/components/FormPaperLayout/Form";
import Markdown from "~/components/Markdown";
import InternalLink from "~/components/Navigation/InternalLink/index";
import { type MenuItem, OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import { OverflowMenuConverterVNext } from "~/components/OverflowMenu/OverflowMenuConverterVNext";
import List from "~/components/PagingList";
import { PaperLayoutVNext } from "~/components/PaperLayout/PaperLayoutVNext";
import PermissionCheck from "~/components/PermissionCheck/PermissionCheck";
import TransitionAnimation from "~/components/TransitionAnimation/TransitionAnimation";
import { ExpandableFormSection, FormSectionHeading, MarkdownEditor, Note, required, Summary, Text } from "~/components/form";
import AccountSelect from "~/components/form/AccountSelect/AccountSelect";
import { CardFill } from "~/components/form/Sections/ExpandableFormSection";
import TimeSpanSelector from "~/components/form/TimeSpanSelector/TimeSpanSelector";
import timezoneSummary from "~/components/timezoneSummary";
import ListTitle from "~/primitiveComponents/dataDisplay/ListTitle/ListTitle";
import Select from "~/primitiveComponents/form/Select/Select";
import { TabItem, UrlNavigationTabsContainer } from "~/primitiveComponents/navigation/Tabs";
import CommonSummaryHelper from "~/utils/CommonSummaryHelper/index";
import StringHelper from "~/utils/StringHelper";
import TimeSpanHelper from "~/utils/TimeSpanHelper";
import ExternalLink from "../../../../components/Navigation/ExternalLink/ExternalLink";
import InternalRedirect from "../../../../components/Navigation/InternalRedirect/InternalRedirect";
import getCronExpressionSummary from "../../../projects/components/Triggers/Scheduled/ScheduleEditors/getCronExpressionSummary";
import { InfrastructureLayout, InfrastructureLayoutBusy } from "../InfrastructureLayout/InfrastructureLayout";
enum ScheduleType {
    None = "None",
    Interval = "Interval",
    Cron = "Cron"
}
type MachinePolicyPageProps = MachinePolicyProps & {
    spaceId: string;
};
type MachinePolicyProps = CreateMachinePolicyProps | SpecificMachinePolicyProps;
interface CreateMachinePolicyProps {
    create: true;
}
interface SpecificMachinePolicyProps {
    machinePolicyId: string;
}
type MachinePolicyInnerProps = MachinePolicyPageProps & {
    initialData: InitialData;
    machinePolicyUpdatedDispatcher: MachinePolicyUpdatedDispatcher;
};
class MachineUsingPolicyList extends List<DeploymentTargetResource> {
}
interface MachinePolicyState extends FormBaseComponentState<MachinePolicyResource> {
    deleted: boolean;
    newId: string | null;
    machinesUsingPolicyList: ResourceCollection<DeploymentTargetResource> | undefined;
    workersUsingPolicyList: ResourceCollection<WorkerMachineResource> | undefined;
    scheduleType: ScheduleType;
    accounts: AccountResource[];
}
interface InitialData {
    timezones: ServerTimezoneResource[];
    template: MachinePolicyResource;
    accounts: AccountResource[];
    scheduleType: ScheduleType;
    model: MachinePolicyResource;
    machinesUsingPolicyList: ResourceCollection<DeploymentTargetResource> | undefined;
    workersUsingPolicyList: ResourceCollection<WorkerMachineResource> | undefined;
}
const Title = "Machine Policies";
const MachinePolicyLayoutFormPage = FormPage<InitialData>();
export function MachinePolicyPage(props: MachinePolicyPageProps) {
    const notifyDispatcher = useNotifyMachinePolicyUpdate();
    return (<MachinePolicyLayoutFormPage title={Title} load={async () => {
            const timezones = repository.ServerStatus.getTimezones();
            const accounts = repository.Accounts.all();
            const template = await repository.MachinePolicies.getTemplate();
            const machinePolicy = await (isCreatePolicyProps(props) ? template : repository.MachinePolicies.get(props.machinePolicyId));
            const scheduleType = machinePolicy.MachineHealthCheckPolicy.HealthCheckInterval ? ScheduleType.Interval : machinePolicy.MachineHealthCheckPolicy.HealthCheckCron ? ScheduleType.Cron : ScheduleType.None;
            const machinesUsingPolicyList = isCreatePolicyProps(props) ? undefined : repository.MachinePolicies.getMachines(machinePolicy);
            const workersUsingPolicyList = isCreatePolicyProps(props) ? undefined : repository.MachinePolicies.getWorkers(machinePolicy);
            return {
                template,
                accounts: await accounts,
                model: machinePolicy,
                scheduleType,
                timezones: await timezones,
                machinesUsingPolicyList: await machinesUsingPolicyList,
                workersUsingPolicyList: await workersUsingPolicyList,
            };
        }} renderWhenLoaded={(data) => <MachinePolicyLayoutInner initialData={data} machinePolicyUpdatedDispatcher={notifyDispatcher} {...props}/>} renderAlternate={(args) => <InfrastructureLayoutBusy title={Title} {...args}/>}/>);
}
class MachinePolicyLayoutInner extends FormBaseComponent<MachinePolicyInnerProps, MachinePolicyState, MachinePolicyResource> {
    constructor(props: MachinePolicyInnerProps) {
        super(props);
        this.state = {
            deleted: false,
            newId: null,
            scheduleType: props.initialData.scheduleType,
            machinesUsingPolicyList: props.initialData.machinesUsingPolicyList,
            workersUsingPolicyList: props.initialData.workersUsingPolicyList,
            accounts: props.initialData.accounts,
            model: props.initialData.model,
            cleanModel: cloneDeep(props.initialData.model),
        };
    }
    render() {
        const legacyOverflowActions: Array<MenuItem | MenuItem[]> = [];
        if (!isCreatePolicyProps(this.props) && this.state.model) {
            if (!this.state.model.IsDefault) {
                legacyOverflowActions.push([
                    OverflowMenuItems.deleteItem("Delete", "Are you sure you want to delete this machine policy?", this.handleDeleteConfirm, <div>
                            <p>Any deployment targets that belong to this policy will be reassigned to the default machine policy.</p>
                            <p>Deleting this machine policy is permanent. There is no going back.</p>
                            <p>Do you wish to continue?</p>
                        </div>, { permission: Permission.MachinePolicyDelete }),
                ]);
            }
            legacyOverflowActions.push([
                OverflowMenuItems.navItem("Audit Trail", links.auditPage.generateUrl({ regardingAny: [this.state.model.Id] }), {
                    permission: Permission.EventView,
                    wildcard: true,
                }),
            ]);
        }
        const overflowMenu = OverflowMenuConverterVNext.convertAll(legacyOverflowActions);
        const title = isCreatePolicyProps(this.props) ? "Create Machine Policy" : this.state.model ? this.state.model.Name : StringHelper.ellipsis;
        const saveText: string = this.state.newId ? "Machine policy created" : "Machine policy details updated";
        const numberOfMachines = (this.state.machinesUsingPolicyList && this.state.machinesUsingPolicyList.TotalResults) || 0;
        const numberOfWorkers = (this.state.workersUsingPolicyList && this.state.workersUsingPolicyList.TotalResults) || 0;
        return (<InfrastructureLayout>
                <Form model={this.state.model} cleanModel={this.state.cleanModel} savePermission={{ permission: isCreatePolicyProps(this.props) ? Permission.MachinePolicyCreate : Permission.MachinePolicyEdit }} onSaveClick={this.handleSaveClick} saveText={saveText}>
                    {({ FormContent, createSaveAction }) => (<PaperLayoutVNext title={title} primaryAction={createSaveAction({})} errors={this.errors} overflowActions={overflowMenu.menuItems} busy={this.state.busy} breadcrumbsItems={[{ label: "Machine Policies", pageUrl: links.machinePoliciesPage.generateUrl({ spaceId: this.props.spaceId }) }]}>
                            {overflowMenu.dialogs}
                            <FormContent expandAllOnMount={isCreatePolicyProps(this.props)}>
                                {this.state.deleted && <InternalRedirect to={links.machinePoliciesPage.generateUrl({ spaceId: this.props.spaceId })}/>}
                                {this.state.newId && <InternalRedirect to={links.editMachinePolicyPage.generateUrl({ spaceId: this.props.spaceId, machinePolicyId: this.state.newId })}/>}
                                {this.state.model && (<TransitionAnimation>
                                        <UrlNavigationTabsContainer defaultValue="details">
                                            <TabItem label="Details" value="details">
                                                <ExpandableFormSection errorKey="Name" title="Name" focusOnExpandAll summary={this.state.model.Name ? Summary.summary(this.state.model.Name) : Summary.placeholder("Please enter a name for your machine policy")} help="A short, memorable, unique name for this machine policy.">
                                                    <Text value={this.state.model.Name} onChange={(Name) => this.setModelState({ Name })} label="Name" validate={required("Please enter a machine policy name")} error={this.getFieldError("Name")} autoFocus={true}/>
                                                </ExpandableFormSection>

                                                <ExpandableFormSection errorKey="Description" title="Description" summary={this.descriptionSummary()} help="This summary will appear on the machine policy overview page.">
                                                    <MarkdownEditor value={this.state.model.Description} label="Description" onChange={(Description) => this.setModelState({ Description })}/>
                                                </ExpandableFormSection>

                                                <FormSectionHeading title="Health Checks"/>

                                                <ExpandableFormSection errorKey="ScheduleType" title="Schedule Type" summary={this.getScheduleTypeSummary()} help="Select the schedule that the health check should run on.">
                                                    <RadioButtonGroup value={this.state.scheduleType} onChange={this.handleScheduleTypeChanged}>
                                                        <RadioButton value={ScheduleType.None} label="Never" key={ScheduleType.None}/>
                                                        <Note>No automatic health checks will be performed</Note>
                                                        <RadioButton value={ScheduleType.Interval} label="Interval" key={ScheduleType.Interval}/>
                                                        <Note>Automatic health checks will be scheduled a set duration apart</Note>
                                                        <RadioButton value={ScheduleType.Cron} label="Cron expression" key={ScheduleType.Cron}/>
                                                        <Note>Automatic health checks will run on the schedule specified by the cron expression</Note>
                                                    </RadioButtonGroup>
                                                </ExpandableFormSection>

                                                {this.state.scheduleType === ScheduleType.Interval && this.state.model.MachineHealthCheckPolicy.HealthCheckInterval && (<ExpandableFormSection errorKey="MachineHealthCheckPolicy.HealthCheckInterval" title="Time Between Checks" summary={this.healthCheckIntervalSummary()} help="Select the time between automatic health checks.">
                                                        <TimeSpanSelector value={this.state.model.MachineHealthCheckPolicy.HealthCheckInterval} onChange={(HealthCheckInterval) => this.setChildState2("model", "MachineHealthCheckPolicy", { HealthCheckInterval })}/>
                                                    </ExpandableFormSection>)}

                                                {this.state.scheduleType === ScheduleType.Cron && (<ExpandableFormSection errorKey="HealthCheckCron" title="Cron Expression" summary={getCronExpressionSummary(this.state.model.MachineHealthCheckPolicy.HealthCheckCron)} help="Select the cron expression for the automatic health check schedule.">
                                                        <Text label="Cron expression" value={this.state.model.MachineHealthCheckPolicy.HealthCheckCron || ""} onChange={(HealthCheckCron) => this.setChildState2("model", "MachineHealthCheckPolicy", { HealthCheckCron })}/>
                                                        <Note>{getCronExpressionSummary(this.state.model.MachineHealthCheckPolicy.HealthCheckCron).node}</Note>
                                                        <Note>
                                                            For information about cron expressions, see our docs:
                                                            <ExternalLink href="CronExpressions">Constructing a cron expression in Octopus</ExternalLink>.
                                                        </Note>
                                                    </ExpandableFormSection>)}

                                                {this.state.scheduleType === ScheduleType.Cron && (<ExpandableFormSection errorKey="MachineHealthCheckPolicy.HealthCheckCronTimezone" title="Cron Timezone" summary={timezoneSummary(this.props.initialData.timezones, this.state.model.MachineHealthCheckPolicy.HealthCheckCronTimezone)} help="Select timezone for the cron expression.">
                                                        <Select value={this.state.model.MachineHealthCheckPolicy.HealthCheckCronTimezone} allowClear={true} onChange={(HealthCheckCronTimezone) => {
                            if (HealthCheckCronTimezone) {
                                this.setChildState2("model", "MachineHealthCheckPolicy", { HealthCheckCronTimezone });
                            }
                        }} items={this.props.initialData.timezones.map((pg) => ({ value: pg.Id, text: pg.Name }))} label="Select timezone"/>
                                                        <Note>Schedule run times will be calculated using this timezone.</Note>
                                                    </ExpandableFormSection>)}

                                                <ExpandableFormSection errorKey="HealthCheckType" title="Health Check Type" summary={this.healthCheckTypeSummary()} help="Select the type of health check to perform.">
                                                    <RadioButtonGroup value={this.state.model.MachineHealthCheckPolicy.HealthCheckType} onChange={(x) => this.setChildState2("model", "MachineHealthCheckPolicy", { HealthCheckType: x })}>
                                                        <RadioButton value={HealthCheckType.RunScript} label="Run health check scripts" isDefault={true}/>
                                                        <RadioButton value={HealthCheckType.OnlyConnectivity} label="Only perform connection test (useful for raw scripting)"/>
                                                    </RadioButtonGroup>
                                                    <Note>
                                                        SSH health checks will also check for the presence of <ExternalLink href="SSHTargetRequirements">required dependencies</ExternalLink> unless <code>only perform connection test</code> is
                                                        selected.
                                                    </Note>
                                                </ExpandableFormSection>

                                                {this.state.model.MachineHealthCheckPolicy.HealthCheckType === HealthCheckType.RunScript && (<ExpandableFormSection errorKey="PowerShellHealthCheckPolicy" title="PowerShell Script Policy" fillCardWidth={CardFill.FillRight} summary={this.machineScriptPolicySummary(this.state.model.MachineHealthCheckPolicy.PowerShellHealthCheckPolicy)} help="Select the script policy for endpoints running PowerShell.">
                                                        {!this.state.model.IsDefault && (<RadioButtonGroup value={this.state.model.MachineHealthCheckPolicy.PowerShellHealthCheckPolicy && this.state.model.MachineHealthCheckPolicy.PowerShellHealthCheckPolicy.RunType} onChange={(x) => this.setChildState3("model", "MachineHealthCheckPolicy", "PowerShellHealthCheckPolicy", { RunType: x })}>
                                                                <RadioButton value={MachineScriptPolicyRunType.InheritFromDefault} label="Inherit from default machine policy" isDefault={true}/>
                                                                <RadioButton value={MachineScriptPolicyRunType.Inline} label="Use custom script"/>
                                                            </RadioButtonGroup>)}
                                                        {this.state.model.MachineHealthCheckPolicy.PowerShellHealthCheckPolicy && this.state.model.MachineHealthCheckPolicy.PowerShellHealthCheckPolicy.RunType === MachineScriptPolicyRunType.Inline && (<div>
                                                                <CodeEditor value={this.state.model.MachineHealthCheckPolicy.PowerShellHealthCheckPolicy.ScriptBody} allowFullScreen={true} autoExpand={true} language={ScriptingLanguage.PowerShell} onChange={(x) => this.setChildState3("model", "MachineHealthCheckPolicy", "PowerShellHealthCheckPolicy", { ScriptBody: x })} showToolbar={true} showCopyButton={true} scriptingLanguageSelectorOptions={{
                                supportedLanguages: [ScriptingLanguage.PowerShell],
                                onScriptingLanguageChanged: noOp,
                            }}/>
                                                                <Note>
                                                                    Please see the <ExternalLink href="CustomHealthCheckScripts">documentation on machine policy scripting</ExternalLink>
                                                                </Note>
                                                            </div>)}
                                                    </ExpandableFormSection>)}
                                                {this.state.model.MachineHealthCheckPolicy.HealthCheckType === HealthCheckType.RunScript && (<ExpandableFormSection errorKey="BashHealthCheckPolicy" title="Bash Script Policy" fillCardWidth={CardFill.FillRight} summary={this.machineScriptPolicySummary(this.state.model.MachineHealthCheckPolicy.BashHealthCheckPolicy)} help="Select the script policy for endpoints running bash.">
                                                        {!this.state.model.IsDefault && (<RadioButtonGroup value={this.state.model.MachineHealthCheckPolicy.BashHealthCheckPolicy && this.state.model.MachineHealthCheckPolicy.BashHealthCheckPolicy.RunType} onChange={(x) => this.setChildState3("model", "MachineHealthCheckPolicy", "BashHealthCheckPolicy", { RunType: x })}>
                                                                <RadioButton value={MachineScriptPolicyRunType.InheritFromDefault} label="Inherit from default machine policy" isDefault={true}/>
                                                                <RadioButton value={MachineScriptPolicyRunType.Inline} label="Use custom script"/>
                                                            </RadioButtonGroup>)}
                                                        {this.state.model.MachineHealthCheckPolicy.BashHealthCheckPolicy && this.state.model.MachineHealthCheckPolicy.BashHealthCheckPolicy.RunType === MachineScriptPolicyRunType.Inline && (<div>
                                                                <CodeEditor value={this.state.model.MachineHealthCheckPolicy.BashHealthCheckPolicy.ScriptBody} allowFullScreen={true} autoExpand={true} language={ScriptingLanguage.Bash} onChange={(x) => this.setChildState3("model", "MachineHealthCheckPolicy", "BashHealthCheckPolicy", { ScriptBody: x })} showToolbar={true} showCopyButton={true} scriptingLanguageSelectorOptions={{
                                supportedLanguages: [ScriptingLanguage.Bash],
                                onScriptingLanguageChanged: noOp,
                            }}/>
                                                                <Note>
                                                                    Please see the <ExternalLink href="CustomHealthCheckScripts">documentation on machine policy scripting</ExternalLink>
                                                                </Note>
                                                            </div>)}
                                                    </ExpandableFormSection>)}

                                                <FormSectionHeading title="Machine Connectivity"/>

                                                <ExpandableFormSection errorKey="MachineConnectivityBehavior" title="During Health Checks" summary={this.machineConnectivityBehaviorSummary(this.state.model.MachineConnectivityPolicy.MachineConnectivityBehavior)} help="Select the behavior for machine connectivity.">
                                                    <RadioButtonGroup value={this.state.model.MachineConnectivityPolicy.MachineConnectivityBehavior} onChange={(x) => this.setChildState2("model", "MachineConnectivityPolicy", { MachineConnectivityBehavior: x })}>
                                                        <RadioButton value={MachineConnectivityBehavior.ExpectedToBeOnline} label="Unavailable deployment targets cause health checks to fail" isDefault={true}/>
                                                        <RadioButton value={MachineConnectivityBehavior.MayBeOfflineAndCanBeSkipped} label="Unavailable deployment targets will not cause health checks to fail"/>
                                                    </RadioButtonGroup>
                                                    {this.state.model.MachineConnectivityPolicy.MachineConnectivityBehavior === MachineConnectivityBehavior.MayBeOfflineAndCanBeSkipped && (<Callout title="Health checks" type={"information"}>
                                                            Health checks will succeed without warning if a deployment target in this policy is unavailable.
                                                        </Callout>)}
                                                </ExpandableFormSection>

                                                <ExpandableFormSection errorKey="ConnectionConnectTimeout" title="Connect Timeout" summary={this.getTimespanSummaryOrDefault(TimeSpanHelper.timeSpanTextToHumanReadableString(this.state.model.ConnectionConnectTimeout), "ConnectionConnectTimeout")} help="The amount of time to wait for a listening Tentacle or SSH target to respond to a connection request.">
                                                    <TimeSpanSelector granularity={TimeSpanSelector.HourMinuteSecondGranularity} value={this.state.model.ConnectionConnectTimeout} onChange={(ConnectionConnectTimeout) => this.setModelState({ ConnectionConnectTimeout })} error={this.getFieldError("ConnectionConnectTimeout")}/>
                                                    <Note>The actual timeout may be shorter due to operating system limits</Note>
                                                    {this.getResetLink("ConnectionConnectTimeout", "timeout")}
                                                </ExpandableFormSection>

                                                <ExpandableFormSection errorKey="ConnectionRetryCountLimit" title="Retry Attempts" summary={this.hasDefaultValue("ConnectionRetryCountLimit")
                        ? Summary.default(this.state.model.ConnectionRetryCountLimit + " times")
                        : this.state.model.ConnectionRetryCountLimit
                            ? Summary.summary(this.state.model.ConnectionRetryCountLimit + " times")
                            : Summary.placeholder("Please enter the number of retries")} help="The number of times the connection should be retried if it fails to connect.">
                                                    <Text value={this.state.model.ConnectionRetryCountLimit.toString()} onChange={(v) => this.setModelState({ ConnectionRetryCountLimit: parseInt(v, 10) })} label="Retry Attempts" type="number" min={1} validate={required("Please enter the number of retries")} error={this.getFieldError("ConnectionRetryCountLimit")}/>
                                                    {this.getResetLink("ConnectionRetryCountLimit", "limit")}
                                                </ExpandableFormSection>

                                                <ExpandableFormSection errorKey="ConnectionRetrySleepInterval" title="Retry Wait Interval" summary={this.getTimespanSummaryOrDefault(TimeSpanHelper.timeSpanTextToHumanReadableString(this.state.model.ConnectionRetrySleepInterval), "ConnectionRetrySleepInterval")} help="The amount of time to wait between connection attempts.">
                                                    <TimeSpanSelector granularity={TimeSpanSelector.HourMinuteSecondGranularity} value={this.state.model.ConnectionRetrySleepInterval} onChange={(ConnectionRetrySleepInterval) => this.setModelState({ ConnectionRetrySleepInterval })} error={this.getFieldError("ConnectionRetrySleepInterval")}/>
                                                    {this.getResetLink("ConnectionRetrySleepInterval", "interval")}
                                                </ExpandableFormSection>

                                                <ExpandableFormSection errorKey="ConnectionRetryTimeLimit" title="Retry Time Limit" summary={this.getTimespanSummaryOrDefault(TimeSpanHelper.timeSpanTextToHumanReadableString(this.state.model.ConnectionRetryTimeLimit), "ConnectionRetryTimeLimit")} help="The amount of time after which to stop retry attempts even if the retry count limit has not been reached.">
                                                    <TimeSpanSelector granularity={TimeSpanSelector.HourMinuteSecondGranularity} value={this.state.model.ConnectionRetryTimeLimit} onChange={(ConnectionRetryTimeLimit) => this.setModelState({ ConnectionRetryTimeLimit })} error={this.getFieldError("ConnectionRetryTimeLimit")}/>
                                                    {this.getResetLink("ConnectionRetryTimeLimit", "limit")}
                                                </ExpandableFormSection>

                                                <ExpandableFormSection errorKey="PollingRequestQueueTimeout" title="Polling Request Queue Timeout" summary={this.getTimespanSummaryOrDefault(TimeSpanHelper.timeSpanTextToHumanReadableString(this.state.model.PollingRequestQueueTimeout), "PollingRequestQueueTimeout")} help="The amount of time that the server will wait for a polling tentacle to collect the next request before cancelling the request.">
                                                    <TimeSpanSelector granularity={TimeSpanSelector.HourMinuteSecondGranularity} value={this.state.model.PollingRequestQueueTimeout} onChange={(PollingRequestQueueTimeout) => this.setModelState({ PollingRequestQueueTimeout })} error={this.getFieldError("PollingRequestQueueTimeout")}/>
                                                    {this.getResetLink("PollingRequestQueueTimeout", "timeout")}
                                                </ExpandableFormSection>

                                                <FormSectionHeading title="Machine Updates"/>

                                                <ExpandableFormSection errorKey="CalamariUpdateBehavior" title="Calamari" summary={this.calamariUpdateBehaviorSummary(this.state.model.MachineUpdatePolicy.CalamariUpdateBehavior)} help="Select the behavior for Calamari updates.">
                                                    <RadioButtonGroup value={this.state.model.MachineUpdatePolicy.CalamariUpdateBehavior} onChange={(x) => this.setChildState2("model", "MachineUpdatePolicy", { CalamariUpdateBehavior: x })}>
                                                        <RadioButton value={CalamariUpdateBehavior.UpdateOnDeployment} label="Automatically update Calamari when a deployment target is involved in a deployment" isDefault={true}/>
                                                        <RadioButton value={CalamariUpdateBehavior.UpdateOnNewMachine} label="Automatically update Calamari the first time a deployment target comes online and then when it is involved in a deployment"/>
                                                        <RadioButton value={CalamariUpdateBehavior.UpdateAlways} label="Always keep Calamari up to date"/>
                                                    </RadioButtonGroup>
                                                </ExpandableFormSection>

                                                <ExpandableFormSection errorKey="TentacleUpdateBehavior" title="Tentacle" summary={this.tentacleUpdateBehaviorSummary(this.state.model.MachineUpdatePolicy.TentacleUpdateBehavior)} help="Select the behavior for Tentacle updates.">
                                                    <RadioButtonGroup value={this.state.model.MachineUpdatePolicy.TentacleUpdateBehavior} onChange={(x) => this.setChildState2("model", "MachineUpdatePolicy", { TentacleUpdateBehavior: x })}>
                                                        <RadioButton value={TentacleUpdateBehavior.NeverUpdate} label="Manually - from Deployment Targets" isDefault={true}/>
                                                        <RadioButton value={TentacleUpdateBehavior.Update} label="Automatically"/>
                                                    </RadioButtonGroup>
                                                </ExpandableFormSection>

                                                <ExpandableFormSection errorKey="TentacleUpdateAccountId" title="Tentacle Update Account" summary={CommonSummaryHelper.resourceSummary(this.state.model.MachineUpdatePolicy.TentacleUpdateAccountId, this.state.accounts, "account")} help={<span>
                                                            Select the{" "}
                                                            <InternalLink to={links.infrastructureAccountsPage.generateUrl({ spaceId: this.props.spaceId })} openInSelf={false}>
                                                                account
                                                            </InternalLink>{" "}
                                                            to use for Tentacle updates.
                                                        </span>}>
                                                    <AccountSelect onRequestRefresh={this.refreshAccounts} type={AccountType.UsernamePassword} value={this.state.model.MachineUpdatePolicy.TentacleUpdateAccountId} allowClear={true} onChange={(x) => this.setChildState2("model", "MachineUpdatePolicy", { TentacleUpdateAccountId: x })} items={this.state.accounts}/>
                                                    {this.state.model.MachineUpdatePolicy.TentacleUpdateAccountId !== "" && (<Callout title="Warning" type={"warning"}>
                                                            The selected account will be used for Tentacle updates instead of the service account running Tentacle. If Tentacle is running as <strong>Local System</strong> this option will not work. See{" "}
                                                            <ExternalLink href="TentacleUpdateAccount">the documentation on Tentacle update account.</ExternalLink>
                                                        </Callout>)}
                                                </ExpandableFormSection>

                                                <ExpandableFormSection errorKey="KubernetesAgentUpdateBehavior" title="Kubernetes" summary={this.kubernetesAgentUpdateBehaviorSummary(this.state.model.MachineUpdatePolicy.KubernetesAgentUpdateBehavior)} help="Select the behavior for Kubernetes agent and worker updates.">
                                                    <RadioButtonGroup value={this.state.model.MachineUpdatePolicy.KubernetesAgentUpdateBehavior} onChange={(x) => this.setChildState2("model", "MachineUpdatePolicy", { KubernetesAgentUpdateBehavior: x })}>
                                                        <RadioButton value={KubernetesAgentUpdateBehavior.NeverUpdate} label="Manually"/>
                                                        <Note>
                                                            Update <InternalLink to={links.deploymentTargetsPage.generateUrl({ spaceId: this.props.spaceId })}>Kubernetes agents</InternalLink> and{" "}
                                                            <InternalLink to={links.workerMachinesPage.generateUrl({ spaceId: this.props.spaceId })}>Kubernetes workers</InternalLink> manually via the UI.
                                                        </Note>
                                                        <RadioButton value={KubernetesAgentUpdateBehavior.Update} label="Automatically" isDefault={true}/>
                                                    </RadioButtonGroup>
                                                </ExpandableFormSection>

                                                <FormSectionHeading title="Recover from Communication Errors with Tentacle"/>

                                                <ExpandableFormSection key="IsRpcCallRetryEnabled" errorKey="MachineRpcCallRetryPolicy.Enabled" title="Manage" summary={this.state.model.MachineRpcCallRetryPolicy.Enabled
                        ? Summary.summary("Allow Octopus to re-attempt failed communications with Tentacle is Enabled")
                        : Summary.default("Allow Octopus to re-attempt failed communications with Tentacle is Disabled")} help="Allow Octopus to re-attempt failed communications with Tentacle">
                                                    <BooleanRadioButtonGroup value={this.state.model.MachineRpcCallRetryPolicy.Enabled} onChange={(x) => this.setChildState2("model", "MachineRpcCallRetryPolicy", { Enabled: x })} accessibleName={"RPC retries"}>
                                                        <BooleanRadioButton value={false} label="Disabled" isDefault={true}/>
                                                        <BooleanRadioButton value={true} label="Enabled"/>
                                                    </BooleanRadioButtonGroup>
                                                    <Note>
                                                        <p>By enabling this feature you allow Octopus to re-attempt failed communication with Tentacle for a set duration.</p>
                                                        <p>
                                                            <b>When disabled</b>
                                                        </p>
                                                        <p>If a network error occurs while Octopus Deploy is communicating with Tentacle, the deployment, runbook run or health check will fail.</p>
                                                        <p>
                                                            <b>When enabled</b>
                                                        </p>
                                                        <p>If a network error occurs while Octopus Deploy is communicating with Tentacle, it will re-attempt communication with Tentacle for the amount of time configured below.</p>
                                                        <p>However, if communication with Tentacle continues to fail after the lapsed duration, the deployment, runbook run or health check will fail.</p>
                                                    </Note>
                                                </ExpandableFormSection>

                                                <ExpandableFormSection errorKey="MachineRpcCallRetryPolicy.RetryDuration" title="During a Deployment or Runbook Run, Re-attempt Failed Communication with Tentacle for" summary={this.getTimespanSummaryOrDefaultForRpcRetryPolicy(TimeSpanHelper.timeSpanTextToHumanReadableString(this.state.model.MachineRpcCallRetryPolicy.RetryDuration), "RetryDuration")} help="Maximum amount of time allowed during a deployment or runbook run to re-attempt failed communication with Tentacle">
                                                    <TimeSpanSelector granularity={TimeSpanSelector.MinuteSecondGranularity} value={this.state.model.MachineRpcCallRetryPolicy.RetryDuration} onChange={(RetryDuration) => this.setChildState2("model", "MachineRpcCallRetryPolicy", { RetryDuration })}/>
                                                    {this.getResetLinkRpcRetryPolicy("RetryDuration", "duration")}
                                                </ExpandableFormSection>

                                                <ExpandableFormSection errorKey="MachineRpcCallRetryPolicy.HealthCheckRetryDuration" title="During a Health Check, Re-attempt Failed Communication with Tentacle for" summary={this.getTimespanSummaryOrDefaultForRpcRetryPolicy(TimeSpanHelper.timeSpanTextToHumanReadableString(this.state.model.MachineRpcCallRetryPolicy.HealthCheckRetryDuration), "HealthCheckRetryDuration")} help="Maximum amount of time allowed during a health check to re-attempt failed communication with Tentacle">
                                                    <TimeSpanSelector granularity={TimeSpanSelector.MinuteSecondGranularity} value={this.state.model.MachineRpcCallRetryPolicy.HealthCheckRetryDuration} onChange={(HealthCheckRetryDuration) => this.setChildState2("model", "MachineRpcCallRetryPolicy", { HealthCheckRetryDuration })}/>
                                                    {this.getResetLinkRpcRetryPolicy("HealthCheckRetryDuration", "duration")}
                                                </ExpandableFormSection>

                                                <FormSectionHeading title="Cleaning Up Unavailable Deployment Targets"/>

                                                <ExpandableFormSection errorKey="DeleteMachinesBehavior" title="Behavior" summary={this.deleteMachinesBehaviorSummary(this.state.model.MachineCleanupPolicy.DeleteMachinesBehavior)} help="Select the behavior for deleting deployment targets.">
                                                    <RadioButtonGroup value={this.state.model.MachineCleanupPolicy.DeleteMachinesBehavior} onChange={(x) => this.setChildState2("model", "MachineCleanupPolicy", { DeleteMachinesBehavior: x })}>
                                                        <RadioButton value={DeleteMachinesBehavior.DoNotDelete} label="Do not delete deployment targets automatically" isDefault={true}/>
                                                        <RadioButton value={DeleteMachinesBehavior.DeleteUnavailableMachines} label="Automatically delete unavailable machines"/>
                                                    </RadioButtonGroup>
                                                    {this.state.model.MachineCleanupPolicy.DeleteMachinesBehavior === DeleteMachinesBehavior.DeleteUnavailableMachines && (<div>
                                                            <TimeSpanSelector value={this.state.model.MachineCleanupPolicy.DeleteMachinesElapsedTimeSpan} onChange={(x) => this.setChildState2("model", "MachineCleanupPolicy", { DeleteMachinesElapsedTimeSpan: x })}/>
                                                            <Callout title="Warning" type={"warning"}>
                                                                Please be aware this will cause unavailable deployment targets to be deleted during health checks if they do not become available within this time.
                                                            </Callout>
                                                        </div>)}
                                                </ExpandableFormSection>
                                            </TabItem>
                                            {this.state.model.Id && (<TabItem label="Usage" value="usage" onActive={() => this.onUsageTabActive()}>
                                                    <PermissionCheck permission={Permission.MachineView} wildcard={true}>
                                                        <ExpandableFormSection errorKey="InUseByMachines" title="Deployment Targets" summary={numberOfMachines > 0
                            ? Summary.summary(<div>
                                                                              This policy is being used by <b>{numberOfMachines}</b> {numberOfMachines === 1 ? "deployment target" : "deployment targets"}.
                                                                          </div>)
                            : Summary.summary(<div>This policy is not currently used by any machines.</div>)} help={numberOfMachines > 0 ? (<div>
                                                                        This policy is being used by <b>{numberOfMachines}</b> {numberOfMachines === 1 ? "deployment target" : "deployment targets"}.
                                                                    </div>) : (<div>This policy is not currently used by any machines.</div>)}>
                                                            <div>
                                                                {numberOfMachines > 0 && (<MachineUsingPolicyList initialData={this.state.machinesUsingPolicyList} onRow={(item) => this.buildMachineUsingPolicyRow(item)} onRowRedirectUrl={(machine: DeploymentTargetResource) => links.deploymentTargetSettingsPage.generateUrl({ spaceId: machine.SpaceId, machineId: machine.Id })} onFilter={this.filterMachines} filterSearchEnabled={true} autoFocusOnFilterSearch={false} apiSearchParams={["partialName"]} filterHintText="Filter by name..." showPagingInNumberedStyle={true}/>)}
                                                            </div>
                                                        </ExpandableFormSection>
                                                    </PermissionCheck>
                                                    <PermissionCheck permission={Permission.WorkerView} wildcard={true}>
                                                        <ExpandableFormSection errorKey="InUseByWorkers" title="Workers" summary={numberOfWorkers > 0
                            ? Summary.summary(<div>
                                                                              This policy is being used by <b>{numberOfWorkers}</b> {numberOfWorkers === 1 ? "worker" : "workers"}.
                                                                          </div>)
                            : Summary.summary(<div>This policy is not currently used by any machines.</div>)} help={numberOfWorkers > 0 ? (<div>
                                                                        This policy is being used by <b>{numberOfWorkers}</b> {numberOfWorkers === 1 ? "worker" : "workers"}.
                                                                    </div>) : (<div>This policy is not currently used by any machines.</div>)}>
                                                            <div>
                                                                {numberOfWorkers > 0 && (<List initialData={this.state.workersUsingPolicyList} onRow={(item) => this.buildMachineUsingPolicyRow(item)} onRowRedirectUrl={(worker: WorkerMachineResource) => links.workerMachineSettingsPage.generateUrl({ spaceId: worker.SpaceId, machineId: worker.Id })} onFilter={this.filterMachines} filterSearchEnabled={true} autoFocusOnFilterSearch={false} apiSearchParams={["partialName"]} filterHintText="Filter by name..." showPagingInNumberedStyle={true}/>)}
                                                            </div>
                                                        </ExpandableFormSection>
                                                    </PermissionCheck>
                                                </TabItem>)}
                                        </UrlNavigationTabsContainer>
                                    </TransitionAnimation>)}
                            </FormContent>
                        </PaperLayoutVNext>)}
                </Form>
            </InfrastructureLayout>);
    }
    private async onUsageTabActive() {
        if (this.state.machinesUsingPolicyList || isCreatePolicyProps(this.props)) {
            return;
        }
        await this.doBusyTask(async () => {
            const [machinesUsingPolicyResponse, workersUsingPolicyResponse] = await Promise.all([repository.MachinePolicies.getMachines(this.state.model), repository.MachinePolicies.getWorkers(this.state.model)]);
            this.setState({
                machinesUsingPolicyList: machinesUsingPolicyResponse,
                workersUsingPolicyList: workersUsingPolicyResponse,
            });
        });
    }
    private refreshAccounts = () => {
        return this.doBusyTask(async () => {
            this.setState({ accounts: await repository.Accounts.all() });
        });
    };
    private handleScheduleTypeChanged = (scheduleTypeString: string) => {
        const scheduleType = scheduleTypeString as ScheduleType;
        this.setState({ scheduleType });
        this.setChildState2("model", "MachineHealthCheckPolicy", {
            HealthCheckInterval: scheduleType === ScheduleType.Interval ? this.state.cleanModel.MachineHealthCheckPolicy.HealthCheckInterval || "1.00:00:00" : undefined,
            HealthCheckCron: scheduleType === ScheduleType.Cron ? this.state.cleanModel.MachineHealthCheckPolicy.HealthCheckCron : undefined,
        });
    };
    private buildMachineUsingPolicyRow(machine: MachineResource) {
        return (<div>
                <ListTitle>{machine.Name}</ListTitle>
            </div>);
    }
    private filterMachines(filter: string, resource: MachineResource) {
        return !filter || filter.length === 0 || !resource || resource.Name.toLowerCase().includes(filter.toLowerCase());
    }
    private descriptionSummary() {
        return this.state.model.Description ? Summary.summary(<Markdown markup={this.state.model.Description}/>) : Summary.placeholder("No description provided");
    }
    private healthCheckIntervalSummary() {
        if (this.state.model.MachineHealthCheckPolicy.HealthCheckInterval) {
            return Summary.summary(TimeSpanHelper.timeSpanTextToHumanReadableString(this.state.model.MachineHealthCheckPolicy.HealthCheckInterval));
        }
    }
    private getScheduleTypeSummary() {
        let summary = "";
        switch (this.state.scheduleType) {
            case ScheduleType.None:
                summary = "Never";
                break;
            case ScheduleType.Interval:
                summary = "Runs at a set interval";
                break;
            case ScheduleType.Cron:
                summary = "Runs according to a cron expression";
                break;
            default:
                return Summary.placeholder("Please select a schedule type");
        }
        return Summary.summary(summary);
    }
    private healthCheckTypeSummary() {
        switch (this.state.model.MachineHealthCheckPolicy.HealthCheckType) {
            case HealthCheckType.RunScript:
                return Summary.default("Run health check scripts");
            case HealthCheckType.OnlyConnectivity:
                return Summary.summary("Only perform connection test");
        }
    }
    private timeSpanHasDefaultValue(key: keyof MachinePolicyResource) {
        return TimeSpanHelper.timeSpanTextValuesAreEqual(this.state.model[key] as string, this.props.initialData.template[key] as string);
    }
    private getTimespanSummaryOrDefaultForRpcRetryPolicy(text: string, key: keyof MachineRpcCallRetryPolicy) {
        return TimeSpanHelper.timeSpanTextValuesAreEqual(this.state.model.MachineRpcCallRetryPolicy[key] as string, this.props.initialData.template.MachineRpcCallRetryPolicy[key] as string) ? Summary.default(text) : Summary.summary(text);
    }
    private getTimespanSummaryOrDefault(text: string, key: keyof MachinePolicyResource) {
        return this.timeSpanHasDefaultValue(key) ? Summary.default(text) : Summary.summary(text);
    }
    private hasDefaultValue(key: keyof MachinePolicyResource) {
        return this.state.model[key] === this.props.initialData.template[key];
    }
    private hasDefaultValueRpcRetryPolicy(key: keyof MachineRpcCallRetryPolicy) {
        return this.state.model.MachineRpcCallRetryPolicy[key] === this.props.initialData.template.MachineRpcCallRetryPolicy[key];
    }
    private getResetLink(key: keyof MachinePolicyResource, valueDescription: string) {
        if (this.hasDefaultValue(key)) {
            return null;
        }
        const onClick = (e: {
            preventDefault: () => void;
        }) => {
            e.preventDefault();
            const newState: Partial<MachinePolicyResource> = {
                [key]: this.props.initialData.template[key],
            };
            this.setModelState(newState as Pick<MachinePolicyResource, keyof MachinePolicyResource>);
        };
        return (<Note>
                <a href="#" onClick={onClick}>
                    Reset to the default {valueDescription}
                </a>
            </Note>);
    }
    private getResetLinkRpcRetryPolicy(key: keyof MachineRpcCallRetryPolicy, valueDescription: string) {
        if (this.hasDefaultValueRpcRetryPolicy(key)) {
            return null;
        }
        const onClick = (e: {
            preventDefault: () => void;
        }) => {
            e.preventDefault();
            const newState: Partial<MachineRpcCallRetryPolicy> = {
                [key]: this.props.initialData.template.MachineRpcCallRetryPolicy[key],
            };
            this.setChildState2("model", "MachineRpcCallRetryPolicy", newState as Pick<MachineRpcCallRetryPolicy, keyof MachineRpcCallRetryPolicy>);
        };
        return (<Note>
                <a href="#" onClick={onClick}>
                    Reset to the default {valueDescription}
                </a>
            </Note>);
    }
    private machineScriptPolicySummary(value: {
        RunType: MachineScriptPolicyRunType;
    }) {
        if (!value) {
            logger.error("Expecting a valid MachineScriptPolicy.");
            return;
        }
        switch (value.RunType) {
            case MachineScriptPolicyRunType.InheritFromDefault:
                return Summary.default("Inherit from default machine policy");
            case MachineScriptPolicyRunType.Inline:
                return Summary.summary("Use custom script");
            default:
                return Summary.summary(<div>Unsupported MachineScriptPolicyRunType " + {value.RunType}</div>);
        }
    }
    private machineConnectivityBehaviorSummary(value: MachineConnectivityBehavior) {
        switch (value) {
            case MachineConnectivityBehavior.ExpectedToBeOnline:
                return Summary.default("Unavailable deployment targets cause health checks to fail");
            case MachineConnectivityBehavior.MayBeOfflineAndCanBeSkipped:
                return Summary.summary("Unavailable deployment targets will not cause health checks to fail");
            default:
                return Summary.summary("Unsupported MachineConnectivityBehavior");
        }
    }
    private calamariUpdateBehaviorSummary(value: CalamariUpdateBehavior) {
        switch (value) {
            case CalamariUpdateBehavior.UpdateOnDeployment:
                return Summary.default("Automatically update Calamari when a deployment target is involved in a deployment");
            case CalamariUpdateBehavior.UpdateOnNewMachine:
                return Summary.summary("Automatically update Calamari the first time a deployment target comes online and then when it is involved in a deployment");
            case CalamariUpdateBehavior.UpdateAlways:
                return Summary.summary("Always keep Calamari up to date");
            default:
                return Summary.summary("Unsupported CalamariUpdateBehavior");
        }
    }
    private tentacleUpdateBehaviorSummary(value: TentacleUpdateBehavior) {
        switch (value) {
            case TentacleUpdateBehavior.NeverUpdate:
                return Summary.default("Manually - from Deployment Targets");
            case TentacleUpdateBehavior.Update:
                return Summary.summary("Automatically");
            default:
                return Summary.summary("Unsupported TentacleUpdateBehavior");
        }
    }
    private kubernetesAgentUpdateBehaviorSummary(value: KubernetesAgentUpdateBehavior) {
        switch (value) {
            case KubernetesAgentUpdateBehavior.NeverUpdate:
                return Summary.summary("Manually");
            case KubernetesAgentUpdateBehavior.Update:
                return Summary.default("Automatically");
            default:
                return Summary.summary("Unsupported KubernetesAgentUpdateBehavior");
        }
    }
    private deleteMachinesBehaviorSummary(value: DeleteMachinesBehavior) {
        switch (value) {
            case DeleteMachinesBehavior.DoNotDelete:
                return Summary.default("Do not delete deployment targets automatically");
            case DeleteMachinesBehavior.DeleteUnavailableMachines:
                return Summary.summary(<div>Automatically delete unavailable deployment targets every {TimeSpanHelper.timeSpanTextToHumanReadableString(this.state.model.MachineCleanupPolicy.DeleteMachinesElapsedTimeSpan as string)}</div>);
            default:
                return Summary.summary("Unsupported DeleteMachinesBehavior");
        }
    }
    private handleSaveClick = async () => {
        await this.doBusyTask(async () => {
            const isNew = this.state.model.Id == null;
            const result = await repository.MachinePolicies.save(this.state.model);
            this.props.machinePolicyUpdatedDispatcher({ previous: this.state.cleanModel, current: result });
            this.setState({
                model: result,
                cleanModel: cloneDeep(result),
                newId: isNew ? result.Id : null,
            });
        });
    };
    private handleDeleteConfirm = async () => {
        await repository.MachinePolicies.del(this.state.model);
        this.setState((state) => {
            return {
                model: null,
                cleanModel: null, //reset model so that dirty state doesn't prevent navigation
                deleted: true,
            };
        });
        return true;
    };
    static displayName = "MachinePolicyLayoutInner";
}
function isCreatePolicyProps(props: MachinePolicyProps): props is CreateMachinePolicyProps {
    return "create" in props;
}
