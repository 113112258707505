import { css, cx } from "@emotion/css";
import { space, themeTokens } from "@octopusdeploy/design-system-tokens";
import type { LinkHref } from "@octopusdeploy/portal-routes";
import { resolvePathWithSpaceId } from "@octopusdeploy/portal-routes";
import { isUrl } from "app/components/Navigation/InternalLink/isUrl";
import cn from "classnames";
import { forwardRef } from "react";
import * as React from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { client } from "~/clientInstance";
import { useUrlResolver } from "~/components/Navigation/useUrlResolver";
import styles from "./style.module.less";
export type FontWeight = "normal" | "bold" | "bolder" | "lighter" | 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900;
interface InternalLinkProps {
    size?: number;
    weight?: FontWeight;
    width?: string;
    color?: string;
    className?: string;
    to: LinkHref;
    openInSelf?: boolean;
    onClick?: () => void;
    children?: React.ReactNode;
    accessibleName?: string;
    isDisabled?: boolean;
    showOpensInNewTabIcon?: boolean;
}
const InternalLink = forwardRef<HTMLAnchorElement, InternalLinkProps>(({ size, weight, width, color, className, to, openInSelf = true, onClick, children, accessibleName, isDisabled, showOpensInNewTabIcon = false }, ref) => {
    const params = useParams<{
        spaceId?: string;
    }>();
    const urlResolver = useUrlResolver();
    const handleClick = (e: React.MouseEvent<HTMLAnchorElement, {}>) => {
        if (isDisabled)
            return;
        if (onClick) {
            onClick();
        }
        e.stopPropagation();
    };
    const style: React.CSSProperties = {};
    if (size) {
        style.fontSize = size + "rem";
    }
    if (weight) {
        style.fontWeight = weight;
    }
    if (width) {
        style.width = width;
    }
    if (color) {
        style.color = color;
    }
    if (isDisabled) {
        style.color = themeTokens.color.text.disabled;
        style.pointerEvents = "none";
    }
    // The client's spaceId and the route's spaceId should be in sync,
    // but because we haven't modelled this relationship in a reliable way,
    // this is not guaranteed to be true, particularly in tests
    const spaceId = (params.spaceId || client.spaceId) ?? undefined;
    const resolvedPath = isUrl(to) ? urlResolver.resolve(to) : resolvePathWithSpaceId(to, spaceId);
    /* eslint-disable react/forbid-elements */
    return (<Link aria-label={accessibleName} aria-disabled={isDisabled} className={cn(styles.internalLink, className)} style={style} to={resolvedPath} target={openInSelf ? "_self" : "_blank"} onClick={handleClick} innerRef={ref}>
            {children}
            {showOpensInNewTabIcon && !openInSelf && <OpensInNewTabIcon />}
        </Link>);
});
export const OpensInNewTabIcon = () => <em className={cx("fa-solid fa-external-link", iconStyles)} aria-hidden="true"/>;
export default InternalLink;
const iconStyles = css({
    marginLeft: space[4],
});
