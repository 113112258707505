import { css } from "@emotion/css";
import type { SimpleMenuItem, PageAction, PrimaryPageAction } from "@octopusdeploy/design-system-components";
import { space } from "@octopusdeploy/design-system-tokens";
import type { ProjectResource, RunbookProcessResource, RunbookResource, EnvironmentResource, ResourceCollection, RunbooksDashboardItemResource, TenantResource, RunbookRunTemplateResource, RunbookSnapshotResource, GitRefResource, TaskListBffArgs, } from "@octopusdeploy/octopus-server-client";
import { HasRunbooksInGit, Permission, TaskState } from "@octopusdeploy/octopus-server-client";
import type { TaskFilterState } from "@octopusdeploy/portal-routes";
import { links, TaskDateFilterTypeValues, TaskFilterStateValues } from "@octopusdeploy/portal-routes";
import moment from "moment";
import React, { useState } from "react";
import { useProjectContext } from "~/areas/projects/context";
import { repository } from "~/clientInstance";
import type { DoBusyTask, Errors } from "~/components/DataBaseComponent";
import DataBaseComponent, { useDoBusyTaskEffect } from "~/components/DataBaseComponent";
import { NoResults } from "~/components/NoResults/NoResults";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import { useRefreshLoop } from "~/hooks/useRefreshLoop";
import { PageHeaderBranchSelectorVNext } from "../../BranchSelector/PageHeaderBranchSelector";
import { useProjectRunbooksGitRef, type GitRefQueryParamsProps } from "../../BranchSelector/useProjectGitRef";
import { LastPublishedChip } from "../LastPublishedChip";
import { RunbooksPaperLayout } from "../Layouts";
import PublishButton from "../PublishButton";
import RunNowButton from "../RunNowButton";
import { useRunbookContext } from "../RunbookContext";
import RunbookOnboarding from "../RunbookOnboarding";
import { ScheduledTriggersBar } from "../Triggers/ScheduledTriggersBar";
import { RunbookRunList } from "./RunbookRunList";
import type { RunbookTaskFilter } from "./RunbooksAdvancedFilterLayout";
import { RunbooksAdvancedFilterLayout } from "./RunbooksAdvancedFilterLayout";
interface NewRunbookRunsListPageProps extends GitRefQueryParamsProps {
    spaceId: string;
}
export class NewRunbookRunsListPage extends DataBaseComponent<NewRunbookRunsListPageProps> {
    constructor(props: NewRunbookRunsListPageProps) {
        super(props);
        this.state = {};
    }
    componentDidMount() {
        this.clearErrors();
    }
    render() {
        return <NewRunbookRunsListPageContainer {...this.props} spaceId={this.props.spaceId} doBusyTask={this.doBusyTask} busy={this.state.busy} errors={this.errors}/>;
    }
    static displayName = "NewRunbookRunsListPage";
}
interface NewRunbookRunsListPageContainerProps extends NewRunbookRunsListPageProps {
    doBusyTask: DoBusyTask;
    busy?: Promise<unknown> | boolean;
    errors?: Errors;
}
function NewRunbookRunsListPageContainer(props: NewRunbookRunsListPageContainerProps) {
    const runbookContext = useRunbookContext();
    const projectContext = useProjectContext();
    const [runbookProcess, setRunbookProcess] = useState<RunbookProcessResource>();
    const [publishedRunbookSnapshot, setPublishedRunbookSnapshot] = useState<RunbookSnapshotResource>();
    const [runbookRunTemplate, setRunbookRunTemplate] = useState<RunbookRunTemplateResource>();
    const project = projectContext.state && projectContext.state.model;
    const runbook = runbookContext.state && runbookContext.state.runbook;
    const [gitRef, setGitRef] = useProjectRunbooksGitRef(project, props.queryParams, props.setQueryParams);
    const hasGitRunbooks = HasRunbooksInGit(project.PersistenceSettings);
    const getData = async () => {
        const publishedRunbookSnapshot = runbook && runbook.PublishedRunbookSnapshotId ? await repository.RunbookSnapshots.get(runbook.PublishedRunbookSnapshotId) : undefined;
        const runbookRunTemplate = publishedRunbookSnapshot ? await repository.RunbookSnapshots.getRunbookRunTemplate(publishedRunbookSnapshot) : undefined;
        return { publishedRunbookSnapshot, runbookRunTemplate };
    };
    useDoBusyTaskEffect(props.doBusyTask, async () => {
        if (!project || !runbook) {
            return;
        }
        const process = hasGitRunbooks ? await repository.Runbooks.getRunbookProcess(project, runbook.RunbookProcessId, projectContext.state.gitRef?.CanonicalName) : await repository.Runbooks.getRunbookProcess(project, runbook.RunbookProcessId);
        setRunbookProcess(process);
        const { publishedRunbookSnapshot, runbookRunTemplate } = await getData();
        setPublishedRunbookSnapshot(publishedRunbookSnapshot);
        setRunbookRunTemplate(runbookRunTemplate);
    }, [project, runbook]);
    function getPrimaryAction(runbook: RunbookResource | undefined, runbookProcess: RunbookProcessResource | undefined): PrimaryPageAction | undefined {
        if (!runbook || !runbookProcess) {
            return undefined;
        }
        if (runbookProcess.Steps.length === 0) {
            return {
                type: "navigate",
                label: "Define your Runbook Process",
                path: links.projectRunbookProcessListPage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug, runbookId: runbook.Id, processId: runbook.RunbookProcessId }),
                hasPermissions: isAllowed({ permission: Permission.RunbookEdit, project: project.Id, wildcard: true }),
            };
        }
        return {
            type: "custom",
            key: "Run now",
            content: <RunNowButton spaceId={props.spaceId}/>,
            hasPermissions: isAllowed({
                permission: Permission.RunbookRunCreate,
                project: project.Id,
                wildcard: true,
            }),
        };
    }
    function getPageActions(runbook: RunbookResource | undefined, runbookProcess: RunbookProcessResource | undefined): PageAction[] {
        if (!runbook || !runbookProcess) {
            return [];
        }
        if (hasGitRunbooks) {
            return [];
        }
        if (runbookProcess.Steps.length === 0) {
            return [];
        }
        const isRunbookRunTemplateModified = runbookRunTemplate && (runbookRunTemplate.IsRunbookProcessModified || runbookRunTemplate.IsVariableSetModified || runbookRunTemplate.IsLibraryVariableSetModified);
        return [
            {
                type: "custom",
                key: "Publish",
                content: <PublishButton publishedRunbookSnapshot={publishedRunbookSnapshot} isRunbookRunTemplateModified={isRunbookRunTemplateModified}/>,
                hasPermissions: isAllowed({
                    permission: Permission.RunbookEdit,
                    project: project.Id,
                    wildcard: true,
                }),
            },
        ];
    }
    function getOverflowActions(runbook: RunbookResource | undefined): SimpleMenuItem[] | undefined {
        if (!runbook) {
            return undefined;
        }
        const overflowActions: SimpleMenuItem[] = [];
        const isAllowedToViewAudit = isAllowed({
            permission: Permission.EventView,
            wildcard: true,
        });
        if (!hasGitRunbooks) {
            overflowActions.push({
                type: "internal-link",
                label: "View Snapshots",
                path: links.projectRunbookSnapshotsPage.generateUrl({ spaceId: runbook.SpaceId, projectSlug: project.Slug, runbookId: runbook.Id }),
            });
        }
        if (isAllowedToViewAudit) {
            overflowActions.push({
                type: "internal-link",
                label: "Audit Trail",
                path: links.auditPage.generateUrl({ projects: [project.Id], documentTypes: ["Runbooks"], regardingAny: [runbook.Id] }),
            });
        }
        return overflowActions;
    }
    const primaryAction = getPrimaryAction(runbook, runbookProcess);
    const overflowActions = getOverflowActions(runbook);
    const pageActions = getPageActions(runbook, runbookProcess);
    const isRunbookRunTemplateModified = runbookRunTemplate && (runbookRunTemplate.IsRunbookProcessModified || runbookRunTemplate.IsVariableSetModified || runbookRunTemplate.IsLibraryVariableSetModified);
    const titleChip = publishedRunbookSnapshot && <LastPublishedChip project={project} publishedRunbookSnapshot={publishedRunbookSnapshot} isRunbookRunTemplateModified={isRunbookRunTemplateModified}/>;
    return (<RunbooksPaperLayout title={runbook?.Name} titleChip={titleChip} breadcrumbsItems={[{ label: "Runbooks", pageUrl: links.projectRunbooksPage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug }) }]} busy={props.busy} primaryAction={primaryAction} pageActions={pageActions} overflowActions={overflowActions} errors={props.errors} titleAccessory={<PageHeaderBranchSelectorVNext project={project} gitRef={gitRef ?? props.queryParams.gitRef} setGitRef={setGitRef}/>}>
            {runbook && <ScheduledTriggersBar project={project} doBusyTask={props.doBusyTask} runbook={runbook}/>}
            {runbook && <NewRunbookRunsListPageInternal project={project} runbook={runbook} hasGitRunbooks={hasGitRunbooks} gitRef={projectContext.state.gitRef} {...props}/>}
        </RunbooksPaperLayout>);
}
interface NewRunbookRunsListPageInternalProps {
    project: ProjectResource;
    runbook: RunbookResource;
    doBusyTask: DoBusyTask;
    hasGitRunbooks: boolean;
    gitRef?: GitRefResource;
}
function getTaskStatesFromFilterState(taskFilterState?: TaskFilterState) {
    switch (taskFilterState) {
        case TaskFilterStateValues.Incomplete:
            return [TaskState.Queued, TaskState.Executing, TaskState.Cancelling].join(",");
        case TaskFilterStateValues.Completed:
            return [TaskState.Canceled, TaskState.Success, TaskState.Failed, TaskState.TimedOut].join(",");
        case TaskFilterStateValues.Unsuccessful:
            return [TaskState.Canceled, TaskState.Failed, TaskState.TimedOut].join(",");
        case TaskFilterStateValues.Queued:
            return TaskState.Queued;
        case TaskFilterStateValues.Executing:
            return TaskState.Executing;
        case TaskFilterStateValues.Cancelling:
            return TaskState.Cancelling;
        case TaskFilterStateValues.Success:
            return TaskState.Success;
        case TaskFilterStateValues.Canceled:
            return TaskState.Canceled;
        case TaskFilterStateValues.TimedOut:
            return TaskState.TimedOut;
        case TaskFilterStateValues.Failed:
            return TaskState.Failed;
        case TaskFilterStateValues.Running:
            return [TaskState.Executing, TaskState.Cancelling].join(",");
        default:
            return undefined;
    }
}
function composeDateFilter(filter: RunbookTaskFilter) {
    // We add one second to 'toDate' because moment considers the end of day to be 11:59:59.
    // We convert it to UTC because that's how we store dates in the backend.
    switch (filter.dateFilterType) {
        case TaskDateFilterTypeValues.CompletedTime:
            return {
                fromCompletedDate: moment.utc(filter.fromDate).format(),
                toCompletedDate: moment.utc(filter.toDate).add(1, "second").format(),
            };
        case TaskDateFilterTypeValues.QueueTime:
            return {
                fromQueueDate: moment.utc(filter.fromDate).format(),
                toQueueDate: moment.utc(filter.toDate).add(1, "second").format(),
            };
        case TaskDateFilterTypeValues.StartTime:
            return {
                fromStartDate: moment.utc(filter.fromDate).format(),
                toStartDate: moment.utc(filter.toDate).add(1, "second").format(),
            };
        default:
            return {};
    }
}
function NewRunbookRunsListPageInternal({ project, runbook, doBusyTask, hasGitRunbooks, gitRef }: NewRunbookRunsListPageInternalProps) {
    const defaultFilter: RunbookTaskFilter = {
        hasPendingInterruptions: false,
        hasWarningsOrErrors: false,
        fromDate: moment().subtract(1, "month").startOf("day").toDate(),
        toDate: moment().endOf("day").toDate(),
    };
    const pageSize = 30;
    const [take, setTake] = useState<number>(pageSize);
    const [runbookProcess, setRunbookProcess] = useState<RunbookProcessResource>();
    const [environments, setEnvironments] = useState<EnvironmentResource[]>();
    const [tenants, setTenants] = useState<TenantResource[]>();
    const [hasMoreTasks, setHasMoreTasks] = useState<boolean>();
    const [runbookRuns, setRunbookRuns] = useState<ResourceCollection<RunbooksDashboardItemResource>>();
    const [filter, setFilter] = useState<RunbookTaskFilter>(defaultFilter);
    const refresh = useDoBusyTaskEffect(doBusyTask, async () => {
        if (!project || !runbook) {
            return;
        }
        const processPromise = hasGitRunbooks ? repository.Runbooks.getRunbookProcess(project, runbook.RunbookProcessId, gitRef?.CanonicalName) : repository.Runbooks.getRunbookProcess(project, runbook.RunbookProcessId);
        const searchFilter: TaskListBffArgs = {
            states: getTaskStatesFromFilterState(filter.state),
            project: runbook.ProjectId,
            runbook: runbook.Id,
            environment: filter.environment,
            tenant: filter.tenant,
            spaces: [runbook.SpaceId],
            hasPendingInterruptions: filter.hasPendingInterruptions ? true : undefined, // There is a bug in the endpoint that will not return any results if this is false, need to not supply it at all.
            hasWarningsOrErrors: filter.hasWarningsOrErrors ? true : undefined,
            includeSystem: false,
            ...composeDateFilter(filter),
            take: take,
        };
        const tasks = await repository.Tasks.getBffList(searchFilter);
        const taskIds = tasks.Items.map((task) => task.Id);
        setHasMoreTasks(tasks.Items.length < tasks.TotalResults);
        const runs = await repository.Runbooks.getTaskList(project, runbook.Id, { take: taskIds.length, taskIds });
        setRunbookRuns(runs);
        setRunbookProcess(await processPromise);
    }, [project, runbook, filter, take]);
    useRefreshLoop(refresh, 6000);
    useDoBusyTaskEffect(doBusyTask, async () => {
        const environmentsPromise = repository.Environments.all();
        if (isAllowed({ permission: Permission.TenantView, tenant: "*" })) {
            const tenants = await repository.Tenants.all();
            setTenants(tenants);
        }
        setEnvironments(await environmentsPromise);
    }, []);
    function onSetFilter(callback: (prev: RunbookTaskFilter) => RunbookTaskFilter) {
        setTake(pageSize);
        setFilter(callback);
    }
    const styles = {
        noRunsContainer: css({
            marginTop: space[24],
            marginBottom: space[24],
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
        }),
    };
    // If we're still loading, don't render anything
    if (!runbookProcess || !runbookRuns || !environments || !tenants) {
        return null;
    }
    if (runbookProcess.Steps.length === 0 && runbookRuns.Items.length === 0) {
        // Show onboarding if there are no steps and no runs. We don't want to show onboarding if there are
        // runs but they've just deleted the process.
        return <RunbookOnboarding />;
    }
    if (runbookRuns.TotalResults === 0) {
        return (<div className={styles.noRunsContainer}>
                There are no runs for this Runbook yet.
                <NoResults />
            </div>);
    }
    return (<RunbooksAdvancedFilterLayout defaultFilter={defaultFilter} filter={filter} setFilter={onSetFilter} environments={environments} tenants={tenants}>
            {runbookRuns.Items.length > 0 ? (<RunbookRunList project={project} runs={runbookRuns.Items} environments={environments} tenants={tenants} canLoadMore={!!hasMoreTasks} onLoadMore={() => {
                setTake((currentTake) => currentTake + pageSize);
            }}/>) : (<div className={styles.noRunsContainer}>
                    There are no matching runs.
                    <NoResults />
                </div>)}
        </RunbooksAdvancedFilterLayout>);
}
