import { css, cx } from "@emotion/css";
//eslint-disable-next-line @octopusdeploy/custom-portal-rules/no-restricted-imports
//eslint-disable-next-line @octopusdeploy/custom-portal-rules/no-restricted-imports
import { ClickAwayListener } from "@material-ui/core";
//eslint-disable-next-line @octopusdeploy/custom-portal-rules/no-restricted-imports
import { IconButton, Popover, SearchIcon } from "@octopusdeploy/design-system-components";
import { borderRadius, colorScales, space, text, themeTokens } from "@octopusdeploy/design-system-tokens";
import MobileDetect from "mobile-detect";
import React, { useCallback, useRef } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import type { DataBaseComponentState, DoBusyTask, Errors } from "~/components/DataBaseComponent";
import DataBaseComponent from "~/components/DataBaseComponent";
import type { ReasonForActivation } from "~/globalSearch/analytics/useTrackGlobalSearchActivated";
import { useTrackGlobalSearchActivated } from "~/globalSearch/analytics/useTrackGlobalSearchActivated";
import type { TextInput } from "~/primitiveComponents/form/Text/Text";
import { DebounceText } from "~/primitiveComponents/form/Text/Text";
import type { GlobalSearchRef } from "./GlobalSearch";
import GlobalSearch from "./GlobalSearch";
interface SearchAndOpenPopoverProps {
    isFullWidth: boolean;
}
//eslint-disable-next-line @typescript-eslint/no-empty-interface
interface SearchAndOpenPopoverState extends DataBaseComponentState {
}
// We're just using a class component to inject our doBusyTask. When we have functional equivlanets, this can be removed.
export class SearchAndOpenPopover extends DataBaseComponent<SearchAndOpenPopoverProps, SearchAndOpenPopoverState> {
    render() {
        return <SearchAndOpenPopoverInternal doBusyTask={this.doBusyTask} busy={this.state?.busy} errors={this.errors} isFullWidth={this.props.isFullWidth}/>;
    }
    static displayName = "SearchAndOpenPopover";
}
interface SearchAndOpenPopoverInternalProps extends SearchAndOpenPopoverProps {
    doBusyTask: DoBusyTask;
    busy?: Promise<void>;
    errors?: Errors;
}
interface SearchAndOpenPopoverState {
    keyword: string;
    isPopoverOpen: boolean;
}
const SearchAndOpenPopoverInternal: React.FC<SearchAndOpenPopoverInternalProps> = ({ doBusyTask, busy, errors, isFullWidth }) => {
    const trackGlobalSearchActivated = useTrackGlobalSearchActivated();
    const firstMenuItem = useRef<GlobalSearchRef | null>(null);
    const [popoverState, setPopoverState] = React.useState<SearchAndOpenPopoverState>({ keyword: "", isPopoverOpen: false });
    const searchInputRef = useRef<TextInput | null>(null);
    const searchAnchorElement = useRef<HTMLDivElement | null>(null);
    const handleOpen = (e: React.MouseEvent | null, reason: ReasonForActivation | "Forced") => {
        if (reason !== "Forced") {
            trackGlobalSearchActivated(reason);
        }
        e?.preventDefault();
        setPopoverState((prevState) => ({ ...prevState, isPopoverOpen: true }));
        // Need to scroll to top for mobile, or the results stay at the top of the container.
        const md = new MobileDetect(window.navigator.userAgent);
        if (md.isPhoneSized()) {
            window.scroll({ top: 0, left: 0 });
        }
    };
    const handleClose = useCallback(() => {
        setPopoverState((prevState) => ({ ...prevState, isPopoverOpen: false, keyword: "" }));
    }, [setPopoverState]);
    useHotkeys("ctrl+space", () => {
        handleOpen(null, "HotKey");
    }, {
        enableOnFormTags: ["INPUT", "SELECT", "TEXTAREA"],
    }, []);
    const handleSearchBlur = useCallback(() => {
        searchInputRef?.current?.focus();
    }, []);
    React.useEffect(() => {
        if (popoverState.isPopoverOpen) {
            searchInputRef?.current?.focus();
        }
        else {
            searchInputRef?.current?.blur();
        }
    }, [popoverState.isPopoverOpen, searchInputRef]);
    const stylesForSearchInput = useStylesForSearchAndPopover(isFullWidth);
    const onKeyDownInSearchInput = useCallback((event: React.KeyboardEvent<{}>) => {
        if (event.key === "ArrowDown") {
            firstMenuItem.current?.focusFirstItem();
            event.preventDefault();
        }
        else if (event.key === "Tab") {
            handleClose();
        }
    }, [handleClose]);
    const onKeyDownAnywhere = useCallback((event: React.KeyboardEvent<{}>) => {
        if (event.key === "Escape") {
            handleClose();
        }
    }, [handleClose]);
    return (<ClickAwayListener onClickAway={handleClose}>
            <div className={rootStyles} onKeyDown={onKeyDownAnywhere} onClick={() => {
            searchInputRef?.current?.focus();
        }}>
                <DebounceText autoComplete={"off"} inputProps={{
            "aria-label": "Search",
            "aria-placeholder": "Type Ctrl+Space to search...",
            startAdornment: <GlobalSearchIcon />,
            endAdornment: popoverState.keyword && (<IconButton className={stylesForSearchInput.clearSearchButton} onClick={() => {
                    // Refocus when they cancel/clear text to make typing your next search easy.
                    searchInputRef?.current?.focus();
                    setPopoverState((prevState) => ({ ...prevState, keyword: "" }));
                }} icon="Cancel"/>),
            classes: stylesForSearchInput.input,
            disableUnderline: true,
        }} value={popoverState.keyword ?? ""} 
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    onChange={(e: any) => {
            setPopoverState((prevState) => ({ ...prevState, keyword: e }));
            if (!popoverState.isPopoverOpen) {
                handleOpen(null, "Forced");
            }
        }} onFocus={(e) => {
            if (!popoverState.isPopoverOpen) {
                handleOpen(null, "Focused");
            }
            // Automatically select all if the user re-opens the search with an existing keyword (UX nicety).
            if (popoverState.keyword) {
                searchInputRef?.current?.select();
            }
        }} textInputRef={(inputElement) => {
            searchInputRef.current = inputElement;
        }} inputContainerRef={(inputContainerElement) => (searchAnchorElement.current = inputContainerElement)} onKeyDown={onKeyDownInSearchInput} margin="dense" customMargins="0"/>
                {!popoverState.isPopoverOpen && (<div className={searchInputPlaceholderStyles}>
                        Type <span className={globalSearchShortcutStyles}>Ctrl+Space</span> to search or switch projects...
                    </div>)}
                <Popover placement="bottom-start" anchorEl={searchAnchorElement.current} open={popoverState.isPopoverOpen}>
                    <GlobalSearch onBlur={handleSearchBlur} keyword={popoverState.keyword.trim()} doBusyTask={doBusyTask} busy={busy} errors={errors} handleClose={handleClose} ref={firstMenuItem}/>
                </Popover>
            </div>
        </ClickAwayListener>);
};
SearchAndOpenPopoverInternal.displayName = "SearchAndOpenPopoverInternal"
function GlobalSearchIcon() {
    return (<div className={searchIconWrapperStyles}>
            <SearchIcon />
        </div>);
}
const useStylesForSearchAndPopover = (isFullWidth: boolean) => {
    return {
        input: {
            root: cx(searchInputRootStyles, {
                [searchInputRootFullWidthStyles]: isFullWidth,
                [searchInputRootFixedWidthStyles]: !isFullWidth,
            }),
            input: searchInputStyles,
        },
        clearSearchButton: clearSearchButtonStyles,
    };
};
const rootStyles = css({
    position: "relative",
    display: "flex",
    alignItems: "center",
    cursor: "text",
});
const searchInputRootStyles = css({
    height: 36,
    padding: `0 ${space[12]}`,
    border: "1px solid",
    borderRadius: borderRadius.small,
    borderColor: themeTokens.color.textField.border.default,
    ":hover": {
        borderColor: themeTokens.color.textField.border.hover,
    },
    ":focus-within": {
        borderColor: themeTokens.color.textField.border.active,
    },
    transitionTimingFunction: "ease-in-out",
    transitionProperty: "background-color",
    transitionDuration: "0.2s",
});
const searchInputRootFixedWidthStyles = css({
    width: "23.5rem",
    ":focus-within": {
        borderColor: themeTokens.color.textField.border.active,
    },
});
const searchInputRootFullWidthStyles = css({
    width: "100%",
});
const searchInputStyles = css({
    "&.MuiInputBase-input": {
        padding: `0 ${space[8]}`,
        color: themeTokens.color.text.primary,
        "&::placeholder": {
            color: colorScales.navy[100],
            opacity: 1,
        },
    },
});
const clearSearchButtonStyles = css({
    "--iconNeutral": colorScales.navy[400],
});
const searchInputPlaceholderStyles = css({
    position: "absolute",
    left: "2.5rem",
    right: "1rem",
    font: text.regular.default.small,
    color: themeTokens.color.text.tertiary,
    pointerEvents: "none",
    whiteSpace: "nowrap",
    overflow: "clip",
});
const globalSearchShortcutStyles = css({
    font: text.regular.bold.small,
});
const searchIconWrapperStyles = css({
    display: "flex",
    alignItems: "center",
    height: "20px",
    width: "20px",
    color: themeTokens.color.icon.tertiary,
});
export default SearchAndOpenPopover;
