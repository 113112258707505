import { css } from "@emotion/css";
import { space } from "@octopusdeploy/design-system-tokens";
import type { ReactNode } from "react";
import React from "react";
import type { ActionEvent, AnalyticActionDispatcher, AnalyticErrorCallback, AnalyticTrackedActionDispatcher } from "~/analytics/Analytics";
import { Action } from "~/analytics/Analytics";
import { repository } from "~/clientInstance";
import { LifecycleContextualHelp } from "~/components/ContextualHelp/ContextualHelpSnippets";
import { DataBaseComponent } from "~/components/DataBaseComponent/index";
import { OnboardingDialogFrame } from "~/components/OnboardingDialog/OnboardingDialog";
import CreateEnvironmentsDialogLayout from "~/components/ProjectBasedActivation/CreateEnvironmentsDialogLayout";
import { GuidedSetupFrame } from "../OnboardingDialog/GuidedSetupFrame";
import addEnvironmentsImageDark from "./assets/img-environments-dark.svg";
import addEnvironmentsImageLight from "./assets/img-environments-light.svg";
interface CreateEnvironmentsPageProps {
    spaceId: string;
    fullScreen?: boolean;
    title?: ReactNode;
    isKubernetesFlow?: boolean;
    nextButtonTitle?: string;
    skipButtonTitle?: string;
    hideBackButton?: boolean;
    isOnboarding?: boolean;
    next: () => void;
    skip?: () => void;
    close: () => void;
    trackAction: AnalyticTrackedActionDispatcher;
    dispatchAction: AnalyticActionDispatcher;
}
export class CreateEnvironmentsPage extends DataBaseComponent<CreateEnvironmentsPageProps, {}> {
    private async saveEnvironment(name: string, order: number) {
        return await repository.Environments.create({
            Name: name,
            Description: "",
            UseGuidedFailure: false,
            AllowDynamicInfrastructure: true,
            SortOrder: order,
            ExtensionSettings: [],
        }, {});
    }
    private async saveEnvironments(names: Array<string>) {
        await this.doBusyTask(async () => {
            const ev: ActionEvent = {
                action: Action.Add,
                resource: "Environment",
                data: {
                    target: names.join(";"),
                },
            };
            await this.props.trackAction("First Environments Created", ev, async (cb: AnalyticErrorCallback) => {
                await Promise.all(names.map((name, index) => this.saveEnvironment(name, index)));
            });
        });
    }
    private areEnvironmentsValid(environments: Array<string>): boolean {
        return !environments.some((env) => !this.isEnvironmentNameValid(env)) && !this.hasDuplicates(environments);
    }
    private isEnvironmentNameValid(envName: string): boolean {
        // This validation rules comes from CreateEnvironmentCommand.
        const maxLength = 50;
        // The full set of invalid characters can vary by file system.
        // This list comes from https://learn.microsoft.com/en-us/dotnet/api/system.io.path.getinvalidpathchars
        const invalidPathAndFileNameChars = ["\"", "<", ">", "|"];
        const invalidChars = [...invalidPathAndFileNameChars, "{", "}"];
        return !!envName && envName.length <= maxLength && !envName.split("").some((x) => invalidChars.includes(x));
    }
    public hasDuplicates(environments: Array<string>): boolean {
        return !!environments.map((x) => x.trim()).filter((x, index) => environments.indexOf(x) !== index).length;
    }
    render() {
        const { spaceId, fullScreen, title, isKubernetesFlow } = this.props;
        const helpPanelContent = !isKubernetesFlow ? (<div>
                Octopus organizes the servers and services where you deploy your software into <strong>environments</strong>. They represent the stages of your deployment pipeline, known in Octopus as a <strong>Lifecycle</strong>
                <LifecycleContextualHelp position={fullScreen ? "bottom-start" : "right-start"}/>.
            </div>) : (<div>
                Octopus organizes the Kubernetes clusters where you deploy your software into <strong>environments</strong>. They represent the stages of your deployment pipeline, known in Octopus as a <strong>Lifecycle</strong>
                <LifecycleContextualHelp position={fullScreen ? "bottom-start" : "right-start"}/>.
            </div>);
        const onToggleHelp = () => {
            this.props.dispatchAction("Toggle Create Environments Dialog Help Section", { action: Action.Toggle, resource: "Project" });
        };
        const createEnvironmentsPageContent = (<CreateEnvironmentsDialogLayout spaceId={spaceId} title={title} fullScreen={fullScreen} skipButtonTitle={this.props.skipButtonTitle} onSkipClick={() => {
                this.props.dispatchAction("Skip First Environments Step", { action: Action.Cancel, resource: "Environment" });
                this.props.skip?.() ?? this.props.next();
                return true;
            }} saveButtonTitle={this.props.nextButtonTitle} onSaveClick={async (environments: Array<string>) => {
                const nonEmptyEnvironments = environments.filter((e) => e);
                if (!this.areEnvironmentsValid(nonEmptyEnvironments)) {
                    return false;
                }
                await this.saveEnvironments(nonEmptyEnvironments);
                this.props.next();
                return true;
            }} hideBackButton={this.props.hideBackButton}/>);
        return !fullScreen ? (<OnboardingDialogFrame name="create-environments" helpPanelImage={{ src: addEnvironmentsImageLight, altText: "Create Environments Image", darkThemeSrc: addEnvironmentsImageDark }} helpPanelContent={helpPanelContent} onToggleHelp={onToggleHelp} onCloseDialog={this.props.close}>
                {createEnvironmentsPageContent}
            </OnboardingDialogFrame>) : (<GuidedSetupFrame helpPanelImage={{ src: addEnvironmentsImageLight, altText: "Create Environments Image", darkThemeSrc: addEnvironmentsImageDark }} helpPanelContent={helpPanelContent} showThemeSelector={this.props.isOnboarding} onClose={this.props.close}>
                <div className={styles.noFrameFlexFullScreen}>{createEnvironmentsPageContent}</div>
            </GuidedSetupFrame>);
    }
    static displayName = "CreateEnvironmentsPage";
}
const styles = {
    noFrameFlexFullScreen: css({
        marginTop: space[40],
        height: "100%",
        // remove padding around Create Environments dialog
        "& > div": {
            padding: 0,
        },
    }),
};
