import { css } from "@emotion/css";
import { space } from "@octopusdeploy/design-system-tokens";
import type { GitRefResource } from "@octopusdeploy/octopus-server-client";
import { HasRunbooksInGit, HasVariablesInGit } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import CannotCommitCallout from "~/areas/projects/components/VersionControl/CannotCommitCallout";
import { useProjectContext } from "~/areas/projects/context/index";
export function GitCallouts(props: {
    isVariablesPage?: boolean;
    isRunbooksPage?: boolean;
    gitRefResource: GitRefResource | undefined;
}) {
    const projectContext = useProjectContext();
    if (props.isVariablesPage && !HasVariablesInGit(projectContext.state.model.PersistenceSettings))
        return null;
    if (props.isRunbooksPage && !HasRunbooksInGit(projectContext.state.model.PersistenceSettings))
        return null;
    return (<div className={calloutsStyles}>
            <CannotCommitCallout gitRef={props.gitRefResource}/>
        </div>);
}
const calloutsStyles = css({
    display: "flex",
    flexDirection: "column",
    gap: space["8"],
});
