import { css } from "@emotion/css";
import { ActionButton, ActionButtonType, LinearProgress } from "@octopusdeploy/design-system-components";
import { borderRadius, letterSpacing, text, themeTokens } from "@octopusdeploy/design-system-tokens";
import * as React from "react";
import { required, Text } from "~/components/form/index";
interface WorkerPoolCreationFormProps {
    model: WorkerPoolConfiguration;
    onModelChange: (model: WorkerPoolConfiguration) => void;
    onCreate: (model: WorkerPoolConfiguration) => void;
    onCancel: () => void;
    notProvidedErrorMessage: string;
    helpText: string;
    isBusy: boolean;
    error?: WorkerPoolFieldErrors;
}
export interface WorkerPoolConfiguration {
    name: string;
}
export type WorkerPoolFieldErrors = {
    [K in keyof WorkerPoolConfiguration]?: string;
};
export const WorkerPoolCreationForm = (props: WorkerPoolCreationFormProps) => (<div>
        <LinearProgress variant={"indeterminate"} show={props.isBusy}/>
        <div className={formContainerStyles}>
            <h3 className={titleStyles}>{"Create new worker pool"}</h3>
            <Text value={props.model.name} helperText={props.helpText} onChange={(name) => props.onModelChange({ ...props.model, name })} label={"Name"} autoFocus={true} warning={props.error?.name} validate={required(props.notProvidedErrorMessage)}/>
            <div className={actionsContainerStyles}>
                <ActionButton label={"Cancel"} type={ActionButtonType.Ternary} onClick={(e) => {
        e.preventDefault();
        props.onCancel();
    }}/>
                <ActionButton label={"Create Worker Pool"} type={ActionButtonType.Primary} onClick={(e) => {
        e.preventDefault();
        props.onCreate(props.model);
    }}/>
            </div>
        </div>
    </div>);
const formContainerStyles = css({
    width: "100%",
    height: "auto",
    padding: "1rem",
    backgroundColor: themeTokens.color.background.secondary.default,
    marginBlockEnd: "1rem",
    borderRadius: borderRadius.small,
});
const titleStyles = css({
    font: text.regular.bold.large,
    letterSpacing: letterSpacing.wide,
    margin: 0,
});
const actionsContainerStyles = css({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "end",
    gap: "1rem",
});
