import { LinearProgress, PageHeaderPrimary } from "@octopusdeploy/design-system-components";
import type { InsightsReportResource } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import { compact } from "lodash";
import * as React from "react";
import type { ReportFilter } from "~/areas/insights/components/Reports/ReportMetricsLayout/useReportFilters";
import type { Errors } from "~/components/DataBaseComponent/index";
import { Navigation } from "~/components/NavigationSidebarLayout/index";
import { PortalPageLayout } from "~/components/PortalPageLayout/PortalPageLayout";
import { ErrorPanel } from "~/components/form/index";
export interface InsightsReportLayoutProps {
    report: InsightsReportResource;
    reportFilter?: ReportFilter;
    children: React.ReactNode;
}
export function InsightsReportLayout({ report, children, reportFilter }: InsightsReportLayoutProps) {
    const sidebarLinks = [
        Navigation.navItem("Overview", links.insightsReportOverviewPage.generateUrl({ spaceId: report.SpaceId, reportId: report.Id }, reportFilter)),
        Navigation.navItem("Deployment frequency", links.insightsReportDeploymentFrequencyPage.generateUrl({ spaceId: report.SpaceId, reportId: report.Id }, reportFilter)),
        Navigation.navItem("Deployment lead time", links.insightsReportDeploymentLeadTimePage.generateUrl({ spaceId: report.SpaceId, reportId: report.Id }, reportFilter)),
        Navigation.navItem("Deployment failure rate", links.insightsReportDeploymentFailureRatePage.generateUrl({ spaceId: report.SpaceId, reportId: report.Id }, reportFilter)),
        Navigation.navItem("Mean time to recovery", links.insightsReportDeploymentMeanTimeToRecoveryPage.generateUrl({ spaceId: report.SpaceId, reportId: report.Id }, reportFilter)),
        Navigation.navItem("Deployment duration", links.insightsReportDeploymentDurationPage.generateUrl({ spaceId: report.SpaceId, reportId: report.Id }, reportFilter)),
        Navigation.navItem("Settings", links.insightsReportSettingsPage.generateUrl({ spaceId: report.SpaceId, reportId: report.Id })),
    ];
    const computedSidebarLinks = compact(sidebarLinks);
    return (<main>
            <PortalPageLayout description={report.Description} navItems={computedSidebarLinks} content={children} header={<PageHeaderPrimary breadcrumbs={[{ label: "Insights", pageUrl: links.insightReportListPage.generateUrl({ spaceId: report.SpaceId }) }]} title={report.Name} logo={{ href: report.Links.Logo, accessibleName: `${report.Name} logo` }}/>}/>
        </main>);
}
export interface InsightsReportLoadingLayoutProps {
    errors: Errors | undefined;
    busy: boolean;
}
export function InsightsReportLoadingLayout({ busy, errors }: InsightsReportLoadingLayoutProps) {
    return (<main>
            <PageHeaderPrimary title="Insights"/>
            <LinearProgress variant={"indeterminate"} show={busy}/>
            {errors && <ErrorPanel message={errors.message} errors={errors.errors} parsedHelpLinks={errors.parsedHelpLinks} helpText={errors.helpText} helpLink={errors.helpLink}/>}
        </main>);
}
