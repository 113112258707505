import { css } from "@emotion/css";
import { IconButton, Tooltip } from "@octopusdeploy/design-system-components";
import { OctopusLogoIcon } from "@octopusdeploy/design-system-components/src/components/Icon/OctopusLogoIcon";
import { borderRadius, fontSize, fontWeight, space, text, themeTokens } from "@octopusdeploy/design-system-tokens";
import React from "react";
import { FullScreenDialogFrame } from "../DialogLayout/Custom";
import { ThemeSelector } from "../Theme/ThemeSelector";
import { useThemePaletteType } from "../Theme/useThemePaletteType";
import type { HelpPanelImage } from "./HelpPanel";
type GuidedSetupFrameProps = {
    children: React.ReactNode;
    onClose: () => void;
    helpPanelContent?: React.ReactNode;
    helpPanelImage?: HelpPanelImage;
    showThemeSelector?: boolean;
};
export function GuidedSetupFrame(props: GuidedSetupFrameProps) {
    const isDarkModeEnabled = useThemePaletteType() === "dark";
    function getHelpPanelImageSrc() {
        return isDarkModeEnabled && props.helpPanelImage?.darkThemeSrc ? props.helpPanelImage.darkThemeSrc : props.helpPanelImage?.src;
    }
    return (<FullScreenDialogFrame className={styles.fullScreenDialogFrame}>
            <div className={styles.closeButton}>
                <Tooltip content="Close Project Setup">
                    <IconButton icon="Cancel" onClick={props.onClose}/>
                </Tooltip>
            </div>
            <div className={styles.container}>
                <div className={styles.leftPanel}>
                    <div className={styles.header}>
                        <div className={styles.title.logoContainer}>
                            <OctopusLogoIcon />
                        </div>
                        {props.showThemeSelector && (<div className={styles.themeToggleContainer}>
                                <div className="themeToggleLabel">Theme</div>
                                <ThemeSelector></ThemeSelector>
                            </div>)}
                    </div>
                    {props.children}
                </div>
                <div className={styles.rightPanel}>
                    <div className={styles.helpPanelWrapper}>
                        {props.helpPanelImage && <img src={getHelpPanelImageSrc()} alt={props.helpPanelImage.altText}/>}
                        {props.helpPanelContent}
                    </div>
                </div>
            </div>
        </FullScreenDialogFrame>);
}
const mediumWidth = "600px";
const largeWidth = "1074px";
const styles = {
    fullScreenDialogFrame: css({
        zIndex: 100,
        backgroundColor: themeTokens.color.background.primary.default,
    }),
    container: css({
        display: "grid",
        gridTemplateColumns: "minmax(0, 1fr)",
        height: "100vmax",
        backgroundColor: themeTokens.color.background.primary.default,
        [`@media (min-width: ${largeWidth})`]: {
            gridTemplateColumns: "minmax(0, 2fr) minmax(0, 1fr)",
        },
    }),
    leftPanel: css({
        width: "100%",
        maxWidth: "900px",
        marginLeft: "auto",
        marginRight: "auto",
        padding: space[24],
        display: "flex",
        flexDirection: "column",
        backgroundColor: themeTokens.color.background.primary.default,
        [`@media (min-width: ${mediumWidth})`]: {
            padding: `${space[48]} ${space[64]}`,
        },
    }),
    rightPanel: css({
        display: "none",
        backgroundColor: themeTokens.color.background.secondary.default,
        [`@media (min-width: ${largeWidth})`]: {
            display: "block",
        },
        "& img": css({
            height: "auto",
            margin: 0,
            marginBottom: space[16],
            width: "100%",
            borderRadius: borderRadius.small,
        }),
    }),
    helpPanelWrapper: css({
        font: text.regular.default.large,
        display: "block",
        margin: "auto",
        maxWidth: `calc(360px + calc(${space[24]} * 2))`,
        padding: `128px ${space[24]} 0`,
        height: "100%",
        ["@media (min-width: 1300px)"]: {
            maxWidth: "360px",
            padding: `128px 0 0`,
        },
        ["@media (min-width: 2200px)"]: {
            padding: `128px ${space[48]} 0`,
            maxWidth: `calc(456px + calc(${space[48]} * 2))`,
        },
        ["@media (min-width: 2560px)"]: {
            padding: `128px 0 0`,
            maxWidth: "456px",
        },
        p: { marginTop: 0 },
    }),
    header: css({
        display: "flex",
        justifyContent: "space-between",
    }),
    closeButton: css({
        top: space[24],
        right: space[24],
        alignSelf: "flex-end",
        "& svg": {
            fill: themeTokens.color.button.icon.neutral,
            height: "20px",
            width: "20px",
        },
        "&&": {
            position: "absolute",
            "& button": {
                padding: space[6],
            },
        },
    }),
    title: {
        logoContainer: css({
            display: "flex",
            alignItems: "center",
            position: "relative",
            left: "-4px",
            svg: {
                height: "32px",
            },
        }),
    },
    themeToggleContainer: css({
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        gap: space[12],
        "& .themeToggleLabel": {
            color: themeTokens.color.text.secondary,
            fontSize: fontSize["medium"],
            fontWeight: fontWeight["400"],
        },
    }),
};
