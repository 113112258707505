/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { ActionButton, ActionButtonType, Callout } from "@octopusdeploy/design-system-components";
import type { PrimaryPageAction } from "@octopusdeploy/design-system-components";
import type { BasicRepository, KubernetesTentacleEndpointResource, MachineConnectionStatus, MachineResource, NewMachineResource, TentacleEndpointResource } from "@octopusdeploy/octopus-server-client";
import { CommunicationStyle, EndpointsHelper, MachineModelHealthStatus, UpgradeStatus } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import { noOp } from "@octopusdeploy/utilities";
import * as React from "react";
import { repository } from "~/clientInstance";
import type { DataBaseComponentState } from "~/components/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent";
import FormPage from "~/components/FormPage/FormPage";
import { PaperLayoutVNext } from "~/components/PaperLayout/PaperLayoutVNext";
import { Section } from "~/components/Section/Section";
import TaskLogLines from "~/components/TaskLogLines/TaskLogLines";
import { Note } from "~/components/form";
import { DataTable } from "~/primitiveComponents/dataDisplay/DataTable/DataTable";
import { DataTableBody } from "~/primitiveComponents/dataDisplay/DataTable/DataTableBody";
import { DataTableRow } from "~/primitiveComponents/dataDisplay/DataTable/DataTableRow";
import { DataTableRowColumn } from "~/primitiveComponents/dataDisplay/DataTable/DataTableRowColumn";
import { DataTableRowHeaderColumn } from "~/primitiveComponents/dataDisplay/DataTable/DataTableRowHeaderColumn";
import DateFormatter from "~/utils/DateFormatter";
import InternalRedirect from "../../../../components/Navigation/InternalRedirect/InternalRedirect";
interface MachineConnectionsBaseProps {
    repository: BasicRepository<MachineResource, NewMachineResource>;
    itemDescription: string;
    getConnectionStatus(machine: MachineResource): Promise<MachineConnectionStatus>;
}
interface MachineConnectionsProps {
    machineId: string;
}
type MachineConnectionsPageProps = MachineConnectionsBaseProps & MachineConnectionsProps;
interface MachineConnectionsInternalProps extends MachineConnectionsPageProps {
    initialData: InitialData;
}
interface MachineConnectionsState extends DataBaseComponentState {
    redirectToTaskId?: string;
    machine: MachineResource;
}
interface InitialData {
    machine: MachineResource;
    connectionStatus: MachineConnectionStatus;
}
interface CommonVersionDetails {
    Version: string | null;
    UpgradeStatus: UpgradeStatus;
    UpgradeLocked: boolean;
}
export function DeploymentTargetConnectionsPage({ machineId }: MachineConnectionsProps) {
    return <MachineConnectionsPage repository={repository.Machines} itemDescription={"deployment target"} getConnectionStatus={(machine: MachineResource) => repository.Machines.getConnectionStatus(machine)} machineId={machineId}/>;
}
export function WorkerMachineConnectionsPage({ machineId }: MachineConnectionsProps) {
    return <MachineConnectionsPage repository={repository.Workers} itemDescription={"worker machine"} getConnectionStatus={(machine: MachineResource) => repository.Workers.getConnectionStatus(machine)} machineId={machineId}/>;
}
const Title = "Connectivity";
const MachineConnectionsFormPage = FormPage<InitialData>();
const MachineConnectionsPage: React.FC<MachineConnectionsPageProps> = (props: MachineConnectionsPageProps) => {
    return (<MachineConnectionsFormPage title={Title} load={async () => {
            const machineId = props.machineId;
            const machine = await props.repository.get(machineId);
            const connectionStatus = await props.getConnectionStatus(machine);
            return { machineId, machine, connectionStatus };
        }} renderWhenLoaded={(data) => <MachineConnectionsLayoutInternal initialData={data} {...props}/>}/>);
};
MachineConnectionsPage.displayName = "MachineConnectionsPage"
class MachineConnectionsLayoutInternal extends DataBaseComponent<MachineConnectionsInternalProps, MachineConnectionsState> {
    constructor(props: MachineConnectionsInternalProps) {
        super(props);
        this.state = {
            machine: props.initialData.machine,
        };
    }
    render() {
        if (this.state.redirectToTaskId) {
            return <InternalRedirect to={links.taskPage.generateUrl({ taskId: this.state.redirectToTaskId })} push={true}/>;
        }
        const checkHealthPageAction: PrimaryPageAction = {
            type: "button",
            label: "Check health",
            disabled: this.state.busy,
            onClick: () => this.performHealthCheck(),
        };
        const communicationsSection = this.renderCommunicationStyleInfo();
        const calamariUpgradeSection = this.renderCalamariUpgradeInfo();
        const tentacleSection = this.renderTentacleInfo();
        const logsSection = this.renderLogsInfo();
        return (<PaperLayoutVNext title={Title} primaryAction={checkHealthPageAction} busy={this.state.busy} errors={this.errors}>
                {communicationsSection && <Section>{communicationsSection}</Section>}

                {calamariUpgradeSection && <Section sectionHeader="Calamari Upgrade Recommended">{calamariUpgradeSection}</Section>}

                {tentacleSection && <Section sectionHeader="Tentacle">{tentacleSection}</Section>}

                {logsSection && <Section sectionHeader="Recent Communication Logs">{logsSection}</Section>}
            </PaperLayoutVNext>);
    }
    private renderLogsInfo() {
        const connectionStatus = this.props.initialData.connectionStatus;
        if (!connectionStatus) {
            return null;
        }
        return <TaskLogLines lines={connectionStatus.Logs} showTimestamps={true} onFetchRange={noOp} isFetchDisabled={true}/>;
    }
    private renderCommunicationStyleInfo() {
        const machine = this.state.machine;
        const connectionStatus = this.props.initialData.connectionStatus;
        if (machine.Endpoint.CommunicationStyle === CommunicationStyle.None) {
            return (<Callout type={"success"} title="Healthy">
                    <p>Cloud Regions are always healthy. Hooray!</p>
                </Callout>);
        }
        else {
            switch (connectionStatus.Status) {
                case MachineModelHealthStatus.Healthy:
                    return (<Callout type={"success"} title="Healthy">
                            <p>The last health check completed successfully</p>
                            <Note>Last health check {DateFormatter.momentAgo(connectionStatus.LastChecked as string)}</Note>
                        </Callout>);
                case MachineModelHealthStatus.Unhealthy:
                    return (<Callout type={"danger"} title="Unhealthy">
                            <p>The last health check encountered errors</p>
                            <Note>Last health check {DateFormatter.momentAgo(connectionStatus.LastChecked as string)}</Note>
                        </Callout>);
                case MachineModelHealthStatus.HasWarnings:
                    return (<Callout type={"warning"} title="Healthy with warnings">
                            <p>The last health check encountered warnings</p>
                            <Note>Last health check {DateFormatter.momentAgo(connectionStatus.LastChecked as string)}</Note>
                        </Callout>);
                case MachineModelHealthStatus.Unavailable:
                    return (<Callout type={"danger"} title="Unavailable">
                            <Note>Last health check {DateFormatter.momentAgo(connectionStatus.LastChecked as string)}</Note>
                        </Callout>);
                case MachineModelHealthStatus.Unknown:
                    return (<Callout type={"warning"} title="Unknown">
                            <p>This {this.props.itemDescription} was just added, and a health check has not been performed.</p>
                        </Callout>);
            }
        }
    }
    private renderCalamariUpgradeInfo() {
        const machine = this.state.machine;
        if (!machine.HasLatestCalamari) {
            const calamariUpgradeButton = <ActionButton type={ActionButtonType.Secondary} label={"Upgrade Calamari"} disabled={this.state.busy} onClick={() => this.performCalamariUpgrade()}/>;
            return calamariUpgradeButton;
        }
    }
    private renderTentacleInfo() {
        const machine = this.state.machine;
        if (EndpointsHelper.isAnyTentacle(machine.Endpoint)) {
            const { Version: version, UpgradeLocked: upgradeLocked, UpgradeStatus: upgradeStatus } = this.GetVersionDetails(machine.Endpoint);
            if (!version) {
                return null;
            }
            return (<div>
                    {upgradeStatus === UpgradeStatus.UpgradeAvailable && <Callout type={"information"} title="Upgrade available"/>}
                    {upgradeStatus === UpgradeStatus.UpgradeSuggested && <Callout type={"information"} title="Upgrade suggested"/>}
                    {upgradeStatus === UpgradeStatus.UpgradeRequired && <Callout type={"danger"} title="Upgrade required"/>}
                    <DataTable>
                        <DataTableBody>
                            <DataTableRow>
                                <DataTableRowHeaderColumn>Current Version</DataTableRowHeaderColumn>
                                <DataTableRowColumn>{version}</DataTableRowColumn>
                            </DataTableRow>
                            {EndpointsHelper.isKubernetesTentacle(machine.Endpoint) && this.GetKubernetesAgentVersionDetails(machine.Endpoint)}
                            <DataTableRow>
                                <DataTableRowHeaderColumn>&nbsp;</DataTableRowHeaderColumn>
                                <DataTableRowColumn>
                                    {upgradeLocked && <ActionButton label={"Unlock current version"} disabled={this.state.busy} onClick={() => this.lockVersion(false)}/>}
                                    {!upgradeLocked && (<div>
                                            <ActionButton label={"Lock current version"} disabled={this.state.busy} onClick={() => this.lockVersion(true)}/>
                                            <Note>Ensures this Tentacle does not get updated past currently installed version or get prompted when newer versions are available.</Note>
                                        </div>)}
                                </DataTableRowColumn>
                            </DataTableRow>
                            <DataTableRow>
                                <DataTableRowHeaderColumn>&nbsp;</DataTableRowHeaderColumn>
                                <DataTableRowColumn>{upgradeStatus !== UpgradeStatus.NoUpgrades && <ActionButton label={"Upgrade to latest"} disabled={this.state.busy} onClick={() => this.upgradeTentacle()}/>}</DataTableRowColumn>
                            </DataTableRow>
                        </DataTableBody>
                    </DataTable>
                </div>);
        }
    }
    private GetKubernetesAgentVersionDetails(endpoint: KubernetesTentacleEndpointResource) {
        return (<>
                <DataTableRow>
                    <DataTableRowHeaderColumn>Tentacle Version</DataTableRowHeaderColumn>
                    <DataTableRowColumn>{endpoint.KubernetesAgentDetails?.TentacleVersion}</DataTableRowColumn>
                </DataTableRow>
                <DataTableRow>
                    <DataTableRowHeaderColumn>Helm Release Name</DataTableRowHeaderColumn>
                    <DataTableRowColumn>{endpoint.KubernetesAgentDetails?.HelmReleaseName}</DataTableRowColumn>
                </DataTableRow>
                <DataTableRow>
                    <DataTableRowHeaderColumn>Namespace</DataTableRowHeaderColumn>
                    <DataTableRowColumn>{endpoint.KubernetesAgentDetails?.KubernetesNamespace}</DataTableRowColumn>
                </DataTableRow>
            </>);
    }
    private GetVersionDetails(endpoint: TentacleEndpointResource | KubernetesTentacleEndpointResource): CommonVersionDetails {
        if (EndpointsHelper.isTentacle(endpoint)) {
            const tentacleDetails = endpoint.TentacleVersionDetails;
            let upgradeStatus = UpgradeStatus.NoUpgrades;
            if (tentacleDetails.UpgradeRequired) {
                upgradeStatus = UpgradeStatus.UpgradeRequired;
            }
            else if (tentacleDetails.UpgradeSuggested) {
                upgradeStatus = UpgradeStatus.UpgradeSuggested;
            }
            else if (tentacleDetails.UpgradeAvailable) {
                upgradeStatus = UpgradeStatus.UpgradeAvailable;
            }
            const version = tentacleDetails.Version;
            const upgradeLocked = tentacleDetails.UpgradeLocked;
            return { Version: version, UpgradeLocked: upgradeLocked, UpgradeStatus: upgradeStatus };
        }
        if (EndpointsHelper.isKubernetesTentacle(endpoint)) {
            const kubernetesAgentDetails = endpoint.KubernetesAgentDetails;
            const version = kubernetesAgentDetails?.AgentVersion ?? null;
            const upgradeStatus = kubernetesAgentDetails?.UpgradeStatus ?? UpgradeStatus.NoUpgrades;
            const upgradeLocked = endpoint.UpgradeLocked;
            return { Version: version, UpgradeLocked: upgradeLocked, UpgradeStatus: upgradeStatus };
        }
        throw new Error(`Cannot get Version Details for Unsupported Endpoint ${endpoint}`);
    }
    private async lockVersion(isToBeLocked: boolean) {
        const machine = this.state.machine;
        if (EndpointsHelper.isTentacle(machine.Endpoint)) {
            machine.Endpoint.TentacleVersionDetails.UpgradeLocked = isToBeLocked;
            await this.doBusyTask(async () => {
                await this.props.repository.save(machine);
                this.setState({ machine });
            });
        }
        if (EndpointsHelper.isKubernetesTentacle(machine.Endpoint)) {
            machine.Endpoint.UpgradeLocked = isToBeLocked;
            await this.doBusyTask(async () => {
                await this.props.repository.save(machine);
                this.setState({ machine });
            });
        }
    }
    private async upgradeTentacle() {
        return this.doBusyTask(async () => {
            const res = await repository.Machines.upgradeTentacle(this.state.machine.Id);
            if (res.Tasks.length > 0) {
                this.setState({ redirectToTaskId: res.Tasks[0].Id });
            }
        });
    }
    private async performHealthCheck() {
        return this.doBusyTask(async () => {
            const task = await repository.Tasks.createHealthCheckTaskForMachine(this.state.machine);
            this.setState({ redirectToTaskId: task.Id });
        });
    }
    private async performCalamariUpgrade() {
        return this.doBusyTask(async () => {
            const task = await repository.Tasks.createUpdateCalamariOnTargetTask(this.state.machine);
            this.setState({ redirectToTaskId: task.Id });
        });
    }
    static displayName = "MachineConnectionsLayoutInternal";
}
