/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { Checkbox, Callout } from "@octopusdeploy/design-system-components";
import type { PageAction, PrimaryPageAction } from "@octopusdeploy/design-system-components";
import type { AnonymousVcsCredentials, ConvertProjectToVersionControlledCommand, GitBranchResource, GitHubVcsCredentials, GitPersistenceSettings, PreviewProtectedBranchesRequest, ProjectResource, ReferenceVcsCredentials, ResourceCollection, UsernamePasswordVcsCredentials, VersionControlCompatibilityResponse, } from "@octopusdeploy/octopus-server-client";
import { AuthenticationType, basePathToShowByDefault, branchToShowByDefault, HasGitPersistenceSettings, IsUsingAnonymousAuth, IsUsingGitHubAuth, IsUsingReferenceAuth, IsUsingUsernamePasswordAuth, Permission, PersistenceSettingsType, toGitBranch, toGitBranchShort, } from "@octopusdeploy/octopus-server-client";
import React from "react";
import type { AnalyticErrorCallback } from "~/analytics/Analytics";
import { ProjectPaperLayout } from "~/areas/projects/components/ProjectPaperLayout";
import type { CancelConfigureGitOptions } from "~/areas/projects/components/ProjectSettings/VersionControl/Analytics/useNotifyCancelConfigureGit";
import { useNotifyCancelConfigureGit } from "~/areas/projects/components/ProjectSettings/VersionControl/Analytics/useNotifyCancelConfigureGit";
import type { ProjectRouteParams } from "~/areas/projects/components/ProjectsRoutes/ProjectRouteParams";
import type { ConfigureGitOptions } from "~/areas/projects/components/VersionControl/Analytics/useNotifyConfigureGit";
import { useNotifyConfigureGit } from "~/areas/projects/components/VersionControl/Analytics/useNotifyConfigureGit";
import type { GitSettingsUpdateOptions } from "~/areas/projects/components/VersionControl/Analytics/useNotifyGitSettingsUpdate";
import { useNotifyGitSettingsUpdate } from "~/areas/projects/components/VersionControl/Analytics/useNotifyGitSettingsUpdate";
import { client, repository } from "~/clientInstance";
import { isFeatureToggleEnabled } from "~/components/FeatureToggle/New/FeatureToggleContext";
import type { OptionalFormBaseComponentState } from "~/components/FormBaseComponent";
import FormBaseComponent from "~/components/FormBaseComponent";
import type { PrimarySavePageAction } from "~/components/FormPaperLayout/Form";
import { Form } from "~/components/FormPaperLayout/Form";
import type { PermissionCheckProps } from "~/components/PermissionCheck/PermissionCheck";
import { hasPermission } from "~/components/PermissionCheck/PermissionCheck";
import TransitionAnimation from "~/components/TransitionAnimation/TransitionAnimation";
import { ExpandableFormSection, Note, Summary, Text, UnstructuredFormSection } from "~/components/form";
import type { SummaryNode } from "~/components/form";
import { required } from "~/components/form/Validators";
import { useOctopusFeatureToggle } from "~/hooks/useOctopusFeatureToggle";
import { DebounceText } from "~/primitiveComponents/form/Text/Text";
import type { WithProjectContextInjectedProps } from "../../../context";
import { useProjectContext } from "../../../context";
import { GitRefChip } from "../../Releases/GitRefChip/GitRefChip";
import type { CommitMessageWithDetails } from "../../VersionControl/CommitMessageWithDetails";
import { getFormattedCommitMessage } from "../../VersionControl/CommitMessageWithDetails";
import CommitDialog from "./CommitDialog";
import { VersionControlSettingsConnectionDetailsWithDataLoading } from "./Connection/VersionControlSettingsConnectionDetailsWithDataLoading";
import { GitChip } from "./GitChip";
import { SettingsNoteSection } from "./SettingsNoteSection";
import TestConnectionButton from "./TestConnectionButton";
import { UnsupportedFeaturesSection } from "./UnsupportedFeaturesSection";
import styles from "./style.module.less";
interface VersionControlSettingsState extends OptionalFormBaseComponentState<GitPersistenceSettings> {
    project?: ProjectResource;
    commitDialogActive: boolean;
    commitMessage: CommitMessageWithDetails;
    vcsCompatibilityReport: VersionControlCompatibilityResponse | null;
    protectedBranches: ResourceCollection<GitBranchResource> | null;
    initialCommitBranchName: string | undefined;
    saveForm: () => Promise<boolean>;
}
interface VersionControlSettingsAnalyticsProps {
    updateVcsSettingsEventDispatcher: (options: GitSettingsUpdateOptions) => Promise<ProjectResource>;
    configureVcsEventDispatcher: (options: ConfigureGitOptions) => void;
    cancelConfiguringVcsEventDispatcher: (options: CancelConfigureGitOptions) => void;
}
interface GitRunbooksMigrationEnabledProps {
    GitRunbooksMigrationEnabled: boolean;
}
type VersionControlSettingsProps = ProjectRouteParams & WithProjectContextInjectedProps & GitRunbooksMigrationEnabledProps;
type VersionControlSettingsPropsInternal = VersionControlSettingsProps & VersionControlSettingsAnalyticsProps & GitRunbooksMigrationEnabledProps;
const defaultCommitMessage = "Initial commit of deployment process";
class VersionControlSettingsInternal extends FormBaseComponent<VersionControlSettingsPropsInternal, VersionControlSettingsState, GitPersistenceSettings> {
    constructor(props: VersionControlSettingsPropsInternal) {
        super(props);
        this.state = {
            commitDialogActive: false,
            commitMessage: { summary: "", details: "" },
            vcsCompatibilityReport: null,
            protectedBranches: null,
            initialCommitBranchName: undefined,
            saveForm: () => Promise.resolve(false),
        };
    }
    async componentDidMount() {
        await this.doBusyTask(async () => {
            const project = this.props.projectContext.state.model;
            const isVersionControlled = project.IsVersionControlled;
            const vcsCompatibilityReport = !isVersionControlled ? await repository.Projects.vcsCompatibilityReport(project) : null;
            this.setState({
                project,
                vcsCompatibilityReport,
                model: this.buildModel(project),
                cleanModel: this.buildModel(project),
            });
            if (project.IsVersionControlled) {
                await this.refreshProtectedBranches();
            }
        });
    }
    buildModel(project: ProjectResource): GitPersistenceSettings {
        if (!HasGitPersistenceSettings(project.PersistenceSettings)) {
            return this.defaultVersionControlSettings(project);
        }
        return {
            Type: PersistenceSettingsType.VersionControlled,
            Url: project.PersistenceSettings.Url,
            DefaultBranch: project.PersistenceSettings.DefaultBranch || toGitBranchShort(branchToShowByDefault),
            BasePath: project.PersistenceSettings.BasePath || basePathToShowByDefault,
            Credentials: project.PersistenceSettings.Credentials,
            ConversionState: project.PersistenceSettings.ConversionState,
            ProtectedDefaultBranch: project.PersistenceSettings.ProtectedDefaultBranch,
            ProtectedBranchNamePatterns: project.PersistenceSettings.ProtectedBranchNamePatterns ?? [],
        };
    }
    handleSaveClick = async () => {
        if (!this.state.model) {
            throw Error("Tried to save with an empty model");
        }
        const model = this.state.model;
        await this.doBusyTask(async () => {
            if (!this.state.project) {
                throw new Error("No Project loaded");
            }
            if (HasGitPersistenceSettings(this.state.project.PersistenceSettings)) {
                await this.saveProject({ ...this.state.project, PersistenceSettings: model });
            }
            else {
                const formattedMessage = getFormattedCommitMessage(this.state.commitMessage, defaultCommitMessage);
                const vcsSettingsAndCommitMessage: ConvertProjectToVersionControlledCommand = {
                    CommitMessage: formattedMessage,
                    VersionControlSettings: model,
                    InitialCommitBranchName: this.state.initialCommitBranchName,
                };
                await this.convertProjectToVcs(this.state.project, vcsSettingsAndCommitMessage);
                // Select the initial commit branch, or the default branch after converting
                const initialGitRef = this.state.initialCommitBranchName ? toGitBranch(this.state.initialCommitBranchName) : toGitBranch(model.DefaultBranch);
                await this.props.projectContext.actions.refreshModel(initialGitRef);
            }
        });
    };
    handleBasePathChange = async (basePath: string) => {
        // We want to ensure the `.octopus/` prefix is applied to the base path,
        // despite not allowing the portal to configure the root
        this.setModelState({ BasePath: basePath ? `.octopus/${basePath}` : `.octopus` });
    };
    getBasePath = (): string => {
        // The `.octopus/` base path is expected to always be set on the incoming BasePath
        // despite not being (currently) configurable.
        // To ensure it isn't configurable, we strip the root directory from the value bound to the textbox
        if (!this.state.model) {
            return "";
        }
        let basePath = this.state.model.BasePath;
        if (basePath.startsWith(".octopus")) {
            basePath = basePath.substring(".octopus".length);
        }
        if (basePath.startsWith("/")) {
            return basePath.substring(1);
        }
        return basePath;
    };
    getBranchNamePatterns = (): string => {
        return this.state.model?.ProtectedBranchNamePatterns.join(", ") ?? "";
    };
    onDefaultBranchProtectedUpdated = async (newValue: boolean) => {
        this.setState({ initialCommitBranchName: undefined });
        this.setModelState({ ProtectedDefaultBranch: newValue }, async () => {
            await this.refreshProtectedBranches();
        });
    };
    onBranchNamePatternsUpdated = async (newValue: string) => {
        const newPatterns = newValue
            .split(",")
            .map((p) => p.trim())
            .filter((p) => p); // Remove empty items
        this.setModelState({ ProtectedBranchNamePatterns: newPatterns });
        await this.refreshProtectedBranches();
    };
    refreshProtectedBranches = async () => {
        if (!this.state.project || !this.state.model) {
            return;
        }
        if (this.state.model.ProtectedBranchNamePatterns.length > 0 || this.state.model.ProtectedDefaultBranch) {
            const request: PreviewProtectedBranchesRequest = {
                defaultBranch: this.state.model.DefaultBranch,
                protectedDefaultBranch: this.state.model.ProtectedDefaultBranch,
                protectedBranchNamePatterns: this.state.model.ProtectedBranchNamePatterns,
            };
            const branches = await repository.Projects.previewProtectedBranches(this.state.project, request);
            this.setState({ protectedBranches: branches });
        }
        else {
            this.setState({ protectedBranches: null });
        }
    };
    onUrlChanged = (url: string) => {
        this.setModelState({ Url: url });
    };
    onCredentialsChanged = (credentials: GitHubVcsCredentials | ReferenceVcsCredentials | UsernamePasswordVcsCredentials | AnonymousVcsCredentials) => {
        if (IsUsingUsernamePasswordAuth(credentials)) {
            this.setChildState2("model", "Credentials", credentials);
        }
        else if (IsUsingReferenceAuth(credentials)) {
            this.setChildState2("model", "Credentials", credentials);
        }
        else if (IsUsingGitHubAuth(credentials)) {
            this.setChildState2("model", "Credentials", credentials);
        }
        else if (IsUsingAnonymousAuth(credentials)) {
            this.setChildState2("model", "Credentials", credentials);
        }
        else {
            throw new Error("Unknown authentication type");
        }
    };
    render() {
        const gitRunbooksMigrationEnabled = this.props.GitRunbooksMigrationEnabled && isFeatureToggleEnabled("GitRunbooksFeatureToggle");
        const isExpanded = this.state.model && !this.state.model.Url;
        const formFields = this.state.model && this.state.project && (<>
                <ExpandableFormSection errorKey="BasePath" title="Git File Storage Directory" summary={this.basePathSummary()} help="The directory where Octopus should store the project files in the repository" isExpandedByDefault={isExpanded}>
                    <Text inputProps={{
                startAdornment: (<>
                                    <code>.octopus</code>
                                    &nbsp;/
                                </>),
            }} key="BasePath" value={this.getBasePath()} onChange={this.handleBasePathChange} label="Git File Storage Directory" error={this.getFieldError("BasePath")} disabled={!!this.state.busy} accessibleName={"Directory in the repository where Octopus related files should be stored"}/>
                    <Note>This controls where in your repository the Octopus configuration files will be written.</Note>
                    <Note>
                        If this is the only Octopus project which will be persisted in the repository, <code>.octopus</code> is a good option. If multiple Octopus projects will be stored in the same repository, adding the project name to the path is
                        the recommended convention, e.g. <code>.octopus/acme</code>
                    </Note>
                </ExpandableFormSection>
                <ExpandableFormSection errorKey="DefaultBranch" title="Branch Settings" summary={this.branchSummary()} help="Enter the details for your branches" isExpandedByDefault={isExpanded}>
                    <Text key="DefaultBranch" value={this.state.model.DefaultBranch} onChange={(DefaultBranch) => this.setModelState({ DefaultBranch })} label="Default Branch" error={this.getFieldError("DefaultBranch")} validate={required("Enter a default branch.")} disabled={!!this.state.busy} accessibleName={"Name of the default branch on the Git repository"}/>
                    {!gitRunbooksMigrationEnabled && <Note>Runbooks will use variables from the default branch</Note>}

                    <Checkbox value={this.state.model.ProtectedDefaultBranch} onChange={this.onDefaultBranchProtectedUpdated} label={"Default branch is protected"} note={"If the default branch is protected, you may not have permission to push to it."} disabled={!!this.state.busy} accessibleName={"Default branch is protected"}/>

                    {this.state.model.ProtectedDefaultBranch && !this.state.project.IsVersionControlled && (<>
                            <Text key={"InitialCommitBranchName"} value={this.state.initialCommitBranchName ?? ""} onChange={(initialCommitBranchName) => this.setState({ initialCommitBranchName })} label={"Initial Commit Branch"} error={this.getFieldError("InitialCommitBranchName")} disabled={!!this.state.busy} accessibleName={"Name of the branch on the Git repository where the OCL files will be committed to initially"}/>
                            <Note>If the branch does not exist, it will be created.</Note>
                        </>)}

                    {this.state.project.IsVersionControlled && (<>
                            {/* It'd be nice if we could use a Multi-Select here, but we don't have a use for the search functionality and dropdown */}
                            {/* For now, we're using a text box and just splitting the value by ",". It's a bit sketchy, but it's better than doing it in the backend */}
                            {/* We can look into a new component like the multi-select later */}
                            <DebounceText value={this.getBranchNamePatterns()} onChange={this.onBranchNamePatternsUpdated} debounceDelay={500} accessibleName={"Branch name patterns"} label={"Protected branch patterns"}/>
                            <Note>This setting only applies within Octopus and will not affect your protected branches in Git. Use wildcard syntax to specify the range of branches to include. Multiple patterns can be separated by a comma.</Note>

                            {this.state.protectedBranches && this.state.protectedBranches.TotalResults > 0 && (<div className={styles.branchList}>
                                    Applies to {this.state.protectedBranches.TotalResults} branches
                                    <br />
                                    <div className={styles.branchListItems}>
                                        {this.state.protectedBranches.Items.map((branch) => (<GitRefChip key={branch.CanonicalName} vcsRef={{ GitRef: branch.CanonicalName }}/>))}
                                    </div>
                                </div>)}
                        </>)}
                </ExpandableFormSection>
            </>);
        const configurationDialog = this.state.model && this.state.project && this.state.commitDialogActive && (<CommitDialog open={this.state.commitDialogActive} defaultSummary={defaultCommitMessage} commitMessage={this.state.commitMessage} setCommitMessage={(commitMessage: CommitMessageWithDetails) => this.setState({ commitMessage })} errors={this.errors} project={this.state.project} onNext={() => {
                // TODO: try and clone the repository, passing the commit message.
                // if that succeeds, save the VCS settings
                return this.state.saveForm();
            }} onCancel={() => {
                this.props.cancelConfiguringVcsEventDispatcher({
                    hasInitialCommitBranch: false,
                });
            }} onClose={() => {
                this.setState({ commitDialogActive: false });
            }}/>);
        const hasErrors = this.state.vcsCompatibilityReport && this.state.vcsCompatibilityReport.Errors.length > 0 ? true : false;
        const enabled = this.state.project?.IsVersionControlled ?? false;
        const isVersionControlled = this.state.project ? this.state.project.IsVersionControlled : false;
        const pageActions: PageAction[] = [];
        if (!this.hasCompatibilityReportErrors() && this.state.model && this.state.project) {
            pageActions.push({ type: "custom", content: <TestConnectionButton disabled={this.state.busy || !this.state.model.Url} project={this.state.project} model={this.state.model}/>, key: "Test Connection" });
        }
        return (<Form saveText="Your project now supports version control" savePermission={this.editPermission()} onSaveClick={this.handleSaveClick} forceDisableFormSaveButton={hasErrors} model={this.state.model} cleanModel={this.state.cleanModel}>
                {({ FormContent, createSaveAction }) => {
                return (<ProjectPaperLayout title={"Version Control Settings"} titleChip={<GitChip size="small" enabled={enabled}/>} busy={this.state.busy} errors={this.errors} primaryAction={this.getSavePrimaryAction(createSaveAction({
                        saveButtonLabel: enabled ? "Save" : "Configure...",
                        saveButtonBusyLabel: enabled ? "Saving" : "Configuring...",
                    }))} pageActions={pageActions}>
                            <FormContent>
                                <SettingsNoteSection isVersionControlled={isVersionControlled}/>
                                {this.state.vcsCompatibilityReport && <UnsupportedFeaturesSection vcsCompatibilityReport={this.state.vcsCompatibilityReport}/>}
                                {this.state.model && (<TransitionAnimation>
                                        {this.state.project && this.state.project.IsDisabled && (<UnstructuredFormSection stretchContent={true}>
                                                <Callout type={"warning"} title={"This project is currently disabled"}/>
                                            </UnstructuredFormSection>)}
                                        <VersionControlSettingsConnectionDetailsWithDataLoading doBusyTask={this.doBusyTask} busy={this.state.busy} errors={this.errors} getFieldError={this.getFieldError} spaceId={this.props.spaceId} isNew={this.state.model.Url === ""} setCredential={this.onCredentialsChanged} credential={this.state.model.Credentials} setUrl={this.onUrlChanged} url={this.state.model?.Url}/>
                                        {formFields}
                                    </TransitionAnimation>)}
                                {configurationDialog}
                            </FormContent>
                        </ProjectPaperLayout>);
            }}
            </Form>);
    }
    private hasCompatibilityReportErrors = () => (this.state.vcsCompatibilityReport && this.state.vcsCompatibilityReport.Errors.length > 0 ? true : false);
    private isEnabled = () => this.state.project?.IsVersionControlled ?? false;
    private getSavePrimaryAction = (defaultPrimaryAction: PrimarySavePageAction): PrimaryPageAction => {
        // For this page (unlike most form layouts) we only save after a successful clone.
        // We do that by first popping up a dialog, doing the clone and then saving if it succeeds.
        // In order to do that, here we're storing a reference to the default form save function
        // to call later on from the dialog.
        const onClick = (e: React.MouseEvent | undefined) => {
            if (this.isEnabled()) {
                defaultPrimaryAction.onClick(e);
            }
            else {
                e?.preventDefault();
                this.setState({
                    saveForm: defaultPrimaryAction.onClick as () => Promise<boolean>,
                    commitDialogActive: true,
                });
            }
        };
        return {
            type: "button",
            label: this.hasCompatibilityReportErrors() ? "Conversion errors must be resolved" : defaultPrimaryAction.label,
            disabled: defaultPrimaryAction.disabled,
            busyLabel: defaultPrimaryAction.busyLabel,
            onClick,
        };
    };
    private basePathSummary(): SummaryNode {
        if (!this.state.model || this.state.model.DefaultBranch === basePathToShowByDefault) {
            return Summary.default(basePathToShowByDefault);
        }
        if (!this.state.model.BasePath) {
            return Summary.placeholder("Enter a base path");
        }
        return Summary.summary(this.state.model.BasePath);
    }
    private branchSummary(): SummaryNode {
        const defaultBranchName = this.defaultBranchName();
        // The default branch is the only required field in here, so if that's
        // not set, reminding the user to set a value there should be all that
        // we need to show.
        if (!defaultBranchName) {
            return Summary.placeholder("Configure default branch");
        }
        const defaultBranchProtected = this.state.model?.ProtectedDefaultBranch ?? false;
        const totalProtectedBranchPatterns = this.state.model?.ProtectedBranchNamePatterns.length ?? 0;
        let summary = "";
        if (defaultBranchProtected || totalProtectedBranchPatterns > 0) {
            summary = `protected branches are configured`;
        }
        else {
            summary = `no protected branches`;
        }
        return Summary.summary(<span>
                Default branch is <strong>{defaultBranchName}</strong>, {summary}
            </span>);
    }
    private defaultBranchName(): string | undefined {
        const shortBranchName = toGitBranchShort(branchToShowByDefault);
        if (!this.state.model || this.state.model.DefaultBranch === shortBranchName) {
            return shortBranchName;
        }
        if (!this.state.model.DefaultBranch) {
            return undefined;
        }
        return this.state.model.DefaultBranch;
    }
    private editPermission(): PermissionCheckProps {
        return {
            permission: Permission.ProjectEdit,
            project: this.state.project && this.state.project.Id,
            tenant: "*",
        };
    }
    private async saveProject(project: ProjectResource) {
        if (!HasGitPersistenceSettings(project.PersistenceSettings)) {
            throw new Error("Cannot save project when persistence settings are not git settings");
        }
        const result = await this.props.updateVcsSettingsEventDispatcher({
            hasProtectedBranches: project.PersistenceSettings.ProtectedDefaultBranch || project.PersistenceSettings.ProtectedBranchNamePatterns.length > 0,
            updateAction: async (_: AnalyticErrorCallback) => await repository.Projects.save(project),
            authenticationType: project.PersistenceSettings.Credentials.Type,
        });
        this.setState({
            model: this.buildModel(result),
            cleanModel: this.buildModel(result),
            project: result,
        });
        if (!this.errors?.errors.length) {
            window.location.reload();
        }
    }
    private async convertProjectToVcs(project: ProjectResource, command: ConvertProjectToVersionControlledCommand) {
        this.props.configureVcsEventDispatcher({
            hasInitialCommitBranch: this.state.initialCommitBranchName !== undefined,
            authenticationType: command.VersionControlSettings.Credentials.Type,
        });
        await repository.Projects.convertToVcs(project, command);
        const updatedProject = await repository.Projects.get(project.Id);
        client.dispatchEvent({ type: "ProjectModified", project: updatedProject });
        if (!HasGitPersistenceSettings(updatedProject.PersistenceSettings))
            throw new Error("Converted Project is not version controlled");
        await this.props.projectContext.actions.onVersionControlEnabled(updatedProject);
        this.setState({
            model: this.buildModel(updatedProject),
            cleanModel: this.buildModel(updatedProject),
            project: updatedProject,
        });
    }
    private defaultVersionControlSettings(project: ProjectResource): GitPersistenceSettings {
        const canViewLibraryGitCredentials = hasPermission(Permission.GitCredentialView);
        const gitHubConnectionsAreEnabled = isFeatureToggleEnabled("GitHubConnectionsFeatureToggle");
        const defaultGitHub: GitHubVcsCredentials = {
            Type: AuthenticationType.GitHub,
            Id: "",
        };
        const defaultReference: ReferenceVcsCredentials = {
            Type: AuthenticationType.Reference,
            Id: "",
        };
        const defaultUsernamePassword: UsernamePasswordVcsCredentials = {
            Type: AuthenticationType.UsernamePassword,
            Username: "",
            Password: {
                HasValue: false,
            },
        };
        const defaultCredentials = canViewLibraryGitCredentials ? (gitHubConnectionsAreEnabled ? defaultGitHub : defaultReference) : defaultUsernamePassword;
        return {
            Type: PersistenceSettingsType.VersionControlled,
            Url: "",
            DefaultBranch: toGitBranchShort(branchToShowByDefault),
            BasePath: basePathToShowByDefault,
            Credentials: defaultCredentials,
            ConversionState: { VariablesAreInGit: false, RunbooksAreInGit: false },
            ProtectedDefaultBranch: false,
            ProtectedBranchNamePatterns: [],
        };
    }
    static displayName = "VersionControlSettingsInternal";
}
export function VersionControlSettingsInternalWithAnalytics(props: VersionControlSettingsProps) {
    const configureVcsEventDispatcher = useNotifyConfigureGit();
    const updateVcsSettingsEventDispatcher = useNotifyGitSettingsUpdate();
    const cancelConfiguringVcsEventDispatcher = useNotifyCancelConfigureGit();
    return (<VersionControlSettingsInternal {...props} cancelConfiguringVcsEventDispatcher={cancelConfiguringVcsEventDispatcher} updateVcsSettingsEventDispatcher={updateVcsSettingsEventDispatcher} configureVcsEventDispatcher={configureVcsEventDispatcher}/>);
}
export function VersionControlSettingsPage({ spaceId, projectSlug, branchName }: ProjectRouteParams) {
    const projectContext = useProjectContext();
    const gitRunbooksMigrationEnabled = useOctopusFeatureToggle("git-runbooks-migration", false);
    return <VersionControlSettingsInternalWithAnalytics spaceId={spaceId} projectSlug={projectSlug} branchName={branchName} projectContext={projectContext} GitRunbooksMigrationEnabled={gitRunbooksMigrationEnabled}/>;
}
