import type { LogEvent, LoggingSink } from "@octopusdeploy/logging";
import { convertToSerilogCompactFormat, createBufferingSink, createConsoleSink, createLogger, globalLogConfiguration, consoleLoggers } from "@octopusdeploy/logging";
import { repository, session } from "~/clientInstance";
import { isFeatureToggleEnabled } from "~/components/FeatureToggle/New/FeatureToggleContext";
export function configureSerilogIngestionSink() {
    const bufferingSink = createBufferingSink(createSerilogIngestionSink(), serilogIngestionSinkIsEnabled);
    globalLogConfiguration.attachSink(bufferingSink);
}
function serilogIngestionSinkIsEnabled(): boolean | "indeterminate" {
    // We currently only allow log events to be sent if the client is connected
    // If we aren't connected or authenticated, any events logged will keep queueing until we do connect
    if (!session.isAuthenticated() || session.featureToggles === null)
        return "indeterminate";
    return !isFeatureToggleEnabled("DisablePortalLogIngestionFeatureToggle");
}
function createSerilogIngestionSink(): LoggingSink {
    return {
        receiveLogEvents(logEvents: LogEvent[]) {
            // Nothing should block on this, or report progress. It is a "background" task
            // noinspection JSIgnoredPromiseFromCall
            fireAndForgetLogEvents(logEvents);
        },
    };
}
async function fireAndForgetLogEvents(logEvents: LogEvent[]) {
    try {
        await repository.Logs.ingestLogEvents(logEvents.map(convertToSerilogCompactFormat));
    }
    catch (e) {
        // Don't keep pumping messages to the serilog sink if something is failing. Just go directly to the console instead.
        createLogger(createConsoleSink(consoleLoggers), () => "configureSerilogIngestion.fireAndForgetLogEvents").error("Unable to ingest messages: {ErrorMessage}", { ErrorMessage: e.toString() });
    }
}
