import { css, cx } from "@emotion/css";
import type { TextThemeName, ThemeName } from "@octopusdeploy/design-system-components";
import { Theme as DesignSystemTheme } from "@octopusdeploy/design-system-components";
import { space, themeTokens } from "@octopusdeploy/design-system-tokens";
import { Environment } from "@octopusdeploy/utilities";
import { useDrawerResizeHandler } from "app/components/DevTools/useDrawerResizeHandler";
import React from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useThemePaletteType } from "~/components//Theme/useThemePaletteType";
import type { DrawerDockLocation } from "~/components/DevTools/DevToolsContext";
import { useDevToolsDockLocation, useIsDevToolsOpen, useToggleDevTools } from "~/components/DevTools/DevToolsContext";
import { useIsSystemFontEnabled } from "~/components/RootRoutes/useIsSystemFontEnabled";
import { DevBanner } from "./DevBanner";
interface LayoutWithDevToolsProps {
    children: React.ReactNode;
    devToolsContent: React.ReactNode;
}
export function LayoutWithDevTools({ children, devToolsContent }: LayoutWithDevToolsProps) {
    const isOpen = useIsDevToolsOpen();
    const toggleDrawer = useToggleDevTools();
    const dockLocation = useDevToolsDockLocation();
    const [showDevBanner, setShowDevBanner] = React.useState(Environment.isInDevelopmentMode());
    useHotkeys("ctrl+shift+`", toggleDrawer, {
        enableOnFormTags: ["INPUT", "SELECT", "TEXTAREA"],
    }, []);
    return (<PanelWithDrawer drawerLocation={dockLocation ?? "bottom"} drawerContent={devToolsContent} isOpen={isOpen}>
            {showDevBanner && <DevBanner onClose={() => setShowDevBanner(false)}/>}
            {children}
        </PanelWithDrawer>);
}
type Orientation = "horizontal" | "vertical";
interface PanelDockProps {
    drawerLocation: DrawerDockLocation;
    children: React.ReactNode;
    drawerContent: React.ReactNode;
    isOpen: boolean;
}
function PanelWithDrawer({ drawerLocation, children, drawerContent, isOpen }: PanelDockProps) {
    return (<div className={cx(panelWithDrawerStyles, panelWithDrawerDockLocationStyles[drawerLocation])}>
            <Panel>{children}</Panel>

            {isOpen && <PanelDrawer drawerLocation={drawerLocation}>{drawerContent}</PanelDrawer>}
        </div>);
}
const panelWithDrawerStyles = css({
    display: "flex",
    flexDirection: "column",
    height: "100vh",
});
const panelWithDrawerDockLocationStyles: Record<DrawerDockLocation, string> = {
    left: css({
        flexDirection: "row-reverse",
    }),
    right: css({
        flexDirection: "row",
    }),
    bottom: css({
        flexDirection: "column",
    }),
};
interface MainPanelProps {
    children: React.ReactNode;
}
function Panel({ children }: MainPanelProps) {
    return <div className={panelStyles}>{children}</div>;
}
const panelStyles = css({
    flex: 1,
    display: "flex",
    flexDirection: "column",
    minHeight: 0,
});
interface PanelDrawerProps {
    drawerLocation: DrawerDockLocation;
    children: React.ReactNode;
}
function PanelDrawer({ children, drawerLocation }: PanelDrawerProps) {
    const dockElement = React.useRef<HTMLDivElement | null>(null);
    const orientation: Orientation = drawerLocation === "left" || drawerLocation === "right" ? "horizontal" : "vertical";
    const { size, onResizeDrawer, isResizing } = useDrawerResizeHandler(dockElement, drawerLocation);
    const dimensionStyles = size === undefined
        ? undefined
        : {
            [orientation === "horizontal" ? "maxWidth" : "maxHeight"]: `${size}px`,
            [orientation === "horizontal" ? "width" : "height"]: `${size}px`,
        };
    const dockPositionStyles = drawerLocation === "bottom" ? dockBottomStyles : drawerLocation === "left" ? cx(dockHorizontalStyles, dockLeftStyles) : cx(dockHorizontalStyles, dockRightStyles);
    const palette = useThemePaletteType();
    const themeName: ThemeName = palette;
    const textThemeName: TextThemeName = useIsSystemFontEnabled() ? "textV2" : "text";
    return (
    // The dev tools should always be displayed in the default theme and should not be affected
    // by any theme overrides that may exist in the developer tooling, such as the Font Override Tool.
    <DesignSystemTheme themeName={themeName} textThemeName={textThemeName}>
            <div ref={dockElement} className={cx(dockStyles, dockPositionStyles, { [dockDefaultDimension]: size === undefined, [dockWithDimensions]: size !== undefined })} style={dimensionStyles}>
                <DrawerDragHandle drawerDockLocation={drawerLocation} onResizeDrawer={onResizeDrawer} isResizing={isResizing}/>
                {children}
            </div>
        </DesignSystemTheme>);
}
const dockStyles = css({
    flex: "auto",
    backgroundColor: themeTokens.color.background.secondary.default,
    zIndex: 10,
});
const dockBottomStyles = css({
    position: "sticky",
    // There's some strange extra space at the bottom of the page when the dev tools are open, which causes some weird scrolling at the bottom of the page.
    // Adding some gap here rather than sticking to 0 avoids this.
    bottom: space[12],
});
const dockHorizontalStyles = css({
    position: "sticky",
    top: 0,
    height: "100vh",
});
const dockLeftStyles = css({
    display: "flex",
    flexDirection: "row-reverse",
});
const dockRightStyles = css({
    display: "flex",
    flexDirection: "row",
});
const dockDefaultDimension = css({
    flex: 0.3,
});
const dockWithDimensions = css({
    flex: "auto",
});
interface DrawerDragHandleProps {
    isResizing: boolean;
    drawerDockLocation: DrawerDockLocation;
    onResizeDrawer: () => void;
}
function DrawerDragHandle({ onResizeDrawer, drawerDockLocation, isResizing }: DrawerDragHandleProps) {
    return (<div className={cx(dragHandleStyles.root, dragHandleByDockLocationStyles[drawerDockLocation], { [dragHandleStyles.resizing]: isResizing })} onMouseDown={onResizeDrawer}>
            <div className={cx(hitboxStyles, hitboxByDockLocationStyles[drawerDockLocation])}/>
        </div>);
}
const hitboxStyles = css({
    zIndex: 10,
    position: "absolute",
    width: "100%",
    height: "100%",
});
const hitboxByDockLocationStyles: Record<DrawerDockLocation, string> = {
    left: css({
        width: "15px",
        right: "-7.5px",
        cursor: "col-resize",
    }),
    right: css({
        width: "15px",
        left: "-7.5px",
        cursor: "col-resize",
    }),
    bottom: css({
        height: "15px",
        top: "-7.5px",
        cursor: "row-resize",
    }),
};
const dragHandleByDockLocationStyles: Record<DrawerDockLocation, string> = {
    left: css({
        borderRight: `solid 1px ${themeTokens.color.border.primary}`,
        paddingLeft: space[8],
    }),
    right: css({
        borderLeft: `solid 1px ${themeTokens.color.border.primary}`,
        paddingRight: space[8],
    }),
    bottom: css({
        borderTop: `solid 1px ${themeTokens.color.border.primary}`,
        paddingBottom: space[8],
    }),
};
const dragHandleStyles = {
    root: css({
        backgroundColor: themeTokens.color.background.secondary.default,
        zIndex: 10,
    }),
    resizing: css({
        borderColor: themeTokens.color.border.selected,
    }),
};
