/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { ActionButton, ActionButtonType, Callout } from "@octopusdeploy/design-system-components";
import type { PackageNote, RunbookSnapshotResource, ArtifactResource, ResourceCollection, ProjectResource, RunbookResource, ReleaseTemplatePackage, ReleaseTemplateGitResource } from "@octopusdeploy/octopus-server-client";
import { PackageReferenceNamesMatch, Permission } from "@octopusdeploy/octopus-server-client";
import * as _ from "lodash";
import { isEqual, compact } from "lodash";
import * as React from "react";
import { GitResourcesList } from "~/areas/projects/components/Releases/GitResourcesList";
import type { GitReferenceModel } from "~/areas/projects/components/Releases/gitResourceModel";
import { getNotesForPackages, splitPackagesIntoBoundOrUnboundFeeds } from "~/areas/projects/components/releaseAndRunbookHelpers";
import ArtifactLink from "~/areas/tasks/components/Task/Artifacts/ArtifactLink";
import { repository } from "~/clientInstance";
import BaseComponent from "~/components/BaseComponent";
import type { DoBusyTask } from "~/components/DataBaseComponent/DataBaseComponent";
import Markdown from "~/components/Markdown/index";
import ExternalLink from "~/components/Navigation/ExternalLink/ExternalLink";
import { PagingList } from "~/components/PagingList/PagingList";
import PermissionCheck, { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import Section from "~/components/Section";
import TimeFromNowLabel from "~/components/TimeLabels/TimeFromNowLabel";
import { FormSectionHeading, Note } from "~/components/form";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
import PackagesList from "../../Releases/PackagesList/PackagesList";
import { VariableManifest } from "../../Releases/VariableManifest/VariableManifest";
import { VariableSnapshot } from "../../Releases/VariableSnapshot/VariableSnapshot";
import type { PackageModel } from "../../Releases/packageModel";
import { buildPartialReleaseNotes } from "../../Releases/releaseNoteHelper";
import styles from "./RunbookSnapshotInfoPage.module.less";
interface RunbookSnapshotInfoState {
    packages: PackageModel[];
    gitReferences: GitReferenceModel[];
    artifacts: ResourceCollection<ArtifactResource>;
    showFullNotes: boolean;
    currentPageIndex?: number;
    currentSkip: number;
}
class ArtifactsList extends PagingList<ArtifactResource> {
}
interface RunbookSnapshotInfoProps {
    doBusyTask: DoBusyTask;
    runbook: RunbookResource;
    project: ProjectResource;
    runbookSnapshot: RunbookSnapshotResource;
    runbookRunId?: string; // Filter to dependencies, artifacts, etc for a specific run.
    hideUpdateVariableSnapshot?: boolean;
    disableQueryStringFilters?: boolean;
    variableSnapshotRefreshKey: string;
}
export class RunbookSnapshotInfo extends BaseComponent<RunbookSnapshotInfoProps, RunbookSnapshotInfoState> {
    private packageResolveMessage: string = "Package will be resolved during runbook run";
    constructor(props: RunbookSnapshotInfoProps) {
        super(props);
        this.state = {
            packages: [],
            gitReferences: [],
            artifacts: null!,
            showFullNotes: false,
            currentPageIndex: 0,
            currentSkip: 0,
        };
    }
    async componentDidMount() {
        await this.reload();
    }
    async componentDidUpdate(prevProps: RunbookSnapshotInfoProps) {
        const nextRunbook = this.props.runbook;
        const currentRunbook = prevProps.runbook;
        if (!isEqual(currentRunbook, nextRunbook)) {
            await this.reload();
        }
    }
    reload = async () => {
        const project = this.props.project;
        return this.props.doBusyTask(async () => {
            await this.init(project, this.props.runbookSnapshot);
            this.setState(await this.refreshActiveComponents(this.props.runbookSnapshot));
        }, { timeOperationOptions: timeOperationOptions.forInitialLoad() });
    };
    render() {
        return (<>
                {this.props.runbookSnapshot.Notes && this.getRunbookSnapshotNoteSection()}
                <FormSectionHeading key="packages" title="Packages"/>
                <Section key="sectionPackages" sectionHeader="">
                    <PermissionCheck permission={Permission.FeedView} alternate={<Callout type={"information"} title={"Permission required"}>
                                The {Permission.FeedView} permission is required to view packages
                            </Callout>}>
                        <PermissionCheck permission={Permission.RunbookView} project={this.props.runbookSnapshot.ProjectId} wildcard={true} alternate={<Callout type={"information"} title={"Permission required"}>
                                    The {Permission.RunbookView} permission is required to view packages
                                </Callout>}>
                            <div className={styles.runbookSnapshotPackagesLayout}>
                                <PackagesList packages={this.state.packages} buildInformation={null}/>
                            </div>
                        </PermissionCheck>
                    </PermissionCheck>
                </Section>
                <FormSectionHeading key="gitReferences" title="Git References"/>
                <Section key="sectionGitReferences" sectionHeader="">
                    <PermissionCheck permission={Permission.FeedView} alternate={<Callout type={"information"} title={"Permission required"}>
                                The {Permission.FeedView} permission is required to view git references
                            </Callout>}>
                        <PermissionCheck permission={Permission.RunbookView} project={this.props.runbookSnapshot.ProjectId} wildcard={true} alternate={<Callout type={"information"} title={"Permission required"}>
                                    The {Permission.RunbookView} permission is required to view git references
                                </Callout>}>
                            <div className={styles.runbookSnapshotPackagesLayout}>
                                <GitResourcesList gitReferences={this.state.gitReferences}/>
                            </div>
                        </PermissionCheck>
                    </PermissionCheck>
                </Section>
                {/*Include the VariableSnapshot outside of the Section so that the table can extend to the edge of the paper element*/}
                {this.props.runbookSnapshot && (<PermissionCheck permission={Permission.VariableView} project={this.props.runbookSnapshot.ProjectId} wildcard={true}>
                        {/* If we're rendering for a Runbook run and it's a Git Runbook */}
                        {this.props.runbookRunId && this.props.runbookSnapshot.GitReference?.GitCommit ? (<VariableManifest spaceId={this.props.runbookSnapshot.SpaceId} projectId={this.props.runbookSnapshot.ProjectId} snapshot={this.props.runbookSnapshot} executionId={this.props.runbookRunId} doBusyTask={this.props.doBusyTask}/>) : (<VariableSnapshot spaceId={this.props.runbookSnapshot.SpaceId} projectId={this.props.runbookSnapshot.ProjectId} snapshot={this.props.runbookSnapshot} doBusyTask={this.props.doBusyTask} updateVariablesRefreshKey={this.props.variableSnapshotRefreshKey} onUpdate={this.reload} hideUpdateSnapshot={this.props.hideUpdateVariableSnapshot} disableQueryStringFilters={this.props.disableQueryStringFilters}/>)}
                    </PermissionCheck>)}
                {this.state.artifacts && (<>
                        <FormSectionHeading key="artifacts" title="Artifacts"/>
                        <div className={styles.runbookSnapshotArtifactsLayout}>
                            <ArtifactsList initialData={this.state.artifacts} onRow={(artifact: ArtifactResource) => (<>
                                        <ArtifactLink artifact={artifact} key="link"/>
                                        <div key="time" className={styles.time}>
                                            <TimeFromNowLabel time={artifact.Created}/>
                                        </div>
                                    </>)} showPagingInNumberedStyle={true} currentPageIndex={this.state.currentPageIndex} onPageSelected={this.handleArtifactsPageSelected} empty={<Note>
                                        No artifacts have been added. Learn more about <ExternalLink href="Artifacts">collecting artifacts</ExternalLink>.
                                    </Note>}/>
                        </div>
                    </>)}
            </>);
    }
    private async init(project: ProjectResource, runbookSnapshot: RunbookSnapshotResource) {
        let templatePackages: ReleaseTemplatePackage[] | undefined = undefined;
        let templateGitReferences: ReleaseTemplateGitResource[] | undefined = undefined;
        if (isAllowed({ permission: Permission.RunbookView, project: project.Id, wildcard: true })) {
            if (this.props.runbookRunId) {
                const runDetails = await repository.Runbooks.getRunbookRunDetails(project, this.props.runbookRunId);
                templatePackages = runDetails.Packages;
                templateGitReferences = runDetails.GitReferences;
            }
            else {
                const runbookProcess = await repository.RunbookProcess.get(runbookSnapshot.FrozenRunbookProcessId);
                const template = await repository.RunbookProcess.getRunbookSnapshotTemplate(runbookProcess, runbookSnapshot.Id);
                templatePackages = template?.Packages;
                templateGitReferences = template?.GitResources;
            }
        }
        this.matchAndMapPackagesAndGitReferences(project, runbookSnapshot, templatePackages, templateGitReferences);
    }
    private async matchAndMapPackagesAndGitReferences(project: ProjectResource, runbookSnapshot: RunbookSnapshotResource, templatePackages: ReleaseTemplatePackage[] | undefined, templateGitReferences: ReleaseTemplateGitResource[] | undefined) {
        const allPackages = templatePackages
            ? compact(templatePackages.map((packageTemplate) => {
                const selectionForStep = runbookSnapshot.SelectedPackages.find((selected) => selected.ActionName === packageTemplate.ActionName && PackageReferenceNamesMatch(selected!.PackageReferenceName!, packageTemplate.PackageReferenceName!));
                if (selectionForStep) {
                    return {
                        ActionName: packageTemplate.ActionName,
                        PackageId: packageTemplate.PackageId,
                        PackageReferenceName: packageTemplate.PackageReferenceName!,
                        ProjectName: packageTemplate.ProjectName,
                        FeedName: packageTemplate.FeedName,
                        FeedId: packageTemplate.FeedId,
                        Version: (selectionForStep as any).Version,
                        Notes: {
                            Notes: null,
                            Succeeded: true,
                            FailureReason: null,
                            Published: null,
                        },
                    };
                }
            }))
            : [];
        const allGitReferences = templateGitReferences
            ? compact(templateGitReferences?.map((gitResource) => {
                const selectionForStep = runbookSnapshot.SelectedGitResources.find((selected) => selected.ActionName === gitResource.ActionName);
                if (selectionForStep) {
                    return {
                        ActionName: gitResource.ActionName,
                        RepositoryUri: gitResource.RepositoryUri,
                        FilePathFilters: gitResource.FilePathFilters,
                        GitResource: selectionForStep.GitReferenceResource ?? gitResource.GitResourceSelectedLastRelease,
                    };
                }
            }))
            : [];
        this.setState(() => ({ packages: allPackages, gitReferences: allGitReferences }));
        if (isAllowed({ permission: Permission.FeedView, project: project.Id, wildcard: true })) {
            // `loadPackages` is not awaited to prevent the page from freezing while waiting for package notes to load.
            this.loadPackages(allPackages);
        }
    }
    private async refreshActiveComponents(runbookSnapshot: RunbookSnapshotResource) {
        const artifacts = await this.loadArtifactsPromise(runbookSnapshot, this.state.currentSkip)!;
        const resultForState: RunbookSnapshotInfoState = {
            ...this.state,
            artifacts,
        };
        return resultForState;
    }
    private async loadPackages(allPackages: PackageModel[]) {
        const boundUnbound = splitPackagesIntoBoundOrUnboundFeeds(allPackages);
        // Bound packages all get a standard release notes string
        this.setState((existingState) => {
            boundUnbound.bound.forEach((bound) => (bound.Notes.Notes = this.packageResolveMessage));
            return {
                packages: [..._.differenceWith(existingState.packages, boundUnbound.bound, this.packageNoteEquals), ...boundUnbound.bound],
            };
        });
        try {
            (await getNotesForPackages(boundUnbound.unBound)).forEach((notes) => {
                this.setState((existingState) => {
                    // for every package that was returned, update the existing package
                    // with the returned notes.
                    const updated = existingState.packages.map((existing) => _.assign(existing, this.findMatchingNotesPackage(existing, notes.Packages)));
                    return {
                        packages: updated,
                    };
                });
            });
        }
        catch (err) {
            this.setState((existingState) => {
                // for every package that was requested, set the state to error.
                // Possible bug: The Success property looks like it should be referring to PackageNoteResult.Succeeded.
                const updated = existingState.packages.map((existing) => _.assign(existing, this.findMatchingNotesPackage(existing, boundUnbound.unBound, { Notes: { Success: false, FailureMessage: err.ErrorMessage } })));
                return {
                    packages: updated,
                };
            });
        }
    }
    /**
     * Finding runbookSnapshot notes in a bulk fashion from the server means:
     * 1. Requesting the package details (in a request with a bunch of other packages)
     * 2. Assigning the returned details back to the matching packages from the state
     * 3. Optionally setting the some additional field, typically when a batch request failed and all packages need to show an error
     * This function will attempt to find a matching package from the list of returned packages, and if so assign the values from 3
     * to it, and then return it. Otherwise it will return an empty object. The returned object is expected to be assigned to
     * the package in the state to result in an updated package object that can be displayed to the user.
     * @param {PackageNote} original The original package details to match against the package returned by the server
     * @param {PackageNote[]} packages The list of packages returned by the server
     * @param assign An object that is assigned to the matching package, if one was found. It is like an "overlay" on matching packages.
     * @returns {(PackageNote | undefined) | {}} An empty object if no match was found, and the returned package
     * with the assign object assigned to it.
     */
    private findMatchingNotesPackage(original: PackageNote, packages: PackageNote[], assign: any = null) {
        const packageWithNotes = packages.find((pkgWithNotes) => this.packageNoteEquals(pkgWithNotes, original));
        if (packageWithNotes) {
            if (assign) {
                _.assign(packageWithNotes, assign);
            }
        }
        return packageWithNotes || {};
    }
    private packageNoteEquals(a: PackageNote, b: PackageNote) {
        return a.PackageId === b.PackageId && a.Version === b.Version && a.FeedId === b.FeedId;
    }
    private buildNotes() {
        if (this.state.showFullNotes) {
            return <Markdown markup={this.props.runbookSnapshot.Notes}/>;
        }
        const [runbookSnapshotNotes, isTruncated] = buildPartialReleaseNotes(this.props.runbookSnapshot.Notes, 10);
        return (<div>
                <Markdown markup={runbookSnapshotNotes}/>
                {isTruncated && <ActionButton type={ActionButtonType.Ternary} onClick={() => this.setState({ showFullNotes: true })} label="Show more"/>}
            </div>);
    }
    private getRunbookSnapshotNoteSection() {
        return [
            <FormSectionHeading key="runbookSnapshotNoteHeading" title="Notes"/>,
            <Section key="runbookSnapshotSection" sectionHeader="">
                <div className={styles.runbookSnapshotNoteLayout}>{this.buildNotes()}</div>
            </Section>,
        ];
    }
    private loadArtifactsPromise = (runbookSnapshot: RunbookSnapshotResource, skip: number) => isAllowed({ permission: Permission.ArtifactView, wildcard: true })
        ? repository.Artifacts.list({
            regarding: this.props.runbookRunId ?? runbookSnapshot.Id,
            skip,
            take: 10,
            order: "asc",
        })
        : null;
    private handleArtifactsPageSelected = async (skip: number, p: number) => {
        this.setState({ currentPageIndex: p, currentSkip: skip });
        this.setState({ artifacts: await this.loadArtifactsPromise(this.props.runbookSnapshot, skip)! });
    };
    static displayName = "RunbookSnapshotInfo";
}
