/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { css } from "@emotion/css";
import { Divider, NavIconButton, NotificationIcon, Tooltip } from "@octopusdeploy/design-system-components";
import { colorScales } from "@octopusdeploy/design-system-tokens";
import { useTrackEvent } from "@octopusdeploy/portal-analytics";
import cn from "classnames";
import { values } from "lodash";
import * as React from "react";
import { useSelector } from "react-redux";
import { BaseComponent } from "~/components/BaseComponent/BaseComponent";
import { createNotificationCallToActionClickedAnalyticsEvent } from "~/components/Navbar/analytics/NotificationCallToActionClickedAnalyticsEvent";
import { createNotificationsIconClickedAnalyticsEvent } from "~/components/Navbar/analytics/NotificationsIconClickedAnalyticsEvent";
import { useOctopusFeatureToggle } from "~/hooks/useOctopusFeatureToggle";
import { Popover } from "~/primitiveComponents/dataDisplay/Popover/Popover";
import ExternalLink from "../Navigation/ExternalLink";
import InternalLink from "../Navigation/InternalLink";
import * as notificationLocalState from "./NotificationPoller/notificationLocalState";
import styles from "./notifications.module.less";
import type { NotificationItem, NotificationItemExternalLink, NotificationItemInternalLink, NotificationItemLink } from "./notifications.redux";
import { NotificationType } from "./notifications.redux";
interface NotificationsMenuState {
    isMenuOpen: boolean;
    notifications: NotificationItem[];
    showNewNotificationsAlert: boolean;
}
interface NotificationsMenuInternalProps {
    notifications: NotificationItem[];
    onNotificationOpened: (options: {
        hasActiveAlert: boolean;
    }) => void;
    onNotificationCallToActionClicked: (notification: NotificationItem) => void;
}
const defaultNotification: NotificationItem = {
    id: null!,
    text: "You have no notifications.",
    type: NotificationType.happy,
    description: "Happy deployments!",
};
class NotificationsMenuInternal extends BaseComponent<NotificationsMenuInternalProps, NotificationsMenuState> {
    private menuButton: HTMLElement | null = null;
    constructor(props: NotificationsMenuInternalProps) {
        super(props);
        this.state = {
            isMenuOpen: false,
            notifications: [defaultNotification],
            showNewNotificationsAlert: false,
        };
    }
    async componentDidMount() {
        this.checkNotificationsAlert(this.props);
    }
    UNSAFE_componentWillReceiveProps(newProps: NotificationsMenuInternalProps) {
        if (this.props.notifications !== newProps.notifications) {
            this.checkNotificationsAlert(newProps);
        }
    }
    checkNotificationsAlert = (props: NotificationsMenuInternalProps) => {
        const showNewNotificationsAlert = !!props.notifications.find((n) => {
            const currentValue = notificationLocalState.getCurrentValue(n.id);
            const notificationHasValue = !!n.value;
            return !currentValue || !((notificationHasValue && currentValue === n.value) || !notificationHasValue);
        });
        this.setState({ showNewNotificationsAlert });
    };
    renderNotification = (notification: NotificationItem, index: number) => {
        return (<div key={notification.text} className={styles.container}>
                {index > 0 && <Divider />}
                <div className={styles.notification}>
                    <div className={styles.grow}>
                        <div className={cn(styles.title, {
                [styles.textHappy]: notification.type === NotificationType.happy,
                [styles.textInfo]: notification.type === NotificationType.info,
                [styles.textWarn]: notification.type === NotificationType.warn,
                [styles.textError]: notification.type === NotificationType.error,
            })}>
                            {notification.text}
                        </div>
                        <div className={styles.description}>{notification.description}</div>
                        {notification.links && (<div className={styles.links}>
                                {notification.links.map((link, i) => (<span key={i}>
                                        {i > 0 && " | "}
                                        {this.renderLink(notification, link)}
                                    </span>))}
                            </div>)}
                    </div>
                    <div className={styles.icon}>{this.displayIcon(notification.type)}</div>
                </div>
            </div>);
    };
    renderLink(notification: NotificationItem, link: NotificationItemLink | NotificationItemInternalLink | NotificationItemExternalLink) {
        switch (link.type) {
            case "External": {
                return (<ExternalLink href={link.href} onClick={() => this.props.onNotificationCallToActionClicked(notification)}>
                        {link.label}
                    </ExternalLink>);
            }
            case "Internal": {
                return (<InternalLink to={link.href} onClick={() => this.props.onNotificationCallToActionClicked(notification)}>
                        {link.label}
                    </InternalLink>);
            }
            case "Refresh": {
                return (<a onClick={() => {
                        this.props.onNotificationCallToActionClicked(notification);
                        window.location.reload();
                    }} href="#">
                        {link.label}
                    </a>);
            }
            default: {
                return <span>{link.label}</span>;
            }
        }
    }
    render() {
        const notifications = [...this.props.notifications];
        if (notifications.length === 0) {
            notifications.push(defaultNotification);
        }
        return (<div>
                <NotificationButton onClick={this.handleTouchTap} accessibleName="Notifications" hasNewNotifications={this.state.showNewNotificationsAlert} isOpen={this.state.isMenuOpen} ref={this.setRef}/>
                <Popover open={this.state.isMenuOpen} anchorEl={this.menuButton} onClose={this.handleRequestClose} anchorOrigin={{ horizontal: "right", vertical: "bottom" }} transformOrigin={{ horizontal: "right", vertical: "top" }}>
                    {notifications.map(this.renderNotification)}
                </Popover>
            </div>);
    }
    private markNotificationsAsRead = () => {
        this.props.notifications.forEach((n) => notificationLocalState.setCurrentValue(n.id, n.value));
        this.setState({
            showNewNotificationsAlert: false,
        });
    };
    private displayIcon = (type: NotificationType) => {
        switch (type) {
            case NotificationType.happy:
                return <div className={cn("fa-regular fa-smile", styles.notificationIcon, styles.bgHappy)}/>;
            case NotificationType.info:
                return <div className={cn("fa-solid fa-circle-info", styles.notificationIcon, styles.bgInfo)}/>;
            case NotificationType.warn:
                return <div className={cn("fa-solid fa-triangle-exclamation", styles.notificationIcon, styles.bgWarn)}/>;
            case NotificationType.error:
                return <div className={cn("fa-solid fa-triangle-exclamation", styles.notificationIcon, styles.bgError)}/>;
        }
    };
    private setRef = (el: HTMLElement | null) => {
        this.menuButton = el;
    };
    private handleTouchTap = (event: React.MouseEvent) => {
        event.preventDefault();
        const hasActiveAlert = this.state.showNewNotificationsAlert;
        this.markNotificationsAsRead();
        this.setState({
            isMenuOpen: !this.state.isMenuOpen,
        });
        this.props.onNotificationOpened({ hasActiveAlert });
    };
    private handleRequestClose = () => {
        this.setState({
            isMenuOpen: false,
        });
    };
    static displayName = "NotificationsMenuInternal";
}
interface NotificationButtonUpliftProps {
    onClick: React.MouseEventHandler;
    accessibleName: string;
    hasNewNotifications: boolean;
    isOpen: boolean;
}
const NotificationButton = React.forwardRef<HTMLButtonElement, NotificationButtonUpliftProps>(({ onClick, accessibleName, hasNewNotifications, isOpen }, ref) => {
    return (<Tooltip content="Notifications">
            <NavIconButton onClick={onClick} accessibleName={accessibleName} aria-expanded={isOpen} ref={ref} icon={<>
                        <NotificationIcon />
                        {hasNewNotifications && <div className={newNotificationsDotStyles}/>}
                    </>}/>
        </Tooltip>);
});
const newNotificationsDotStyles = css({
    position: "absolute",
    top: 4,
    right: 4,
    height: 14,
    width: 14,
    borderRadius: 10,
    background: colorScales.red[500],
});
export function NotificationsMenu() {
    const showHeartbeat = useOctopusFeatureToggle("heartbeat", false);
    const notifications = useSelector((state: GlobalState) => values(state.notifications));
    if (showHeartbeat) {
        const heartbeatNotification: NotificationItem = {
            id: "heartbeat-notification",
            text: "Heartbeat",
            description: "The heartbeat feature toggle is ON",
            type: NotificationType.happy,
        };
        notifications.push(heartbeatNotification);
    }
    const trackEvent = useTrackEvent();
    return (<NotificationsMenuInternal notifications={notifications} onNotificationOpened={({ hasActiveAlert }) => trackEvent(createNotificationsIconClickedAnalyticsEvent(hasActiveAlert))} onNotificationCallToActionClicked={({ id }) => trackEvent(createNotificationCallToActionClickedAnalyticsEvent(id))}/>);
}
export default NotificationsMenu;
