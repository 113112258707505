import type { AreaNavListItem, GlobalLayoutProps, NavigationBarActionData, AreaNavListGroupItem, AreaNavListGroup, AreaNavPanelExpandState } from "@octopusdeploy/design-system-components";
import { GlobalLayout, useIsLargerThanIpadResolution, Avatar } from "@octopusdeploy/design-system-components";
import type { UserFavouriteProjectSummary } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import { AnalyticLinkLocationProvider } from "@octopusdeploy/portal-analytics";
import { links } from "@octopusdeploy/portal-routes";
import { exhaustiveCheck, isNotNull } from "@octopusdeploy/type-utils";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import useIsMultiTenancyEnabledFeatureFlag from "~/areas/configuration/hooks/useIsMultiTenancyEnabledFeatureFlag";
import { WelcomeDialog } from "~/areas/dashboard/Onboarding/WelcomeDialog/WelcomeDialog";
import { client, repository, session } from "~/clientInstance";
import { isFeatureToggleEnabled } from "~/components/FeatureToggle/New/FeatureToggleContext";
import { hasPermissionsInAnyScope } from "~/components/GlobalLayout/hasPermissionInAnyScope";
import DeprecationNotificationsPoller from "~/components/Navbar/NotificationPoller/DeprecationNotificationsPoller";
import LicenceNotificationPoller from "~/components/Navbar/NotificationPoller/LicenceNotificationPoller";
import PageLayoutUpliftNotifications from "~/components/Navbar/NotificationPoller/PageLayoutUpliftNotification";
import UpdateAvailableNotificationPoller from "~/components/Navbar/NotificationPoller/UpdateAvailableNotificationPoller";
import { hasPermission, isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import type { SpaceContext } from "~/components/SpaceLoader/SpaceLoader";
import { isSpaceNotFound, isSpecificSpaceContext } from "~/components/SpaceLoader/SpaceLoader";
import SystemMessagesBanner from "~/components/SystemMessagesBanner/SystemMessagesBanner";
import SearchAndOpenPopover from "~/globalSearch/SearchAndOpenPopover";
import { useOctopusFeatureToggle } from "~/hooks/useOctopusFeatureToggle";
import { usePageRegistration } from "~/routing/pageRegistrations/PageRegistrationContext";
import { ContextualHelpLayout } from "../ContextualHelpLayout/ContextualHelpLayout";
import type { DoBusyTask } from "../DataBaseComponent";
import DataBaseComponent, { useDoBusyTaskEffect } from "../DataBaseComponent";
import { HelpNavigationActionButton } from "../Navbar/HelpNavigationActionButton";
import TargetTagsNotifications from "../Navbar/NotificationPoller/TargetTagsNotifications";
import NotificationsMenu from "../Navbar/NotificationsMenu";
import { SpaceSwitcherNavigationBarItem } from "../Navbar/SpaceSwitcherNavigationBarItem";
import { InPageNavVisibilityProvider } from "./InPageNavVisibilityContext";
import { UnhandledErrorPanel } from "./UnhandledErrorPanel";
import { getNavigationSideBarBottomItems, getSideNavigationAreas } from "./portalNavigationSiderBarItems";
interface PortalGlobalLayoutProps {
    spaceContext: SpaceContext;
    children: React.ReactNode;
}
export class PortalGlobalLayout extends DataBaseComponent<PortalGlobalLayoutProps> {
    render() {
        return <PortalGlobalLayoutInternal {...this.props} doBusyTask={this.doBusyTask}/>;
    }
    static displayName = "PortalGlobalLayout";
}
interface PortalGlobalLayoutInternalProps extends PortalGlobalLayoutProps {
    doBusyTask: DoBusyTask;
}
function PortalGlobalLayoutInternal({ spaceContext, children, doBusyTask }: React.PropsWithChildren<PortalGlobalLayoutInternalProps>) {
    const isLargerThanIpad = useIsLargerThanIpadResolution();
    const actions: NavigationBarActionData[] = [...getNavigationActions(isLargerThanIpad)];
    const [isInPageNavVisible, setIsInPageNavVisible] = React.useState(false);
    const areaNavPanelDisplayMode = isInPageNavVisible ? "overlay" : "inline";
    const [areaNavPanelExpandState, setAreaNavPanelExpandState] = React.useState<AreaNavPanelExpandState>(getDefaultAreaNavPanelExpandState(areaNavPanelDisplayMode));
    const areaNavItems = usePortalPageAreaNavItems(spaceContext, doBusyTask);
    const hasAreaNavItems = areaNavItems.length > 0;
    React.useLayoutEffect(() => {
        setAreaNavPanelExpandState(getDefaultAreaNavPanelExpandState(areaNavPanelDisplayMode));
    }, [areaNavPanelDisplayMode]);
    const pageRegistration = usePageRegistration();
    const sideBarTopNavigationItems = pageRegistration ? getSideNavigationAreas(spaceContext, pageRegistration, hasAreaNavItems, areaNavPanelExpandState, setAreaNavPanelExpandState, areaNavPanelDisplayMode) : [];
    const showBlueprints = useOctopusFeatureToggle("blueprints", false);
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const sideBarBottomNavigationItems = pageRegistration ? getNavigationSideBarBottomItems(session.currentUser!, spaceContext, showBlueprints) : [];
    const scrollAreaRef = React.useRef<HTMLDivElement>(null);
    const location = useLocation();
    const prevPathnameRef = React.useRef(location.pathname);
    useEffect(() => {
        if (prevPathnameRef.current !== location.pathname && scrollAreaRef.current && typeof scrollAreaRef.current.scrollTo === "function") {
            scrollAreaRef.current.scrollTo({ top: 0 });
            prevPathnameRef.current = location.pathname;
        }
    }, [location]);
    return (<InPageNavVisibilityProvider isVisible={isInPageNavVisible} setIsVisible={setIsInPageNavVisible}>
            <GlobalLayout scrollAreaRef={scrollAreaRef} areaNavPanel={{
            items: areaNavItems,
            displayMode: areaNavPanelDisplayMode,
            expandState: areaNavPanelExpandState,
            setExpandState: setAreaNavPanelExpandState,
        }} topNavBar={{
            logo: isSpaceNotFound(spaceContext) ? undefined : <SpaceSwitcherNavigationBarItem spaceContext={spaceContext}/>,
            searchBar: isSpaceNotFound(spaceContext) ? undefined : <SearchAndOpenPopover isFullWidth={!isLargerThanIpad}/>,
            actions,
        }} sideNavBar={{
            topItems: sideBarTopNavigationItems,
            bottomItems: sideBarBottomNavigationItems,
        }}>
                <UpdateAvailableNotificationPoller />
                <LicenceNotificationPoller />
                <PageLayoutUpliftNotifications />
                <TargetTagsNotifications />
                <DeprecationNotificationsPoller />
                <WelcomeDialog />
                <AnalyticLinkLocationProvider location="Main Navigation Error">
                    <UnhandledErrorPanel />
                </AnalyticLinkLocationProvider>
                <SystemMessagesBanner />
                <ContextualHelpLayout>{children}</ContextualHelpLayout>
            </GlobalLayout>
        </InPageNavVisibilityProvider>);
}
function getDefaultAreaNavPanelExpandState(displayMode: "overlay" | "inline"): AreaNavPanelExpandState {
    return displayMode === "inline" ? { isExpanded: true } : { isExpanded: false };
}
function getNavigationActions(isLargerThanIpad: boolean): NavigationBarActionData[] {
    return [{ key: "notifications", content: <NotificationsMenu /> }, isLargerThanIpad ? { key: "help-menu", content: <HelpNavigationActionButton /> } : null].filter(isNotNull);
}
export function usePortalPageAreaNavItems(spaceContext: SpaceContext, doBusyTask: DoBusyTask): GlobalLayoutProps["areaNavPanel"]["items"] {
    const registration = usePageRegistration();
    const isMultiTenancyEnabled = useIsMultiTenancyEnabledFeatureFlag();
    const isDynamicEnvironmentsEnabled = isFeatureToggleEnabled("DynamicEnvironmentsFeatureToggle");
    const favouriteProjects = useFavouriteProjects(doBusyTask);
    if (!registration) {
        return [];
    }
    switch (registration.verticalNavigationPageArea) {
        case "Projects":
            return getProjectsAreaNavPanelItems(spaceContext, isMultiTenancyEnabled, isDynamicEnvironmentsEnabled, favouriteProjects);
        case "Tasks":
        case "User Profile":
        case "Dev Tools":
        case "Configuration":
        case "Insights":
        case "Blueprints":
            return [];
        default:
            exhaustiveCheck(registration.verticalNavigationPageArea, "Not all page areas have been handled");
    }
}
function useFavouriteProjects(doBusyTask: DoBusyTask): UserFavouriteProjectSummary[] | "not-loaded" {
    const [favouriteProjects, setFavouriteProjects] = useState<UserFavouriteProjectSummary[] | "not-loaded">("not-loaded");
    const refresh = useDoBusyTaskEffect(doBusyTask, async () => {
        if (hasPermission(Permission.ProjectView)) {
            const result = await repository.Users.getProjectFavourites();
            setFavouriteProjects(result.Projects);
        }
        else {
            setFavouriteProjects([]);
        }
    }, []);
    useEffect(() => {
        return client.subscribe((event) => {
            if (event.type === "UserFavouriteProjectCreated" || event.type === "UserFavouriteProjectDeleted") {
                refresh();
            }
        });
    }, [refresh]);
    return favouriteProjects;
}
function getProjectsAreaNavPanelItems(spaceContext: SpaceContext, isMultiTenancyEnabled: boolean, isDynamicEnvironmentsEnabled: boolean, favouriteProjects: UserFavouriteProjectSummary[] | "not-loaded"): ReadonlyArray<AreaNavListItem> {
    if (isSpecificSpaceContext(spaceContext)) {
        const gitHubConnectionsEnabled = isFeatureToggleEnabled("GitHubConnectionsFeatureToggle");
        const favouriteProjectItems: AreaNavListGroupItem[] = favouriteProjects === "not-loaded"
            ? [{ placeholderLabel: "..." }]
            : favouriteProjects.length === 0
                ? [{ placeholderLabel: "No favorite projects" }]
                : favouriteProjects.map((p) => ({
                    label: p.Name,
                    href: links.projectRootRedirect.generateUrl({ spaceId: spaceContext.Id, projectSlug: p.Slug }),
                    icon: <Avatar shape={"squared"} src={client.resolve(p.Logo)} size={20} alt={`${p.Name} Logo`}/>,
                }));
        return ([
            { label: "Projects", href: links.projectsPage.generateUrl({ spaceId: spaceContext.Id }) },
            "spacer",
            ...groupWithTrailingDivider("My Favorites", favouriteProjectItems),
            ...groupWithTrailingDivider(undefined, [
                hasPermissionsInAnyScope(spaceContext.Id, Permission.TenantView) ? { label: "Tenants", href: links.tenantsPage.generateUrl({ spaceId: spaceContext.Id }) } : null,
                isMultiTenancyEnabled && isAllowed({ permission: Permission.VariableView, wildcard: true }, { spaceId: spaceContext.Id }) ? { label: "Tenant Tag Sets", href: links.tagSetsPage.generateUrl({ spaceId: spaceContext.Id }) } : null,
                isAllowed({ permission: [Permission.VariableView, Permission.LibraryVariableSetView], wildcard: true }, { spaceId: spaceContext.Id })
                    ? {
                        label: "Variable Sets",
                        href: links.variableSetsPage.generateUrl({ spaceId: spaceContext.Id }),
                    }
                    : null,
            ]),
            ...groupWithTrailingDivider("Infrastructure", [
                isAllowed({ permission: [Permission.EnvironmentView, Permission.MachineView, Permission.WorkerView], wildcard: true }, { spaceId: spaceContext.Id })
                    ? {
                        label: "Overview",
                        href: links.infrastructureOverviewPage.generateUrl({ spaceId: spaceContext.Id }),
                    }
                    : null,
                isAllowed({ permission: [Permission.MachineView], wildcard: true }, { spaceId: spaceContext.Id })
                    ? {
                        label: "Deployment Targets",
                        href: links.deploymentTargetsPage.generateUrl({ spaceId: spaceContext.Id }),
                    }
                    : null,
                isDynamicEnvironmentsEnabled && isAllowed({ permission: [Permission.EnvironmentView, Permission.MachineView] }, { spaceId: spaceContext.Id })
                    ? {
                        label: "Dynamic Environments",
                        href: links.dynamicEnvironmentsPage.generateUrl({ spaceId: spaceContext.Id }),
                    }
                    : null,
                isAllowed({ permission: [Permission.EnvironmentView, Permission.MachineView] }, { spaceId: spaceContext.Id })
                    ? {
                        label: "Environments",
                        href: links.infrastructureEnvironmentsPage.generateUrl({ spaceId: spaceContext.Id }),
                    }
                    : null,
                isAllowed({ permission: Permission.MachinePolicyView, wildcard: true }, { spaceId: spaceContext.Id })
                    ? {
                        label: "Machine Policies",
                        href: links.machinePoliciesPage.generateUrl({ spaceId: spaceContext.Id }),
                    }
                    : null,
                isAllowed({ permission: Permission.ProxyView, wildcard: true }, { spaceId: spaceContext.Id }) ? { label: "Machine Proxies", href: links.proxiesPage.generateUrl({ spaceId: spaceContext.Id }) } : null,
                isAllowed({ permission: [Permission.WorkerView], wildcard: true }, { spaceId: spaceContext.Id }) ? { label: "Workers", href: links.workerMachinesPage.generateUrl({ spaceId: spaceContext.Id }) } : null,
                isAllowed({ permission: [Permission.EnvironmentView] }, { spaceId: spaceContext.Id }) ? { label: "Worker Pools", href: links.workerPoolsPage.generateUrl({ spaceId: spaceContext.Id }) } : null,
            ]),
            groupWithNullableItems("Manage", [
                isAllowed({ permission: Permission.AccountView, wildcard: true }, { spaceId: spaceContext.Id })
                    ? {
                        label: "Accounts",
                        href: links.infrastructureAccountsPage.generateUrl({ spaceId: spaceContext.Id }),
                    }
                    : null,
                isAllowed({ permission: Permission.FeedView }, { spaceId: spaceContext.Id }) ? { label: "Build Information", href: links.buildInformationOverviewPage.generateUrl({ spaceId: spaceContext.Id }) } : null,
                isAllowed({ permission: Permission.CertificateView, wildcard: true }, { spaceId: spaceContext.Id }) ? { label: "Certificates", href: links.certificatesPage.generateUrl({ spaceId: spaceContext.Id }) } : null,
                isAllowed({ permission: Permission.FeedView }, { spaceId: spaceContext.Id }) ? { label: "External Feeds", href: links.feedsPage.generateUrl({ spaceId: spaceContext.Id }) } : null,
                isAllowed({ permission: [Permission.GitCredentialView], wildcard: true }, { spaceId: spaceContext.Id })
                    ? {
                        label: "Git Credentials",
                        href: links.gitCredentialsPage.generateUrl({ spaceId: spaceContext.Id }),
                    }
                    : null,
                gitHubConnectionsEnabled && isAllowed({ permission: [Permission.GitCredentialView], wildcard: true }, { spaceId: spaceContext.Id })
                    ? {
                        label: "GitHub Connections",
                        href: links.gitConnectionsPage.generateUrl({ spaceId: spaceContext.Id }),
                    }
                    : null,
                isAllowed({ permission: Permission.LifecycleView, wildcard: true }, { spaceId: spaceContext.Id }) ? { label: "Lifecycles", href: links.lifecyclesPage.generateUrl({ spaceId: spaceContext.Id }) } : null,
                isAllowed({ permission: Permission.FeedView }, { spaceId: spaceContext.Id }) ? { label: "Packages", href: links.builtInRepositoryPage.generateUrl({ spaceId: spaceContext.Id }) } : null,
                isAllowed({ permission: Permission.VariableView, wildcard: true }, { spaceId: spaceContext.Id }) ? { label: "Script Modules", href: links.scriptModulesPage.generateUrl({ spaceId: spaceContext.Id }) } : null,
                isAllowed({ permission: Permission.ActionTemplateView }, { spaceId: spaceContext.Id }) ? { label: "Step Templates", href: links.stepTemplatesPage.generateUrl({ spaceId: spaceContext.Id }) } : null,
            ]),
        ] as const).filter(isNotNull);
    }
    return [];
}
function groupWithTrailingDivider(heading: string | undefined, groupItems: (AreaNavListGroupItem | null)[]): AreaNavListItem[] {
    const group = groupWithNullableItems(heading, groupItems);
    if (group === undefined) {
        return [];
    }
    return [group, "divider"];
}
function groupWithNullableItems(heading: string | undefined, groupItems: (AreaNavListGroupItem | null)[]): AreaNavListGroup | undefined {
    const nonNullGroupItems = groupItems.filter(isNotNull);
    if (nonNullGroupItems.length === 0)
        return undefined;
    return {
        heading,
        items: nonNullGroupItems,
    };
}
