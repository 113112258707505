/* eslint-disable @typescript-eslint/init-declarations */
/* eslint-disable @octopusdeploy/custom-portal-rules/no-restricted-imports */
import { Fade } from "@material-ui/core";
import { Callout, CircularProgress } from "@octopusdeploy/design-system-components";
import { logger } from "@octopusdeploy/logging";
import type { ActionTemplateSearchResource, BlueprintResource, FeedResource, GitBranchResource, GitRef, GitRefResource, IProcessResource, ModifyDeploymentProcessCommand, PersistenceSettings, ProjectResource, VariableSetResource, } from "@octopusdeploy/octopus-server-client";
import { canCommitTo, DeploymentProcessRepository, GetPrimaryPackageReference, HasVariablesInGit, isProtectedBranch, Permission, ProcessType } from "@octopusdeploy/octopus-server-client";
import type { LinkHref } from "@octopusdeploy/portal-routes";
import { links } from "@octopusdeploy/portal-routes";
import { noOp } from "@octopusdeploy/utilities";
import * as React from "react";
import { useContext } from "react";
import type { ActionEvent, AnalyticErrorCallback, AnalyticTrackedActionDispatcher } from "~/analytics/Analytics";
import { Action, useProjectScopedAnalyticTrackedActionDispatch } from "~/analytics/Analytics";
import { ProcessBlueprintStepDetails } from "~/areas/projects/components/Process/Blueprints/ProcessBlueprintStepDetails";
import { getBlueprintIdFromAction } from "~/areas/projects/components/Process/Blueprints/blueprintId";
import { UnassignedTargetTagsWarningCalloutContent } from "~/areas/projects/components/Process/ConnectedDeploymentTargetsStatus/UnassignedTargetTagsWarningCallout";
import { useMaybeProcessBlueprintsFromContext } from "~/areas/projects/components/Process/Contexts/ProcessBlueprintsContextProvider";
import ProcessActionDetails from "~/areas/projects/components/Process/ProcessActionDetails";
import ProcessBlueprintActionDetails from "~/areas/projects/components/Process/ProcessBlueprintActionDetails";
import ProcessBlueprintParentStepDetails from "~/areas/projects/components/Process/ProcessBlueprintParentStepDetails";
import ProcessParentStepDetails from "~/areas/projects/components/Process/ProcessParentStepDetails";
import type { SampleProjectTourContextProps } from "~/areas/projects/components/ProjectLayout/SampleProjectTour/SampleProjectTour";
import { SampleProjectTourContext } from "~/areas/projects/components/ProjectLayout/SampleProjectTour/SampleProjectTour";
import type { LoadedLibraryVariableSets } from "~/areas/projects/components/Variables/AllVariables/AllVariables";
import { loadLibraryVariableSetVariables } from "~/areas/projects/components/Variables/AllVariables/AllVariables";
import type { CommitMessageWithDetails } from "~/areas/projects/components/VersionControl/CommitMessageWithDetails";
import { getFormattedCommitMessage } from "~/areas/projects/components/VersionControl/CommitMessageWithDetails";
import type { ProjectContextProps, ProjectContextState } from "~/areas/projects/context";
import { useProjectContext } from "~/areas/projects/context";
import { usePersistenceSettingsContext } from "~/areas/projects/context/PersistenceSettingsContext";
import { client, repository } from "~/clientInstance";
import type { ActionPlugin } from "~/components/Actions/pluginRegistry";
import pluginRegistry from "~/components/Actions/pluginRegistry";
import BaseComponent from "~/components/BaseComponent/index";
import type { Errors } from "~/components/DataBaseComponent";
import type { DoBusyTask } from "~/components/DataBaseComponent/DataBaseComponent";
import { Loading } from "~/components/Loading/Loading";
import InternalRedirect from "~/components/Navigation/InternalRedirect";
import { NoResults } from "~/components/NoResults/NoResults";
import type { ConvertedOverflowMenuItems } from "~/components/OverflowMenu/OverflowMenuConverterVNext";
import { OverflowMenuConverterVNext } from "~/components/OverflowMenu/OverflowMenuConverterVNext";
import { Section } from "~/components/Section/Section";
import { createGlobalRequestContext } from "~/globalRequestContext";
import useLocalStorage from "~/hooks/useLocalStorage";
import { useOctopusFeatureToggle } from "~/hooks/useOctopusFeatureToggle";
import PageTitleHelper from "~/utils/PageTitleHelper";
import { hasPermission, isAllowed } from "../../../../components/PermissionCheck/PermissionCheck";
import type { RunbookContextProps } from "../Runbooks/RunbookContext";
import { useOptionalRunbookContext } from "../Runbooks/RunbookContext";
import type { GetCommitButtonProps } from "../VersionControl/CommitButton";
import { GetCommitButton } from "../VersionControl/CommitButton";
import { assembleExistingAction, assembleNewAction, assembleParentStep, getCommonOverflowMenuItems, isRunOnServerOrWorkerPool, isVersionControlledProcess, loadAvailableWorkerPools, MergedProcessResult, mergeProcesses, NoMergeRequiredResult, processScopedEditPermission, whereConfiguredToRun, } from "./Common/CommonProcessHelpers";
import { useMaybeLoadedActionTemplatesFromContext } from "./Contexts/ProcessActionTemplatesContextProvider";
import type { ProcessContextProps } from "./Contexts/ProcessContext";
import { loadProcess, useProcessContext } from "./Contexts/ProcessContext";
import type { ProcessStateSelectors } from "./Contexts/ProcessContextState";
import type { BoundErrorActionsType, ProcessErrorSelectors } from "./Contexts/ProcessErrors/ProcessErrorsContext";
import { ProcessErrorActions, useProcessErrorActions, useProcessErrorSelectors } from "./Contexts/ProcessErrors/ProcessErrorsContext";
import { useMaybeFeedsFromContext } from "./Contexts/ProcessFeedsContextProvider";
import type { ProcessQueryStringContextProps } from "./Contexts/ProcessQueryString/ProcessQueryStringContext";
import { useProcessQueryStringContext } from "./Contexts/ProcessQueryString/ProcessQueryStringContext";
import type { BoundWarningActionsType } from "./Contexts/ProcessWarnings/ProcessWarningsContext";
import { ProcessWarningActions, useProcessWarningActions } from "./Contexts/ProcessWarnings/ProcessWarningsContext";
import { getAllActions, hasSteps } from "./Contexts/processModelSelectors";
import { ProcessContextFormPaperLayout } from "./CustomPaperLayouts/ProcessContextFormPaperLayout";
import { ProcessPaperLayout } from "./CustomPaperLayouts/ProcessPaperLayout";
import { EnhancedActionTemplateSelectionPage } from "./Pages";
import ProcessSidebarLayout from "./ProcessSidebarLayout";
import type { ProcessStepsLayoutLoaderLookupData } from "./ProcessStepsLayoutLoader";
import type { ProcessStepActionState, ProcessStepLookupState } from "./ProcessStepsLayoutTypes";
import ProcessesMergedDialog from "./ProcessesMergedDialog";
import type { AssembledAction, ProcessFilter, StepWarningDetails, StoredAction, StoredStep } from "./types";
import { EnvironmentOption, ExecutionLocation } from "./types";
export enum ProcessPageIntent {
    Unknown = "Unknown",
    ChooseStepTemplates = "ChooseStepTemplates",
    ChooseChildStepTemplates = "ChooseChildStepTemplates",
    CreateNewAction = "CreateNewAction",
    CreateNewChildAction = "CreateNewChildAction",
    ViewAction = "ViewAction",
    ViewParentStep = "ViewParentStep",
    ViewBlueprintAction = "ViewBlueprintAction",
    ViewBlueprintParentStep = "ViewBlueprintParentStep"
}
export function isIntentToCreateNew(intent: ProcessPageIntent) {
    return intent === ProcessPageIntent.CreateNewAction || intent === ProcessPageIntent.CreateNewChildAction;
}
export function intentFromFilter(filter: ProcessFilter): ProcessPageIntent {
    if (filter.new && filter.actionType) {
        if (filter.parentStepId) {
            return ProcessPageIntent.CreateNewChildAction;
        }
        return ProcessPageIntent.CreateNewAction;
    }
    if (filter.stepTemplates) {
        return ProcessPageIntent.ChooseStepTemplates;
    }
    if (filter.childStepTemplates && filter.parentStepId) {
        return ProcessPageIntent.ChooseChildStepTemplates;
    }
    if (filter.actionId) {
        return ProcessPageIntent.ViewAction;
    }
    if (filter.parentStepId) {
        return ProcessPageIntent.ViewParentStep;
    }
    if (filter.blueprintId && filter.blueprintActionId) {
        return ProcessPageIntent.ViewBlueprintAction;
    }
    if (filter.blueprintId && filter.blueprintParentStepId) {
        return ProcessPageIntent.ViewBlueprintParentStep;
    }
    return ProcessPageIntent.Unknown;
}
export interface ProcessStepActionData {
    stepLookups: ProcessStepLookupState;
    stepOther: ProcessStepActionState;
    step: StoredStep;
    action: StoredAction;
}
export interface ProcessParentStepData {
    stepNumber: string;
    step: StoredStep;
    machineRoles: string[];
    isFirstStep: boolean;
    errors?: Errors | undefined;
}
type ProcessStepsLayoutProps = {
    lookups: ProcessStepsLayoutLoaderLookupData;
    gitRef: GitRef | undefined;
    gitRefResource: GitRefResource | undefined;
    titleAccessory?: React.ReactElement;
    errors: Errors | undefined;
    busy: Promise<void> | undefined;
    doBusyTask: DoBusyTask;
    isBuiltInWorkerEnabled: boolean;
    stepSlug?: string;
    setShowK8sStatusItem?: (isKubernetesStep: boolean) => void;
};
interface ProcessPageState {
    actionData: ProcessStepActionData | null;
    parentStepData: ProcessParentStepData | null;
    redirectTo?: LinkHref;
    commitMessage: CommitMessageWithDetails;
    currentActionName: string;
    currentStepName: string;
    disableDirtyFormChecking?: boolean;
    project: ProjectResource | null;
    isGuidedSetup: boolean;
}
type ProcessStepsLayoutInternalProps = {
    lookups: ProcessStepsLayoutLoaderLookupData;
    gitRef: GitRef | undefined;
    gitRefResource: GitRefResource | undefined;
    titleAccessory?: React.ReactElement;
    errors: Errors | undefined;
    busy: Promise<void> | undefined;
    doBusyTask: DoBusyTask;
    isBuiltInWorkerEnabled: boolean;
    projectContext: ProjectContextProps;
    processContext: ProcessContextProps;
    persistenceSettings: PersistenceSettings;
    onProjectUpdated: (project: ProjectResource, gitRef: GitRefResource | string | undefined) => Promise<void>;
    runbookContext: RunbookContextProps | undefined;
    processErrorActions: BoundErrorActionsType;
    processErrorSelectors: ProcessErrorSelectors;
    processWarningActions: BoundWarningActionsType;
    processQueryStringContext: ProcessQueryStringContextProps;
    feeds: FeedResource[];
    feedsLoaded: boolean;
    blueprintsLoaded: boolean;
    blueprints: BlueprintResource[];
    actionTemplates: ActionTemplateSearchResource[];
    actionTemplatesLoaded: boolean;
    trackAction: AnalyticTrackedActionDispatcher;
    stepSlug?: string;
    sampleProjectTourContext?: SampleProjectTourContextProps;
    setShowK8sStatusItem?: (isKubernetesStep: boolean) => void;
    showUnassignedTargetTagWarning: boolean;
};
function getDefaultCommitMessage(processType: ProcessType): string {
    return processType === ProcessType.Runbook ? "Update runbook process" : "Update deployment process";
}
const loadingIndicatorForStep: JSX.Element = (<Section>
        <CircularProgress size="small"/>
    </Section>);
function checkCloudConnectionsPermissionsForUser(): boolean {
    const requiredPermissions = [Permission.VariableView, Permission.VariableViewUnscoped, Permission.VariableEditUnscoped, Permission.LibraryVariableSetView];
    if (requiredPermissions.every((permission) => hasPermission(permission)))
        return true;
    return false;
}
export async function getProjectVariables(project: ProjectResource, gitRefResource: GitRefResource | undefined, spaceId: string, persistenceSettings: PersistenceSettings): Promise<VariableSetResource | undefined> {
    if (!checkCloudConnectionsPermissionsForUser())
        return undefined;
    let variableSet: VariableSetResource;
    try {
        variableSet = await repository.ProjectVariables.get(project.Id, gitRefResource?.CanonicalName);
    }
    catch (error) {
        //Prevent a missing file error from breaking the page
        if (error.ErrorMessage && error.ErrorMessage.match(/The file '\.octopus\/variables\.ocl' on the '.*?' branch does not exist in the Git repository\./)) {
            logger.error("Failed to get project variables", error);
            return {
                Variables: [],
                OwnerId: "",
                ScopeValues: {
                    Actions: [],
                    Channels: [],
                    Environments: [],
                    Machines: [],
                    Roles: [],
                    TenantTags: [],
                    Processes: [],
                },
                Version: 0,
                Id: "",
                Links: { Self: "" },
                SpaceId: spaceId,
            };
        }
        throw error;
    }
    if (HasVariablesInGit(persistenceSettings)) {
        //Not sure if this is how I should deal with this
        const sensitiveVariableSet = await repository.ProjectVariables.getSensitive(project.Id, persistenceSettings);
        variableSet.Variables = variableSet.Variables.concat(sensitiveVariableSet.Variables);
        return variableSet;
    }
    return variableSet;
}
export async function getLibraryVariables(projectContextState: ProjectContextState): Promise<LoadedLibraryVariableSets[] | undefined> {
    if (!checkCloudConnectionsPermissionsForUser())
        return undefined;
    return await loadLibraryVariableSetVariables(projectContextState.model);
}
// This class is based off BaseComponent because it is required to ensure the asynchronous calls
// to setState don't result in a console error like "Warning: Can't perform a React state update on an unmounted component. ..."
class ProcessStepsLayout extends BaseComponent<ProcessStepsLayoutInternalProps, ProcessPageState> {
    private isAddingStep = false;
    private isLoadingDataForActionId: string | undefined = undefined;
    private isLoadingDataForParentStepId: string | undefined = undefined;
    private isLoadingDataForBlueprintParentStepId: string | undefined = undefined;
    private isLoadingDataForBlueprintActionId: string | undefined = undefined;
    private openCommitDialog?: () => void;
    constructor(props: ProcessStepsLayoutInternalProps) {
        super(props);
        this.state = {
            actionData: null,
            parentStepData: null,
            commitMessage: { summary: "", details: "" },
            currentActionName: "",
            currentStepName: "",
            project: null,
            isGuidedSetup: false,
        };
    }
    async componentDidMount() {
        await this.props.doBusyTask(async () => {
            const { model: project } = this.props.projectContext.state;
            this.setState({
                project,
            });
        });
    }
    // markse: @Performance - I've tried splitting this up into a shouldComponentUpdate and having the guard conditions in there to save renders. Doing this does
    // save some re-renders, but makes the code much less readable. I decided to let readability win here, in order to get DX adoption. But splitting
    // this up is an option if we want to investigate some perf gains.
    async componentDidUpdate() {
        if (!this.hasLoadedContexts() || !this.hasLoadedNecessaryLookupData()) {
            return;
        }
        const queryFilter = this.props.processQueryStringContext.state.queryFilter;
        if (!this.canSafelyNavigateToFilter(queryFilter)) {
            logger.warn("Failed to find action by ID in context, attempting to find action based on name.");
            // Something about the ID has likely changed server-side.
            // We'll attempt a lookup by name, then redirect to the ID.
            const foundByName = this.needToRedirectToStepBasedOnName(queryFilter, this.state.currentStepName, this.state.currentActionName, (actionId: string) => {
                this.props.processQueryStringContext.actions.showProcessAction(actionId);
            }, (parentStepId: string) => {
                this.props.processQueryStringContext.actions.showProcessParentStep(parentStepId);
            });
            if (foundByName)
                return;
            logger.warn("Failed to find action by ID or Name, falling back to empty step editor.");
            this.props.processQueryStringContext.actions.showEmptyStepEditor();
            return;
        }
        let currentIntent = intentFromFilter(queryFilter);
        if (this.props.stepSlug) {
            currentIntent = ProcessPageIntent.ViewAction;
        }
        switch (currentIntent) {
            case ProcessPageIntent.ViewAction:
                {
                    let { actionId } = queryFilter;
                    const { actionType, templateId } = queryFilter;
                    if (this.props.stepSlug) {
                        const step = this.props.processContext.selectors.getStepBySlug(this.props.stepSlug);
                        actionId = step.ActionIds?.[0];
                    }
                    const guardAgainstAlreadyLoading = this.isLoadingDataForActionId === actionId;
                    if (guardAgainstAlreadyLoading) {
                        return;
                    }
                    const guardAgainstUnnecessaryReload = this.filtersAreAlignedWithActionData(actionId);
                    if (guardAgainstUnnecessaryReload) {
                        return;
                    }
                    await this.props.doBusyTask(async () => {
                        this.isLoadingDataForActionId = actionId;
                        let actionData: ProcessStepActionData | null = null;
                        try {
                            actionData = await this.loadActionData(actionId, actionType, templateId, currentIntent);
                        }
                        finally {
                            this.isLoadingDataForActionId = undefined;
                            // Set the current step/action name (as these get a default when we assembleNewAction).
                            let currentStepName = this.state.currentStepName;
                            let currentActionName = this.state.currentActionName;
                            if (actionData) {
                                const actionId = actionData.action.Id;
                                const { selectors } = this.props.processContext;
                                const action = selectors.getActionById(actionId);
                                const step = selectors.getStepById(action.ParentId);
                                currentStepName = step.Name;
                                currentActionName = action.Name;
                            }
                            this.setState({ actionData, parentStepData: null, currentStepName, currentActionName });
                        }
                    });
                }
                break;
            case ProcessPageIntent.ViewParentStep:
                {
                    const parentStepId = queryFilter.parentStepId;
                    const guardAgainstAlreadyLoading = this.isLoadingDataForParentStepId === parentStepId;
                    if (guardAgainstAlreadyLoading) {
                        return;
                    }
                    const guardAgainstUnnecessaryReload = !parentStepId || this.filtersAreAlignedWithParentStepData(parentStepId);
                    if (guardAgainstUnnecessaryReload) {
                        return;
                    }
                    await this.props.doBusyTask(async () => {
                        this.isLoadingDataForParentStepId = parentStepId;
                        let parentStepData: ProcessParentStepData | null = null;
                        try {
                            parentStepData = await this.loadParentStepData();
                        }
                        finally {
                            this.isLoadingDataForParentStepId = undefined;
                            this.setState({ parentStepData, actionData: null });
                        }
                    });
                }
                break;
            case ProcessPageIntent.CreateNewChildAction:
            case ProcessPageIntent.CreateNewAction:
                {
                    if (this.isAddingStep) {
                        return;
                    }
                    await this.props.doBusyTask(async () => {
                        this.isAddingStep = true;
                        let actionData: ProcessStepActionData | null = null;
                        try {
                            const { actionId, actionType, templateId, tags } = queryFilter;
                            actionData = await this.loadActionData(actionId, actionType, templateId, currentIntent, tags);
                            if (actionData && actionData.step && actionData.action) {
                                const step = actionData.step;
                                const action = actionData.action;
                                if (currentIntent === ProcessPageIntent.CreateNewAction) {
                                    this.props.processContext.actions.addStep(step, action);
                                }
                                else if (currentIntent === ProcessPageIntent.CreateNewChildAction && queryFilter.parentStepId) {
                                    this.props.processContext.actions.addChildAction(queryFilter.parentStepId, actionData.action);
                                }
                                this.setState({ parentStepData: null, actionData: null, isGuidedSetup: !!tags?.length }, () => this.props.processQueryStringContext.actions.showProcessAction(action.Id));
                            }
                            else {
                                throw Error("Failed to create step or action.");
                            }
                        }
                        finally {
                            this.isAddingStep = false;
                            if (actionData && actionData.action) {
                                const action = actionData.action;
                                this.setState({ parentStepData: null, actionData: null }, () => this.props.processQueryStringContext.actions.showProcessAction(action.Id));
                            }
                            else {
                                this.setState({ parentStepData: null, actionData: null });
                            }
                        }
                    });
                }
                break;
            case ProcessPageIntent.ViewBlueprintAction: {
                if (!this.props.blueprintsLoaded) {
                    return;
                }
                const { blueprintActionId } = queryFilter;
                const guardAgainstAlreadyLoading = this.isLoadingDataForBlueprintActionId === blueprintActionId;
                if (guardAgainstAlreadyLoading) {
                    return;
                }
                const guardAgainstUnnecessaryReload = this.filtersAreAlignedWithActionData(blueprintActionId);
                if (guardAgainstUnnecessaryReload) {
                    return;
                }
                await this.props.doBusyTask(async () => {
                    this.isLoadingDataForBlueprintActionId = blueprintActionId;
                    let actionData: ProcessStepActionData | null = null;
                    try {
                        actionData = await this.loadBlueprintActionData();
                    }
                    finally {
                        this.isLoadingDataForBlueprintActionId = undefined;
                        this.setState({ parentStepData: null, actionData });
                    }
                });
                break;
            }
            case ProcessPageIntent.ViewBlueprintParentStep:
                {
                    if (!this.props.blueprintsLoaded) {
                        return;
                    }
                    const blueprintParentStepId = queryFilter.blueprintParentStepId;
                    const guardAgainstAlreadyLoading = this.isLoadingDataForBlueprintParentStepId === blueprintParentStepId;
                    if (guardAgainstAlreadyLoading) {
                        return;
                    }
                    const guardAgainstUnnecessaryReload = !blueprintParentStepId || this.filtersAreAlignedWithParentStepData(blueprintParentStepId);
                    if (guardAgainstUnnecessaryReload) {
                        return;
                    }
                    await this.props.doBusyTask(async () => {
                        this.isLoadingDataForBlueprintParentStepId = blueprintParentStepId;
                        let parentStepData: ProcessParentStepData | null = null;
                        try {
                            parentStepData = await this.loadBlueprintParentStepData();
                        }
                        finally {
                            this.isLoadingDataForBlueprintParentStepId = undefined;
                            this.setState({ parentStepData, actionData: null });
                        }
                    });
                }
                break;
        }
    }
    filtersAreAlignedWithActionData = (actionId: string | undefined): boolean => {
        const { actionData: currentActionData } = this.state;
        return !!actionId && !!currentActionData && currentActionData.action.Id === actionId;
    };
    filtersAreAlignedWithParentStepData = (parentStepId: string): boolean => {
        const { parentStepData: currentParentStepData } = this.state;
        return !!parentStepId && !!currentParentStepData && currentParentStepData.step.Id === parentStepId;
    };
    render() {
        if (this.state.redirectTo) {
            return <InternalRedirect to={this.state.redirectTo}/>;
        }
        const processContext = this.props.processContext;
        const { model: project } = this.props.projectContext.state;
        const { selectors, actions } = processContext;
        const processType = selectors.getProcessType();
        const isVersionControlled = isVersionControlledProcess(this.props.projectContext.state.model, processType);
        const actionLabel = isVersionControlled ? "Commit" : "Save";
        if (!this.hasLoadedContexts() || !this.hasLoadedNecessaryLookupData() || !this.canSafelyNavigateToFilter(this.props.processQueryStringContext.state.queryFilter)) {
            return (<ProcessContextFormPaperLayout disableDirtyFormChecking={this.state.disableDirtyFormChecking} busy={true} doBusyTask={this.props.doBusyTask} model={undefined} cleanModel={undefined} onSaveClick={noOp} saveButtonLabel={actionLabel} titleAccessory={this.props.titleAccessory} gitRefResource={this.props.gitRefResource}/>);
        }
        const runbook = this.props.runbookContext?.state?.runbook;
        const overflowMenuItems = getCommonOverflowMenuItems(project, this.props.gitRefResource, runbook, processType, selectors, actions, this.props.projectContext.actions, this.props.processErrorActions, this.props.processWarningActions, this.redirectToList);
        const convertedItems: ConvertedOverflowMenuItems = OverflowMenuConverterVNext.convertAll(overflowMenuItems);
        const intent = intentFromFilter(this.props.processQueryStringContext.state.queryFilter);
        const filter = this.props.processQueryStringContext.state.queryFilter;
        let layout: React.ReactNode = null;
        if ((intent === ProcessPageIntent.CreateNewAction || intent === ProcessPageIntent.CreateNewChildAction) && filter.new && filter.actionType) {
            layout = <Loading busy={true}/>;
        }
        else if (intent === ProcessPageIntent.ChooseStepTemplates && filter.stepTemplates) {
            layout = this.renderActionTemplateSelectionPage();
        }
        else if (intent === ProcessPageIntent.ChooseChildStepTemplates && filter.childStepTemplates && filter.parentStepId) {
            layout = this.renderActionTemplateSelectionPage(filter.parentStepId);
        }
        else if (intent === ProcessPageIntent.ViewAction && filter.actionId) {
            if (filter.actionId === this.state.actionData?.action.Id) {
                layout = this.renderProcessStepDetailsPage(intent);
            }
            else {
                layout = loadingIndicatorForStep;
            }
        }
        else if (intent === ProcessPageIntent.ViewParentStep && filter.parentStepId) {
            if (filter.parentStepId === this.state.parentStepData?.step.Id) {
                layout = this.renderProcessStepDetailsPage(intent);
            }
            else {
                layout = loadingIndicatorForStep;
            }
        }
        else if (intent === ProcessPageIntent.ViewBlueprintAction && filter.blueprintId && filter.blueprintActionId) {
            layout = this.state.actionData ? (<ProcessBlueprintActionDetails blueprintId={filter.blueprintId} blueprintActionId={filter.blueprintActionId} doBusyTask={this.props.doBusyTask} busy={this.props.busy} stepLookups={this.state.actionData.stepLookups} stepOther={this.state.actionData.stepOther} actionTemplates={this.props.actionTemplates} gitRefResource={this.props.gitRefResource}/>) : (<ProcessPaperLayout processType={processType} busy={true}/>);
        }
        else if (intent === ProcessPageIntent.ViewBlueprintParentStep && filter.blueprintId && filter.blueprintParentStepId) {
            layout = this.state.parentStepData ? (<ProcessBlueprintParentStepDetails blueprintId={filter.blueprintId} stepNumber={this.state.parentStepData.stepNumber} step={this.state.parentStepData.step} machineRoles={this.state.parentStepData.machineRoles} isFirstStep={this.state.parentStepData.isFirstStep}/>) : (<ProcessPaperLayout processType={processType} busy={true}/>);
        }
        else if (this.props.stepSlug) {
            const actionId = this.props.processContext.selectors.getStepBySlug(this.props.stepSlug)?.ActionIds[0];
            if (actionId === this.state.actionData?.action.Id) {
                layout = this.renderProcessStepDetailsPage(ProcessPageIntent.ViewAction);
            }
            else {
                layout = loadingIndicatorForStep;
            }
        }
        else {
            logger.info("Failed to determine layout for intent {intent}. Assuming no results.", { intent });
            layout = (<>
                    <Section>
                        <Callout type={"information"} title={"Step could not be found"}>
                            {project.IsVersionControlled ? (<span>The requested step could not be found on this branch. Please select from the available steps or review your current branch selection.</span>) : (<span>The requested step could not be found. Please select from the available steps.</span>)}
                        </Callout>
                    </Section>
                    <NoResults />
                </>);
        }
        const innerLayout = (<ProcessSidebarLayout render={() => {
                const animationReloadKey = !!filter.actionId ? filter.actionId : filter.parentStepId;
                return (<Fade in={true} mountOnEnter unmountOnExit key={animationReloadKey}>
                            <div>{layout}</div>
                        </Fade>);
            }} gitRefResource={this.props.gitRefResource}/>);
        return (<ProcessErrorActions>
                {(errorActions) => (<ProcessWarningActions>
                        {(warningActions) => {
                    const saveProcess = async (isNavigationConfirmation: boolean, newBranch?: GitBranchResource) => {
                        let isValid = true;
                        const cm = this.state.commitMessage;
                        if (isNavigationConfirmation && isVersionControlled && this.openCommitDialog) {
                            await this.openCommitDialog();
                        }
                        else {
                            await this.props.doBusyTask(async () => {
                                const allActions = getAllActions(this.props.processContext.state.model)();
                                const toTemplate = (a: StoredAction) => {
                                    return this.props.actionTemplates.find((t) => t.Type == a.ActionType);
                                };
                                const ev: ActionEvent = {
                                    action: isVersionControlled ? Action.Commit : Action.Save,
                                    resource: this.props.processContext.state.processType === ProcessType.Runbook ? "Runbook" : "Deployment Process",
                                    data: { stepTemplate: allActions.map((a) => toTemplate(a)?.Name).filter((name): name is string => name !== undefined) },
                                    isDefaultBranch: this.props.projectContext.state.isDefaultBranch,
                                    commitMessage: cm.summary.length > 0,
                                    isProtectedBranch: isVersionControlled ? isProtectedBranch(this.props.gitRefResource) : undefined,
                                    commitBranch: isVersionControlled ? (newBranch ? "New branch" : "Same branch") : undefined,
                                };
                                const gitRef = newBranch ? newBranch.CanonicalName : this.props.gitRef;
                                await this.props.trackAction("Save Deployment Process", ev, async (cb: AnalyticErrorCallback) => {
                                    //TODO: this merge conflict logic can be moved into a side effect in the reducer if we
                                    if (!processContext.state.model.process?.Id) {
                                        throw Error("Failed to find processId");
                                    }
                                    //TODO: markse - Move this into an onSave method that we can test in isolation.
                                    const { cleanModelProcess: clientCleanProcessResource, modelProcess: clientProcessResource } = processContext.selectors.getProcessesForMerge();
                                    this.applyCommonLogicToProcessResource(clientProcessResource, processContext.selectors.getActionPlugin);
                                    this.applyCommonLogicToProcessResource(clientCleanProcessResource, processContext.selectors.getCleanActionPlugin);
                                    const serverProcessResource = await loadProcess(processContext.selectors.getProcessType(), processContext.state.model.process.Id, project, gitRef);
                                    const mergeResult = mergeProcesses(clientCleanProcessResource, clientProcessResource, serverProcessResource);
                                    const command: ModifyDeploymentProcessCommand = { ...mergeResult.value, Links: { ...serverProcessResource.Links } };
                                    command.ChangeDescription = getFormattedCommitMessage(cm, getDefaultCommitMessage(processContext.selectors.getProcessType()));
                                    if (mergeResult instanceof NoMergeRequiredResult) {
                                        const result = await processContext.actions.saveOnServer(command, (errors) => {
                                            errorActions.setErrors(errors, processContext.selectors);
                                            cb(errors);
                                            isValid = false;
                                            // The save action will give us errors only, clear any warnings.
                                            warningActions.clearWarnings();
                                        }, async () => {
                                            errorActions.clearErrors();
                                            warningActions.clearWarnings();
                                            await this.validateDeploymentProcess(warningActions, selectors);
                                            //all is well, let's update project context
                                            await this.props.projectContext.actions.refreshModel();
                                        }, gitRef);
                                        if (result !== null) {
                                            this.setState({ commitMessage: { summary: "", details: "" } });
                                        }
                                    }
                                    else if (mergeResult instanceof MergedProcessResult) {
                                        processContext.actions.conflictDetected(serverProcessResource, mergeResult.value);
                                    }
                                });
                                // Only update the project summary if the deployment process has changed
                                if (this.props.processContext.state.processType !== ProcessType.Runbook) {
                                    await this.props.onProjectUpdated(this.props.projectContext.state.model, this.props.gitRef);
                                }
                            });
                            if (isValid && !hasSteps(this.props.processContext.state.model)()) {
                                const spaceId = this.props.processContext.state.model.process?.SpaceId ?? "";
                                const ownerSlug = this.props.processContext.state.ownerSlug;
                                const redirectTo = links.deploymentProcessPage.generateUrl({ spaceId: spaceId, projectSlug: ownerSlug });
                                this.setState({ redirectTo });
                            }
                            if (this.props.sampleProjectTourContext !== undefined) {
                                this.props.sampleProjectTourContext.deploymentProcessSaved(isValid);
                            }
                            return isValid;
                        }
                    };
                    const onNewBranchCreating = async (branchName: string) => {
                        const ev: ActionEvent = {
                            action: Action.Commit,
                            resource: "Branch",
                            data: { source: "Commit changes dialog" },
                        };
                        let newBranchResource: GitBranchResource | null = null;
                        await this.props.trackAction("Create branch", ev, async (cb: AnalyticErrorCallback) => {
                            try {
                                newBranchResource = await repository.ProjectBranches.createBranch(this.props.projectContext.state.model.Id, branchName, this.props.gitRef ?? "");
                            }
                            catch (ex) {
                                this.setState({ disableDirtyFormChecking: false });
                                cb(ex);
                                throw ex;
                            }
                        });
                        if (newBranchResource) {
                            this.setState({ disableDirtyFormChecking: true });
                            if (await saveProcess(false, newBranchResource)) {
                                this.props.projectContext.actions.changeGitRef(branchName);
                            }
                            this.setState({ disableDirtyFormChecking: false });
                        }
                    };
                    return (<ProcessContextFormPaperLayout model={selectors.getModel()} cleanModel={selectors.getCleanModel()} busy={this.props.busy} doBusyTask={this.props.doBusyTask} errors={this.props.errors} // We have to pass errors here for our ConfirmNavigate to function correctly.
                     savePermission={{
                            permission: processScopedEditPermission(processContext.selectors.getProcessType()),
                            project: project.Id,
                            wildcard: true,
                        }} saveButtonLabel={actionLabel} onSaveClick={saveProcess} overflowActions={convertedItems.menuItems} customPrimaryAction={isVersionControlled
                            ? ({ isDisabled, onClick }) => ({
                                type: "custom",
                                content: <GetCommitButton {...this.getCommitButtonProps({ isDisabled, onClick: () => onClick() })} onNewBranchCreating={onNewBranchCreating}/>,
                                key: "Get Commit",
                            })
                            : undefined} confirmNavigateSaveLabel={`${actionLabel} changes` + (isVersionControlled ? "..." : "")} disableDirtyFormChecking={this.state.disableDirtyFormChecking} hideAddStepButton={intent === ProcessPageIntent.ChooseStepTemplates} titleAccessory={this.props.titleAccessory} gitRefResource={this.props.gitRefResource}>
                                    {convertedItems.dialogs}
                                    <ProcessesMergedDialog open={selectors.isMerging() && !selectors.isMergeDialogClosed()} onClose={actions.mergeDialogClosed} onDiscard={actions.discardedChanges} onMerge={actions.mergedProcess} onAcceptClientChanges={actions.acceptedClientChanges}/>
                                    {selectors.isProcessMerged() && (<Callout title="Action Required" type={"warning"}>
                                            This process has been merged with the server process but has not been saved. Please review the process before saving.
                                        </Callout>)}
                                    {innerLayout}
                                </ProcessContextFormPaperLayout>);
                }}
                    </ProcessWarningActions>)}
            </ProcessErrorActions>);
    }
    private async validateDeploymentProcess(warningActions: BoundWarningActionsType, selectors: ProcessStateSelectors) {
        if (this.props.showUnassignedTargetTagWarning && isAllowed({ permission: Permission.EnvironmentView, environment: "*" }) && isAllowed({ permission: Permission.MachineView })) {
            const deploymentProcessRepository = new DeploymentProcessRepository(client, this.props.projectContext.state.model, this.props.gitRefResource, createGlobalRequestContext("ProjectContext"));
            const validationResult = await deploymentProcessRepository.validate(this.props.gitRefResource);
            const unassignedTargetTags = Object.keys(validationResult.TagsWithoutTargetsByStepId).flatMap((stepId) => {
                const tags = validationResult.TagsWithoutTargetsByStepId[stepId].map((s) => s);
                return tags;
            });
            const uniqueUnassignedTargetTags = Array.from(new Set(unassignedTargetTags));
            // Adjust details object shape to match split value/summary shape of StepWarningDetails
            // The original value coming from the API is simply a key-value pair where key is the step name/index in the deployment process
            const detailsObject = Object.keys(validationResult.Details).reduce((acc: Record<string, StepWarningDetails>, stepId) => {
                acc[stepId] = {
                    summary: "This step has unassigned target tags",
                };
                return acc;
            }, {});
            if (validationResult.HasWarnings) {
                warningActions.setWarnings({
                    warnings: [],
                    fieldWarnings: {},
                    details: {
                        global: (<div>
                                    <UnassignedTargetTagsWarningCalloutContent unassignedTargetTags={uniqueUnassignedTargetTags}/>
                                </div>),
                        ...detailsObject,
                    },
                }, selectors);
            }
        }
    }
    private getCommitButtonProps({ isDisabled, onClick }: {
        isDisabled: boolean;
        onClick: (event: React.MouseEvent | undefined) => Promise<boolean>;
    }): GetCommitButtonProps {
        return {
            project: this.props.projectContext.state.model,
            gitRef: this.props.gitRef,
            canCommitToGitRef: canCommitTo(this.props.gitRefResource),
            defaultCommitMessage: getDefaultCommitMessage(this.props.processContext.selectors.getProcessType()),
            commitMessage: this.state.commitMessage,
            updateCommitMessage: (commitMessage: CommitMessageWithDetails) => this.setState({ commitMessage }),
            commitMessageAccessibleName: `Commit message for saving the ${this.props.processContext.selectors.getProcessType()} process`,
            commitDetailsAccessibleName: `Commit details for saving the ${this.props.processContext.selectors.getProcessType()} process`,
            commitButtonAccessibleName: `Commit changes to the ${this.props.processContext.selectors.getProcessType()} process`,
            onInitializing: (openDialog) => (this.openCommitDialog = openDialog),
            label: "Commit",
            busyLabel: "Committing",
            onClick,
            disabled: isDisabled,
        };
    }
    private redirectToList = () => {
        const type = this.props.processContext.selectors.getProcessType();
        const spaceId = this.props.processContext.state.model.process?.SpaceId ?? "";
        const ownerSlug = this.props.processContext.state.ownerSlug;
        const project = this.props.projectContext.state.model;
        if (type === ProcessType.Deployment) {
            const redirectTo = links.deploymentProcessPage.generateUrl({ spaceId: spaceId, projectSlug: ownerSlug });
            this.setState({ redirectTo });
        }
        else {
            const runbook = this.props.runbookContext?.state.runbook;
            if (runbook) {
                const redirectTo = links.projectRunbookProcessListPage.generateUrl({ spaceId: spaceId, projectSlug: ownerSlug, runbookId: runbook.Id, processId: runbook.RunbookProcessId });
                this.setState({ redirectTo });
            }
        }
    };
    private canSafelyNavigateToFilter(filter: ProcessFilter): boolean {
        // Check if this action actually exists in our context (the user may have refreshed the screen and not yet saved, so our filter and context are out of sync).
        // The filter will tell us if we're looking at an action or parentStep.
        const { actionId, parentStepId } = filter;
        if (actionId && this.props.processContext.selectors.hasValidProcess() && !this.props.processContext.selectors.tryGetActionById(actionId)) {
            return false;
        }
        if (parentStepId && this.props.processContext.selectors.hasValidProcess() && !this.props.processContext.selectors.tryGetStepById(parentStepId)) {
            return false;
        }
        return true;
    }
    private needToRedirectToStepBasedOnName(filter: ProcessFilter, currentStepName: string, currentActionName: string, onFoundAction: (actionId: string) => void, onFoundParentStep: (parentStepId: string) => void): boolean {
        // The filter will tell us if we're looking at an action or parentStep.
        const { actionId, parentStepId } = filter;
        if (actionId && currentActionName && this.props.processContext.selectors.hasValidProcess()) {
            const shouldRedirectToAction = this.props.processContext.selectors.tryGetActionByName(currentActionName);
            if (shouldRedirectToAction) {
                onFoundAction(shouldRedirectToAction.Id);
                return true;
            }
        }
        if (parentStepId && currentStepName && this.props.processContext.selectors.hasValidProcess()) {
            const shouldRedirectToParentStep = this.props.processContext.selectors.tryGetStepByName(currentStepName);
            if (shouldRedirectToParentStep) {
                onFoundParentStep(shouldRedirectToParentStep.Id);
                return true;
            }
        }
        return false;
    }
    // @Cleanup: markse - This was common logic we were previously applying at the point of saving a single action. Flagging for later review.
    // For multi-step editing, we now loop over all actions and apply to each. Now sure how I feel about this happening right at the point of save.
    private applyCommonLogicToProcessResource(process: IProcessResource, getPluginForAction: (actionId: string) => ActionPlugin) {
        const availableWorkerPools = loadAvailableWorkerPools(this.props.lookups.workerPoolsSummary);
        process.Steps.forEach((step) => {
            step.Actions.forEach((action) => {
                const plugin = getPluginForAction(action.Id);
                const runOn = whereConfiguredToRun(!!step.Properties["Octopus.Action.TargetRoles"], action, availableWorkerPools, plugin);
                if (runOn) {
                    if (!isRunOnServerOrWorkerPool(runOn)) {
                        action.Container = { FeedId: null, Image: null, GitUrl: null, Dockerfile: null };
                    }
                    else {
                        if (runOn.executionLocation === ExecutionLocation.OctopusServer || runOn.executionLocation === ExecutionLocation.WorkerPool) {
                            step.Properties["Octopus.Action.TargetRoles"] = "";
                        }
                        if (runOn.executionLocation !== ExecutionLocation.WorkerPool && runOn.executionLocation !== ExecutionLocation.WorkerPoolForRoles) {
                            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                            action.WorkerPoolId = null!;
                            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                            action.WorkerPoolVariable = null!;
                        }
                        action.Container = runOn.container;
                    }
                }
                if (!action.Name || action.Name.length === 0) {
                    const primaryPackage = GetPrimaryPackageReference(action.Packages);
                    if (primaryPackage) {
                        action.Name = primaryPackage.PackageId;
                    }
                }
            });
        });
    }
    private loadActionData = async (actionId: string | undefined, actionType: string | undefined, templateId: string | undefined, intent: ProcessPageIntent, targetTags?: string[]): Promise<ProcessStepActionData | null> => {
        if (!this.hasLoadedContexts() || !this.hasLoadedNecessaryLookupData()) {
            return null;
        }
        let plugin: ActionPlugin | null = null;
        if (actionId) {
            plugin = this.props.processContext.selectors.getActionPlugin(actionId);
        }
        else if (actionType) {
            plugin = await pluginRegistry.getAction(actionType);
        }
        if (!plugin) {
            throw new Error("Failed to load plugin.");
        }
        const { feeds, actionTemplates, projectContext, persistenceSettings } = this.props;
        const processContextSelectors = this.props.processContext.selectors;
        const isNew = isIntentToCreateNew(intent);
        let result: AssembledAction;
        if (isNew) {
            if (!actionType) {
                throw Error("No action type was provided");
            }
            result = await assembleNewAction(actionType, plugin, actionTemplates, templateId, feeds, projectContext.state.model, processContextSelectors.getProcessType(), targetTags);
        }
        else {
            if (!actionId) {
                throw Error("Missing action id");
            }
            result = assembleExistingAction(actionId, processContextSelectors, actionTemplates, feeds);
        }
        PageTitleHelper.setPageTitle(result.pageTitle);
        if (!result.action) {
            logger.error("Failed to load action data", { result });
            throw new Error("Expecting an action to exist.");
        }
        const stepLookups = await this.loadLookupData(result.action, persistenceSettings);
        const stepOther: ProcessStepActionState = {
            actionTypeName: result.actionTypeName,
            pageTitle: result.pageTitle,
            isBuiltInWorkerEnabled: this.props.isBuiltInWorkerEnabled,
            environmentOption: (result.action.Environments || []).length > 0 ? EnvironmentOption.Include : (result.action.ExcludedEnvironments || []).length > 0 ? EnvironmentOption.Exclude : EnvironmentOption.All,
            runOn: result.action && plugin ? whereConfiguredToRun(!!result.step.Properties["Octopus.Action.TargetRoles"], result.action, stepLookups.availableWorkerPools, plugin) : null,
        };
        return { stepLookups, stepOther, step: result.step, action: result.action };
    };
    private loadParentStepData = async (): Promise<ProcessParentStepData | null> => {
        if (!this.hasLoadedContexts() || !this.hasLoadedNecessaryLookupData()) {
            return null;
        }
        const { parentStepId } = this.props.processQueryStringContext.state.queryFilter;
        if (!parentStepId) {
            throw new Error("Failed to find parentStepId");
        }
        const processContextSelectors = this.props.processContext.selectors;
        const result = assembleParentStep(parentStepId, processContextSelectors);
        PageTitleHelper.setPageTitle(result.pageTitle);
        const stepLookups = await this.loadLookupData(null, this.props.persistenceSettings);
        const stepNumber = this.props.processContext.selectors.getStepNumber(result.step.Id);
        const isFirstStep = this.props.processContext.selectors.isFirstStep(result.step.Id);
        return { stepNumber: stepNumber.toString(), step: result.step, machineRoles: stepLookups.machineRoles, isFirstStep };
    };
    private loadBlueprintActionData = async (): Promise<ProcessStepActionData | null> => {
        if (!this.hasLoadedContexts() || !this.hasLoadedNecessaryLookupData()) {
            return null;
        }
        const { blueprintId, blueprintActionId } = this.props.processQueryStringContext.state.queryFilter;
        if (!blueprintId || !blueprintActionId) {
            throw new Error("Failed to find required ids for blueprint");
        }
        const step = this.props.blueprints.find((b) => b.Id === blueprintId)?.Steps.find((s) => s.Actions.some((a) => a.Id === blueprintActionId));
        const blueprintAction = step?.Actions.find((a) => a.Id === blueprintActionId);
        if (!step || !blueprintAction) {
            logger.warn("Failed to find action in blueprint, falling back to empty step editor.");
            this.props.processQueryStringContext.actions.showEmptyStepEditor();
            return null;
        }
        const plugin = await pluginRegistry.getAction(blueprintAction.ActionType);
        const { actionTemplates } = this.props;
        PageTitleHelper.setPageTitle(step.Name);
        const stepLookups = await this.loadLookupData(null, this.props.persistenceSettings);
        const actionTypeName = actionTemplates.find((x) => x.Type === blueprintAction.ActionType)?.Name ?? "";
        const stepOther: ProcessStepActionState = {
            actionTypeName,
            pageTitle: step.Name,
            isBuiltInWorkerEnabled: this.props.isBuiltInWorkerEnabled,
            environmentOption: (blueprintAction.Environments || []).length > 0 ? EnvironmentOption.Include : (blueprintAction.ExcludedEnvironments || []).length > 0 ? EnvironmentOption.Exclude : EnvironmentOption.All,
            runOn: whereConfiguredToRun(!!step.Properties["Octopus.Action.TargetRoles"], blueprintAction, stepLookups.availableWorkerPools, plugin),
        };
        const storedStep: StoredStep = { ...step, ActionIds: step.Actions.map((a) => a.Id) };
        return { stepLookups, stepOther, step: storedStep, action: { ...blueprintAction, ParentId: "", plugin } };
    };
    private loadBlueprintParentStepData = async (): Promise<ProcessParentStepData | null> => {
        if (!this.hasLoadedContexts() || !this.hasLoadedNecessaryLookupData()) {
            return null;
        }
        const { blueprintId, blueprintParentStepId } = this.props.processQueryStringContext.state.queryFilter;
        if (!blueprintParentStepId) {
            throw new Error("Failed to find required ids for blueprint");
        }
        const step = this.props.blueprints.find((b) => b.Id === blueprintId)?.Steps.find((s) => s.Id === blueprintParentStepId);
        if (!step) {
            logger.warn("Failed to find step in blueprint, falling back to empty step editor.");
            this.props.processQueryStringContext.actions.showEmptyStepEditor();
            return null;
        }
        PageTitleHelper.setPageTitle(step.Name);
        const stepLookups = await this.loadLookupData(null, this.props.persistenceSettings);
        const actionIdInProcess = Object.values(this.props.processContext.state.model.actions.byId).find((a) => getBlueprintIdFromAction(a) === blueprintId)?.Id;
        const stepIdInProcess = Object.values(this.props.processContext.state.model.steps.byId).find((s) => s.ActionIds.some((a) => a === actionIdInProcess))?.Id;
        if (!stepIdInProcess) {
            logger.warn("Failed to find step id in process, falling back to empty step editor.");
            this.props.processQueryStringContext.actions.showEmptyStepEditor();
            return null;
        }
        const stepNumber = this.props.processContext.state.model.steps.allIds.indexOf(stepIdInProcess) + 1;
        return { stepNumber: stepNumber.toString(), step: { ...step, ActionIds: step.Actions.map((a) => a.Id) }, machineRoles: stepLookups.machineRoles, isFirstStep: stepNumber === 1 };
    };
    private hasLoadedContexts(): boolean {
        const processContextHasLoaded = this.props.processContext.selectors.hasValidProcess();
        const projectContextHasLoaded = !!this.props.projectContext.state.model;
        const processQueryStringContextHasLoaded = !!this.props.processQueryStringContext.state.queryFilter;
        return processContextHasLoaded && projectContextHasLoaded && processQueryStringContextHasLoaded;
    }
    private hasLoadedNecessaryLookupData(): boolean {
        const { actionTemplatesLoaded, feedsLoaded, blueprintsLoaded } = this.props;
        return actionTemplatesLoaded && feedsLoaded && blueprintsLoaded;
    }
    private async loadLookupData(action: StoredAction | null, persistenceSettings: PersistenceSettings): Promise<ProcessStepLookupState> {
        let actionTemplate;
        const projectVariablesPromise = getProjectVariables(this.props.projectContext.state.model, this.props.gitRefResource, this.props.processContext.state.model.process?.SpaceId ?? "", persistenceSettings);
        const libraryVariableSetsPromise = getLibraryVariables(this.props.projectContext.state);
        if ((this.props.processQueryStringContext.state.queryFilter.templateId || (action && action.Properties["Octopus.Action.Template.Id"])) && !hasPermission(Permission.ActionTemplateView)) {
            actionTemplate = { type: "No Permission" } as const;
        }
        else if (this.props.processQueryStringContext.state.queryFilter.templateId) {
            actionTemplate = await repository.ActionTemplates.get(this.props.processQueryStringContext.state.queryFilter.templateId);
        }
        else if (action && action.Properties["Octopus.Action.Template.Id"]) {
            actionTemplate = await repository.ActionTemplates.get(action.Properties["Octopus.Action.Template.Id"].toString());
        }
        else {
            actionTemplate = null;
        }
        const projectVariables = await projectVariablesPromise;
        const libraryVariableSets = await libraryVariableSetsPromise;
        const lookups: ProcessStepLookupState = {
            environments: Object.values(this.props.lookups.environmentsById),
            machineRoles: this.props.lookups.machineRoles,
            availableWorkerPools: loadAvailableWorkerPools(this.props.lookups.workerPoolsSummary),
            tagIndex: this.props.lookups.tagIndex,
            actionTemplate,
            channels: this.props.lookups.channelsById ? Object.values(this.props.lookups.channelsById) : [],
            projectVariables,
            libraryVariableSets,
            projectTriggers: this.props.lookups.projectTriggers,
            userOnboarding: this.props.lookups.userOnboarding,
        };
        return lookups;
    }
    private renderProcessStepDetailsPage = (intent: ProcessPageIntent) => {
        const { processContext, processErrorSelectors } = this.props;
        const processType = processContext.selectors.getProcessType();
        if (this.state.actionData && this.state.actionData.stepOther && this.state.actionData.stepLookups && this.state.actionData.action) {
            const actionId = this.state.actionData.action.Id;
            const { selectors } = this.props.processContext;
            const isNew = isIntentToCreateNew(intent) || selectors.isNewAction(actionId);
            const errors = processErrorSelectors.getActionFieldErrors(actionId, selectors);
            // TODO: Review this pattern with frontend. Do not copy.
            // From hereon, we reference action/step via the selectors, and actionData.action is now essentially stale / out of sync.
            // This is an unfortunately side-effect of managing both CreateNewAction and ViewAction under the one layout. There is a
            // handoff between the two intentions, so we use actionData as a middle-man/dumping-ground until we have the necessary information
            // in context.
            const action = selectors.getActionById(actionId);
            const cleanAction = selectors.tryGetCleanActionById(actionId);
            const step = selectors.getStepById(action.ParentId);
            if (action.ActionType === "Octopus.Blueprint") {
                return (<ProcessBlueprintStepDetails step={step} action={action} stepOther={this.state.actionData.stepOther} isNew={isNew} cleanAction={cleanAction} doBusyTask={this.props.doBusyTask} busy={this.props.busy} gitRefResource={this.props.gitRefResource}/>);
            }
            return (<ProcessActionDetails doBusyTask={this.props.doBusyTask} step={step} busy={this.props.busy} action={action} cleanAction={cleanAction} setCurrentActionName={(actionName) => {
                    this.setState({ currentActionName: actionName });
                }} setCurrentStepName={(stepName) => {
                    this.setState({ currentStepName: stepName });
                }} stepLookups={this.state.actionData.stepLookups} stepOther={this.state.actionData.stepOther} actionTemplates={this.props.actionTemplates} processType={processType} isNew={isNew} errors={errors} refreshStepLookups={async () => {
                    await this.props.doBusyTask(async () => {
                        // This line is required to ensure that all other setState()
                        // calls have been completed before we load lookup data.
                        await new Promise((resolve) => setTimeout(resolve));
                        const stepLookups = await this.loadLookupData(selectors.getActionById(actionId), this.props.persistenceSettings);
                        const actionData = this.state.actionData;
                        if (actionData) {
                            actionData.stepLookups = stepLookups;
                            this.setState({ actionData });
                        }
                    });
                }} projectTriggers={this.props.lookups.projectTriggers} userOnboarding={this.props.lookups.userOnboarding} isGuidedSetup={this.state.isGuidedSetup} setShowK8sStatusItem={this.props.setShowK8sStatusItem} gitRefResource={this.props.gitRefResource}/>);
        }
        else if (this.state.parentStepData && this.state.parentStepData.step) {
            const { selectors } = this.props.processContext;
            const stepId = this.state.parentStepData.step.Id;
            const step = selectors.getStepById(stepId);
            const cleanStep = selectors.tryGetCleanStepById(stepId);
            const isNew = isIntentToCreateNew(intent);
            const errors = processErrorSelectors.getStepFieldErrors(stepId);
            return (<ProcessParentStepDetails stepNumber={this.state.parentStepData.stepNumber} step={step} cleanStep={cleanStep} currentStepName={this.state.currentStepName} setCurrentStepName={(stepName) => {
                    this.setState({ currentStepName: stepName });
                }} machineRoles={this.state.parentStepData.machineRoles} isFirstStep={this.state.parentStepData.isFirstStep} isNew={isNew} errors={errors}/>);
        }
        return <ProcessPaperLayout processType={processType} busy={true}/>;
    };
    private renderActionTemplateSelectionPage = (parentStepId?: string) => {
        const { busy, errors, gitRefResource } = this.props;
        const processType = this.props.processContext.selectors.getProcessType();
        return <EnhancedActionTemplateSelectionPage processType={processType} parentStepId={parentStepId} busy={busy} errors={errors} gitRefResource={gitRefResource}/>;
    };
    static displayName = "ProcessStepsLayout";
}
const EnhancedProcessStepsLayout: React.FC<ProcessStepsLayoutProps> = (props) => {
    const projectContext = useProjectContext();
    const processContext = useProcessContext();
    const persistenceSettings = usePersistenceSettingsContext();
    const processErrorActions = useProcessErrorActions();
    const processErrorSelectors = useProcessErrorSelectors();
    const processWarningActions = useProcessWarningActions();
    const processQueryStringContext = useProcessQueryStringContext();
    const runbookContext = useOptionalRunbookContext();
    const trackAction = useProjectScopedAnalyticTrackedActionDispatch(projectContext.state.model.Id);
    const sampleProjectTourContext = useContext(SampleProjectTourContext);
    const maybeLoadedActionTemplates = useMaybeLoadedActionTemplatesFromContext();
    const actionTemplatesLoaded = maybeLoadedActionTemplates !== "NotLoaded";
    const actionTemplates = maybeLoadedActionTemplates === "NotLoaded" ? [] : maybeLoadedActionTemplates;
    const maybeFeeds = useMaybeFeedsFromContext();
    const feedsLoaded = maybeFeeds !== "NotLoaded";
    const feeds = maybeFeeds === "NotLoaded" ? [] : maybeFeeds;
    const maybeBlueprints = useMaybeProcessBlueprintsFromContext();
    const blueprintsLoaded = maybeBlueprints !== "NotLoaded";
    const blueprints = maybeBlueprints === "NotLoaded" ? [] : maybeBlueprints;
    const isImprovedTargetFeedbackFeatureEnabled = useOctopusFeatureToggle("improved-target-feedback", false);
    const [showUnassignedTargetTagWarning] = useLocalStorage(`Octopus.Project.${projectContext.state.model.Id}.ShowUnassignedTargetTagWarning`, true);
    return (<ProcessStepsLayout onProjectUpdated={projectContext.actions.onProjectUpdated} feeds={feeds} feedsLoaded={feedsLoaded} actionTemplates={actionTemplates} actionTemplatesLoaded={actionTemplatesLoaded} projectContext={projectContext} processContext={processContext} processErrorActions={processErrorActions} processWarningActions={processWarningActions} processQueryStringContext={processQueryStringContext} runbookContext={runbookContext} trackAction={trackAction} processErrorSelectors={processErrorSelectors} sampleProjectTourContext={sampleProjectTourContext} blueprintsLoaded={blueprintsLoaded} blueprints={blueprints} persistenceSettings={persistenceSettings} showUnassignedTargetTagWarning={showUnassignedTargetTagWarning && isImprovedTargetFeedbackFeatureEnabled} {...props}/>);
};
EnhancedProcessStepsLayout.displayName = "EnhancedProcessStepsLayout"
export default EnhancedProcessStepsLayout;
