import { css } from "@emotion/css";
import { ExternalLink } from "@octopusdeploy/design-system-components";
import { borderRadius, fontSize, fontWeight, letterSpacing, lineHeight, space, text, themeTokens } from "@octopusdeploy/design-system-tokens";
import React from "react";
import { DataTable, DataTableHeader, DataTableRow, DataTableHeaderColumn, DataTableBody, DataTableRowColumn } from "~/primitiveComponents/dataDisplay/DataTable";
import PopoverHelp from "~/primitiveComponents/dataDisplay/PopoverHelp/PopoverHelp";
export default function KubernetesDeploymentTargetsPopover() {
    return (<PopoverHelp trigger="click" placement={"bottom-start"} size="medium">
            <div>
                <div className={styles.helpPopoverDescription}>These are some of the key differences between the Kubernetes Agent and the Kubernetes API:</div>
                <div className={styles.helpPopoverTableContainer}>
                    <DataTable>
                        <DataTableHeader>
                            <DataTableRow className={styles.helpPopoverTableHeaderRow}>
                                <DataTableHeaderColumn></DataTableHeaderColumn>
                                <DataTableHeaderColumn>Kubernetes Agent</DataTableHeaderColumn>
                                <DataTableHeaderColumn>Kubernetes API</DataTableHeaderColumn>
                            </DataTableRow>
                        </DataTableHeader>
                        <DataTableBody className={styles.helpPopoverTableBody}>
                            <DataTableRow>
                                <DataTableRowColumn className={styles.helpPopoverTableHeaderRow}>Connection method</DataTableRowColumn>
                                <DataTableRowColumn className={styles.helpPopoverTableData}>Polling agent in cluster</DataTableRowColumn>
                                <DataTableRowColumn className={styles.helpPopoverTableData}>Direct API communication</DataTableRowColumn>
                            </DataTableRow>
                            <DataTableRow>
                                <DataTableRowColumn className={styles.helpPopoverTableHeaderRow}>Setup complexity</DataTableRowColumn>
                                <DataTableRowColumn className={styles.helpPopoverTableData}>Generally simpler</DataTableRowColumn>
                                <DataTableRowColumn className={styles.helpPopoverTableData}>Requires more setup</DataTableRowColumn>
                            </DataTableRow>
                            <DataTableRow>
                                <DataTableRowColumn className={styles.helpPopoverTableHeaderRow}>Security</DataTableRowColumn>
                                <DataTableRowColumn className={styles.helpPopoverTableData}>
                                    <div>No need to configure firewall</div>
                                    <div>No need to provide external access to cluster</div>
                                </DataTableRowColumn>
                                <DataTableRowColumn className={styles.helpPopoverTableData}>Depends on the cluster configuration</DataTableRowColumn>
                            </DataTableRow>
                        </DataTableBody>
                    </DataTable>
                </div>
                <div className={styles.helpPopoverLink}>
                    <ExternalLink label="Read full comparison" href="k8sTargetComparison" size="medium"></ExternalLink>
                </div>
            </div>
        </PopoverHelp>);
}
const styles = {
    title: css({
        marginTop: space[48],
        marginBottom: space[16],
    }),
    heading: css({
        marginTop: space[32],
        marginBottom: space[32],
        font: text.heading.large,
    }),
    question: css({}),
    buttons: css({
        display: "flex",
        justifyContent: "flex-end",
        gap: space[8],
        marginTop: space[48],
    }),
    alternateOption: css({
        margin: `${space[4]} 0 ${space[16]} 0`,
        fontSize: fontSize.medium,
        color: themeTokens.color.text.secondary,
    }),
    noCluster: css({
        marginTop: space[32],
    }),
    kubernetesAgentCardsWrapper: css({
        display: "flex",
        flexWrap: "wrap",
        gap: space[16],
        marginBottom: space[16],
    }),
    healthCheckCallout: css({
        marginTop: space[32],
    }),
    helpPopoverDescription: css({
        fontSize: fontSize.medium,
        color: themeTokens.color.text.primary,
        fontWeight: fontWeight[400],
        lineHeight: lineHeight.xSmall,
        letterSpacing: letterSpacing.wide,
        marginBottom: space[16],
    }),
    helpPopoverTableContainer: css({
        border: `1px solid ${themeTokens.color.border.primary}`,
        borderRadius: borderRadius["large"],
    }),
    helpPopoverTableHeaderRow: css({
        "&, & th": {
            fontSize: fontSize.xSmall,
            color: themeTokens.color.text.primary,
            fontWeight: 600,
            lineHeight: lineHeight.xSmall,
            letterSpacing: letterSpacing.wide,
            paddingTop: space[4],
            paddingBottom: space[4],
        },
        "& th, & th:hover": {
            backgroundColor: themeTokens.color.background.secondary.default,
        },
        "& th:first-child": {
            borderTopLeftRadius: borderRadius["large"],
        },
        "& th:last-child": {
            borderTopRightRadius: borderRadius["large"],
        },
    }),
    helpPopoverTableBody: css({
        "& tr td": {
            paddingTop: space[6],
            paddingBottom: space[6],
            verticalAlign: "middle",
        },
    }),
    helpPopoverTableData: css({
        fontSize: fontSize.xSmall,
        color: themeTokens.color.text.primary,
        fontWeight: fontWeight[400],
        lineHeight: lineHeight.xSmall,
        letterSpacing: letterSpacing.wide,
    }),
    helpPopoverLink: css({
        marginTop: space[24],
        "& a": {
            fontWeight: fontWeight[700],
        },
    }),
};
