import { css } from "@emotion/css";
import type { SimpleMenuItem, PrimaryPageAction, PageAction } from "@octopusdeploy/design-system-components";
import { Button, EmptyStateDashboardIllustration, EmptyStateDashboardNoPermissionIllustration, NavigationButton, NavigationButtonType } from "@octopusdeploy/design-system-components";
import { borderRadius, colorScales, fontSize, fontWeight, letterSpacing, lineHeight, space, text, themeTokens } from "@octopusdeploy/design-system-tokens";
import type { ProjectResource } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import React, { useState } from "react";
import type { AnalyticActionDispatcher } from "~/analytics/Analytics";
import { Action, useAnalyticActionDispatch } from "~/analytics/Analytics";
import { Level1EmptyStateLayout } from "~/components/GettingStarted/Level1EmptyStateLayout";
import ExternalVideoLink from "~/components/Navigation/ExternalLink/ExternalVideoLink";
import { PermissionCheck } from "~/components/PermissionCheck";
import { useOctopusFeatureToggle } from "~/hooks/useOctopusFeatureToggle";
import type { DoBusyTask } from "../DataBaseComponent";
import AddNewProjectDialog from "./AddNewProjectDialog";
import NewProjectWizard from "./NewProjectWizard";
import { ProjectsGettingStartedGuides } from "./ProjectsGettingStartedGuides";
interface OnboardingProject {
    Name: string;
    Slug: string;
    LogoUrl?: string;
}
interface DashboardOnboardingLayoutProps {
    spaceId: string;
    canContinueOnboarding: boolean;
    onboardingProject?: OnboardingProject;
    header?: {
        title: string;
        primaryAction?: PrimaryPageAction;
        actions?: PageAction[];
        overflowActions?: SimpleMenuItem[];
    };
    busy: Promise<void> | undefined;
    doBusyTask: DoBusyTask;
}
function DashboardOnboardingLayout({ spaceId, canContinueOnboarding, onboardingProject, header, busy, doBusyTask }: DashboardOnboardingLayoutProps) {
    const [openDialog, setOpenDialog] = useState(false);
    const dispatchAction = useAnalyticActionDispatch();
    const isOnboardingHalfReady = isOnboardingHalfDone(canContinueOnboarding, onboardingProject);
    const onboardingTitle = isOnboardingHalfReady ? "Project Setup" : "Deploy your first application";
    const onboardingIntro = isOnboardingHalfReady ? (<div className={onboardingIntroContainerStyles}>
            {onboardingProject?.LogoUrl && (<div className={logoContainerStyles}>
                    <img className={logoStyles} src={onboardingProject?.LogoUrl} alt="Project Logo"/>
                </div>)}
            <div className={continueOnboardingIntroStyles}>
                <strong>{onboardingProject.Name}</strong> is almost setup
            </div>
        </div>) : ("We'll guide you along the process of adding your first project through to releasing your first deployment.");
    const learnMoreLink = (<ExternalVideoLink href="OnboardingGettingStartedVideo" className={learnMoreLinkStyles}>
            Video (3 mins)
        </ExternalVideoLink>);
    const isKGSFeatureEnabled = useOctopusFeatureToggle("kubernetes-guided-setup", false);
    const isKGSNthProjectFeatureEnabled = useOctopusFeatureToggle("kubernetes-guided-setup-nth-project", false);
    const isKubernetesSmallImprovementsFeatureEnabled = useOctopusFeatureToggle("kubernetes-small-improvements", false);
    const onboardingActions = isOnboardingHalfReady ? [ContinueProjectSetup(isKGSFeatureEnabled, spaceId, onboardingProject, setOpenDialog)] : [GettingStarted(dispatchAction, setOpenDialog), learnMoreLink];
    const closeDialog = (project: ProjectResource | undefined) => {
        if (project) {
            dispatchAction("Save First Project", { action: Action.Save, resource: "Project" });
        }
        else {
            dispatchAction("Cancel First Project Creation", { action: Action.Cancel, resource: "Project" });
        }
        setOpenDialog(false);
    };
    return (<PermissionCheck permission={Permission.ProjectCreate} alternate={<NoActionDashboard />}>
            <Level1EmptyStateLayout title={onboardingTitle} titleStyles={isOnboardingHalfDone(canContinueOnboarding, onboardingProject) ? continueTitleStyles : undefined} intro={onboardingIntro} actionButtonsAndLinks={onboardingActions} image={<EmptyStateDashboardIllustration />} analyticLocation={"Onboarding dashboard"} header={header} bottomSection={isKubernetesSmallImprovementsFeatureEnabled ? <ProjectsGettingStartedGuides /> : null}/>

            {!isOnboardingHalfReady && !isKGSNthProjectFeatureEnabled ? (<AddNewProjectDialog open={openDialog} close={(project) => closeDialog(project)}/>) : (<NewProjectWizard open={openDialog} fullScreen={true} spaceId={spaceId} projectSlug={onboardingProject?.Slug} isOnboarding={isOnboardingHalfReady} busy={busy} doBusyTask={doBusyTask} close={(project) => (isOnboardingHalfReady ? setOpenDialog(false) : closeDialog(project))}/>)}
        </PermissionCheck>);
}
function ContinueProjectSetup(isKGSEnabled: boolean, spaceId: string, project: OnboardingProject, setOpenDialog: (open: boolean) => void) {
    return isKGSEnabled ? (<Button importance="primary" label="Continue" onClick={() => setOpenDialog(true)}/>) : (<NavigationButton label="Continue" href={links.deploymentsPage.generateUrl({ spaceId, projectSlug: project.Slug }, { configureProject: true })} type={NavigationButtonType.Primary}/>);
}
function GettingStarted(dispatchAction: AnalyticActionDispatcher, setOpenDialog: (open: boolean) => void) {
    return (<Button importance="primary" label="Get Started" onClick={() => {
            dispatchAction("Add First Project", { action: Action.Add, resource: "Project" });
            setOpenDialog(true);
        }}/>);
}
function isOnboardingHalfDone(canContinueOnboarding: boolean, onboardingProject: OnboardingProject | undefined): onboardingProject is OnboardingProject {
    return canContinueOnboarding && onboardingProject !== undefined;
}
function NoActionDashboard() {
    return (<Level1EmptyStateLayout title={"Permissions"} intro={"No projects have been setup yet. Your current permissions don\u2019t allow you to create projects. If you require more information or believe this is in error please contact your administrator. "} image={<EmptyStateDashboardNoPermissionIllustration />}/>);
}
const onboardingIntroContainerStyles = css({
    display: "flex",
    alignItems: "center",
});
const logoContainerStyles = css({
    marginRight: space["12"],
    width: "2.25rem",
    height: "2.25rem",
    borderRadius: borderRadius.medium,
    background: colorScales.white,
});
const logoStyles = css({
    width: "100%",
    height: "100%",
    objectFit: "scale-down",
    borderRadius: borderRadius.small,
});
const learnMoreLinkStyles = css({
    font: text.regular.default.medium,
    fontWeight: fontWeight["700"],
});
const continueOnboardingIntroStyles = css({
    fontSize: fontSize.xLarge,
    lineHeight: lineHeight.large,
});
const continueTitleStyles = css({
    all: "unset",
    font: text.regular.bold.large,
    fontWeight: fontWeight["700"],
    letterSpacing: letterSpacing.wider,
    color: themeTokens.color.text.secondary,
});
export default DashboardOnboardingLayout;
