import { css } from "@emotion/css";
import { OctopusIcon } from "@octopusdeploy/design-system-components";
import { space, text, themeTokens } from "@octopusdeploy/design-system-tokens";
import type { ProjectResource, SpaceResource } from "@octopusdeploy/octopus-server-client";
import type { UserOnboardingResource } from "@octopusdeploy/octopus-server-client/src/resources/userOnboardingResource";
import { useTrackEvent } from "@octopusdeploy/portal-analytics";
import React, { useState } from "react";
import { Action, useAnalyticActionDispatch } from "~/analytics/Analytics";
import { repository } from "~/clientInstance";
import type { DataBaseComponentState, Errors } from "~/components/DataBaseComponent";
import DataBaseComponent from "~/components/DataBaseComponent";
import InternalLink from "~/components/Navigation/InternalLink";
import OnboardingDialog from "~/components/OnboardingDialog/OnboardingDialog";
import { OnboardingDialogLayout } from "~/components/OnboardingDialog/OnboardingDialogLayout";
import { WelcomeToOctopus } from "~/components/OnboardingDialog/WelcomeToOctopus";
import AddNewProjectDialog from "~/components/ProjectBasedActivation/AddNewProjectDialog";
import { ErrorPanel } from "~/components/form";
import useLocalStorage from "~/hooks/useLocalStorage";
import { useOctopusFeatureToggle } from "~/hooks/useOctopusFeatureToggle";
import { createWelcomeDialogDismissedAnalyticsEvent } from "./Analytics/WelcomeDialogAnalytics";
import { WelcomeDialogActionCards } from "./WelcomeDialogActionCards";
import { WelcomeDialogHelpPanelContent } from "./WelcomeDialogHelpPanelContent";
export type WelcomeDialogStates = "none" | "welcome-dialog" | "new-project-dialog";
export interface WelcomeDialogInternalProps {
    userOnboarding: UserOnboardingResource | undefined;
    currentSpace: SpaceResource;
    busy: Promise<void> | undefined;
    errors: Errors | undefined;
    onDialogClosed: () => void;
    onCreateSampleProject: (sampleProjectReference: string) => Promise<ProjectResource | undefined>;
}
export function WelcomeDialogInternal(props: WelcomeDialogInternalProps) {
    // TODO: Delete this variable along with KubernetesGuidedSetupFeatureToggle and feature slug kubernetes-guided-setup.
    // This variable is for development purposes and is used to force the welcome dialog to show.
    const [forceWelcomeDialog] = useLocalStorage(`KubernetesGuidedSetupFeatureToggle_ForceWelcomeDialog`, false);
    const { userOnboarding, currentSpace, busy, errors, onDialogClosed, onCreateSampleProject } = props;
    const dispatchAction = useAnalyticActionDispatch();
    const trackEvent = useTrackEvent();
    const [activeDialog, setActiveDialog] = useState<WelcomeDialogStates>("welcome-dialog");
    const setState = (value: WelcomeDialogStates) => {
        if (value === "none")
            onDialogClosed();
        setActiveDialog(value);
    };
    const dismissDialog = () => {
        trackEvent(createWelcomeDialogDismissedAnalyticsEvent());
        setState("none");
    };
    const isKubernetesGuidedSetupFeatureEnabled = useOctopusFeatureToggle("kubernetes-guided-setup", false);
    if (!isKubernetesGuidedSetupFeatureEnabled || !forceWelcomeDialog) {
        if (!userOnboarding || !userOnboarding.IsTrialInstance || userOnboarding.WelcomeDialogDismissedDate !== null)
            return <></>;
    }
    return (<>
            {!isKubernetesGuidedSetupFeatureEnabled ? (<OnboardingDialog name="welcome-dialog" open={activeDialog === "welcome-dialog"} close={dismissDialog} helpPanelContent={<WelcomeDialogHelpPanelContent />}>
                    <OnboardingDialogLayout actions={<InternalLink to={"#"} onClick={dismissDialog} size={0.875} weight={600} className={dialogStyles.skipLink} isDisabled={!!busy}>
                                Skip and start using Octopus
                            </InternalLink>} onCloseDialog={() => dismissDialog()} title={<DialogTitle />} busy={busy}>
                        <div className={dialogStyles.prompt}>Where would you like to start?</div>
                        <div className={dialogStyles.cardList}>
                            <WelcomeDialogActionCards onCreateSampleProject={onCreateSampleProject} busy={busy} currentSpace={currentSpace} onChange={setState}/>
                        </div>
                        {errors && <ErrorPanel message={errors.message} errors={errors.errors} parsedHelpLinks={errors.parsedHelpLinks} helpText={errors.helpText} helpLink={errors.helpLink} statusCode={errors.statusCode}/>}
                    </OnboardingDialogLayout>
                </OnboardingDialog>) : (<WelcomeToOctopus open={activeDialog === "welcome-dialog"} onClose={dismissDialog} forceWelcomeDialog={forceWelcomeDialog} userOnboarding={userOnboarding} currentSpace={currentSpace}/>)}

            {!isKubernetesGuidedSetupFeatureEnabled ? (<AddNewProjectDialog open={!isKubernetesGuidedSetupFeatureEnabled && activeDialog === "new-project-dialog"} close={(project) => {
                if (project) {
                    dispatchAction("Save Welcome Dialog Project", { action: Action.Save, resource: "Project" });
                    setState("none");
                }
                else {
                    dispatchAction("Back to Welcome Dialog from New Project Dialog", { action: Action.Cancel, resource: "Project" });
                    setState("welcome-dialog");
                }
            }} cancelButtonLabel="Back"/>) : null}
        </>);
}
const dialogStyles = {
    prompt: css({ font: text.regular.default.large, marginBottom: space[4] }),
    skipLink: css({ paddingRight: space[16] }),
    cardList: css({
        display: "flex",
        flexWrap: "wrap",
        "& div:first-child": {
            marginLeft: "0.125rem",
        },
        "& div:last-child": {
            marginLeft: "0.125rem",
        },
    }),
};
interface WelcomeDialogState extends DataBaseComponentState {
    userOnboarding?: UserOnboardingResource;
    currentSpace?: SpaceResource;
}
export class WelcomeDialog extends DataBaseComponent<{}, WelcomeDialogState> {
    constructor(props: {}) {
        super(props);
        this.state = {};
    }
    async componentDidMount() {
        await this.doBusyTask(async () => {
            if (!repository.spaceId)
                return;
            const [response, space] = await Promise.all([repository.UserOnboarding.getForCurrentUser(), repository.Spaces.get(repository.spaceId)]);
            this.setState({ userOnboarding: response?.UserOnboardingResource, currentSpace: space });
        });
    }
    async handleDialogClosed() {
        if (this.state.userOnboarding) {
            const updated: UserOnboardingResource = { ...this.state.userOnboarding, WelcomeDialogDismissedDate: new Date() };
            this.setState({ userOnboarding: updated });
            this.doBusyTask(async () => await repository.UserOnboarding.modifyForCurrentUser(updated));
        }
    }
    async handleCreateSampleProject(sampleProjectReference: string): Promise<ProjectResource | undefined> {
        let project: ProjectResource | undefined = undefined;
        await this.doBusyTask(async () => {
            const sampleProject = await repository.Projects.createSampleProject("Sample Project", sampleProjectReference);
            project = sampleProject.Project;
        });
        return project;
    }
    render() {
        const { currentSpace, userOnboarding } = this.state;
        if (!currentSpace) {
            return null;
        }
        return (<WelcomeDialogInternal userOnboarding={userOnboarding} currentSpace={currentSpace} busy={this.state.busy} errors={this.errors} onDialogClosed={() => this.handleDialogClosed()} onCreateSampleProject={(sampleProjectReference: string) => this.handleCreateSampleProject(sampleProjectReference)}/>);
    }
    static displayName = "WelcomeDialog";
}
function DialogTitle() {
    return (<div className={titleStyles.dialogTitle}>
            <div className={titleStyles.titleIcon}>
                <OctopusIcon />
            </div>
            <div className={titleStyles.titleContainer}>
                <h2 className={titleStyles.title}>Welcome to Octopus</h2>
                <div className={titleStyles.subtitle}>Simple, reliable, and auditable deployments</div>
            </div>
        </div>);
}
const titleStyles = {
    dialogTitle: css({
        marginBottom: space[32],
        display: "flex",
        flexWrap: "wrap",
        gap: space[12],
        alignItems: "center",
    }),
    titleContainer: css({
        "@media (max-width: 600px)": {
            flexBasis: "100%",
        },
    }),
    titleIcon: css({
        height: "46px",
        width: "43px",
    }),
    title: css({
        color: themeTokens.color.text.primary,
        font: text.heading.xLarge,
        margin: 0,
    }),
    subtitle: css({
        font: text.regular.default.medium,
        color: themeTokens.color.text.secondary,
    }),
};
