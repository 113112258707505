import { css } from "@emotion/css";
import { colorScales } from "@octopusdeploy/design-system-tokens";
import type { IconSvgResource } from "@octopusdeploy/octopus-server-client";
import React from "react";
import { ProjectCardIcon } from "~/areas/projects/components/Projects/ProjectCardIcon";
import IconEditorSvg from "~/components/form/IconEditor/IconEditorSvg";
interface IconAndLogoPreviewProps {
    icon?: IconSvgResource;
    iconColor: string;
    file?: File;
}
export const IconAndLogoPreview = ({ icon, iconColor, file }: IconAndLogoPreviewProps) => {
    return (<>
            {file ? (<div className={styles.customLogo}>
                    <img src={URL.createObjectURL(file)}/>
                </div>) : (<div className={styles.icon(iconColor)}>{icon ? <IconEditorSvg icon={icon} isSelected={true}/> : <ProjectCardIcon />}</div>)}
        </>);
};
const styles = {
    customLogo: css({
        overflow: "hidden",
        backgroundColor: colorScales.navy[100],
        img: {
            width: "100%",
            height: "100%",
            objectFit: "contain",
            objectPosition: "center",
        },
    }),
    icon: (iconColor: string) => css({
        backgroundColor: iconColor,
    }),
};
