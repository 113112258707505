import { css, cx } from "@emotion/css";
import { BooleanRadioButton, BooleanRadioButtonGroup, useIsMobile } from "@octopusdeploy/design-system-components";
import { space, text, themeTokens } from "@octopusdeploy/design-system-tokens";
import type { DashboardConfigurationResource, EnvironmentResource, ProjectGroupResource, ProjectSummaryResource, TenantResource } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import { isEqual } from "lodash";
import { useCallback } from "react";
import * as React from "react";
import { repository } from "~/clientInstance";
import { DataBaseComponent, type DoBusyTask, type Errors, useDoBusyTaskEffect } from "~/components/DataBaseComponent/index";
import CustomSaveDialogLayout from "~/components/DialogLayout/Custom/CustomSaveDialogLayout";
import { ContentSizeBasedDialogFrame, CustomDialogActions, CustomSaveDialogActions } from "~/components/DialogLayout/Custom/index";
import { Feature, FeatureToggle } from "~/components/FeatureToggle/index";
import { EnvironmentMultiSelect } from "~/components/MultiSelect/EnvironmentMultiSelect";
import { ProjectGroupMultiSelect } from "~/components/MultiSelect/ProjectGroupMultiSelect";
import { ProjectMultiSelect } from "~/components/MultiSelect/ProjectMultiSelect";
import { TenantMultiSelect } from "~/components/MultiSelect/TenantMultiSelect";
import { TenantTagMultiSelect } from "~/components/MultiSelect/TenantTagMultiSelect";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import { PermissionCheck } from "~/components/PermissionCheck/index";
interface ProjectsDashboardAdvancedFiltersDialogProps {
    onDashboardConfigurationChange: (newValue: DashboardConfigurationResource) => void;
    open: boolean;
    close: () => void;
}
interface AdvancedFiltersDialogInternalProps extends ProjectsDashboardAdvancedFiltersDialogProps {
    busy?: Promise<void>;
    doBusyTask: DoBusyTask;
    errors?: Errors;
}
interface DashboardConfigurationModel extends DashboardConfigurationResource {
    includeAllProjectGroups: boolean;
    includeAllProjects: boolean;
    includeAllEnvironments: boolean;
    includeAllTenants: boolean;
}
export class ProjectsDashboardAdvancedFiltersDialogLayout extends DataBaseComponent<ProjectsDashboardAdvancedFiltersDialogProps> {
    constructor(props: ProjectsDashboardAdvancedFiltersDialogProps) {
        super(props);
        this.state = {};
    }
    render() {
        return (<AdvancedFiltersDialogLayoutInternal onDashboardConfigurationChange={this.props.onDashboardConfigurationChange} open={this.props.open} close={this.props.close} doBusyTask={this.doBusyTask} busy={this.state.busy} errors={this.errors}/>);
    }
    static displayName = "ProjectsDashboardAdvancedFiltersDialogLayout";
}
function AdvancedFiltersDialogLayoutInternal({ onDashboardConfigurationChange, close, open, busy, doBusyTask, errors }: AdvancedFiltersDialogInternalProps) {
    const { projects, projectGroups, environments, tenants } = useAdvancedFiltersRelatedData(doBusyTask, open);
    const [filtersModel, setFiltersModel] = React.useState<DashboardConfigurationModel | undefined>(undefined);
    const [cleanFiltersModel, setCleanFiltersModel] = React.useState<DashboardConfigurationModel | undefined>(undefined);
    useDoBusyTaskEffect(doBusyTask, async () => {
        if (!open)
            return;
        const data = await repository.DashboardConfiguration.get();
        const filtersModel = getConfigurationModel(data);
        setFiltersModel(filtersModel);
        setCleanFiltersModel(filtersModel);
    }, [open]);
    const onSaveClick = async () => {
        if (!filtersModel)
            return false;
        const { includeAllEnvironments, includeAllProjectGroups, includeAllProjects, includeAllTenants, ...dashboardConfig } = filtersModel;
        if (includeAllProjectGroups) {
            dashboardConfig.IncludedProjectGroupIds.length = 0;
        }
        if (includeAllProjects) {
            dashboardConfig.IncludedProjectIds.length = 0;
        }
        if (includeAllEnvironments) {
            dashboardConfig.IncludedEnvironmentIds.length = 0;
        }
        if (includeAllTenants) {
            dashboardConfig.IncludedTenantTags.length = 0;
            dashboardConfig.IncludedTenantIds.length = 0;
        }
        return await doBusyTask(async () => {
            const result = await repository.DashboardConfiguration.modify(dashboardConfig);
            onDashboardConfigurationChange(result);
        });
    };
    const onModelUpdated = useCallback((partialModel: Partial<DashboardConfigurationModel>) => {
        setFiltersModel((prev) => (prev ? { ...prev, ...partialModel } : prev));
    }, []);
    return (<CustomSaveDialogLayout open={open} close={close} frame={ContentSizeBasedDialogFrame} onSaveClick={onSaveClick} busy={busy} errors={errors} renderTitle={() => <AdvancedFiltersDialogHeader />} renderContent={() => {
            if (!filtersModel)
                return <div className={dialogContentStyles}></div>;
            return <AdvancedFiltersDialogContent projects={projects} projectGroups={projectGroups} environments={environments} tenants={tenants} filtersModel={filtersModel} setFiltersModel={onModelUpdated} doBusyTask={doBusyTask}/>;
        }} renderActions={(renderProps) => <CustomDialogActions actions={<CustomSaveDialogActions close={renderProps.close} saveButtonLabel="Save" onSaveClick={renderProps.onSaveClick} saveDisabled={isEqual(filtersModel, cleanFiltersModel)}/>}/>}/>);
}
function useAdvancedFiltersRelatedData(doBusyTask: DoBusyTask, open: boolean) {
    const [projects, setProjects] = React.useState<ProjectSummaryResource[]>([]);
    const [projectGroups, setProjectGroups] = React.useState<ProjectGroupResource[]>([]);
    const [environments, setEnvironments] = React.useState<EnvironmentResource[]>([]);
    const [tenants, setTenants] = React.useState<TenantResource[]>([]);
    useDoBusyTaskEffect(doBusyTask, async () => {
        if (!open)
            return;
        setProjects(await repository.Projects.summaries());
        setProjectGroups(await repository.ProjectGroups.all());
        setEnvironments(await repository.Environments.all());
        isAllowed({ permission: Permission.TenantView, tenant: "*" }) && setTenants(await repository.Tenants.all());
    }, [open]);
    return { projects, projectGroups, environments, tenants };
}
function getConfigurationModel(configuration: DashboardConfigurationResource): DashboardConfigurationModel {
    return {
        ...configuration,
        includeAllProjectGroups: !configuration.IncludedProjectGroupIds.length,
        includeAllProjects: !configuration.IncludedProjectIds.length,
        includeAllEnvironments: !configuration.IncludedEnvironmentIds.length,
        includeAllTenants: !configuration.IncludedTenantIds.length && !configuration.IncludedTenantTags.length,
    };
}
function AdvancedFiltersDialogHeader() {
    const isMobile = useIsMobile();
    return (<div className={cx(dialogHeaderStyles, { [dialogWidthStyles]: !isMobile }, { [dialogWidthMobileStyles]: isMobile })}>
            <div className={dialogTitleStyles}>Filters</div>
            <div className={dialogDescriptionStyles}>Change and save the default view of the dashboard to display all or only selected projects, environments or releases for a tenant.</div>
        </div>);
}
const dialogWidthStyles = css({
    width: "44.5rem",
});
const dialogWidthMobileStyles = css({
    width: "100%",
});
const dialogHeaderStyles = css({
    padding: space["24"],
    display: "flex",
    flexDirection: "column",
    gap: space["8"],
});
const dialogTitleStyles = css({
    font: text.heading.medium,
});
const dialogDescriptionStyles = css({
    font: text.regular.default.medium,
    color: themeTokens.color.text.secondary,
});
interface AdvancedFiltersDialogContentProps {
    projects: ProjectSummaryResource[];
    projectGroups: ProjectGroupResource[];
    environments: EnvironmentResource[];
    tenants: TenantResource[];
    filtersModel: DashboardConfigurationModel;
    setFiltersModel: (arg: Partial<DashboardConfigurationModel>) => void;
    doBusyTask: DoBusyTask;
}
function AdvancedFiltersDialogContent({ projects, projectGroups, environments, tenants, filtersModel, setFiltersModel, doBusyTask }: AdvancedFiltersDialogContentProps) {
    const isMobile = useIsMobile();
    return (<div className={cx(dialogContentStyles, { [dialogWidthStyles]: !isMobile }, { [dialogWidthMobileStyles]: isMobile })}>
            <AdvancedFilterSection sectionTitle={"Project Groups"} sectionDescription={"View all or selected project groups"}>
                <BooleanRadioButtonGroup onChange={(includeAllProjectGroups) => setFiltersModel({ includeAllProjectGroups })} value={filtersModel.includeAllProjectGroups} noMargin={true}>
                    <BooleanRadioButton value={true} label="All project groups" isDefault={true}/>
                    <BooleanRadioButton value={false} label="Only selected project groups"/>
                </BooleanRadioButtonGroup>
                {filtersModel.includeAllProjectGroups || <ProjectGroupMultiSelect onChange={(IncludedProjectGroupIds) => setFiltersModel({ IncludedProjectGroupIds })} value={filtersModel.IncludedProjectGroupIds} items={projectGroups}/>}
            </AdvancedFilterSection>
            <AdvancedFilterSection sectionTitle={"Projects"} sectionDescription={"View all or selected projects"}>
                <BooleanRadioButtonGroup onChange={(includeAllProjects) => setFiltersModel({ includeAllProjects })} value={filtersModel.includeAllProjects}>
                    <BooleanRadioButton value={true} label="All projects" isDefault={true}/>
                    <BooleanRadioButton value={false} label="Only selected projects"/>
                </BooleanRadioButtonGroup>
                {filtersModel.includeAllProjects || <ProjectMultiSelect onChange={(IncludedProjectIds) => setFiltersModel({ IncludedProjectIds })} value={filtersModel.IncludedProjectIds} items={projects}/>}
            </AdvancedFilterSection>
            <AdvancedFilterSection sectionTitle={"Environments"} sectionDescription={"View all or selected environments"}>
                <BooleanRadioButtonGroup onChange={(includeAllEnvironments) => setFiltersModel({ includeAllEnvironments })} value={filtersModel.includeAllEnvironments}>
                    <BooleanRadioButton value={true} label="All environments" isDefault={true}/>
                    <BooleanRadioButton value={false} label="Only selected environments"/>
                </BooleanRadioButtonGroup>
                {filtersModel.includeAllEnvironments || <EnvironmentMultiSelect environments={environments} onChange={(IncludedEnvironmentIds) => setFiltersModel({ IncludedEnvironmentIds })} value={filtersModel.IncludedEnvironmentIds}/>}
            </AdvancedFilterSection>
            <FeatureToggle feature={Feature.MultiTenancy}>
                <PermissionCheck permission={Permission.TenantView} tenant="*">
                    <AdvancedFilterSection sectionTitle={"Tenants"} sectionDescription={"View all or selected tenants"}>
                        <BooleanRadioButtonGroup onChange={(includeAllTenants) => setFiltersModel({ includeAllTenants })} value={filtersModel.includeAllTenants}>
                            <BooleanRadioButton value={true} label="All tenants" isDefault={true}/>
                            <BooleanRadioButton value={false} label="Only selected tenants"/>
                        </BooleanRadioButtonGroup>
                        {filtersModel.includeAllTenants || (<div>
                                <TenantMultiSelect value={filtersModel.IncludedTenantIds} items={tenants} onChange={(IncludedTenantIds) => setFiltersModel({ IncludedTenantIds })}/>
                                <TenantTagMultiSelect value={filtersModel.IncludedTenantTags} doBusyTask={doBusyTask} onChange={(IncludedTenantTags) => setFiltersModel({ IncludedTenantTags })}/>
                            </div>)}
                    </AdvancedFilterSection>
                </PermissionCheck>
            </FeatureToggle>
        </div>);
}
const dialogContentStyles = css({
    padding: `0 ${space["24"]} ${space["24"]} ${space["24"]}`,
    height: "31rem",
    overflowY: "auto",
});
interface AdvancedFilterSectionProps {
    sectionTitle: string;
    sectionDescription: string;
    children: React.ReactNode;
}
function AdvancedFilterSection({ sectionTitle, sectionDescription, children }: AdvancedFilterSectionProps) {
    const isMobile = useIsMobile();
    return (<div className={cx(advancedFilterStyles, { [advancedFilterMobileStyles]: isMobile })}>
            <div className={filterInfoStyles}>
                <div className={filterTitleStyles}>{sectionTitle}</div>
                <div className={filterDescriptionStyles}>{sectionDescription}</div>
            </div>
            <div className={filterContentStyles}>{children}</div>
        </div>);
}
const advancedFilterStyles = css({
    display: "flex",
    justifyContent: "space-between",
    gap: space["24"],
    borderTop: `1px solid ${themeTokens.color.border.primary}`,
    padding: `${space["16"]} 0`,
});
const advancedFilterMobileStyles = css({
    flexDirection: "column",
});
const filterInfoStyles = css({
    display: "flex",
    flexDirection: "column",
    gap: space["4"],
    width: "15rem",
});
const filterTitleStyles = css({
    font: text.regular.bold.medium,
});
const filterDescriptionStyles = css({
    color: themeTokens.color.text.secondary,
    font: text.regular.default.medium,
});
const filterContentStyles = css({
    display: "flex",
    flexDirection: "column",
    flex: 1,
});
