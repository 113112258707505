import { css, cx } from "@emotion/css";
import { Divider, EmptyStateDefaultIllustration } from "@octopusdeploy/design-system-components";
import { space, text, themeTokens } from "@octopusdeploy/design-system-tokens";
import { AnalyticLinkLocationProvider } from "@octopusdeploy/portal-analytics";
import { Fragment } from "react";
import * as React from "react";
import type { ReactElement, ReactNode } from "react";
import { useMediaQuery } from "react-responsive";
interface Level2EmptyStateLayoutProps {
    title: string;
    intro: ReactNode;
    actionButtonsAndLinks?: ReactElement[];
    image?: ReactElement;
}
export function Level2EmptyStateLayout({ title, intro, image, actionButtonsAndLinks }: Level2EmptyStateLayoutProps) {
    const isLargerThanIpad = useMediaQuery({ query: `(min-width: 811px)` });
    return (<AnalyticLinkLocationProvider location="Onboarding Page">
            <Divider />
            <section className={cx(onboardingContainerStyles, { [onboardingContainerMobileStyles]: !isLargerThanIpad })}>
                <div className={cx(infoStyles, { [infoMobileStyles]: !isLargerThanIpad })}>
                    <h1 className={titleStyles}>{title}</h1>
                    {intro}
                    {actionButtonsAndLinks && actionButtonsAndLinks.length !== 0 && (<div className={actionsContainerStyles}>
                            {actionButtonsAndLinks.map((item, index) => (<Fragment key={index}>{item}</Fragment>))}
                        </div>)}
                </div>
                <div className={cx(imageContainerStyles, { [imageContainerMobileStyles]: !isLargerThanIpad })}>{image ?? <EmptyStateDefaultIllustration />}</div>
            </section>
        </AnalyticLinkLocationProvider>);
}
const onboardingContainerStyles = css({
    display: "flex",
    gap: space["48"],
    justifyContent: "center",
    color: themeTokens.color.text.primary,
    margin: "5rem 2.5rem",
});
const onboardingContainerMobileStyles = css({
    flexDirection: "column-reverse",
    padding: "1.5rem 1rem",
    margin: "0",
});
const infoStyles = css({
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    gap: space["24"],
    width: "28.75rem",
    font: text.regular.default.large,
});
const infoMobileStyles = css({
    width: "auto",
});
const titleStyles = css({
    all: "unset",
    font: text.heading.medium,
});
const actionsContainerStyles = css({
    display: "flex",
    gap: space["24"],
    alignItems: "center",
    flexWrap: "wrap",
});
const imageContainerStyles = css({
    width: "16.25rem",
    height: "16.25rem",
});
const imageContainerMobileStyles = css({
    width: "auto",
    height: "auto",
});
