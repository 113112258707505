/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { css } from "@emotion/css";
import { fontSize, fontWeight, space, themeTokens } from "@octopusdeploy/design-system-tokens";
import type { LinkHref } from "@octopusdeploy/portal-routes";
import * as React from "react";
import type { TaskStatusIconPropsItem } from "~/areas/projects/components/TaskStatusIcon/TaskStatusIcon";
import { TaskStatusIcon } from "~/areas/projects/components/TaskStatusIcon/TaskStatusIcon";
import InternalLink from "~/components/Navigation/InternalLink";
interface TaskStatusDetailsProps {
    task: TaskStatusIconPropsItem;
    title: string;
    link?: LinkHref;
    subtitle?: string;
    smallIcon?: boolean;
    noPadding?: boolean;
}
export function TaskStatusDetails({ task, title, link, subtitle, smallIcon, noPadding }: TaskStatusDetailsProps) {
    const styles = {
        container: css({
            display: "inline-flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "left",
            padding: noPadding ? "0" : space[8],
        }),
        details: css({
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "flex-start",
            paddingLeft: space[8],
            width: "100%",
        }),
        title: css({
            color: themeTokens.color.text.primary,
            fontWeight: fontWeight[500],
            fontSize: fontSize.medium,
            display: "flex",
            alignItems: "center",
            width: "100%",
        }),
        subtitle: css({
            color: themeTokens.color.text.tertiary,
            fontWeight: fontWeight[400],
            fontSize: fontSize.xSmall,
        }),
        link: css({
            display: "inherit",
            ":hover": {
                backgroundColor: themeTokens.color.background.primary.hovered,
            },
        }),
    };
    const details = (<div className={styles.container}>
            <TaskStatusIcon item={task} size={smallIcon ? 20 : 32}/>
            <div className={styles.details}>
                <span className={styles.title}>{title}</span>
                {subtitle && <span className={styles.subtitle}>{subtitle}</span>}
            </div>
        </div>);
    if (link) {
        return (<InternalLink className={styles.link} to={link}>
                {details}
            </InternalLink>);
    }
    return details;
}
