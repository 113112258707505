import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { css } from "@emotion/css";
import { IconButton, SortHandleIcon } from "@octopusdeploy/design-system-components";
import { borderRadius, borderWidth, colorScales, space, text, themeTokens } from "@octopusdeploy/design-system-tokens";
import classNames from "classnames";
import type { CSSProperties, ReactNode } from "react";
import * as React from "react";
import { Text } from "~/components/form";
export default function SortableItem({ id, name, label, customContent, onItemDeleted, onItemUpdated }: {
    id: string;
    name: string;
    label?: string;
    customContent?: ReactNode;
    onItemDeleted?: () => void;
    onItemUpdated?: (newValue: string) => void;
}) {
    const { attributes, isDragging, listeners, setNodeRef, transform, transition } = useSortable({ id });
    const style: CSSProperties = {
        transform: CSS.Translate.toString(transform),
        transition,
    };
    return isDragging ? (<li className={classNames({ [styles.sortableItemDropArea]: true, [styles.sortableItemDropAreaEditMode]: !!onItemUpdated })} {...attributes} {...listeners} ref={setNodeRef} style={style}>
            <div className={styles.sortableItemDropBar}/>
        </li>) : (<li className={styles.sortableItem} {...attributes} {...listeners} ref={setNodeRef} style={style}>
            <SortHandleIcon />

            {customContent ? (customContent) : !onItemUpdated ? (<span className={styles.itemName}>{name}</span>) : (<div className={styles.itemName} data-no-dnd>
                    <Text label={label} value={name} onChange={(newValue: string) => {
                onItemUpdated(newValue);
            }}/>
                </div>)}

            {onItemDeleted && (<div data-no-dnd>
                    <IconButton icon="Cancel" onClick={() => {
                onItemDeleted();
            }}/>
                </div>)}
        </li>);
}
const styles = {
    sortableItem: css({
        padding: space[12],
        border: `${borderWidth[1]} solid ${themeTokens.color.border.primary}`,
        borderRadius: borderRadius["medium"],
        display: "flex",
        alignItems: "center",
        gap: space[8],
        backgroundColor: themeTokens.color.background.primary.default,
        cursor: "pointer",
        touchAction: "manipulation",
        ".MuiTextField-root": {
            marginBottom: "0 !important",
        },
    }),
    sortableItemDropArea: css({
        padding: space[12],
        height: "50px",
        width: "100%",
        display: "flex",
        alignItems: "center",
    }),
    sortableItemDropAreaEditMode: css({
        height: "78px",
    }),
    sortableItemDropBar: css({
        border: `${borderWidth[1]} solid ${colorScales.blue[500]}`,
        borderRadius: borderRadius["medium"],
        backgroundColor: colorScales.blue[500],
        height: space[4],
        width: "100%",
    }),
    itemName: css({
        font: text.regular.default.large,
        width: "100%",
    }),
};
