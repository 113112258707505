import { ActionButton, ActionButtonType } from "@octopusdeploy/design-system-components";
import * as React from "react";
import { DialogLayoutConnect } from "~/components/Dialog/DialogLayoutConnect";
import type { DialogLayoutCommonProps, DialogLayoutDispatchProps } from "~/components/DialogLayout/DialogLayout";
import { DialogLayout } from "~/components/DialogLayout/DialogLayout";
import type { PermissionCheckProps } from "~/components/PermissionCheck/PermissionCheck";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import FormComponent from "../FormComponent/FormComponent";
import styles from "./style.module.less";
interface OkDialogLayoutProps extends DialogLayoutCommonProps {
    okButtonLabel?: string;
    okButtonAccessibleName?: string;
    okButtonDisabled?: boolean;
    okButtonPermission?: PermissionCheckProps;
    hideCancel?: boolean;
    cancelButtonLabel?: string;
    onOkClick(): Promise<boolean> | Promise<void> | boolean | void;
}
class OkDialogLayoutInternal extends React.Component<OkDialogLayoutProps & DialogLayoutDispatchProps> {
    okClick = async () => {
        const result = await this.props.onOkClick();
        if (typeof result !== "boolean" || result) {
            this.props.close();
        }
    };
    submitForm = async () => {
        if (!this.isDisabled()) {
            await this.okClick();
        }
    };
    isDisabled() {
        return this.props.okButtonDisabled || this.props.busy;
    }
    render() {
        const hasPermissionForOk = !!this.props.okButtonPermission ? isAllowed(this.props.okButtonPermission) : true;
        const { children, ...other } = this.props;
        const ok = <ActionButton key="Ok" label={this.props.okButtonLabel || "Ok"} accessibleName={this.props.okButtonAccessibleName} disabled={this.isDisabled()} onClick={this.okClick} type={ActionButtonType.Primary}/>;
        const cancel = <ActionButton key="Cancel" label={this.props.cancelButtonLabel || "Cancel"} disabled={this.props.busy} onClick={() => this.props.close()}/>;
        const actions = [!this.props.hideCancel && cancel, hasPermissionForOk && ok].filter((a) => !!a);
        return (<DialogLayout actions={actions} closeDialog={this.props.close} {...other}>
                <FormComponent onFormSubmit={this.submitForm} className={styles.dialogWrapper}>
                    {children}
                </FormComponent>
            </DialogLayout>);
    }
    static displayName = "OkDialogLayoutInternal";
}
const OkDialogLayout = DialogLayoutConnect.to(OkDialogLayoutInternal);
OkDialogLayout.displayName = "OkDialogLayout";
export default OkDialogLayout;
