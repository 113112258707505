import { css } from "@emotion/css";
import { Callout, Checkbox } from "@octopusdeploy/design-system-components";
import { space } from "@octopusdeploy/design-system-tokens";
import type { UserPermissionSetResource, UserResource, PermissionDescriptions, UserPermissionRestriction } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import React from "react";
import { client, repository, session } from "~/clientInstance";
export function PermissionsTab() {
    const [userPermissionSet, setUserPermissionSet] = React.useState<UserPermissionSetResource | undefined>(undefined);
    const [permissionDescriptions, setPermissionDescriptions] = React.useState<PermissionDescriptions | undefined>(undefined);
    React.useEffect(() => {
        const fetchData = async (user: UserResource) => {
            setPermissionDescriptions(await repository.PermissionDescriptions.all());
            setUserPermissionSet(await repository.UserPermissions.getAllPermissions(user, true));
        };
        if (session.currentUser)
            fetchData(session.currentUser);
    }, []);
    React.useEffect(() => {
        if (!userPermissionSet)
            return;
        session.refreshPermissions(userPermissionSet);
    }, [userPermissionSet]);
    if (!userPermissionSet || !permissionDescriptions)
        return null;
    const onSystemPermissionChanged = async (permission: Permission, value: boolean) => {
        setUserPermissionSet((prev) => {
            if (prev === undefined)
                return prev;
            let systemPermissions = [...prev.SystemPermissions]; // clone the object
            if (value) {
                if (systemPermissions.indexOf(permission) === -1) {
                    systemPermissions.push(permission);
                }
            }
            else {
                systemPermissions = systemPermissions.filter((p) => p !== permission);
            }
            return { ...prev, SystemPermissions: systemPermissions };
        });
    };
    const onSpacePermissionChanged = async (permission: Permission, value: boolean) => {
        setUserPermissionSet((prev) => {
            if (prev === undefined)
                return prev;
            const spacePermissions = { ...prev.SpacePermissions }; // clone the object
            if (value) {
                let spacePermission: UserPermissionRestriction[] | undefined = spacePermissions[permission];
                if (spacePermission === undefined)
                    spacePermission = [];
                if (spacePermission.find((p) => p.SpaceId === client.spaceId) === undefined) {
                    spacePermissions[permission] = [
                        ...spacePermission,
                        {
                            RestrictedToProjectIds: ["projects-all"],
                            RestrictedToEnvironmentIds: ["environments-all"],
                            RestrictedToTenantIds: ["tenants-all"],
                            RestrictedToProjectGroupIds: ["projectgroups-all"],
                            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                            SpaceId: client.spaceId!,
                        },
                    ];
                }
            }
            else {
                const spacePermission: UserPermissionRestriction[] | undefined = spacePermissions[permission];
                if (spacePermission !== undefined && spacePermission.length > 0) {
                    spacePermissions[permission] = [];
                }
            }
            return { ...prev, SpacePermissions: spacePermissions };
        });
    };
    // Get a list of all possible permissions
    const allPermissions = Object.keys(permissionDescriptions)
        .sort()
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        .map((permission) => Permission[permission as keyof typeof Permission]);
    const allSystemPermissions = allPermissions.filter((permission) => {
        return permissionDescriptions[permission]?.CanApplyAtSystemLevel === true;
    });
    const allSpacePermissions = allPermissions.filter((permission) => {
        return permissionDescriptions[permission]?.CanApplyAtSpaceLevel === true;
    });
    const currentSpacePermissions = allSpacePermissions.filter((permission) => {
        return userPermissionSet.SpacePermissions[permission]?.find((p) => p.SpaceId === client.spaceId) !== undefined;
    });
    const styles = {
        container: css({
            display: "flex",
            flexDirection: "column",
            gap: space[16],
            padding: space[16],
        }),
        permissions: css({
            display: "flex",
            flexDirection: "column",
            gap: space[16],
        }),
    };
    return (<div className={styles.container}>
            <Callout type="warning" title="Permission controls">
                This only controls the permissions in the UI (not the permissions on the Server)
            </Callout>
            <h3>System Permissions</h3>
            <div className={styles.permissions}>
                {allSystemPermissions.map((permission) => {
            const hasPermission = userPermissionSet.SystemPermissions.includes(permission);
            return (<Checkbox key={permission} value={hasPermission} onChange={(value) => {
                    onSystemPermissionChanged(permission, value);
                }} label={permission} noMargin/>);
        })}
            </div>
            <h3>Space Permissions</h3>
            <Callout type="warning" title="Current space">
                This only controls permissions in the current space and if changed applies to all projects, environments, tenants, and project groups in the space.
            </Callout>
            <div className={styles.permissions}>
                {allSpacePermissions.map((permission) => {
            const hasPermission = currentSpacePermissions.includes(permission);
            return (<Checkbox key={permission} value={hasPermission} onChange={(value) => {
                    onSpacePermissionChanged(permission, value);
                }} label={permission} noMargin/>);
        })}
            </div>
        </div>);
}
