/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/init-declarations */
import { ActionButton, ActionButtonType } from "@octopusdeploy/design-system-components";
import { ProcessType, isReleaseResource, isRunbookSnapshotResource } from "@octopusdeploy/octopus-server-client";
import type { ProjectResource, ISnapshotResource, ScopeValues, VariableSetResource, IExecutionResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { useState } from "react";
import { Action, useProjectScopedAnalyticActionDispatch } from "~/analytics/Analytics";
import type { ActionEvent } from "~/analytics/Analytics";
import mergeScopeValues from "~/areas/variables/MergeScopeValues";
import type { VariableWithSource } from "~/areas/variables/VariableDisplayer";
import FilterableVariableDisplayer from "~/areas/variables/VariableDisplayer/FilterableVariableDisplayer";
import { convertVariableResourcesToVariablesWithSource } from "~/areas/variables/convertVariableResourcesToVariablesWithSource";
import { repository } from "~/clientInstance";
import ActionList from "~/components/ActionList/ActionList";
import { useDoBusyTaskEffect } from "~/components/DataBaseComponent";
import type { DoBusyTask } from "~/components/DataBaseComponent/DataBaseComponent";
import { Section } from "~/components/Section/Section";
import FormSectionHeading from "~/components/form/Sections/FormSectionHeading";
import Note from "~/primitiveComponents/form/Note/Note";
import { GitRefChip } from "../GitRefChip/GitRefChip";
interface VariableManifestProps {
    projectId: string;
    spaceId: string;
    snapshot: ISnapshotResource;
    executionId: string;
    doBusyTask: DoBusyTask;
}
interface Model {
    readonly project: ProjectResource;
    readonly snapshot: ISnapshotResource;
    readonly manifestVariables: VariableSetResource;
}
const GetProcessType = (snapshot: ISnapshotResource) => {
    if (isReleaseResource(snapshot)) {
        return ProcessType.Deployment;
    }
    else if (isRunbookSnapshotResource(snapshot)) {
        return ProcessType.Runbook;
    }
    return undefined;
};
const VariableManifestDescription: React.FC<{
    snapshot: ISnapshotResource;
}> = ({ snapshot }) => {
    const processType = GetProcessType(snapshot);
    const snapshotTerm = processType === ProcessType.Deployment ? "deployment" : "run";
    const snapshotGitRef = snapshot.VersionControlReference ?? snapshot.GitReference;
    const variablesGitRef = snapshotGitRef && snapshotGitRef.VariablesGitCommit && snapshotGitRef.GitRef && { GitRef: snapshotGitRef.GitRef, GitCommit: snapshotGitRef.VariablesGitCommit };
    return (<p>
            When this {snapshotTerm} was executed, a snapshot of the project variables was taken
            {variablesGitRef && (<>
                    {" "}
                    from <GitRefChip vcsRef={variablesGitRef}/>
                </>)}
        </p>);
};
VariableManifestDescription.displayName = "VariableManifestDescription"
export function VariableManifest({ projectId, executionId, snapshot, doBusyTask }: VariableManifestProps) {
    const actionDispatch = useProjectScopedAnalyticActionDispatch(projectId);
    const [variables, setVariables] = useState<VariableWithSource[]>([]);
    const [showVariables, setShowVariables] = useState<boolean>(false);
    const [scopes, setScopes] = useState<ScopeValues>({
        Actions: [],
        TenantTags: [],
        Roles: [],
        Channels: [],
        Machines: [],
        Environments: [],
        Processes: [],
    });
    useDoBusyTaskEffect(doBusyTask, async () => {
        // We use the snapshot variables to get the scopes
        let snapshotVariablesPromise: Promise<VariableSetResource[]>;
        // We use the execution's variables to get the actual values
        let executionPromise: Promise<IExecutionResource>;
        if (isRunbookSnapshotResource(snapshot)) {
            snapshotVariablesPromise = repository.RunbookSnapshots.variables(snapshot);
            executionPromise = repository.RunbookRuns.get(executionId);
        }
        else if (isReleaseResource(snapshot)) {
            // This component doesn't currently support releases. There is
            // no reason not to, but we're not adding support for it at the
            // moment so I'm not writing the code (because I won't be able
            // to test it and cant guarantee that it'll work).
            throw new Error("Unknown type of snapshot resource");
        }
        else {
            throw new Error("Unknown type of snapshot resource");
        }
        const execution = await executionPromise;
        const snapshotVariables = await snapshotVariablesPromise;
        const manifestVariables = await repository.Variables.get(execution.ManifestVariableSetId);
        const filteredManifestVariables = getManifestVariablesThatAreInBaseSnapshot(snapshotVariables, manifestVariables);
        setVariables(filteredManifestVariables);
        const mergedScopeValues = mergeScopeValues(snapshotVariables.map((v) => v.ScopeValues));
        setScopes(mergedScopeValues);
    }, [projectId, executionId]);
    const hasVariables = variables && variables.length > 0;
    const showHideSnapshot = (<ActionButton label={showVariables ? "Hide Snapshot" : "Show Snapshot"} type={ActionButtonType.Ternary} onClick={() => {
            setShowVariables((prev) => {
                const ev: ActionEvent = {
                    action: Action.View,
                    resource: "Release",
                };
                actionDispatch("View Variable Manifest", ev);
                return !prev;
            });
        }}/>);
    return (<div>
            <FormSectionHeading title="Variable Snapshot"/>
            <Section sectionHeader="">
                <Note>
                    <VariableManifestDescription snapshot={snapshot}/>
                    {hasVariables ? (<ActionList actions={[showHideSnapshot]}/>) : (<>
                            <p>No variable snapshot exists.</p>
                        </>)}
                </Note>
            </Section>
            {showVariables && (<div>
                    <FilterableVariableDisplayer availableScopes={scopes} variableSections={[variables]} doBusyTask={doBusyTask} hideSource disableQueryStringFilters/>
                </div>)}
        </div>);
}
function getManifestVariablesThatAreInBaseSnapshot(snapshotVariables: VariableSetResource[], manifestVariables: VariableSetResource): VariableWithSource[] {
    // The manifest has all sorts of variables in there, but we only want to show the user-configured ones.
    // We already have the snapshot variables (we fetched them for the scopes) so we will get all the ids
    // from those, and only show manifest variables that match that ID.
    const snapshotVariableIds = snapshotVariables.flatMap((s) => s.Variables.map((v) => v.Id));
    const filteredManifestVariables = manifestVariables.Variables.filter((v) => snapshotVariableIds.includes(v.Id));
    // We hide this, but FilterableVariableDisplayer still requires a source on all variables.
    const hiddenSource = {
        spaceId: "",
        projectName: "",
        projectId: "",
    };
    return convertVariableResourcesToVariablesWithSource(filteredManifestVariables, hiddenSource);
}
