/* eslint-disable @typescript-eslint/init-declarations */
/* eslint-disable no-eq-null */
import { Checkbox } from "@octopusdeploy/design-system-components";
import { logger } from "@octopusdeploy/logging";
import { type GitRefResource, Permission } from "@octopusdeploy/octopus-server-client";
import type * as H from "history";
import React from "react";
import { useActionTemplatesFromContext } from "~/areas/projects/components/Process/Contexts/ProcessActionTemplatesContextProvider";
import { useProcessContext } from "~/areas/projects/components/Process/Contexts/ProcessContext";
import { useProcessSearchFilterContext } from "~/areas/projects/components/Process/Contexts/ProcessSearchFilter/ProcessSearchFilterContext";
import type { ProcessEditorSearchFilterNamedResource } from "~/areas/projects/components/Process/Contexts/ProcessSearchFilter/index";
import type { ProcessSearchFilter } from "~/areas/projects/components/Process/ListItems/ProcessListItemContextMenu";
import ProcessListPageListItemForAction from "~/areas/projects/components/Process/ListItems/ProcessListPageListItemForAction";
import ProcessListPageListItemForParentStep from "~/areas/projects/components/Process/ListItems/ProcessListPageListItemForParentStep";
import type { ProcessListLayoutLoaderLookupData } from "~/areas/projects/components/Process/ProcessListLayoutLoader";
import AdvancedFilterLayout from "~/components/AdvancedFilterLayout/AdvancedFilterLayout";
import type { DoBusyTask, Errors } from "~/components/DataBaseComponent";
import FilterSearchBox from "~/components/FilterSearchBox";
import { NoResults } from "~/components/Images/NoResults/NoResults";
import { PermissionCheck } from "~/components/PermissionCheck";
import EnvironmentSelect from "~/components/form/EnvironmentSelect/EnvironmentSelect";
import processListItemStyles from "../../projects/components/Process/ListItems/ProcessListItem.module.less";
import Onboarding from "./Onboarding";
class FilterLayout extends AdvancedFilterLayout<ProcessSearchFilter> {
}
export interface ProcessListForBlueprintProps {
    lookups: ProcessListLayoutLoaderLookupData;
    busy?: boolean;
    errors?: Errors;
    doBusyTask: DoBusyTask;
    location: H.Location;
    history: H.History;
    gitRefResource: GitRefResource | undefined;
}
const ProcessListForBlueprint: React.FC<ProcessListForBlueprintProps> = ({ lookups, busy, errors, doBusyTask, location, history, gitRefResource }) => {
    const processContext = useProcessContext();
    const processSearchFilterContext = useProcessSearchFilterContext();
    const actionTemplates = useActionTemplatesFromContext();
    const { state: processSearchFilterContextLookupsState, actions: processSearchFilterContextActions } = processSearchFilterContext;
    const hasValidProcess: boolean = processContext.selectors.hasValidProcess();
    const hasSteps: boolean = processContext.selectors.hasSteps();
    return (<>
            {hasValidProcess && !hasSteps && <Onboarding spaceId={processContext.state.model.process?.SpaceId ?? ""} blueprintId={processContext.state.model.process?.Id ?? ""}/>}
            {hasValidProcess && hasSteps && (<>
                    <FilterLayout filter={processSearchFilterContextLookupsState.searchFilter} filterSections={[
                {
                    render: (<>
                                        <PermissionCheck permission={Permission.EnvironmentView} wildcard={true}>
                                            <EnvironmentSelect value={processSearchFilterContextLookupsState.searchFilter.environment?.Id} onChange={(environmentId) => {
                            let environment: ProcessEditorSearchFilterNamedResource;
                            if (environmentId) {
                                const resource = lookups.environmentsById[environmentId];
                                environment = {
                                    Id: resource.Id,
                                    Name: resource.Name,
                                };
                            }
                            processSearchFilterContextActions.onFilterChange((t) => ({ ...t, environment }));
                        }} environments={Object.values(lookups.environmentsById)} allowClear={true} allowFilter={true} fieldName="environment"/>
                                        </PermissionCheck>
                                        <Checkbox label="Include unscoped steps" value={!!processSearchFilterContextLookupsState.searchFilter.includeUnscoped} onChange={(includeUnscoped) => {
                            processSearchFilterContextActions.onFilterChange((prev) => ({ ...prev, includeUnscoped }));
                        }}/>
                                    </>),
                },
            ]} onFilterReset={(filter) => processSearchFilterContextActions.onFilterChange(() => filter)} defaultFilter={processSearchFilterContext.getEmptyFilter()} initiallyShowFilter={processSearchFilterContext.isFiltering} additionalHeaderFilters={[
                <FilterSearchBox placeholder="Filter by name..." value={processSearchFilterContextLookupsState.searchFilter.filterKeyword} onChange={(filterKeyword) => processSearchFilterContextActions.onFilterChange((prev) => ({ ...prev, filterKeyword }))} autoFocus={true}/>,
            ]} renderContent={() => (<div className={processListItemStyles.stepList}>
                                {processSearchFilterContext.filteredSteps.steps.length > 0 ? (processSearchFilterContext.filteredSteps.steps
                    .filter((x) => x.filtered)
                    .map(({ step: filteredStep, index }) => {
                    const step = processContext.selectors.getStepById(filteredStep.Id);
                    if (!step) {
                        logger.info("Failed to find step with name {stepName}", { stepName: filteredStep.Name });
                        return null;
                    }
                    if (step.ActionIds.length === 1) {
                        const action = processContext.selectors.getActionById(step.ActionIds[0]);
                        return <ProcessListPageListItemForAction key={action.Id} actionTemplates={actionTemplates} step={step} action={action} actionIndex={index} lookups={lookups} gitRefResource={gitRefResource}/>;
                    }
                    else {
                        return <ProcessListPageListItemForParentStep key={step.Id} actionTemplates={actionTemplates} step={step} stepIndex={index} lookups={lookups} errors={errors} gitRefResource={gitRefResource}/>;
                    }
                })) : (<NoResults />)}
                            </div>)}/>
                </>)}
        </>);
};
ProcessListForBlueprint.displayName = "ProcessListForBlueprint"
export default ProcessListForBlueprint;
