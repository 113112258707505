/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { ActionButtonType, Tooltip, Callout } from "@octopusdeploy/design-system-components";
import type { PageAction } from "@octopusdeploy/design-system-components";
import type { RunbookSnapshotResource, EventResource, ResourceCollection, ProjectResource, EventCategoryResource, RunbookResource } from "@octopusdeploy/octopus-server-client";
import { ProcessType, Permission } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import { isEqual } from "lodash";
import * as React from "react";
import { ProjectPaperLayout } from "~/areas/projects/components/ProjectPaperLayout";
import { PublishSnapshotDialogLayout } from "~/areas/projects/components/Runbooks/PublishSnapshotDialogLayout/PublishSnapshotDialogLayout";
import { useProjectContext } from "~/areas/projects/context";
import { repository } from "~/clientInstance";
import { RunbookSnapshotPublishedChip } from "~/components/Chips";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import Dialog from "~/components/Dialog/Dialog";
import OpenDialogButton from "~/components/Dialog/OpenDialogButton";
import EventListing from "~/components/EventListing/EventListing";
import { isFeatureToggleEnabled } from "~/components/FeatureToggle/New/FeatureToggleContext";
import InternalRedirect from "~/components/Navigation/InternalRedirect";
import type { MenuItem } from "~/components/OverflowMenu/OverflowMenu";
import { OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import type { ConvertedOverflowMenuItems } from "~/components/OverflowMenu/OverflowMenuConverterVNext";
import { OverflowMenuConverterVNext } from "~/components/OverflowMenu/OverflowMenuConverterVNext";
import PermissionCheck, { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import { FormSectionHeading } from "~/components/form";
import DateFormatter from "~/utils/DateFormatter/DateFormatter";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
import StringHelper from "~/utils/StringHelper";
import UpdateVariables from "../../Releases/UpdateVariables/UpdateVariables";
import { publishingExplainedElement } from "../PublishButton";
import { useRunbookContext } from "../RunbookContext";
import { isRunbookConsumerTryingToRunAnUnpublishedSnapshot } from "../isRunbookConsumerTryingToRunAnUnpublishedSnapshot";
import { RunbookSnapshotInfo } from "./RunbookSnapshotInfo";
import styles from "./RunbookSnapshotInfoPage.module.less";
interface RunbookSnapshotInfoPageState extends DataBaseComponentState {
    runbookSnapshot: RunbookSnapshotResource;
    events: ResourceCollection<EventResource>;
    eventCategories: EventCategoryResource[];
    variableSnapshotRefreshKey: string;
    deleted: boolean;
}
interface RunbookSnapshotInfoPageInternalProps extends RunbookSnapshotInfoPageProps {
    runbook: RunbookResource;
    project: ProjectResource;
    publishShapshot: (snapshot: RunbookSnapshotResource) => Promise<boolean>;
}
class RunbookSnapshotInfoPageInternal extends DataBaseComponent<RunbookSnapshotInfoPageInternalProps, RunbookSnapshotInfoPageState> {
    constructor(props: RunbookSnapshotInfoPageInternalProps) {
        super(props);
        this.state = {
            runbookSnapshot: null!,
            events: null!,
            eventCategories: null!,
            variableSnapshotRefreshKey: DateFormatter.timestamp(),
            deleted: false,
        };
    }
    async componentDidMount() {
        await this.reload();
    }
    async componentDidUpdate(prevProps: RunbookSnapshotInfoPageInternalProps) {
        const nextRunbook = this.props.runbook;
        const currentRunbook = prevProps.runbook;
        if (!isEqual(currentRunbook, nextRunbook)) {
            await this.reload();
        }
    }
    reload = async () => {
        const project = this.props.project;
        return this.doBusyTask(async () => {
            const runbookSnapshot = await repository.RunbookSnapshots.get(this.props.runbookSnapshotId);
            this.setState(await this.refreshActiveComponents(project, runbookSnapshot));
        }, { timeOperationOptions: timeOperationOptions.forInitialLoad() });
    };
    publishSnapshot = () => this.state.runbookSnapshot && this.props.publishShapshot(this.state.runbookSnapshot);
    render() {
        const project = this.props.project;
        const runbook = this.props.runbook;
        if (this.state.deleted) {
            return <InternalRedirect to={links.projectRunbookSnapshotsPage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug, runbookId: runbook.Id })} push={true}/>;
        }
        const convertedOverflowActions = this.getOverflowActions(project, runbook);
        const pageActions: PageAction[] = [];
        if (this.state.runbookSnapshot) {
            if (!runbook.PublishedRunbookSnapshotId || (runbook.PublishedRunbookSnapshotId && runbook.PublishedRunbookSnapshotId !== this.state.runbookSnapshot.Id)) {
                pageActions.push({
                    type: "custom",
                    key: "Publish",
                    hasPermissions: isAllowed({
                        permission: Permission.RunbookEdit,
                        project: project.Id,
                        wildcard: true,
                    }),
                    content: (<Tooltip content={publishingExplainedElement}>
                            <OpenDialogButton label="Publish..." type={ActionButtonType.Ternary} renderDialog={({ open, closeDialog }) => (<Dialog open={open}>
                                        <PublishSnapshotDialogLayout close={closeDialog} onPublishSnapshotDialogClicked={this.publishSnapshot}/>
                                    </Dialog>)}></OpenDialogButton>
                        </Tooltip>),
                });
            }
        }
        if (!isRunbookConsumerTryingToRunAnUnpublishedSnapshot(project, runbook, this.state.runbookSnapshot && this.state.runbookSnapshot.Id)) {
            pageActions.push({
                type: "navigate",
                buttonType: "secondary",
                label: "Run...",
                hasPermissions: isAllowed({ permission: Permission.RunbookRunCreate, project: project.Id, wildcard: true }),
                path: links.createRunbookRunForSnapshotPage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug, runbookId: runbook.Id, runbookSnapshotId: this.props.runbookSnapshotId }),
            });
        }
        return (<ProjectPaperLayout title={this.runbookSnapshotTitle()} titleChip={this.runbookSnapshotTitleChip()} breadcrumbsItems={runbook && [
                { label: "Runbooks", pageUrl: links.projectRunbooksPage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug }) },
                { label: runbook.Name, pageUrl: links.projectRunbookOverviewPage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug, runbookId: runbook.Id }) },
                { label: "Snapshots", pageUrl: links.projectRunbookSnapshotsPage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug, runbookId: runbook.Id }) },
            ]} busy={this.state.busy} errors={this.errors} pageActions={pageActions} overflowActions={this.state.runbookSnapshot ? convertedOverflowActions.menuItems : undefined}>
                {convertedOverflowActions.dialogs}
                <div className={styles.runbookSnapshotDetailsLayout}>
                    {this.state.runbookSnapshot && (<div className={styles.runbookSnapshotDetailsLayoutContent}>
                            <RunbookSnapshotInfo doBusyTask={this.doBusyTask} project={project} runbook={runbook} runbookSnapshot={this.state.runbookSnapshot} variableSnapshotRefreshKey={this.state.variableSnapshotRefreshKey}/>
                            <FormSectionHeading key="runbookRunHistory" title="Run history"/>
                            <PermissionCheck permission={Permission.RunbookRunView} project={this.state.runbookSnapshot.ProjectId} wildcard={true} alternate={<Callout type={"information"} title={"Permission required"}>
                                        The {Permission.RunbookRunView} permission is required to view the runbook run history
                                    </Callout>}>
                                <EventListing data={this.state.events} regarding={[this.state.runbookSnapshot.Id]} eventCategories={this.state.eventCategories}/>
                            </PermissionCheck>
                        </div>)}
                </div>
            </ProjectPaperLayout>);
    }
    private getOverflowActions(project: ProjectResource, runbook: RunbookResource): ConvertedOverflowMenuItems {
        const overflowActions: MenuItem[] = [
            OverflowMenuItems.navItem("Edit", links.projectRunbookSnapshotEditPage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug, runbookId: runbook.Id, runbookSnapshotId: this.props.runbookSnapshotId }), {
                permission: Permission.RunbookEdit,
                project: project.Id,
                wildcard: true,
            }),
        ];
        if (this.state.runbookSnapshot) {
            overflowActions.push(OverflowMenuItems.dialogItem("Update Variables", <UpdateVariables processType={ProcessType.Runbook} onUpdateVariablesClicked={async () => {
                    const runbookSnapshot = await repository.RunbookSnapshots.snapshotVariables(this.state.runbookSnapshot);
                    this.setState({ runbookSnapshot, variableSnapshotRefreshKey: DateFormatter.timestamp() });
                }}/>, {
                permission: Permission.RunbookEdit,
                project: project.Id,
                wildcard: true,
            }));
            overflowActions.push(OverflowMenuItems.deleteItemDefault("runbook snapshot", this.handleDeleteConfirm, {
                permission: Permission.RunbookEdit,
                project: project.Id,
                wildcard: true,
            }, "The runbook snapshot and any of its runs will be permanently deleted and they will disappear from all dashboards."));
            overflowActions.push(OverflowMenuItems.navItem("Audit Trail", links.auditPage.generateUrl({ regardingAny: [this.state.runbookSnapshot.Id] }), {
                permission: Permission.EventView,
                wildcard: true,
            }));
        }
        return OverflowMenuConverterVNext.convertAll(overflowActions);
    }
    private async refreshActiveComponents(project: ProjectResource, runbookSnapshot: RunbookSnapshotResource) {
        const useOptimization = isFeatureToggleEnabled("PermissionCheckOptimizationFeatureToggle");
        const [events, eventCategories] = await Promise.all([
            isAllowed({ permission: Permission.EventView, project: project.Id, wildcard: true })
                ? useOptimization
                    ? repository.Events.getUnpaginatedEvents({ regarding: [runbookSnapshot.Id] })
                    : repository.Events.list({ regarding: [runbookSnapshot.Id] })
                : null!,
            repository.Events.categories({}),
        ]);
        const resultForState: RunbookSnapshotInfoPageState = {
            ...this.state,
            runbookSnapshot,
            events,
            eventCategories,
        };
        return resultForState;
    }
    private runbookSnapshotTitle(): string {
        return this.state.runbookSnapshot?.Name ?? StringHelper.ellipsis;
    }
    private runbookSnapshotTitleChip(): React.ReactElement | undefined {
        return this.state.runbookSnapshot && this.state.runbookSnapshot.Id === this.props.runbook.PublishedRunbookSnapshotId ? <RunbookSnapshotPublishedChip noMargin={true}/> : undefined;
    }
    private handleDeleteConfirm = async (): Promise<boolean> => {
        if (this.state.runbookSnapshot) {
            await repository.RunbookSnapshots.del(this.state.runbookSnapshot);
            this.setState({ deleted: true });
            return true;
        }
        else {
            return false;
        }
    };
    static displayName = "RunbookSnapshotInfoPageInternal";
}
interface RunbookSnapshotInfoPageProps {
    runbookSnapshotId: string;
}
export function RunbookSnapshotInfoPage({ runbookSnapshotId }: RunbookSnapshotInfoPageProps) {
    const runbookContext = useRunbookContext();
    const projectContext = useProjectContext();
    const project = projectContext.state.model;
    const runbook = runbookContext.state.runbook;
    if (!project || !runbook) {
        return <ProjectPaperLayout busy={true}/>;
    }
    return <RunbookSnapshotInfoPageInternal project={project} runbook={runbook} runbookSnapshotId={runbookSnapshotId} publishShapshot={runbookContext.actions.publishSnapshot}/>;
}
