import { css } from "@emotion/css";
import { RadioButtonGroup, RadioButton, Checkbox } from "@octopusdeploy/design-system-components";
import { space, text } from "@octopusdeploy/design-system-tokens";
import React from "react";
export interface OverrideoverrideFontProps {
    selectedFont: string;
    setSelectedFont: (fontValue: string) => void;
    updateFontFamilyOnBody: boolean;
    setUpdateFontFamilyOnBody: (changeFontFamilyOnBody: boolean) => void;
}
const fontOptions = [
    { label: "Default", value: "" },
    // Can remove when we aren't using roboto anymore
    { label: "Roboto", value: "Roboto" },
    { label: "BlinkMacSystemFont", value: "BlinkMacSystemFont" },
    { label: "SF Pro", value: "'SF Pro'" },
    { label: "-apple-system", value: "-apple-system" },
    { label: "Segoe UI", value: "'Segoe UI'" },
    { label: "Oxygen Sans", value: "'Oxygen Sans'" },
    { label: "Oxygen", value: "'Oxygen'" },
    { label: "Ubuntu", value: "Ubuntu" },
    { label: "sans-serif", value: "sans-serif" },
    { label: "Papyrus", value: "Papyrus" },
    { label: "Wingdings", value: "Wingdings" },
];
export function OverrideFont(overrideFontProps: OverrideoverrideFontProps) {
    const { selectedFont, setSelectedFont, updateFontFamilyOnBody, setUpdateFontFamilyOnBody } = overrideFontProps;
    return (<fieldset className={overrideFontFieldset}>
            <legend>
                <h2 className={overrideFontHeading}>Override Font</h2>
            </legend>
            <p className={overrideFontInformation}>This feature allows you to override the font for the page. Unless you are setting a web safe font (e.g. Papyrus) you will need the font installed on your OS.</p>

            <Checkbox label="Include overriding font on the body element" note="Uncheck this if you want to see where we rely on the CSS Cascade from body for defining the Font Family." value={updateFontFamilyOnBody} onChange={(toggleVBodyFontFamily) => setUpdateFontFamilyOnBody(toggleVBodyFontFamily)}/>

            <RadioButtonGroup value={selectedFont} onChange={(newFontValue) => setSelectedFont(newFontValue)} title="Select a Font">
                {fontOptions.map((option) => (<RadioButton label={option.label} value={option.value} key={option.label}/>))}
            </RadioButtonGroup>
        </fieldset>);
}
const overrideFontFieldset = css({
    font: text.regular.default.xSmall,
    border: "none",
    marginBottom: `${space[24]}`,
});
const overrideFontHeading = css({
    font: text.heading.medium,
});
const overrideFontInformation = css({
    marginTop: 0,
    font: text.regular.default.xSmall,
});
